import { Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { Link } from 'components/common/Link';
import { createSubset } from 'services/site';
import { useRouteData } from 'context/RouteDataProvider';
import { BOTTOM_LINKS } from './constants';
import { FooterLink } from './FooterLink';
import { SocialLinks } from './SocialLinks';
import { Divider } from './Divider';
import type { GroupLink } from './types';
import styles from './MiscLinks.module.scss';

const MiscLinks = () => {
  const { t } = useTranslation();
  const { market, pages } = useRouteData();

  return (
    <>
      {market.id === 'poland' && (
        <div className={styles.linksWrapper}>
          <ul className={styles.links}>
            <li>
              <Text variant="s" textColor="light">
                <Link to="/historia-pojazdu" className={styles.link}>
                  Historia pojazdu
                </Link>
              </Text>
            </li>
          </ul>

          <Divider spaceSize="m" />
        </div>
      )}

      <Stack
        gap={{ mobileUp: 6, tabletLandscapeUp: 3 }}
        type="horizontal"
        crossAxisAlign="center"
        mainAxisAlign="spaceBetween"
        className={styles.linksWrapper}
      >
        <SocialLinks />

        <ul className={styles.links}>
          {(createSubset(BOTTOM_LINKS) as GroupLink[]).map(
            ({ label, labelKey, page, pageId, url }) => {
              const pageInfo = page || pages[pageId];
              if (!pageInfo && !url) {
                return null;
              }

              return (
                <li key={pageId}>
                  <FooterLink
                    label={labelKey ? t(labelKey) : label}
                    pageId={pageId}
                    pageInfo={pageInfo}
                    url={url}
                  />
                </li>
              );
            },
          )}
        </ul>
      </Stack>
    </>
  );
};

export { MiscLinks };
