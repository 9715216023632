import { find } from 'lodash';
import { z } from 'zod';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';
import { emailSchema } from 'modules/shared/schemas';
import type { SupportSubject } from 'types/content';
import type { Market } from 'types/market';
import { validateIdentifier } from 'modules/shared/utils';
import { VIN_SUBJECTS, SUBJECTS } from './constants';

const getSubject = (subject: SupportSubject) =>
  subject ? find(SUBJECTS, { id: subject }) : undefined;

const validateCompanyName = ({
  companyName,
  subject,
}: {
  companyName?: string;
  subject: SupportSubject;
}) => {
  const selectedSubject = getSubject(subject);
  const businessCooperationSelected = selectedSubject?.id === 'business/2';
  return !businessCooperationSelected || !!companyName;
};

const getSupportFormSchema = (identifier: Market['identifier'], privacyCheckboxShown = false) =>
  z
    .object({
      email: emailSchema,
      message: z.string().min(1, { message: ERROR_TRANSLATION_KEYS.required }),
      companyName: z.string().optional(),
      subject: z.union([
        z.literal('no-report'),
        z.literal('accounting'),
        z.literal('business/2'),
        z.literal('claim'),
        z.literal('media'),
        z.literal('influencers'),
        z.literal('other'),
        z.literal('test'),
      ]),
      mainActivity: z.string().optional(),
      vin: z.string().optional(),
      privacy: z.boolean().optional(),
    })
    .refine(validateCompanyName, {
      message: ERROR_TRANSLATION_KEYS.required,
      path: ['companyName'],
    })
    .refine(({ privacy }) => !privacyCheckboxShown || privacy, {
      message: ERROR_TRANSLATION_KEYS.required,
      path: ['privacy'],
    })
    .superRefine(
      ({ vin, subject }, refinementContext) =>
        VIN_SUBJECTS.includes(subject) &&
        validateIdentifier({ value: vin, refinementContext, identifier }),
    );

type SupportFormFields = z.infer<ReturnType<typeof getSupportFormSchema>>;

export { getSupportFormSchema };
export type { SupportFormFields };
