import PropTypes from 'prop-types';
import { startsWith } from 'lodash';
import classnames from 'classnames';
import styles from './asIcon.module.scss';

// This component is used as a HOC through `icon-loader`. See `next.config.js`.

export default function asIcon(Svg) {
  const Icon = ({ variant, color, original, className, style, ...otherProps }) => (
    <span
      className={classnames(styles.root, styles[variant], original && styles.original, className)}
      style={{ color, ...style }}
    >
      {startsWith(variant, 'round') && (
        <span className={styles.roundWrapper}>
          <Svg {...otherProps} />
        </span>
      )}
      {!variant && <Svg {...otherProps} />}
    </span>
  );

  Icon.propTypes = {
    variant: PropTypes.oneOf(['round', 'roundSolid']),
    color: PropTypes.string,
    original: PropTypes.bool, // do not modify SVG colors in any way
    className: PropTypes.string,
    style: PropTypes.shape(),
  };
  Icon.defaultProps = {
    variant: undefined,
    color: undefined,
    original: false,
    className: undefined,
    style: undefined,
  };

  return Icon;
}
