import AuthContainer from './AuthContainer';
import UserContainer from './UserContainer';
import PrecheckContainer from './PrecheckContainer';
import ReportContainer from './ReportContainer';

export default [
  new AuthContainer(),
  new UserContainer(),
  new PrecheckContainer(),
  new ReportContainer(),
];
