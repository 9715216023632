import { ExternalLink, Text } from '@carvertical/ui';
import { Link } from 'components/common/Link';
import { useRouteData } from 'context/RouteDataProvider';
import { isExistingMarket } from 'utils/market';
import type { MarketId } from 'types/market';
import type { GroupPage } from './types';
import styles from './FooterLink.module.scss';

type LinkComponent = typeof Link | typeof ExternalLink | 'a';

type FooterLinkProps = {
  pageId: string;
  pageInfo: GroupPage;
  label?: string;
  url?: string;
  className?: string;
};

type ExternalPage = {
  id: string;
  excludedMarkets: MarketId[];
};

const EXTERNAL_PAGES: ExternalPage[] = [
  { id: 'informationSecurityPolicy', excludedMarkets: ['germany'] },
];

const FooterLink = ({
  className,
  label: labelFromProps,
  pageId,
  pageInfo,
  url,
}: FooterLinkProps) => {
  const { market } = useRouteData();

  const externalPage = EXTERNAL_PAGES.find((page) => page.id === pageId);
  const excludedExternalPageMarket = isExistingMarket({
    markets: externalPage?.excludedMarkets || [],
    market,
  });

  let label = labelFromProps;

  if (!label) {
    label = pageInfo?.title;
  }

  let LinkComponent: LinkComponent = url ? ExternalLink : Link;
  if (pageId && externalPage?.id && !excludedExternalPageMarket) {
    LinkComponent = 'a';
  }

  return (
    <Text variant="s" className={className}>
      <LinkComponent href={url || pageInfo.path} className={styles.innerLink}>
        {label}
      </LinkComponent>
    </Text>
  );
};

export { FooterLink };
export type { FooterLinkProps };
