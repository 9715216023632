import { isString, isNaN } from 'lodash';
import format from 'date-fns/format';

const YEAR_FORMAT = 'YYYY';
const YEAR_MONTH_FORMAT = `${YEAR_FORMAT}-MM`;
const DATE_FORMAT = `${YEAR_FORMAT}-MM-DD`;
const DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`;
const TZ_PATTERNS = [
  /(Z)$/, // Z
  /([+-])(\d{2})$/, // ±hh
  /([+-])(\d{2}):?(\d{2})$/, // ±hh:mm or ±hhmm
  /(UTC|(?:[a-zA-Z]+\/[a-zA-Z_]+(?:\/[a-zA-Z_]+)?))$/, // IANA time zone
];

function formatDateTime(date, { withTimeZone = false } = {}) {
  return format(utc(date), `${DATE_TIME_FORMAT}${withTimeZone ? ` UTCZ` : ''}`);
}

function formatLocalDateTime(date) {
  return format(date, DATE_TIME_FORMAT);
}

function formatDate(date) {
  return format(utc(date), DATE_FORMAT);
}

function formatYearMonth(date) {
  return format(utc(date), YEAR_MONTH_FORMAT);
}

function formatYear(date) {
  if (Number(date) && String(Number(date)).length === 4) {
    return String(date);
  }
  return format(utc(date), YEAR_FORMAT);
}

function utc(date = new Date()) {
  if (isString(date)) {
    try {
      date = new Date(date.replace(/\+0000$/, 'Z'));
    } catch (err) {
      try {
        const ts = date.split(/[^\d]/).map(Number);
        ts[1] -= 1; // zero-indexed month
        date = new Date(...ts);
      } catch (err) {
        date = new Date('Invalid Date');
      }
    }
  }
  const isInvalidDate = isNaN(date.getTime());
  if (!isInvalidDate && TZ_PATTERNS.some((pattern) => pattern.exec(date.toISOString()))) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 6e4);
  }
  return date;
}

export { formatDateTime, formatLocalDateTime, formatDate, formatYearMonth, formatYear, utc };
