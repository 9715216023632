import { useSupportPopupStore } from './useSupportPopupStore';

const useSupportPopup = () => {
  const hidden = useSupportPopupStore((state) => state.hidden);
  const open = useSupportPopupStore((state) => state.open);
  const subject = useSupportPopupStore((state) => state.subject);
  const closeSupportPopup = useSupportPopupStore((state) => state.closePopup);
  const openSupportPopup = useSupportPopupStore((state) => state.openPopup);
  const hidePopup = useSupportPopupStore((state) => state.hidePopup);
  const showPopup = useSupportPopupStore((state) => state.showPopup);

  return {
    hidden,
    open,
    subject,
    hidePopup,
    showPopup,
    closeSupportPopup,
    openSupportPopup,
  };
};

export { useSupportPopup };
