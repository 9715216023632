import { useState, useEffect } from 'react';
import BaseLink, { type LinkProps as BaseLinkProps } from 'next/link';
import { useRouter } from 'next/router';
import cx from 'classnames';
import { getLinkProps, removeBasePath } from 'utils/router';
import RouteData from 'components/RouteData';
import type { AnchorProps } from 'types/component';
import { InnerLink } from './InnerLink';

type LinkProps = {
  className?: string;
  to?: string;
  href?: string;
  activeClassName?: string;
  pattern?: string;
} & Omit<BaseLinkProps, 'href'> &
  AnchorProps;

const Link = ({
  activeClassName,
  className,
  href,
  pattern,
  scroll,
  shallow,
  to,
  ...otherProps
}: LinkProps) => {
  const { asPath } = useRouter();
  const [isActive, setIsActive] = useState(false);

  const finalHref = href || to;

  useEffect(() => {
    if (!activeClassName) {
      return;
    }

    setIsActive(removeBasePath(window.location.pathname) === finalHref);
  }, [activeClassName, finalHref, asPath]);

  return (
    <RouteData>
      {({ basePath }) => (
        <BaseLink
          {...getLinkProps(finalHref, pattern, basePath)}
          shallow={shallow}
          passHref
          legacyBehavior
          scroll={scroll}
        >
          <InnerLink
            className={cx(className, isActive && activeClassName)}
            data-testid="Link-innerLink"
            {...otherProps}
          />
        </BaseLink>
      )}
    </RouteData>
  );
};

export { Link };
export type { LinkProps };
