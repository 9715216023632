import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { Text } from '@carvertical/ui';
import { useRouteData } from 'context/RouteDataProvider';
import { NS_HOME } from 'constants/i18n';
import PARTNERS from 'constants/partners';
import { TrustBannerLogos } from './TrustBannerLogos';
import styles from './TrustBanner.module.scss';

type TrustBannerProps = {
  inverted?: boolean;
  color?: 'white' | 'black';
  leftAligned?: boolean;
  textShown?: boolean;
  text?: React.ReactNode;
  logosShown?: boolean;
  className?: string;
  textClassName?: string;
  listClassName?: string;
  logoClassName?: string;
};

const TrustBanner = ({
  className,
  color = 'black',
  inverted = false,
  leftAligned = false,
  listClassName,
  logoClassName,
  logosShown = true,
  text,
  textClassName,
  textShown = true,
}: TrustBannerProps) => {
  const { t } = useTranslation(NS_HOME);
  const { market, page } = useRouteData();
  const { id } = page || {};
  const logos = PARTNERS.MARKET_LOGOS[market.id] || PARTNERS.MARKET_LOGOS.default;

  return (
    <div
      className={cx(
        styles.root,
        styles[color],
        {
          [styles.inverted]: inverted,
          [styles.leftAligned]: leftAligned,
        },
        className,
      )}
    >
      {textShown &&
        (text || (
          <Text
            variant="xs"
            textColor={color === 'black' ? 'dark' : 'light'}
            className={textClassName}
          >
            {t('landing.trustLabel')}
          </Text>
        ))}

      {logosShown && (
        <ul className={cx(styles.logos, listClassName)}>
          <TrustBannerLogos logos={logos} logoClassName={cx(styles.logoImage, logoClassName)} />

          <TrustBannerLogos
            logos={PARTNERS.PAGE_LOGOS[id!] || []}
            logoClassName={cx(styles.logoImage, logoClassName)}
          />
        </ul>
      )}
    </div>
  );
};

export { TrustBanner };
