import { Trans } from 'next-i18next';
import { MessagePage } from '../common/MessagePage';
import NeedsRefresh from '../common/NeedsRefresh';

const AppError = () => (
  <MessagePage error>
    <Trans
      parent="p"
      i18nKey="errorPage.title"
      components={{
        b: <strong />,
      }}
    >
      We’re sorry, but <b>something went wrong</b>. Our team has been notified about this.
    </Trans>
    <NeedsRefresh />
  </MessagePage>
);

export default AppError;
