import { compact } from 'lodash';
import type { ParseKeys } from 'i18next';
import { enabled as secretFeaturesEnabled } from 'services/features';
import type { SupportSubject } from 'types/content';

const VIN_SUBJECTS: Partial<SupportSubject>[] = ['no-report', 'accounting', 'claim'];

const SUBJECTS: { id: SupportSubject; labelKey: ParseKeys; vin?: boolean }[] = compact([
  {
    id: 'no-report',
    labelKey: 'supportModal.subjects.no-report',
    vin: true,
  },
  {
    id: 'accounting',
    labelKey: 'supportModal.subjects.accounting',
    vin: true,
  },
  {
    id: 'business/2',
    labelKey: 'supportModal.subjects.businessGeneral',
  },
  {
    id: 'claim',
    labelKey: 'supportModal.subjects.claim',
    vin: true,
  },
  {
    id: 'media',
    labelKey: 'supportModal.subjects.media',
  },
  {
    id: 'influencers',
    labelKey: 'supportModal.subjects.influencers',
  },
  {
    id: 'other',
    labelKey: 'supportModal.subjects.other',
    vin: false,
  },
  secretFeaturesEnabled() && {
    id: 'test',
    labelKey: 'Test',
    vin: false,
  },
]);

export { VIN_SUBJECTS, SUBJECTS };
