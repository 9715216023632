import { j as n } from "./chunks/jsx-runtime.82a9ddf6.js";
import { j as a } from "./chunks/styles.9b2ce419.js";
import { IconButton as r } from "./iconButton.mjs";
import "react";
import "./chunks/clsx.0d472180.js";
import "./chunks/cn.2ea4f521.js";
import "./icon.mjs";
import "./chunks/warnings.8f6035a3.js";
import "./anchorOrButton.mjs";
const t = ({ ...e }) => /* @__PURE__ */ n.jsx(r, { className: a.root, size: "xs", variant: "transparent", ...e });
try {
  t.displayName = "TextFieldIconButton", t.__docgenInfo = { description: "", displayName: "TextFieldIconButton", props: { label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "string" } }, icon: { defaultValue: null, description: "", name: "icon", required: !0, type: { name: "IconType" } }, as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, iconClassName: { defaultValue: null, description: "", name: "iconClassName", required: !1, type: { name: "string" } } } };
} catch {
}
export {
  t as TextFieldIconButton
};
