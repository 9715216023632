const LIST_IDS_BY_LOCALES: Record<string, string> = {
  ro: 'Ssq5TW',
  pl: 'UcdHdy',
  lt: 'STnBiN',
  hu: 'SdK3tA',
  hr: 'WW6Tip',
  sk: 'TVvs7z',
  es: 'TusEg5',
  lv: 'Vi53Rg',
  pt: 'V7WdvZ',
  fi: 'VGC5xS',
  et: 'SDyQKM',
  bg: 'WV7EFv',
  'es-MX': 'RrEvEw',
  'el-GR': 'ThYgGB',
  'en-GB': 'U2756d',
  'en-EU': 'U2756d',
  en: 'U2756d',
  'es-US': 'U2756d',
  'en-AU': 'U2756d',
  'ru-UA': 'Sa39J2',
  'ru-LT': 'Sa39J2',
  'ru-LV': 'Sa39J2',
  'ru-EE': 'Sa39J2',
  fr: 'U2756d',
  'fr-BE': 'U2756d',
  'fr-CH': 'U2756d',
  se: 'Y6aKvV',
  sv: 'Y6aKvV',
  'sv-FI': 'Y6aKvV',
  it: 'TwTXzr',
  'it-CH': 'TwTXzr',
  uk: 'YbYvW3',
  ua: 'YbYvW3',
  de: 'SDVd3N',
  'de-CH': 'SDVd3N',
  sr: 'RqeKym',
  rs: 'RqeKym',
  cs: 'WnuCnP',
  cz: 'WnuCnP',
  sl: 'XdRbTu',
  si: 'XdRbTu',
  'nl-BE': 'WjaC7B',
  be: 'WjaC7B',
  da: 'W4yR3B',
  dk: 'W4yR3B',
};

const NON_PROD_LIST_ID = 'WKXpKH';

export { LIST_IDS_BY_LOCALES, NON_PROD_LIST_ID };
