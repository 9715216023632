import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Provider as UnstatedProvider } from 'unstated';
import { ErrorBoundary } from '@sentry/nextjs';
import { appWithTranslation } from 'next-i18next';
import { MotionConfig } from 'framer-motion';
import { LazySuggestCorrectMarket } from 'modules/shared';
import { patchHistoryApi } from 'utils/history';
import bus from 'services/bus';
import { registerCountries } from 'services/countries';
import AppProviders from 'context';
import { RouteDataProvider } from 'context/RouteDataProvider';
import containers from 'containers';
import { SupportToggle, SupportPopup } from 'modules/support/components';
import nextI18NextConfig from '../../../next-i18next.config';
import AppError from './AppError';
import AuthHandler from './AuthHandler';
import SiteProvider from './SiteProvider';
import Head from './Head';
import { MetaData } from './MetaData';
import ThirdPartyScriptLoader from './ThirdPartyScriptLoader';
import { ThirdPartyEventHandler } from './ThirdPartyEventHandler';
import WebFontLoader from './WebFontLoader';
import PageLoadProgress from './PageLoadProgress';
import UpdateTracker from './UpdateTracker';
import { LandingParamsProvider } from './LandingParamsProvider';

patchHistoryApi();

const listenToPrint = () => {
  if (window.matchMedia) {
    window.matchMedia('print').addListener((event) => {
      if (event.matches) {
        beforePrint();
      }
    });
  }
  window.onbeforeprint = beforePrint;

  function beforePrint() {
    bus.emit('print');
  }
};

const App = ({ Component, pageProps }) => {
  useEffect(() => {
    listenToPrint();
  }, []);

  registerCountries(pageProps.countries);

  return (
    <ErrorBoundary fallback={<AppError />}>
      <UnstatedProvider inject={containers}>
        <MotionConfig reducedMotion="user">
          <AppProviders>
            <RouteDataProvider value={pageProps}>
              <SiteProvider>
                <AuthHandler>
                  <UpdateTracker>
                    <>
                      <Head />
                      <MetaData />
                      <Component />
                      <LandingParamsProvider />
                      <ThirdPartyScriptLoader />
                      <ThirdPartyEventHandler />
                      <WebFontLoader />
                      <PageLoadProgress />
                      <LazySuggestCorrectMarket />
                      <SupportPopup />
                      <SupportToggle />
                    </>
                  </UpdateTracker>
                </AuthHandler>
              </SiteProvider>
            </RouteDataProvider>
          </AppProviders>
        </MotionConfig>
      </UnstatedProvider>
    </ErrorBoundary>
  );
};

App.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape().isRequired,
};

export default appWithTranslation(App, nextI18NextConfig);
