import { fromPairs } from 'lodash';
import { supportApi } from './base';

type GetMarketValuationParams = {
  vin?: string;
  make?: string;
  model?: string;
  year?: number;
  fuel?: string;
  transmission?: string;
  power?: number;
  odometer?: number;
};
const getMarketValuation = async (params: GetMarketValuationParams) => {
  type Liquidity = { country: string; overall?: { soldInDays: number } };

  const {
    data: { liquidity, pricing },
  } = await supportApi.get('/vehicle-valuation/statistical', { params });

  const getLiquidityDays = ({ overall }: Liquidity) => Math.round(overall?.soldInDays ?? 0);

  return {
    pricing: {
      global: pricing.global,
      ...pricing.market,
    },
    liquidity: {
      global: getLiquidityDays(liquidity.global),
      ...fromPairs(
        liquidity.market
          .map((marketLiquidity: Liquidity) => [
            marketLiquidity.country,
            getLiquidityDays(marketLiquidity),
          ])
          .filter(([, value]: [string, number]) => value > 0),
      ),
    },
  };
};

export { getMarketValuation };
export type { GetMarketValuationParams };
