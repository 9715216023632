import { Container, Heading, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';
import { Section } from 'components/landings/common/Section';
import { IdentifierForm } from 'components/common/IdentifierForm';
import { TrustBanner } from 'components/landings/common/TrustBanner';

type CompactCallToActionSectionProps = { className?: string };

const CompactCallToActionSection = ({ className }: CompactCallToActionSectionProps) => {
  const { t } = useTranslation('home');

  const renderTitle = () => (
    <div className="flex flex-col gap-2">
      <Heading as="h2" variant="m" className="text-center" textColor="light">
        {t('landing.historyReportTitle')}
      </Heading>

      <Text as="p" variant="m" className="text-center" textColor="light">
        {t('landing.ctaSubtitle')}
      </Text>
    </div>
  );

  const renderIdentifierForm = () => <IdentifierForm surroundingBackground="dark" />;

  const renderTrustBanner = () => <TrustBanner color="white" />;

  return (
    <>
      <Section className={cn('lg:hidden', className)} color="colorBlue">
        <Container>
          <div className="flex flex-col items-center gap-6">
            {renderTitle()}

            {renderIdentifierForm()}

            {renderTrustBanner()}
          </div>
        </Container>
      </Section>

      <section className={cn('hidden pb-15 pt-5 lg:flex', className)}>
        <Container className="w-full">
          <div className="grid grid-cols-2 gap-6 rounded-m bg-blue px-13 pb-5 pt-6">
            <div className="flex flex-col gap-4">
              {renderTitle()}

              {renderTrustBanner()}
            </div>

            <div className="mx-auto flex items-center">{renderIdentifierForm()}</div>
          </div>
        </Container>
      </section>
    </>
  );
};

export { CompactCallToActionSection };
