import PropTypes from 'prop-types';
import { useState } from 'react';
import { useMount, useMedia } from 'react-use';
import Image from 'next/image';
import { createMediaQuery } from '../../utils/styles';
import {
  tabletPortraitBreakpoint,
  tabletLandscapeBreakpoint,
  desktopBreakpoint,
  bigDesktopBreakpoint,
} from '../../styles/variables.module.scss';
import styles from './ArtDirectedImage.module.scss';

const ArtDirectedImage = ({
  width,
  src,
  className,
  height,
  srcSet: { forTabletPortraitUp, forTabletLandscapeUp, forDesktopUp, forDesktopBigUp },
  alt,
  fill,
  sizes,
  quality,
  priority,
  onLoadingComplete,
  onError,
}) => {
  const [isClient, setIsClient] = useState(false);

  const images = [
    {
      matches: useMedia(createMediaQuery(bigDesktopBreakpoint), false),
      image: forDesktopBigUp,
    },
    {
      matches: useMedia(createMediaQuery(desktopBreakpoint), false),
      image: forDesktopUp,
    },
    {
      matches: useMedia(createMediaQuery(tabletLandscapeBreakpoint), false),
      image: forTabletLandscapeUp,
    },
    {
      matches: useMedia(createMediaQuery(tabletPortraitBreakpoint), false),
      image: forTabletPortraitUp,
    },
    {
      defaultImage: true,
      matches: !!src,
      image: { src, width, height, fill, sizes },
    },
  ];

  const { image: initialImage } =
    images.find(({ defaultImage, image }) => defaultImage && image) || {};
  const { image: clientImage } = images.find(({ matches, image }) => matches && image) || {};

  useMount(() => setIsClient(true));

  // We are rendering a stable initial image to prevent hydration mismatch.
  // Only when the component is mounted on the client, we are rendering the
  // correct image that matches the media query.
  const image = isClient ? clientImage : initialImage;

  const imageElement = (
    <Image
      onLoadingComplete={onLoadingComplete}
      onError={onError}
      src={image.src}
      alt={alt}
      fill={image.fill}
      sizes={image.sizes}
      quality={quality}
      className={className}
      priority={priority}
      {...(!image.fill && {
        width: image.width,
        height: image.height,
      })}
    />
  );

  if (isClient) {
    return imageElement;
  }

  return (
    <div style={{ width: image.width, height: image.height }}>
      <span className={styles.hidden}>{imageElement}</span>
    </div>
  );
};

ArtDirectedImage.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  srcSet: PropTypes.shape({
    forTabletPortraitUp: PropTypes.shape(),
    forTabletLandscapeUp: PropTypes.shape(),
    forDesktopUp: PropTypes.shape(),
  }),
  alt: PropTypes.string,
  fill: PropTypes.bool,
  sizes: PropTypes.string,
  quality: PropTypes.number,
  priority: PropTypes.bool,
  onLoadingComplete: PropTypes.func,
  onError: PropTypes.func,
};

ArtDirectedImage.defaultProps = {
  src: undefined,
  className: undefined,
  width: undefined,
  height: undefined,
  srcSet: {},
  alt: '',
  fill: false,
  sizes: undefined,
  quality: undefined,
  priority: false,
  onLoadingComplete: undefined,
  onError: undefined,
};

export default ArtDirectedImage;
