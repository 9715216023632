import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { useFeatureFlagEnabled } from './useFeatureFlagEnabled';
import type { FeatureFlag, FeatureFlagPayload } from './types';

type FeatureFlagKeyPayload = FeatureFlagPayload[keyof FeatureFlagPayload];

const useFeatureFlagPayload = (key: FeatureFlag) => {
  const posthog = usePostHog();
  const payloadEnabled = useFeatureFlagEnabled(key);

  const [flagPayload, setFlagPayload] = useState(
    posthog.getFeatureFlagPayload(key) as FeatureFlagKeyPayload,
  );

  useEffect(() => {
    const removeHandler: () => void = posthog.onFeatureFlags(() => {
      const payload = posthog.getFeatureFlagPayload(key);
      if (!payloadEnabled) {
        return;
      }

      setFlagPayload(
        typeof payload === 'string' ? JSON.parse(payload.replace(/'/g, '"')) : payload,
      );
    });

    return removeHandler;
  }, [posthog, key, payloadEnabled]);

  return flagPayload;
};

export { useFeatureFlagPayload };
