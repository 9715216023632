import { useMemo } from 'react';
import Router, { useRouter } from 'next/router';
import qs from 'querystringify';
import parseUrl from 'url-parse';
import getHocName from '../utils/getHocName';
import { getRoute } from '../utils/router';
import { getBasePath } from '../services/site';

const parseHash = (hash) => qs.parse(hash.slice(1));

const createHistoryMethod =
  (methodName) =>
  async (to, opts = {}) =>
    Router[methodName](...getRoute(to, undefined, getBasePath()), opts);

export const history = {
  push: createHistoryMethod('push'),
  replace: createHistoryMethod('replace'),
};

export default function withRouter(WrappedComponent) {
  const WithRouter = (props) => {
    const { asPath: pathname } = useRouter();

    const router = useMemo(() => {
      const parsedPath = parseUrl(pathname);
      const location = {
        pathname: parsedPath.pathname,
        search: parsedPath.query,
        hash: parsedPath.hash,
      };
      const parsedQuery = qs.parse(location.search);
      return {
        history: {
          ...history,
          pushHash: (hash, opts) =>
            history.push(`${location.pathname}${location.search}#${hash}`, opts),
          replaceQuery: (updater, opts) =>
            history.replace(
              location.pathname + qs.stringify(updater({ ...parsedQuery }), true) + location.hash,
              opts,
            ),
        },
        location,
        hash: parseHash(location.hash),
        query: parsedQuery,
      };
    }, [pathname]);

    return <WrappedComponent router={router} {...props} />;
  };

  WithRouter.displayName = getHocName('withFriendlyRouter', WrappedComponent);

  return WithRouter;
}
