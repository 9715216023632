import { useEffect, useState } from 'react';

const CRAWLER_REGEXP = /\bgooglebot|bingbot\b/i;

const useCrawlerDetect = () => {
  const [detected, setDetected] = useState(false);

  useEffect(() => {
    setDetected(!!navigator.userAgent.match(CRAWLER_REGEXP));
  }, []);

  return detected;
};

export { useCrawlerDetect };
