import loadScript from 'load-script';

const scripts = {};

export async function load(url, id, attrs = {}) {
  if (scripts[id]) {
    return scripts[id];
  }
  scripts[id] = new Promise((resolve, reject) => {
    loadScript(url, { attrs: { id, ...attrs } }, (err) => {
      if (err) {
        const node = document.getElementById(id);
        node?.parentNode.removeChild(node);
        reject(err);
        return;
      }
      resolve();
    });
  });
  return scripts[id];
}
