import PropTypes from 'prop-types';
import { createContext, useEffect, useState, useContext } from 'react';

const UserInteractionContext = createContext();

const EVENTS = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel'];
const FALLBACK_TIMEOUT = 10 * 1000;

const UserInteractionProvider = ({ children }) => {
  const [userInteraction, setUserInteraction] = useState(false);

  useEffect(() => {
    const fallbackTimeout = setTimeout(triggerInteraction, FALLBACK_TIMEOUT);

    EVENTS.forEach((event) => {
      window.addEventListener(event, triggerInteraction);
    });

    function triggerInteraction() {
      unsubscribe();
      setUserInteraction(true);
    }

    function unsubscribe() {
      clearTimeout(fallbackTimeout);

      EVENTS.forEach((event) => {
        window.removeEventListener(event, triggerInteraction);
      });
    }

    return unsubscribe;
  }, []);

  return (
    <UserInteractionContext.Provider value={userInteraction}>
      {children}
    </UserInteractionContext.Provider>
  );
};

UserInteractionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const useUserInteraction = () => useContext(UserInteractionContext);

export default UserInteractionProvider;
export { UserInteractionContext, useUserInteraction };
