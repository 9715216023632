import { j as c } from "./jsx-runtime.82a9ddf6.js";
import { createContext as i, useState as l, useMemo as u, useId as d, useLayoutEffect as p, useContext as y } from "react";
const n = i(null), a = ({ children: t }) => {
  const [r, o] = l(), e = u(() => ({ setTitleId: o, titleId: r }), [o, r]);
  return /* @__PURE__ */ c.jsx(n.Provider, { value: e, children: t == null ? void 0 : t(e) });
}, _ = () => y(n), s = ({ setAutomatically: t = !0 } = {}) => {
  const r = d(), o = _(), e = o == null ? void 0 : o.setTitleId;
  return p(() => (t && (e == null || e(r)), () => e == null ? void 0 : e(void 0)), [r, e, t]), r;
};
try {
  a.displayName = "OverlayProvider", a.__docgenInfo = { description: "", displayName: "OverlayProvider", props: {} };
} catch {
}
try {
  s.displayName = "useOverlayTitleId", s.__docgenInfo = { description: "", displayName: "useOverlayTitleId", props: { setAutomatically: { defaultValue: { value: !0 }, description: "", name: "setAutomatically", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  a as O,
  s as u
};
