import { forwardRef } from 'react';
import { beautifyPath } from 'utils/router';
import type { AnchorProps } from 'types/component';

const InnerLink = forwardRef<HTMLAnchorElement, AnchorProps>(({ href, ...otherProps }, ref) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content
  <a href={beautifyPath(href)} ref={ref} {...otherProps} />
));

export { InnerLink };
