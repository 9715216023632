import cx from 'classnames';

type SidebarProps = {
  as?: React.ElementType;
  className?: string;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLDivElement>;

const Sidebar = ({ as: RenderAs = 'div', className, children, ...props }: SidebarProps) => (
  <RenderAs
    className={cx(
      'md:w-35 col-start-1 col-end-2 h-full w-full overflow-hidden border-0 border-r border-solid border-r-grey-200 bg-white py-4',
      className,
    )}
    {...props}
  >
    {children}
  </RenderAs>
);

export { Sidebar };
export type { SidebarProps };
