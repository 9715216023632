import { AnimatePresence, motion } from 'framer-motion';
import { Text } from '@carvertical/ui';
import styles from './IdentifierErrorMessage.module.scss';

type IdentifierErrorMessageProps = {
  message?: string;
  className?: string;
};

const ANIMATION_VARIANTS = {
  hidden: { opacity: 0, height: 0 },
  visible: { opacity: 1, height: 'auto' },
};

const IdentifierErrorMessage = ({ className, message }: IdentifierErrorMessageProps) => (
  <div className={className}>
    <AnimatePresence>
      {message && (
        <motion.div
          className={styles.message}
          variants={ANIMATION_VARIANTS}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          <Text align="left" variant="s+" textColor="inherited">
            {message}
          </Text>
        </motion.div>
      )}
    </AnimatePresence>
  </div>
);

export { IdentifierErrorMessage };
