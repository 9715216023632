import { useEffect } from 'react';
import { find } from 'lodash';
import addDays from 'date-fns/add_days';
import { useRouteData } from 'context/RouteDataProvider';
import { markets } from 'config-ts';
import store from 'services/store';
import type { Market, OptionalMarketIdRecord } from 'types/market';
import { useModal, useGeoData } from '../../../hooks';
import { useMarketSwitcher } from '../../MarketSwitcher/hooks';
import {
  useModalShown,
  useSuggestCorrectMarketActions,
  useSuggestedMarket,
  useType,
} from './useSuggestCorrectMarketStore';

const PREVENT_MARKET_SUGGESTION_DAYS = 30;
const PREVENT_SUGGEST_MARKET_NAME = 'suggestMarketDismissed';
const SUGGESTED_MARKET_OVERRIDES: OptionalMarketIdRecord<Market> = {
  germany: markets.switzerland,
};

const useSuggestCorrectMarket = () => {
  const { market } = useRouteData();
  const { setLanguageModalType } = useMarketSwitcher();
  const { open: openSuggestCheckoutModal } = useModal('suggestCheckout');
  const {
    suggestMarket,
    toggleModalShown,
    initCheckoutMarketSuggestion,
    selectMarket: selectMarketAction,
  } = useSuggestCorrectMarketActions();

  const type = useType();
  const geoData = useGeoData();
  const modalShown = useModalShown();
  const suggestedMarket = useSuggestedMarket();

  const storeMarketPreference = () => {
    store.set(PREVENT_SUGGEST_MARKET_NAME, '1', {
      ttl: addDays(new Date(), PREVENT_MARKET_SUGGESTION_DAYS),
    });
  };

  const suggestOnCheckout = (checkoutUrl: string, forced?: boolean) => {
    openSuggestCheckoutModal();
    setLanguageModalType('checkout');
    initCheckoutMarketSuggestion({ checkoutUrl, forced });
  };

  const selectMarket = (selectedMarket: Market) => selectMarketAction(selectedMarket);

  const confirmMarketSelection = (closeModal = true) => {
    if (type === 'location') {
      storeMarketPreference();
    }

    if (closeModal) {
      toggleModalShown(false);
    }
  };

  useEffect(() => {
    if (!geoData) {
      return;
    }

    const { countryCode, timezone } = geoData;
    const homeMarket = countryCode === market.countryCode;

    if (homeMarket) {
      // This might happen when market changes after login
      if (modalShown && type === 'location') {
        toggleModalShown(false);
      }
      return;
    }

    let marketSuggestion = find(
      markets,
      (singleMarket?: Market) =>
        !!singleMarket && !singleMarket.hidden && singleMarket.countryCode === countryCode,
    );

    if (!marketSuggestion) {
      const continent = (timezone || '').split('/')[0];
      if (continent === 'Europe' && market.countryCode !== markets.europe.countryCode) {
        marketSuggestion = markets.europe;
      } else if (continent === 'America' && market.countryCode !== markets.usa.countryCode) {
        marketSuggestion = markets.usa;
      } else {
        return;
      }
    }

    const overriddenMarket = SUGGESTED_MARKET_OVERRIDES[marketSuggestion.id];
    if (overriddenMarket?.id === market.id) {
      return;
    }

    suggestMarket(marketSuggestion);

    if (type === 'location') {
      toggleModalShown(true);
    }
  }, [geoData, market.countryCode, market.id, modalShown, suggestMarket, toggleModalShown, type]);

  return {
    market,
    confirmMarketSelection,
    selectMarket,
    suggestedMarket,
    storeMarketPreference,
    suggestOnCheckout,
  };
};

export { useSuggestCorrectMarket, PREVENT_SUGGEST_MARKET_NAME };
