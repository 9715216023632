import { values, camelCase, isPlainObject, isArray, get, compact, find, keyBy, omit } from 'lodash';
import { i18n } from 'next-i18next';
import { formatYear } from '../utils/date';
import { getCountryName } from '../services/countries';

const FIELDS = [
  {
    key: 'make',
    path: 'identification.make',
    meta: { icon: 'factory' },
  },
  {
    key: 'model',
    path: 'identification.model.name',
    meta: { icon: 'car' },
  },
  {
    key: 'manufacturerLabel',
    path: 'identification.model.manufacturerLabel',
  },
  {
    key: 'modelVariant',
    path: 'identification.model.variant',
    meta: { icon: 'car' },
  },
  {
    key: 'bodyType',
    path: 'specs.bodyType',
    meta: { icon: 'carSize' },
    format: (value) => i18n.t(`precheck:bodyTypes.${camelCase(value)}`),
  },
  {
    key: 'manufactureYear',
    path: 'identification.manufactureDate',
    meta: { icon: 'calendar' },
    format: formatYear,
  },
  {
    key: 'modelYear',
    path: 'identification.model.year',
    meta: { icon: 'calendar' },
    format: formatYear,
  },
  {
    key: 'powertrainDisplacement',
    path: 'powertrain.displacement',
    meta: { icon: 'engine' },
  },
  {
    key: 'powertrainPower',
    path: 'powertrain.power',
    meta: { icon: 'engineBelt' },
  },
  {
    key: 'fuelType',
    path: 'specs.fuelType',
    meta: { icon: 'fuel' },
    format: (value) => i18n.t(`precheck:fuelTypes.${camelCase(value)}`),
  },
  {
    key: 'transmissionType',
    path: 'specs.transmission.type',
    meta: { icon: 'gearbox' },
    format: (value) => i18n.t(`precheck:transmissionTypes.${camelCase(value)}`),
  },
  {
    key: 'transmissionDescription',
    path: 'specs.transmission.description',
    meta: { icon: 'gearbox' },
  },
  {
    key: 'driveType',
    path: 'specs.driveType',
    meta: { icon: 'car' },
    format: (value) => i18n.t(`report:driveTypes.${camelCase(value)}`),
  },
  {
    key: 'color',
    path: 'exterior.color.standardName',
    meta: { icon: 'car' },
    format: (value) => i18n.t(`report:colors.${camelCase(value)}`),
  },
  {
    key: 'plantLocation',
    path: 'identification.plant.location.countryCode',
    meta: { icon: 'globe' },
    format: (value) => getCountryName(value),
  },
  {
    key: 'numberOfDoors',
    path: 'exterior.numberOfDoors',
    meta: { icon: 'carSize' },
  },
  {
    key: 'steeringWheelPlacement',
    path: 'specs.steeringWheelPlacement',
    format: (value) => i18n.t(`report:steeringWheelPlacements.${camelCase(value)}`),
  },
  {
    key: 'numberOfCylinders',
    path: 'powertrain.numberOfCylinders',
    meta: { icon: 'engine' },
  },
  {
    key: 'numberOfGears',
    path: 'specs.transmission.numberOfGears',
    meta: { icon: 'gearbox' },
  },
  {
    key: 'engineDescription',
    path: 'powertrain.engineDescription',
    meta: { icon: 'engine' },
  },
  {
    key: 'modelMarket',
    path: 'identification.model.market',
    meta: { icon: 'globe' },
  },
  {
    key: 'transmissionName',
    path: 'specs.transmission.name',
    meta: { icon: 'gearbox' },
  },
  {
    key: 'numberOfSeats',
    path: 'equipment.numberOfSeats',
    meta: { icon: 'carSize' },
  },
  {
    key: 'modelYearRange',
    path: 'identification.model.yearRange',
    meta: { icon: 'calendar' },
    format: formatYearRange,
  },
  {
    key: 'modelVariantYearRange',
    path: 'identification.model.variantYearRange',
    meta: { icon: 'calendar' },
    format: formatYearRange,
  },
  {
    key: 'engineCode',
    path: 'powertrain.engineCode',
    meta: { icon: 'engine' },
  },
  {
    key: 'engineNumber',
    path: 'powertrain.engineNumber',
    meta: { icon: 'engine' },
  },
];
const FALLBACK_VALUE = '—';

function formatYearRange({ start, finish } = {}) {
  const years = [];
  if (start) {
    years.push(formatYear(start));
  }
  if (finish) {
    years.push(formatYear(finish));
  }
  return years.join('–');
}

class Spec {
  constructor(key, value, meta = {}) {
    this.key = key;
    this.value = value;
    this.meta = meta;
  }

  toString() {
    if (!this.value) {
      return FALLBACK_VALUE;
    }
    const { format } = find(FIELDS, { key: this.key }) || {};
    if (format) {
      return format(this.value);
    }
    if (isPlainObject(this.value)) {
      const { value, unit } = this.value;
      if (value && unit) {
        return `${value} ${unit}`;
      }
      return values(this.value).join(' ');
    }
    if (isArray(this.value)) {
      return this.value.join(', ');
    }
    return this.value;
  }
}

Spec.createAll = (record) => {
  const list = compact(
    FIELDS.map(({ key, path, meta }) => {
      const value = get(record, path);
      return value ? new Spec(key, value, meta) : null;
    }),
  );

  let keyedList = keyBy(list, 'key');
  if (keyedList.manufacturerLabel) {
    const modelKey = 'model';
    keyedList = {
      ...omit(keyedList, ['manufacturerLabel', 'modelVariant']),
      [modelKey]: new Spec(modelKey, keyedList.manufacturerLabel.value, keyedList[modelKey]?.meta),
    };
  }
  return keyedList;
};

export default Spec;
