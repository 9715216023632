import { z } from 'zod';
import { emailSchema } from 'modules/shared/schemas';

const SubscribeFormSchema = z.object({
  email: emailSchema,
});

type SubscribeFormFields = z.infer<typeof SubscribeFormSchema>;

export { SubscribeFormSchema };
export type { SubscribeFormFields };
