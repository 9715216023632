import { kebabCase, mapValues, reduce, toLower } from 'lodash';
import type { FeatureId, Feature, FeatureIllustration } from '../types';

const IMAGE_ROUTE = '/images/features/';
const FEATURE_KEYS: FeatureId[] = [
  'photos',
  'damage',
  'stolenVehicleCheck',
  'mileage',
  'makeModelDescription',
  'emissionTaxes',
  'valuation',
  'maintenance',
  'usage',
  'titleCheck',
  'safety',
  'registrationRestrictions',
  'financialRestrictions',
  'naturalDisasters',
  // TODO: Uncomment the following features when they are ready
  // 'ppsr',
  // 'timeline',
  // 'fines',
  // 'emissions',
];

const FEATURES = reduce(
  FEATURE_KEYS,
  (result, key) => {
    const feature: Feature = {
      id: key,
      titleKey: `features.${key}.title`,
      descriptionKey: `features.${key}.description`,
    };
    return { ...result, [key]: feature };
  },
  {},
) as Record<FeatureId, Feature>;

const getFeatureIllustration = (id: FeatureId, locale: string): FeatureIllustration => {
  const illustrations = mapValues(FEATURES, (_, key) => {
    const image = kebabCase(key);

    return {
      src: `${IMAGE_ROUTE}${image}-${toLower(locale)}.png`,
      fallbackSrc: `${IMAGE_ROUTE}${image}-en.png`,
    };
  });

  return illustrations[id];
};

export { FEATURES, getFeatureIllustration };
