import { IconButton } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { AnimatePresence, type AnimationProps, motion } from 'framer-motion';
import AuthContainer from 'containers/AuthContainer';
import withContainers from 'hoc/withContainers';
import { UserMenu } from 'modules/user';
import { useModal } from '../../hooks';
import { useHeader } from './HeaderProvider';

type UserMenuDialogProps = {
  containers: {
    auth: AuthContainer;
  };
  children: React.ReactNode;
};

const TRANSITION = {
  y: { type: 'spring', damping: 100, stiffness: 1200 },
};
const DIALOG_ANIMATION_PROPS: AnimationProps = {
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  variants: {
    hidden: ({ height }) => ({ x: '-100%', height }),
    visible: ({ width }) => ({ x: 0, width }),
  },
  transition: TRANSITION,
};
const BACKDROP_ANIMATION_PROPS: AnimationProps = {
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
  variants: {
    hidden: { opacity: 0 },
    visible: { opacity: 0.5 },
  },
};
const CONTENT_ANIMATION_PROPS: AnimationProps = {
  variants: {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        ...TRANSITION,
        delay: 0.1,
      },
    },
  },
  transition: TRANSITION,
};

const UserMenuDialog = ({ containers: { auth } }: UserMenuDialogProps) => {
  const { t } = useTranslation();
  const { pageOffset } = useHeader();
  const { close: closeHamburgerDialog } = useModal('hamburger');
  const {
    open: openAccountDialog,
    close: closeAccountDialog,
    isModalOpen: accountDialogOpen,
  } = useModal('account');

  const router = useRouter();

  const {
    state: { authenticated },
  } = auth;

  const openDialog = () => {
    closeHamburgerDialog();
    openAccountDialog();
  };

  useEffect(() => {
    router.events.on('routeChangeStart', closeAccountDialog);

    return () => {
      router.events.off('routeChangeStart', closeAccountDialog);
    };
  }, [router.events, closeAccountDialog]);

  return (
    <>
      {authenticated && (
        <IconButton
          icon={accountDialogOpen ? 'close' : 'user'}
          label={t('auth.myAccountLabel')}
          variant="white"
          className="visible lg:hidden"
          onClick={accountDialogOpen ? closeAccountDialog : openDialog}
        />
      )}

      <AnimatePresence>
        {accountDialogOpen && (
          <>
            <motion.div
              className="fixed inset-0 -z-10 bg-black bg-opacity-50"
              onClick={closeAccountDialog}
              role="presentation"
              key="backdrop"
              {...BACKDROP_ANIMATION_PROPS}
            />
            <motion.div
              key="dialog"
              role="dialog"
              aria-modal="true"
              className="fixed inset-x-0 bottom-0 -z-10 w-44 overflow-hidden bg-white py-4"
              {...DIALOG_ANIMATION_PROPS}
              style={{ top: `${pageOffset}px` }}
              custom={{ height: `calc(100vh - ${pageOffset}px)` }}
            >
              <motion.div {...CONTENT_ANIMATION_PROPS}>
                <AnimatePresence initial={false}>
                  <UserMenu />
                </AnimatePresence>
              </motion.div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

const WrappedUserMenuDialog = withContainers({ auth: AuthContainer })(UserMenuDialog);

export { WrappedUserMenuDialog as UserMenuDialog };
export type { UserMenuDialogProps };
