import cx from 'classnames';
import { Stack, Text, Heading, AnchorOrButton } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { APP_LINKS } from 'modules/shared/constants/links';
import styles from './AppBanner.module.scss';

type AppBannerProps = {
  className?: string;
};

const AppBanner = ({ className }: AppBannerProps) => {
  const { t } = useTranslation();

  return (
    <Stack
      as="section"
      gap={3}
      className={cx(styles.root, className)}
      mainAxisAlign="center"
      crossAxisAlign="center"
    >
      <Heading as="h2" variant="s" textColor="light">
        {t('footer.appBannerTitle')}
      </Heading>

      <Text variant="s" textColor="light" align="center" className={styles.description}>
        {t('footer.appBannerDescription')}
      </Text>

      <Stack gap={1} as="ul" type="horizontal" className="unstyledList">
        {APP_LINKS.map(({ AppIcon, labelKey, id, href }) => (
          <li key={labelKey}>
            <AnchorOrButton href={href} title={t(labelKey)} className={styles.button}>
              <AppIcon className={cx(styles.icon, id === 'appStore' && styles.shiftUp)} />

              <Text variant="s+" textColor="light" className={styles.label}>
                {t(labelKey)}
              </Text>
            </AnchorOrButton>
          </li>
        ))}
      </Stack>
    </Stack>
  );
};

export { AppBanner };
export type { AppBannerProps };
