import { noop } from 'lodash';
import * as Sentry from '@sentry/nextjs';

function capture(type, thing, withScope = noop, callback = noop) {
  Sentry.withScope((scope) => {
    withScope(scope);
    Sentry[type === 'error' ? 'captureException' : 'captureMessage'](thing);
    callback();
  });
}

function captureError(error, withScope) {
  capture('error', error, withScope, () => log(error));
}

function captureMessage(message, withScope) {
  capture('message', message, withScope);
}

function log(error) {
  /* eslint-disable no-console */
  if (console && console.error) {
    console.error(error);
  }
  /* eslint-enable no-console */
}

export { captureError, captureMessage, log };
