import { d as g, a as u, e as p, n as y, i as k, k as E, j as T, l as w } from "./utils.f8c6b750.js";
import H, { createContext as O } from "react";
import { $ as S } from "./useFocusable.f923b0dc.js";
function V(e, d) {
  let {
    elementType: a = "button",
    isDisabled: s,
    onPress: t,
    onPressStart: i,
    onPressEnd: n,
    onPressUp: r,
    onPressChange: c,
    preventFocusOnPress: o,
    allowFocusWhenDisabled: l,
    // @ts-ignore
    onClick: $,
    href: x,
    target: m,
    rel: h,
    type: P = "button"
  } = e, f;
  a === "button" ? f = {
    type: P,
    disabled: s
  } : f = {
    role: "button",
    tabIndex: s ? void 0 : 0,
    href: a === "a" && s ? void 0 : x,
    target: a === "a" ? m : void 0,
    type: a === "input" ? P : void 0,
    disabled: a === "input" ? s : void 0,
    "aria-disabled": !s || a === "input" ? void 0 : s,
    rel: a === "a" ? h : void 0
  };
  let { pressProps: v, isPressed: F } = g({
    onPressStart: i,
    onPressEnd: n,
    onPressChange: c,
    onPress: t,
    onPressUp: r,
    isDisabled: s,
    preventFocusOnPress: o,
    ref: d
  }), { focusableProps: b } = S(e, d);
  l && (b.tabIndex = s ? -1 : b.tabIndex);
  let C = u(b, v, p(e, {
    labelable: !0
  }));
  return {
    isPressed: F,
    buttonProps: u(f, C, {
      "aria-haspopup": e["aria-haspopup"],
      "aria-expanded": e["aria-expanded"],
      "aria-controls": e["aria-controls"],
      "aria-pressed": e["aria-pressed"],
      onClick: (D) => {
        $ && ($(D), console.warn("onClick is deprecated, please use onPress"));
      }
    })
  };
}
const B = /* @__PURE__ */ new Set([
  "form",
  "formAction",
  "formEncType",
  "formMethod",
  "formNoValidate",
  "formTarget",
  "name",
  "value"
]), I = /* @__PURE__ */ O({});
function N(e, d) {
  [e, d] = k(e, d, I);
  let a = e, { buttonProps: s, isPressed: t } = V(e, d), { focusProps: i, isFocused: n, isFocusVisible: r } = E(e), { hoverProps: c, isHovered: o } = T(e), l = w({
    ...e,
    values: {
      isHovered: o,
      isPressed: t,
      isFocused: n,
      isFocusVisible: r,
      isDisabled: e.isDisabled || !1
    },
    defaultClassName: "react-aria-Button"
  });
  return /* @__PURE__ */ H.createElement("button", {
    ...p(e, {
      propNames: B
    }),
    ...u(s, i, c),
    ...l,
    ref: d,
    slot: e.slot || void 0,
    "data-disabled": e.isDisabled || void 0,
    "data-pressed": a.isPressed || t || void 0,
    "data-hovered": o || void 0,
    "data-focused": n || void 0,
    "data-focus-visible": r || void 0
  });
}
const W = /* @__PURE__ */ y(N);
export {
  W as $,
  I as a
};
