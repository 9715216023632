import { a as Ft } from "./index.69d44069.js";
import { k as F, c as L, b as St, d as zt, l as te, a as Lt, $ as Kt, m as Tt, n as C, g as Mt, f as jt, h as Rt, o as Nt, p as Vt, j as _t } from "./useListState.d732a4d0.js";
import { m as S, D as N, a as B, E as ue, e as k, F as Ot, G as le, b as x, H as Ut, I as Ht, i as ne, J as Wt, p as oe, k as ie, l as re, r as Gt, j as qt } from "./utils.f8c6b750.js";
import v, { useRef as A, useEffect as z, useMemo as V, useState as Yt, createContext as I, forwardRef as _, useContext as ae } from "react";
function Xt(t, e) {
  const u = A(!0), l = A(null);
  z(() => (u.current = !0, () => {
    u.current = !1;
  }), []), z(() => {
    u.current ? u.current = !1 : (!l.current || e.some((o, n) => !Object.is(o, l[n]))) && t(), l.current = e;
  }, e);
}
function E(t, e) {
  let u = t;
  for (F(u, e) && (u = u.parentElement); u && !F(u, e); )
    u = u.parentElement;
  return u || document.scrollingElement || document.documentElement;
}
const Zt = Symbol.for("react-aria.i18n.locale"), Jt = Symbol.for("react-aria.i18n.strings");
let y;
class K {
  /** Returns a localized string for the given key and locale. */
  getStringForLocale(e, u) {
    let o = this.getStringsForLocale(u)[e];
    if (!o)
      throw new Error(`Could not find intl message ${e} in ${u} locale`);
    return o;
  }
  /** Returns all localized strings for the given locale. */
  getStringsForLocale(e) {
    let u = this.strings[e];
    return u || (u = Qt(e, this.strings, this.defaultLocale), this.strings[e] = u), u;
  }
  static getGlobalDictionaryForPackage(e) {
    if (typeof window > "u")
      return null;
    let u = window[Zt];
    if (y === void 0) {
      let o = window[Jt];
      if (!o)
        return null;
      y = {};
      for (let n in o)
        y[n] = new K({
          [u]: o[n]
        }, u);
    }
    let l = y == null ? void 0 : y[e];
    if (!l)
      throw new Error(`Strings for package "${e}" were not included by LocalizedStringProvider. Please add it to the list passed to createLocalizedStringDictionary.`);
    return l;
  }
  constructor(e, u = "en-US") {
    this.strings = Object.fromEntries(Object.entries(e).filter(([, l]) => l)), this.defaultLocale = u;
  }
}
function Qt(t, e, u = "en-US") {
  if (e[t])
    return e[t];
  let l = eu(t);
  if (e[l])
    return e[l];
  for (let o in e)
    if (o.startsWith(l + "-"))
      return e[o];
  return e[u];
}
function eu(t) {
  return Intl.Locale ? new Intl.Locale(t).language : t.split("-")[0];
}
const q = /* @__PURE__ */ new Map(), Y = /* @__PURE__ */ new Map();
class tu {
  /** Formats a localized string for the given key with the provided variables. */
  format(e, u) {
    let l = this.strings.getStringForLocale(e, this.locale);
    return typeof l == "function" ? l(u, this) : l;
  }
  plural(e, u, l = "cardinal") {
    let o = u["=" + e];
    if (o)
      return typeof o == "function" ? o() : o;
    let n = this.locale + ":" + l, i = q.get(n);
    i || (i = new Intl.PluralRules(this.locale, {
      type: l
    }), q.set(n, i));
    let r = i.select(e);
    return o = u[r] || u.other, typeof o == "function" ? o() : o;
  }
  number(e) {
    let u = Y.get(this.locale);
    return u || (u = new Intl.NumberFormat(this.locale), Y.set(this.locale, u)), u.format(e);
  }
  select(e, u) {
    let l = e[u] || e.other;
    return typeof l == "function" ? l() : l;
  }
  constructor(e, u) {
    this.locale = e, this.strings = u;
  }
}
const X = /* @__PURE__ */ new WeakMap();
function uu(t) {
  let e = X.get(t);
  return e || (e = new K(t), X.set(t, e)), e;
}
function lu(t, e) {
  return e && K.getGlobalDictionaryForPackage(e) || uu(t);
}
function O(t, e) {
  let { locale: u } = L(), l = lu(t, e);
  return V(() => new tu(u, l), [
    u,
    l
  ]);
}
let j = /* @__PURE__ */ new Map();
function nu(t) {
  let { locale: e } = L(), u = e + (t ? Object.entries(t).sort((o, n) => o[0] < n[0] ? -1 : 1).join() : "");
  if (j.has(u))
    return j.get(u);
  let l = new Intl.Collator(e, t);
  return j.set(u, l), l;
}
const se = 7e3;
let R = null;
function ou(t, e = "assertive", u = se) {
  R || (R = new iu()), R.announce(t, e, u);
}
class iu {
  createLog(e) {
    let u = document.createElement("div");
    return u.setAttribute("role", "log"), u.setAttribute("aria-live", e), u.setAttribute("aria-relevant", "additions"), u;
  }
  destroy() {
    this.node && (document.body.removeChild(this.node), this.node = null);
  }
  announce(e, u = "assertive", l = se) {
    if (!this.node)
      return;
    let o = document.createElement("div");
    o.textContent = e, u === "assertive" ? this.assertiveLog.appendChild(o) : this.politeLog.appendChild(o), e !== "" && setTimeout(() => {
      o.remove();
    }, l);
  }
  clear(e) {
    this.node && ((!e || e === "assertive") && (this.assertiveLog.innerHTML = ""), (!e || e === "polite") && (this.politeLog.innerHTML = ""));
  }
  constructor() {
    this.node = document.createElement("div"), this.node.dataset.liveAnnouncer = "true", Object.assign(this.node.style, {
      border: 0,
      clip: "rect(0 0 0 0)",
      clipPath: "inset(50%)",
      height: "1px",
      margin: "-1px",
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      width: "1px",
      whiteSpace: "nowrap"
    }), this.assertiveLog = this.createLog("assertive"), this.node.appendChild(this.assertiveLog), this.politeLog = this.createLog("polite"), this.node.appendChild(this.politeLog), document.body.prepend(this.node);
  }
}
function ru(t) {
  let { id: e, label: u, "aria-labelledby": l, "aria-label": o, labelElementType: n = "label" } = t;
  e = S(e);
  let i = S(), r = {};
  u ? (l = l ? `${i} ${l}` : i, r = {
    id: i,
    htmlFor: n === "label" ? e : void 0
  }) : !l && !o && console.warn("If you do not provide a visible label, you must specify an aria-label or aria-labelledby attribute for accessibility");
  let a = St({
    id: e,
    "aria-label": o,
    "aria-labelledby": l
  });
  return {
    labelProps: r,
    fieldProps: a
  };
}
function au(t) {
  let { description: e, errorMessage: u, isInvalid: l, validationState: o } = t, { labelProps: n, fieldProps: i } = ru(t), r = N([
    !!e,
    !!u,
    l,
    o
  ]), a = N([
    !!e,
    !!u,
    l,
    o
  ]);
  return i = B(i, {
    "aria-describedby": [
      r,
      // Use aria-describedby for error message because aria-errormessage is unsupported using VoiceOver or NVDA. See https://github.com/adobe/react-spectrum/issues/1346#issuecomment-740136268
      a,
      t["aria-describedby"]
    ].filter(Boolean).join(" ") || void 0
  }), {
    labelProps: n,
    fieldProps: i,
    descriptionProps: {
      id: r
    },
    errorMessageProps: {
      id: a
    }
  };
}
class ce {
  isDisabled(e) {
    var u;
    return this.disabledBehavior === "all" && (((u = e.props) === null || u === void 0 ? void 0 : u.isDisabled) || this.disabledKeys.has(e.key));
  }
  getNextKey(e) {
    for (e = this.collection.getKeyAfter(e); e != null; ) {
      let u = this.collection.getItem(e);
      if (u.type === "item" && !this.isDisabled(u))
        return e;
      e = this.collection.getKeyAfter(e);
    }
    return null;
  }
  getPreviousKey(e) {
    for (e = this.collection.getKeyBefore(e); e != null; ) {
      let u = this.collection.getItem(e);
      if (u.type === "item" && !this.isDisabled(u))
        return e;
      e = this.collection.getKeyBefore(e);
    }
    return null;
  }
  findKey(e, u, l) {
    let o = this.getItem(e);
    if (!o)
      return null;
    let n = o.getBoundingClientRect();
    do
      e = u(e), o = this.getItem(e);
    while (o && l(n, o.getBoundingClientRect()));
    return e;
  }
  isSameRow(e, u) {
    return e.top === u.top || e.left !== u.left;
  }
  isSameColumn(e, u) {
    return e.left === u.left || e.top !== u.top;
  }
  getKeyBelow(e) {
    return this.layout === "grid" && this.orientation === "vertical" ? this.findKey(e, (u) => this.getNextKey(u), this.isSameRow) : this.getNextKey(e);
  }
  getKeyAbove(e) {
    return this.layout === "grid" && this.orientation === "vertical" ? this.findKey(e, (u) => this.getPreviousKey(u), this.isSameRow) : this.getPreviousKey(e);
  }
  getNextColumn(e, u) {
    return u ? this.getPreviousKey(e) : this.getNextKey(e);
  }
  getKeyRightOf(e) {
    return this.layout === "grid" ? this.orientation === "vertical" ? this.getNextColumn(e, this.direction === "rtl") : this.findKey(e, (u) => this.getNextColumn(u, this.direction === "rtl"), this.isSameColumn) : this.orientation === "horizontal" ? this.getNextColumn(e, this.direction === "rtl") : null;
  }
  getKeyLeftOf(e) {
    return this.layout === "grid" ? this.orientation === "vertical" ? this.getNextColumn(e, this.direction === "ltr") : this.findKey(e, (u) => this.getNextColumn(u, this.direction === "ltr"), this.isSameColumn) : this.orientation === "horizontal" ? this.getNextColumn(e, this.direction === "ltr") : null;
  }
  getFirstKey() {
    let e = this.collection.getFirstKey();
    for (; e != null; ) {
      let u = this.collection.getItem(e);
      if ((u == null ? void 0 : u.type) === "item" && !this.isDisabled(u))
        return e;
      e = this.collection.getKeyAfter(e);
    }
    return null;
  }
  getLastKey() {
    let e = this.collection.getLastKey();
    for (; e != null; ) {
      let u = this.collection.getItem(e);
      if (u.type === "item" && !this.isDisabled(u))
        return e;
      e = this.collection.getKeyBefore(e);
    }
    return null;
  }
  getItem(e) {
    return e !== null ? this.ref.current.querySelector(`[data-key="${CSS.escape(e.toString())}"]`) : null;
  }
  getKeyPageAbove(e) {
    let u = this.ref.current, l = this.getItem(e);
    if (!l)
      return null;
    if (!F(u))
      return this.getFirstKey();
    let o = u.getBoundingClientRect(), n = l.getBoundingClientRect();
    if (this.orientation === "horizontal") {
      let i = o.x - u.scrollLeft, r = Math.max(0, n.x - i + n.width - o.width);
      for (; l && n.x - i > r; )
        e = this.getKeyAbove(e), l = e == null ? null : this.getItem(e), n = l == null ? void 0 : l.getBoundingClientRect();
    } else {
      let i = o.y - u.scrollTop, r = Math.max(0, n.y - i + n.height - o.height);
      for (; l && n.y - i > r; )
        e = this.getKeyAbove(e), l = e == null ? null : this.getItem(e), n = l == null ? void 0 : l.getBoundingClientRect();
    }
    return e ?? this.getFirstKey();
  }
  getKeyPageBelow(e) {
    let u = this.ref.current, l = this.getItem(e);
    if (!l)
      return null;
    if (!F(u))
      return this.getLastKey();
    let o = u.getBoundingClientRect(), n = l.getBoundingClientRect();
    if (this.orientation === "horizontal") {
      let i = o.x - u.scrollLeft, r = Math.min(u.scrollWidth, n.x - i - n.width + o.width);
      for (; l && n.x - i < r; )
        e = this.getKeyBelow(e), l = e == null ? null : this.getItem(e), n = l == null ? void 0 : l.getBoundingClientRect();
    } else {
      let i = o.y - u.scrollTop, r = Math.min(u.scrollHeight, n.y - i - n.height + o.height);
      for (; l && n.y - i < r; )
        e = this.getKeyBelow(e), l = e == null ? null : this.getItem(e), n = l == null ? void 0 : l.getBoundingClientRect();
    }
    return e ?? this.getLastKey();
  }
  getKeyForSearch(e, u) {
    if (!this.collator)
      return null;
    let l = this.collection, o = u || this.getFirstKey();
    for (; o != null; ) {
      let n = l.getItem(o), i = n.textValue.slice(0, e.length);
      if (n.textValue && this.collator.compare(i, e) === 0)
        return o;
      o = this.getKeyBelow(o);
    }
    return null;
  }
  constructor(...e) {
    if (e.length === 1) {
      let u = e[0];
      this.collection = u.collection, this.ref = u.ref, this.collator = u.collator, this.disabledKeys = u.disabledKeys || /* @__PURE__ */ new Set(), this.disabledBehavior = u.disabledBehavior || "all", this.orientation = u.orientation, this.direction = u.direction, this.layout = u.layout || "stack";
    } else
      this.collection = e[0], this.disabledKeys = e[1], this.ref = e[2], this.collator = e[3], this.layout = "stack", this.orientation = "vertical", this.disabledBehavior = "all";
    this.layout === "stack" && this.orientation === "vertical" && (this.getKeyLeftOf = void 0, this.getKeyRightOf = void 0);
  }
}
function su(t) {
  let { selectionManager: e, collection: u, disabledKeys: l, ref: o, keyboardDelegate: n } = t, i = nu({
    usage: "search",
    sensitivity: "base"
  }), r = e.disabledBehavior, a = V(() => n || new ce({
    collection: u,
    disabledKeys: l,
    disabledBehavior: r,
    ref: o,
    collator: i
  }), [
    n,
    u,
    l,
    o,
    i,
    r
  ]), { collectionProps: $ } = zt({
    ...t,
    ref: o,
    selectionManager: e,
    keyboardDelegate: a
  });
  return {
    listProps: $
  };
}
function cu(t) {
  let e;
  for (let u of t)
    e = u;
  return e;
}
const U = /* @__PURE__ */ new WeakMap();
function Z(t, e) {
  let { id: u } = U.get(t);
  if (!u)
    throw new Error("Unknown list");
  return `${u}-${du(e)}`;
}
function du(t) {
  return typeof t == "string" ? t.replace(/\s*/g, "") : "" + t;
}
var de = {};
de = {
  deselectedItem: (t) => `${t.item} غير المحدد`,
  longPressToSelect: "اضغط مطولًا للدخول إلى وضع التحديد.",
  select: "تحديد",
  selectedAll: "جميع العناصر المحددة.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "لم يتم تحديد عناصر",
    one: () => `${e.number(t.count)} عنصر محدد`,
    other: () => `${e.number(t.count)} عنصر محدد`
  })}.`,
  selectedItem: (t) => `${t.item} المحدد`
};
var $e = {};
$e = {
  deselectedItem: (t) => `${t.item} не е избран.`,
  longPressToSelect: "Натиснете и задръжте за да влезете в избирателен режим.",
  select: "Изберете",
  selectedAll: "Всички елементи са избрани.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Няма избрани елементи",
    one: () => `${e.number(t.count)} избран елемент`,
    other: () => `${e.number(t.count)} избрани елементи`
  })}.`,
  selectedItem: (t) => `${t.item} избран.`
};
var be = {};
be = {
  deselectedItem: (t) => `Položka ${t.item} není vybrána.`,
  longPressToSelect: "Dlouhým stisknutím přejdete do režimu výběru.",
  select: "Vybrat",
  selectedAll: "Vybrány všechny položky.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nevybrány žádné položky",
    one: () => `Vybrána ${e.number(t.count)} položka`,
    other: () => `Vybráno ${e.number(t.count)} položek`
  })}.`,
  selectedItem: (t) => `Vybrána položka ${t.item}.`
};
var me = {};
me = {
  deselectedItem: (t) => `${t.item} ikke valgt.`,
  longPressToSelect: "Lav et langt tryk for at aktivere valgtilstand.",
  select: "Vælg",
  selectedAll: "Alle elementer valgt.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Ingen elementer valgt",
    one: () => `${e.number(t.count)} element valgt`,
    other: () => `${e.number(t.count)} elementer valgt`
  })}.`,
  selectedItem: (t) => `${t.item} valgt.`
};
var pe = {};
pe = {
  deselectedItem: (t) => `${t.item} nicht ausgewählt.`,
  longPressToSelect: "Gedrückt halten, um Auswahlmodus zu öffnen.",
  select: "Auswählen",
  selectedAll: "Alle Elemente ausgewählt.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Keine Elemente ausgewählt",
    one: () => `${e.number(t.count)} Element ausgewählt`,
    other: () => `${e.number(t.count)} Elemente ausgewählt`
  })}.`,
  selectedItem: (t) => `${t.item} ausgewählt.`
};
var fe = {};
fe = {
  deselectedItem: (t) => `Δεν επιλέχθηκε το στοιχείο ${t.item}.`,
  longPressToSelect: "Πατήστε παρατεταμένα για να μπείτε σε λειτουργία επιλογής.",
  select: "Επιλογή",
  selectedAll: "Επιλέχθηκαν όλα τα στοιχεία.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Δεν επιλέχθηκαν στοιχεία",
    one: () => `Επιλέχθηκε ${e.number(t.count)} στοιχείο`,
    other: () => `Επιλέχθηκαν ${e.number(t.count)} στοιχεία`
  })}.`,
  selectedItem: (t) => `Επιλέχθηκε το στοιχείο ${t.item}.`
};
var ve = {};
ve = {
  deselectedItem: (t) => `${t.item} not selected.`,
  select: "Select",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "No items selected",
    one: () => `${e.number(t.count)} item selected`,
    other: () => `${e.number(t.count)} items selected`
  })}.`,
  selectedAll: "All items selected.",
  selectedItem: (t) => `${t.item} selected.`,
  longPressToSelect: "Long press to enter selection mode."
};
var he = {};
he = {
  deselectedItem: (t) => `${t.item} no seleccionado.`,
  longPressToSelect: "Mantenga pulsado para abrir el modo de selección.",
  select: "Seleccionar",
  selectedAll: "Todos los elementos seleccionados.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Ningún elemento seleccionado",
    one: () => `${e.number(t.count)} elemento seleccionado`,
    other: () => `${e.number(t.count)} elementos seleccionados`
  })}.`,
  selectedItem: (t) => `${t.item} seleccionado.`
};
var Be = {};
Be = {
  deselectedItem: (t) => `${t.item} pole valitud.`,
  longPressToSelect: "Valikurežiimi sisenemiseks vajutage pikalt.",
  select: "Vali",
  selectedAll: "Kõik üksused valitud.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Üksusi pole valitud",
    one: () => `${e.number(t.count)} üksus valitud`,
    other: () => `${e.number(t.count)} üksust valitud`
  })}.`,
  selectedItem: (t) => `${t.item} valitud.`
};
var ge = {};
ge = {
  deselectedItem: (t) => `Kohdetta ${t.item} ei valittu.`,
  longPressToSelect: "Siirry valintatilaan painamalla pitkään.",
  select: "Valitse",
  selectedAll: "Kaikki kohteet valittu.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Ei yhtään kohdetta valittu",
    one: () => `${e.number(t.count)} kohde valittu`,
    other: () => `${e.number(t.count)} kohdetta valittu`
  })}.`,
  selectedItem: (t) => `${t.item} valittu.`
};
var De = {};
De = {
  deselectedItem: (t) => `${t.item} non sélectionné.`,
  longPressToSelect: "Appuyez de manière prolongée pour passer en mode de sélection.",
  select: "Sélectionner",
  selectedAll: "Tous les éléments sélectionnés.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Aucun élément sélectionné",
    one: () => `${e.number(t.count)} élément sélectionné`,
    other: () => `${e.number(t.count)} éléments sélectionnés`
  })}.`,
  selectedItem: (t) => `${t.item} sélectionné.`
};
var xe = {};
xe = {
  deselectedItem: (t) => `${t.item} לא נבחר.`,
  longPressToSelect: "הקשה ארוכה לכניסה למצב בחירה.",
  select: "בחר",
  selectedAll: "כל הפריטים נבחרו.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "לא נבחרו פריטים",
    one: () => `פריט ${e.number(t.count)} נבחר`,
    other: () => `${e.number(t.count)} פריטים נבחרו`
  })}.`,
  selectedItem: (t) => `${t.item} נבחר.`
};
var Ce = {};
Ce = {
  deselectedItem: (t) => `Stavka ${t.item} nije odabrana.`,
  longPressToSelect: "Dugo pritisnite za ulazak u način odabira.",
  select: "Odaberite",
  selectedAll: "Odabrane su sve stavke.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nije odabrana nijedna stavka",
    one: () => `Odabrana je ${e.number(t.count)} stavka`,
    other: () => `Odabrano je ${e.number(t.count)} stavki`
  })}.`,
  selectedItem: (t) => `Stavka ${t.item} je odabrana.`
};
var Ee = {};
Ee = {
  deselectedItem: (t) => `${t.item} nincs kijelölve.`,
  longPressToSelect: "Nyomja hosszan a kijelöléshez.",
  select: "Kijelölés",
  selectedAll: "Az összes elem kijelölve.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Egy elem sincs kijelölve",
    one: () => `${e.number(t.count)} elem kijelölve`,
    other: () => `${e.number(t.count)} elem kijelölve`
  })}.`,
  selectedItem: (t) => `${t.item} kijelölve.`
};
var ye = {};
ye = {
  deselectedItem: (t) => `${t.item} non selezionato.`,
  longPressToSelect: "Premi a lungo per passare alla modalità di selezione.",
  select: "Seleziona",
  selectedAll: "Tutti gli elementi selezionati.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nessun elemento selezionato",
    one: () => `${e.number(t.count)} elemento selezionato`,
    other: () => `${e.number(t.count)} elementi selezionati`
  })}.`,
  selectedItem: (t) => `${t.item} selezionato.`
};
var Ae = {};
Ae = {
  deselectedItem: (t) => `${t.item} が選択されていません。`,
  longPressToSelect: "長押しして選択モードを開きます。",
  select: "選択",
  selectedAll: "すべての項目を選択しました。",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "項目が選択されていません",
    one: () => `${e.number(t.count)} 項目を選択しました`,
    other: () => `${e.number(t.count)} 項目を選択しました`
  })}。`,
  selectedItem: (t) => `${t.item} を選択しました。`
};
var Pe = {};
Pe = {
  deselectedItem: (t) => `${t.item}이(가) 선택되지 않았습니다.`,
  longPressToSelect: "선택 모드로 들어가려면 길게 누르십시오.",
  select: "선택",
  selectedAll: "모든 항목이 선택되었습니다.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "선택된 항목이 없습니다",
    one: () => `${e.number(t.count)}개 항목이 선택되었습니다`,
    other: () => `${e.number(t.count)}개 항목이 선택되었습니다`
  })}.`,
  selectedItem: (t) => `${t.item}이(가) 선택되었습니다.`
};
var ke = {};
ke = {
  deselectedItem: (t) => `${t.item} nepasirinkta.`,
  longPressToSelect: "Norėdami įjungti pasirinkimo režimą, paspauskite ir palaikykite.",
  select: "Pasirinkti",
  selectedAll: "Pasirinkti visi elementai.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nepasirinktas nė vienas elementas",
    one: () => `Pasirinktas ${e.number(t.count)} elementas`,
    other: () => `Pasirinkta elementų: ${e.number(t.count)}`
  })}.`,
  selectedItem: (t) => `Pasirinkta: ${t.item}.`
};
var Ie = {};
Ie = {
  deselectedItem: (t) => `Vienums ${t.item} nav atlasīts.`,
  longPressToSelect: "Ilgi turiet nospiestu. lai ieslēgtu atlases režīmu.",
  select: "Atlasīt",
  selectedAll: "Atlasīti visi vienumi.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nav atlasīts neviens vienums",
    one: () => `Atlasīto vienumu skaits: ${e.number(t.count)}`,
    other: () => `Atlasīto vienumu skaits: ${e.number(t.count)}`
  })}.`,
  selectedItem: (t) => `Atlasīts vienums ${t.item}.`
};
var we = {};
we = {
  deselectedItem: (t) => `${t.item} er ikke valgt.`,
  longPressToSelect: "Bruk et langt trykk for å gå inn i valgmodus.",
  select: "Velg",
  selectedAll: "Alle elementer er valgt.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Ingen elementer er valgt",
    one: () => `${e.number(t.count)} element er valgt`,
    other: () => `${e.number(t.count)} elementer er valgt`
  })}.`,
  selectedItem: (t) => `${t.item} er valgt.`
};
var Fe = {};
Fe = {
  deselectedItem: (t) => `${t.item} niet geselecteerd.`,
  longPressToSelect: "Druk lang om de selectiemodus te openen.",
  select: "Selecteren",
  selectedAll: "Alle items geselecteerd.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Geen items geselecteerd",
    one: () => `${e.number(t.count)} item geselecteerd`,
    other: () => `${e.number(t.count)} items geselecteerd`
  })}.`,
  selectedItem: (t) => `${t.item} geselecteerd.`
};
var Se = {};
Se = {
  deselectedItem: (t) => `Nie zaznaczono ${t.item}.`,
  longPressToSelect: "Naciśnij i przytrzymaj, aby wejść do trybu wyboru.",
  select: "Zaznacz",
  selectedAll: "Wszystkie zaznaczone elementy.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nie zaznaczono żadnych elementów",
    one: () => `${e.number(t.count)} zaznaczony element`,
    other: () => `${e.number(t.count)} zaznaczonych elementów`
  })}.`,
  selectedItem: (t) => `Zaznaczono ${t.item}.`
};
var ze = {};
ze = {
  deselectedItem: (t) => `${t.item} não selecionado.`,
  longPressToSelect: "Mantenha pressionado para entrar no modo de seleção.",
  select: "Selecionar",
  selectedAll: "Todos os itens selecionados.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nenhum item selecionado",
    one: () => `${e.number(t.count)} item selecionado`,
    other: () => `${e.number(t.count)} itens selecionados`
  })}.`,
  selectedItem: (t) => `${t.item} selecionado.`
};
var Le = {};
Le = {
  deselectedItem: (t) => `${t.item} não selecionado.`,
  longPressToSelect: "Prima continuamente para entrar no modo de seleção.",
  select: "Selecionar",
  selectedAll: "Todos os itens selecionados.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nenhum item selecionado",
    one: () => `${e.number(t.count)} item selecionado`,
    other: () => `${e.number(t.count)} itens selecionados`
  })}.`,
  selectedItem: (t) => `${t.item} selecionado.`
};
var Ke = {};
Ke = {
  deselectedItem: (t) => `${t.item} neselectat.`,
  longPressToSelect: "Apăsați lung pentru a intra în modul de selectare.",
  select: "Selectare",
  selectedAll: "Toate elementele selectate.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Niciun element selectat",
    one: () => `${e.number(t.count)} element selectat`,
    other: () => `${e.number(t.count)} elemente selectate`
  })}.`,
  selectedItem: (t) => `${t.item} selectat.`
};
var Te = {};
Te = {
  deselectedItem: (t) => `${t.item} не выбрано.`,
  longPressToSelect: "Нажмите и удерживайте для входа в режим выбора.",
  select: "Выбрать",
  selectedAll: "Выбраны все элементы.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Нет выбранных элементов",
    one: () => `${e.number(t.count)} элемент выбран`,
    other: () => `${e.number(t.count)} элементов выбрано`
  })}.`,
  selectedItem: (t) => `${t.item} выбрано.`
};
var Me = {};
Me = {
  deselectedItem: (t) => `Nevybraté položky: ${t.item}.`,
  longPressToSelect: "Dlhším stlačením prejdite do režimu výberu.",
  select: "Vybrať",
  selectedAll: "Všetky vybraté položky.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Žiadne vybraté položky",
    one: () => `${e.number(t.count)} vybratá položka`,
    other: () => `Počet vybratých položiek:${e.number(t.count)}`
  })}.`,
  selectedItem: (t) => `Vybraté položky: ${t.item}.`
};
var je = {};
je = {
  deselectedItem: (t) => `Element ${t.item} ni izbran.`,
  longPressToSelect: "Za izbirni način pritisnite in dlje časa držite.",
  select: "Izberite",
  selectedAll: "Vsi elementi so izbrani.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Noben element ni izbran",
    one: () => `${e.number(t.count)} element je izbran`,
    other: () => `${e.number(t.count)} elementov je izbranih`
  })}.`,
  selectedItem: (t) => `Element ${t.item} je izbran.`
};
var Re = {};
Re = {
  deselectedItem: (t) => `${t.item} nije izabrano.`,
  longPressToSelect: "Dugo pritisnite za ulazak u režim biranja.",
  select: "Izaberite",
  selectedAll: "Izabrane su sve stavke.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Nije izabrana nijedna stavka",
    one: () => `Izabrana je ${e.number(t.count)} stavka`,
    other: () => `Izabrano je ${e.number(t.count)} stavki`
  })}.`,
  selectedItem: (t) => `${t.item} je izabrano.`
};
var Ne = {};
Ne = {
  deselectedItem: (t) => `${t.item} ej markerat.`,
  longPressToSelect: "Tryck länge när du vill öppna väljarläge.",
  select: "Markera",
  selectedAll: "Alla markerade objekt.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Inga markerade objekt",
    one: () => `${e.number(t.count)} markerat objekt`,
    other: () => `${e.number(t.count)} markerade objekt`
  })}.`,
  selectedItem: (t) => `${t.item} markerat.`
};
var Ve = {};
Ve = {
  deselectedItem: (t) => `${t.item} seçilmedi.`,
  longPressToSelect: "Seçim moduna girmek için uzun basın.",
  select: "Seç",
  selectedAll: "Tüm ögeler seçildi.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Hiçbir öge seçilmedi",
    one: () => `${e.number(t.count)} öge seçildi`,
    other: () => `${e.number(t.count)} öge seçildi`
  })}.`,
  selectedItem: (t) => `${t.item} seçildi.`
};
var _e = {};
_e = {
  deselectedItem: (t) => `${t.item} не вибрано.`,
  longPressToSelect: "Виконайте довге натиснення, щоб перейти в режим вибору.",
  select: "Вибрати",
  selectedAll: "Усі елементи вибрано.",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "Жодних елементів не вибрано",
    one: () => `${e.number(t.count)} елемент вибрано`,
    other: () => `Вибрано елементів: ${e.number(t.count)}`
  })}.`,
  selectedItem: (t) => `${t.item} вибрано.`
};
var Oe = {};
Oe = {
  deselectedItem: (t) => `未选择 ${t.item}。`,
  longPressToSelect: "长按以进入选择模式。",
  select: "选择",
  selectedAll: "已选择所有项目。",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "未选择项目",
    one: () => `已选择 ${e.number(t.count)} 个项目`,
    other: () => `已选择 ${e.number(t.count)} 个项目`
  })}。`,
  selectedItem: (t) => `已选择 ${t.item}。`
};
var Ue = {};
Ue = {
  deselectedItem: (t) => `未選取「${t.item}」。`,
  longPressToSelect: "長按以進入選擇模式。",
  select: "選取",
  selectedAll: "已選取所有項目。",
  selectedCount: (t, e) => `${e.plural(t.count, {
    "=0": "未選取任何項目",
    one: () => `已選取 ${e.number(t.count)} 個項目`,
    other: () => `已選取 ${e.number(t.count)} 個項目`
  })}。`,
  selectedItem: (t) => `已選取「${t.item}」。`
};
var H = {};
H = {
  "ar-AE": de,
  "bg-BG": $e,
  "cs-CZ": be,
  "da-DK": me,
  "de-DE": pe,
  "el-GR": fe,
  "en-US": ve,
  "es-ES": he,
  "et-EE": Be,
  "fi-FI": ge,
  "fr-FR": De,
  "he-IL": xe,
  "hr-HR": Ce,
  "hu-HU": Ee,
  "it-IT": ye,
  "ja-JP": Ae,
  "ko-KR": Pe,
  "lt-LT": ke,
  "lv-LV": Ie,
  "nb-NO": we,
  "nl-NL": Fe,
  "pl-PL": Se,
  "pt-BR": ze,
  "pt-PT": Le,
  "ro-RO": Ke,
  "ru-RU": Te,
  "sk-SK": Me,
  "sl-SI": je,
  "sr-SP": Re,
  "sv-SE": Ne,
  "tr-TR": Ve,
  "uk-UA": _e,
  "zh-CN": Oe,
  "zh-TW": Ue
};
function $u(t) {
  return t && t.__esModule ? t.default : t;
}
function bu(t, e) {
  let { getRowText: u = (i) => {
    var r, a, $, c;
    return (c = (r = (a = e.collection).getTextValue) === null || r === void 0 ? void 0 : r.call(a, i)) !== null && c !== void 0 ? c : ($ = e.collection.getItem(i)) === null || $ === void 0 ? void 0 : $.textValue;
  } } = t, l = O($u(H), "@react-aria/grid"), o = e.selectionManager.rawSelection, n = A(o);
  Xt(() => {
    var i;
    if (!e.selectionManager.isFocused) {
      n.current = o;
      return;
    }
    let r = J(o, n.current), a = J(n.current, o), $ = e.selectionManager.selectionBehavior === "replace", c = [];
    if (e.selectionManager.selectedKeys.size === 1 && $) {
      if (e.collection.getItem(e.selectionManager.selectedKeys.keys().next().value)) {
        let d = u(e.selectionManager.selectedKeys.keys().next().value);
        d && c.push(l.format("selectedItem", {
          item: d
        }));
      }
    } else if (r.size === 1 && a.size === 0) {
      let d = u(r.keys().next().value);
      d && c.push(l.format("selectedItem", {
        item: d
      }));
    } else if (a.size === 1 && r.size === 0 && e.collection.getItem(a.keys().next().value)) {
      let d = u(a.keys().next().value);
      d && c.push(l.format("deselectedItem", {
        item: d
      }));
    }
    e.selectionManager.selectionMode === "multiple" && (c.length === 0 || o === "all" || o.size > 1 || n.current === "all" || ((i = n.current) === null || i === void 0 ? void 0 : i.size) > 1) && c.push(o === "all" ? l.format("selectedAll") : l.format("selectedCount", {
      count: o.size
    })), c.length > 0 && ou(c.join(" ")), n.current = o;
  }, [
    o
  ]);
}
function J(t, e) {
  let u = /* @__PURE__ */ new Set();
  if (t === "all" || e === "all")
    return u;
  for (let l of t.keys())
    e.has(l) || u.add(l);
  return u;
}
function mu(t) {
  return t && t.__esModule ? t.default : t;
}
function pu(t) {
  let e = O(mu(H), "@react-aria/grid"), u = ue(), l = (u === "pointer" || u === "virtual" || u == null) && typeof window < "u" && "ontouchstart" in window, o = V(() => {
    let i = t.selectionManager.selectionMode, r = t.selectionManager.selectionBehavior, a;
    return l && (a = e.format("longPressToSelect")), r === "replace" && i !== "none" && t.hasItemActions ? a : void 0;
  }, [
    t.selectionManager.selectionMode,
    t.selectionManager.selectionBehavior,
    t.hasItemActions,
    e,
    l
  ]);
  return te(o);
}
function fu(t, e, u) {
  let { isVirtualized: l, keyboardDelegate: o, onAction: n, linkBehavior: i = "action" } = t;
  !t["aria-label"] && !t["aria-labelledby"] && console.warn("An aria-label or aria-labelledby prop is required for accessibility.");
  let { listProps: r } = su({
    selectionManager: e.selectionManager,
    collection: e.collection,
    disabledKeys: e.disabledKeys,
    ref: u,
    keyboardDelegate: o,
    isVirtualized: l,
    selectOnFocus: e.selectionManager.selectionBehavior === "replace",
    shouldFocusWrap: t.shouldFocusWrap,
    linkBehavior: i
  }), a = S(t.id);
  U.set(e, {
    id: a,
    onAction: n,
    linkBehavior: i
  });
  let $ = pu({
    selectionManager: e.selectionManager,
    hasItemActions: !!n
  }), c = Lt(u, {
    isDisabled: e.collection.size !== 0
  }), d = k(t, {
    labelable: !0
  }), b = B(
    d,
    {
      role: "grid",
      id: a,
      "aria-multiselectable": e.selectionManager.selectionMode === "multiple" ? "true" : void 0
    },
    // If collection is empty, make sure the grid is tabbable unless there is a child tabbable element.
    e.collection.size === 0 ? {
      tabIndex: c ? -1 : 0
    } : r,
    $
  );
  return l && (b["aria-rowcount"] = e.collection.size, b["aria-colcount"] = 1), bu({}, e), {
    gridProps: b
  };
}
const Q = {
  expand: {
    ltr: "ArrowRight",
    rtl: "ArrowLeft"
  },
  collapse: {
    ltr: "ArrowLeft",
    rtl: "ArrowRight"
  }
};
function vu(t, e, u) {
  var l, o;
  let { node: n, isVirtualized: i, shouldSelectOnPressUp: r } = t, { direction: a } = L(), { onAction: $, linkBehavior: c } = U.get(e), d = N(), b = A(null), f = () => {
    var s;
    (b.current != null && n.key !== b.current || !(!((s = u.current) === null || s === void 0) && s.contains(document.activeElement))) && x(u.current);
  }, m = {}, h, T = e.selectionManager.isLink(n.key);
  n != null && "expandedKeys" in e && (h = [
    ...e.collection.getChildren(n.key)
  ].length > 1, $ == null && !T && e.selectionManager.selectionMode === "none" && h && ($ = () => e.toggleKey(n.key)), m = {
    "aria-expanded": h ? e.expandedKeys.has(n.key) : void 0,
    "aria-level": n.level + 1,
    "aria-posinset": (n == null ? void 0 : n.index) + 1,
    "aria-setsize": n.level > 0 ? cu(e.collection.getChildren(n == null ? void 0 : n.parentKey)).index + 1 : [
      ...e.collection
    ].filter((p) => p.level === 0).at(-1).index + 1
  });
  let { itemProps: M, ...w } = Kt({
    selectionManager: e.selectionManager,
    key: n.key,
    ref: u,
    isVirtualized: i,
    shouldSelectOnPressUp: r,
    onAction: $ || !((l = n.props) === null || l === void 0) && l.onAction ? Ot((o = n.props) === null || o === void 0 ? void 0 : o.onAction, $ ? () => $(n.key) : void 0) : void 0,
    focus: f,
    linkBehavior: c
  }), P = (s) => {
    if (!s.currentTarget.contains(s.target))
      return;
    let p = Tt(u.current);
    if (p.currentNode = document.activeElement, "expandedKeys" in e && document.activeElement === u.current) {
      if (s.key === Q.expand[a] && e.selectionManager.focusedKey === n.key && h && !e.expandedKeys.has(n.key)) {
        e.toggleKey(n.key), s.stopPropagation();
        return;
      } else if (s.key === Q.collapse[a] && e.selectionManager.focusedKey === n.key && h && e.expandedKeys.has(n.key)) {
        e.toggleKey(n.key), s.stopPropagation();
        return;
      }
    }
    switch (s.key) {
      case "ArrowLeft": {
        let g = a === "rtl" ? p.nextNode() : p.previousNode();
        if (g)
          s.preventDefault(), s.stopPropagation(), x(g), C(g, {
            containingElement: E(u.current)
          });
        else if (s.preventDefault(), s.stopPropagation(), a === "rtl")
          x(u.current), C(u.current, {
            containingElement: E(u.current)
          });
        else {
          p.currentNode = u.current;
          let D = ee(p);
          D && (x(D), C(D, {
            containingElement: E(u.current)
          }));
        }
        break;
      }
      case "ArrowRight": {
        let g = a === "rtl" ? p.previousNode() : p.nextNode();
        if (g)
          s.preventDefault(), s.stopPropagation(), x(g), C(g, {
            containingElement: E(u.current)
          });
        else if (s.preventDefault(), s.stopPropagation(), a === "ltr")
          x(u.current), C(u.current, {
            containingElement: E(u.current)
          });
        else {
          p.currentNode = u.current;
          let D = ee(p);
          D && (x(D), C(D, {
            containingElement: E(u.current)
          }));
        }
        break;
      }
      case "ArrowUp":
      case "ArrowDown":
        !s.altKey && u.current.contains(s.target) && (s.stopPropagation(), s.preventDefault(), u.current.parentElement.dispatchEvent(new KeyboardEvent(s.nativeEvent.type, s.nativeEvent)));
        break;
    }
  }, kt = (s) => {
    if (b.current = n.key, s.target !== u.current) {
      Ut() || e.selectionManager.setFocusedKey(n.key);
      return;
    }
  }, It = w.hasAction ? le(n.props) : {}, G = B(M, It, {
    role: "row",
    onKeyDownCapture: P,
    onFocus: kt,
    // 'aria-label': [(node.textValue || undefined), rowAnnouncement].filter(Boolean).join(', '),
    "aria-label": n.textValue || void 0,
    "aria-selected": e.selectionManager.canSelectItem(n.key) ? e.selectionManager.isSelected(n.key) : void 0,
    "aria-disabled": e.selectionManager.isDisabled(n.key) || void 0,
    "aria-labelledby": d && n.textValue ? `${Z(e, n.key)} ${d}` : void 0,
    id: Z(e, n.key)
  });
  i && (G["aria-rowindex"] = n.index + 1);
  let wt = {
    role: "gridcell",
    "aria-colindex": 1
  };
  return {
    rowProps: {
      ...B(G, m)
    },
    gridCellProps: wt,
    descriptionProps: {
      id: d
    },
    ...w
  };
}
function ee(t) {
  let e, u;
  do
    u = t.lastChild(), u && (e = u);
  while (u);
  return e;
}
const He = /* @__PURE__ */ new WeakMap();
function hu(t, e, u) {
  let { direction: l } = L(), o = t.keyboardDelegate || new ce({
    collection: e.collection,
    ref: u,
    orientation: "horizontal",
    direction: l,
    disabledKeys: e.disabledKeys,
    disabledBehavior: e.selectionManager.disabledBehavior
  }), { labelProps: n, fieldProps: i, descriptionProps: r, errorMessageProps: a } = au({
    ...t,
    labelElementType: "span"
  }), { gridProps: $ } = fu({
    ...t,
    ...i,
    keyboardDelegate: o,
    shouldFocusWrap: !0,
    linkBehavior: "override"
  }, e, u), [c, d] = Yt(!1), { focusWithinProps: b } = Ht({
    onFocusWithinChange: d
  }), f = k(t), m = A(e.collection.size);
  return z(() => {
    u.current && m.current > 0 && e.collection.size === 0 && c && u.current.focus(), m.current = e.collection.size;
  }, [
    e.collection.size,
    c,
    u
  ]), He.set(e, {
    onRemove: t.onRemove
  }), {
    gridProps: B($, f, {
      role: e.collection.size ? "grid" : null,
      "aria-atomic": !1,
      "aria-relevant": "additions",
      "aria-live": c ? "polite" : "off",
      ...b,
      ...i
    }),
    labelProps: n,
    descriptionProps: r,
    errorMessageProps: a
  };
}
var We = {};
We = {
  removeButtonLabel: "إزالة",
  removeDescription: "اضغط على مفتاح DELETE لإزالة علامة."
};
var Ge = {};
Ge = {
  removeButtonLabel: "Премахване",
  removeDescription: "Натиснете Delete, за да премахнете маркера."
};
var qe = {};
qe = {
  removeButtonLabel: "Odebrat",
  removeDescription: "Stisknutím klávesy Delete odeberete značku."
};
var Ye = {};
Ye = {
  removeButtonLabel: "Fjern",
  removeDescription: "Tryk på Slet for at fjerne tag."
};
var Xe = {};
Xe = {
  removeButtonLabel: "Entfernen",
  removeDescription: "Auf „Löschen“ drücken, um das Tag zu entfernen."
};
var Ze = {};
Ze = {
  removeButtonLabel: "Κατάργηση",
  removeDescription: "Πατήστε Διαγραφή για να καταργήσετε την ετικέτα."
};
var Je = {};
Je = {
  removeDescription: "Press Delete to remove tag.",
  removeButtonLabel: "Remove"
};
var Qe = {};
Qe = {
  removeButtonLabel: "Quitar",
  removeDescription: "Pulse Eliminar para quitar la etiqueta."
};
var et = {};
et = {
  removeButtonLabel: "Eemalda",
  removeDescription: "Sildi eemaldamiseks vajutage kustutusklahvi Delete."
};
var tt = {};
tt = {
  removeButtonLabel: "Poista",
  removeDescription: "Poista tunniste painamalla Poista-painiketta."
};
var ut = {};
ut = {
  removeButtonLabel: "Supprimer",
  removeDescription: "Appuyez sur Supprimer pour supprimer l’étiquette."
};
var lt = {};
lt = {
  removeButtonLabel: "הסר",
  removeDescription: "לחץ על מחק כדי להסיר תג."
};
var nt = {};
nt = {
  removeButtonLabel: "Ukloni",
  removeDescription: "Pritisnite Delete za uklanjanje oznake."
};
var ot = {};
ot = {
  removeButtonLabel: "Eltávolítás",
  removeDescription: "Nyomja meg a Delete billentyűt a címke eltávolításához."
};
var it = {};
it = {
  removeButtonLabel: "Rimuovi",
  removeDescription: "Premi Elimina per rimuovere il tag."
};
var rt = {};
rt = {
  removeButtonLabel: "削除",
  removeDescription: "タグを削除するには、Delete キーを押します。"
};
var at = {};
at = {
  removeButtonLabel: "제거",
  removeDescription: "태그를 제거하려면 Delete 키를 누르십시오."
};
var st = {};
st = {
  removeButtonLabel: "Pašalinti",
  removeDescription: "Norėdami pašalinti žymą, paspauskite „Delete“ klavišą."
};
var ct = {};
ct = {
  removeButtonLabel: "Noņemt",
  removeDescription: "Nospiediet Delete [Dzēst], lai noņemtu tagu."
};
var dt = {};
dt = {
  removeButtonLabel: "Fjern",
  removeDescription: "Trykk på Slett for å fjerne taggen."
};
var $t = {};
$t = {
  removeButtonLabel: "Verwijderen",
  removeDescription: "Druk op Verwijderen om de tag te verwijderen."
};
var bt = {};
bt = {
  removeButtonLabel: "Usuń",
  removeDescription: "Naciśnij Usuń, aby usunąć znacznik."
};
var mt = {};
mt = {
  removeButtonLabel: "Remover",
  removeDescription: "Pressione Delete para remover a tag."
};
var pt = {};
pt = {
  removeButtonLabel: "Eliminar",
  removeDescription: "Prima Delete para eliminar a tag."
};
var ft = {};
ft = {
  removeButtonLabel: "Îndepărtaţi",
  removeDescription: "Apăsați pe Delete (Ștergere) pentru a elimina eticheta."
};
var vt = {};
vt = {
  removeButtonLabel: "Удалить",
  removeDescription: "Нажмите DELETE, чтобы удалить тег."
};
var ht = {};
ht = {
  removeButtonLabel: "Odstrániť",
  removeDescription: "Ak chcete odstrániť značku, stlačte kláves Delete."
};
var Bt = {};
Bt = {
  removeButtonLabel: "Odstrani",
  removeDescription: "Pritisnite Delete, da odstranite oznako."
};
var gt = {};
gt = {
  removeButtonLabel: "Ukloni",
  removeDescription: "Pritisnite Obriši da biste uklonili oznaku."
};
var Dt = {};
Dt = {
  removeButtonLabel: "Ta bort",
  removeDescription: "Tryck på Radera för att ta bort taggen."
};
var xt = {};
xt = {
  removeButtonLabel: "Kaldır",
  removeDescription: "Etiketi kaldırmak için Sil tuşuna basın."
};
var Ct = {};
Ct = {
  removeButtonLabel: "Вилучити",
  removeDescription: "Натисніть Delete, щоб вилучити тег."
};
var Et = {};
Et = {
  removeButtonLabel: "删除",
  removeDescription: "按下“删除”以删除标记。"
};
var yt = {};
yt = {
  removeButtonLabel: "移除",
  removeDescription: "按 Delete 鍵以移除標記。"
};
var At = {};
At = {
  "ar-AE": We,
  "bg-BG": Ge,
  "cs-CZ": qe,
  "da-DK": Ye,
  "de-DE": Xe,
  "el-GR": Ze,
  "en-US": Je,
  "es-ES": Qe,
  "et-EE": et,
  "fi-FI": tt,
  "fr-FR": ut,
  "he-IL": lt,
  "hr-HR": nt,
  "hu-HU": ot,
  "it-IT": it,
  "ja-JP": rt,
  "ko-KR": at,
  "lt-LT": st,
  "lv-LV": ct,
  "nb-NO": dt,
  "nl-NL": $t,
  "pl-PL": bt,
  "pt-BR": mt,
  "pt-PT": pt,
  "ro-RO": ft,
  "ru-RU": vt,
  "sk-SK": ht,
  "sl-SI": Bt,
  "sr-SP": gt,
  "sv-SE": Dt,
  "tr-TR": xt,
  "uk-UA": Ct,
  "zh-CN": Et,
  "zh-TW": yt
};
function Bu(t) {
  return t && t.__esModule ? t.default : t;
}
function gu(t, e, u) {
  let { item: l } = t, o = O(Bu(At), "@react-aria/tag"), n = S(), { onRemove: i } = He.get(e) || {}, { rowProps: r, gridCellProps: a, ...$ } = vu({
    node: l
  }, e, u);
  delete r.onKeyDownCapture;
  let { descriptionProps: c, ...d } = $, b = (P) => {
    (P.key === "Delete" || P.key === "Backspace") && (P.preventDefault(), e.selectionManager.isSelected(l.key) ? i == null || i(new Set(e.selectionManager.selectedKeys)) : i == null || i(/* @__PURE__ */ new Set([
      l.key
    ])));
  }, f = ue();
  f === "virtual" && typeof window < "u" && "ontouchstart" in window && (f = "pointer");
  let m = i && (f === "keyboard" || f === "virtual") ? o.format("removeDescription") : "", h = te(m), T = l.key === e.selectionManager.focusedKey, M = k(l.props), w = le(l.props);
  return {
    removeButtonProps: {
      "aria-label": o.format("removeButtonLabel"),
      "aria-labelledby": `${n} ${r.id}`,
      id: n,
      onPress: () => i ? i(/* @__PURE__ */ new Set([
        l.key
      ])) : null,
      excludeFromTabOrder: !0
    },
    rowProps: B(r, M, w, {
      tabIndex: T || e.selectionManager.focusedKey == null ? 0 : -1,
      onKeyDown: i ? b : void 0,
      "aria-describedby": h["aria-describedby"]
    }),
    gridCellProps: B(a, {
      "aria-errormessage": t["aria-errormessage"],
      "aria-label": t["aria-label"]
    }),
    ...d,
    allowsRemoving: !!i
  };
}
const Du = /* @__PURE__ */ I({}), xu = /* @__PURE__ */ I({}), W = /* @__PURE__ */ I(null), Cu = /* @__PURE__ */ I(null), Pt = /* @__PURE__ */ I(null);
function Eu(t, e) {
  [t, e] = ne(t, e, Cu);
  let u = A(null), [l, o] = Wt(), { collection: n, document: i } = Mt(), r = jt({
    ...t,
    children: void 0,
    collection: n
  }), a = k(t), $ = Object.fromEntries(Object.entries(a).map(([h]) => [
    h,
    void 0
  ])), { gridProps: c, labelProps: d, descriptionProps: b, errorMessageProps: f } = hu({
    ...t,
    ...$,
    label: o
  }, r, u);
  var m;
  return /* @__PURE__ */ v.createElement("div", {
    ...a,
    ref: e,
    slot: t.slot || void 0,
    className: (m = t.className) !== null && m !== void 0 ? m : "react-aria-TagGroup",
    style: t.style
  }, /* @__PURE__ */ v.createElement(oe, {
    values: [
      [
        xu,
        {
          ...d,
          elementType: "span",
          ref: l
        }
      ],
      [
        Pt,
        {
          ...c,
          ref: u
        }
      ],
      [
        W,
        r
      ],
      [
        Rt,
        i
      ],
      [
        Du,
        {
          slots: {
            description: b,
            errorMessage: f
          }
        }
      ]
    ]
  }, t.children));
}
const zu = /* @__PURE__ */ _(Eu);
function yu(t, e) {
  let u = Nt(t);
  return /* @__PURE__ */ v.createElement(v.Fragment, null, u, /* @__PURE__ */ v.createElement(Au, {
    props: t,
    forwardedRef: e
  }));
}
function Au({ props: t, forwardedRef: e }) {
  let u = ae(W), [l, o] = ne(t, e, Pt);
  delete l.items, delete l.renderEmptyState;
  let n = Vt({
    items: u.collection,
    children: (d) => {
      switch (d.type) {
        case "item":
          return /* @__PURE__ */ v.createElement(ku, {
            item: d
          });
        default:
          throw new Error("Unsupported node type in TagList: " + d.type);
      }
    }
  }), { focusProps: i, isFocused: r, isFocusVisible: a } = ie(), $ = {
    isEmpty: u.collection.size === 0,
    isFocused: r,
    isFocusVisible: a,
    state: u
  }, c = re({
    className: t.className,
    style: t.style,
    defaultClassName: "react-aria-TagList",
    values: $
  });
  return /* @__PURE__ */ v.createElement("div", {
    ...B(l, i),
    ...c,
    ref: o,
    "data-empty": u.collection.size === 0 || void 0,
    "data-focused": r || void 0,
    "data-focus-visible": a || void 0
  }, u.collection.size === 0 && t.renderEmptyState ? t.renderEmptyState($) : n);
}
const Lu = /* @__PURE__ */ _(yu);
function Pu(t, e) {
  return _t("item", t, e, t.children);
}
const Ku = /* @__PURE__ */ _(Pu);
function ku({ item: t }) {
  let e = ae(W), u = Gt(t.props.ref), { focusProps: l, isFocusVisible: o } = ie({
    within: !0
  }), { rowProps: n, gridCellProps: i, removeButtonProps: r, ...a } = gu({
    item: t
  }, e, u), { hoverProps: $, isHovered: c } = qt({
    isDisabled: !a.allowsSelection,
    onHoverStart: t.props.onHoverStart,
    onHoverChange: t.props.onHoverChange,
    onHoverEnd: t.props.onHoverEnd
  }), d = t.props, b = re({
    ...d,
    id: void 0,
    children: t.rendered,
    defaultClassName: "react-aria-Tag",
    values: {
      ...a,
      isFocusVisible: o,
      isHovered: c,
      selectionMode: e.selectionManager.selectionMode,
      selectionBehavior: e.selectionManager.selectionBehavior
    }
  });
  return z(() => {
    t.textValue || console.warn("A `textValue` prop is required for <Tag> elements with non-plain text children for accessibility.");
  }, [
    t.textValue
  ]), /* @__PURE__ */ v.createElement("div", {
    ref: u,
    ...b,
    ...B(k(d), n, l, $),
    "data-selected": a.isSelected || void 0,
    "data-disabled": a.isDisabled || void 0,
    "data-hovered": c || void 0,
    "data-focused": a.isFocused || void 0,
    "data-focus-visible": o || void 0,
    "data-pressed": a.isPressed || void 0,
    "data-allows-removing": a.allowsRemoving || void 0,
    "data-selection-mode": e.selectionManager.selectionMode === "none" ? void 0 : e.selectionManager.selectionMode
  }, /* @__PURE__ */ v.createElement("div", {
    ...i,
    style: {
      display: "contents"
    }
  }, /* @__PURE__ */ v.createElement(oe, {
    values: [
      [
        Ft,
        {
          slots: {
            remove: r
          }
        }
      ]
    ]
  }, b.children)));
}
export {
  zu as $,
  Lu as a,
  Ku as b
};
