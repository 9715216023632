import { ExternalLink } from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';
import { social } from 'config';
import FacebookIcon from 'assets/icons/facebook.svg';
import InstagramIcon from 'assets/icons/instagram.svg';
import YoutubeIcon from 'assets/icons/social/youtube-play.svg';
import LinkedInIcon from 'assets/icons/social/linkedin.svg';
import type { SVGComponent } from 'types/component';
import type { SocialMediaPlatform } from '../types';

type Link = {
  to: string;
  label: string;
  Icon: SVGComponent;
  platform: SocialMediaPlatform;
};

const LINKS: Link[] = [
  {
    to: social.youTube,
    label: 'YouTube',
    Icon: YoutubeIcon,
    platform: 'youtube',
  },
  {
    to: social.facebook,
    label: 'Facebook',
    Icon: FacebookIcon,
    platform: 'facebook',
  },
  {
    to: social.instagram,
    label: 'Instagram',
    Icon: InstagramIcon,
    platform: 'instagram',
  },
  {
    to: social.linkedin,
    label: 'LinkedIn',
    Icon: LinkedInIcon,
    platform: 'linkedin',
  },
];

type SocialLinkProps = {
  className?: string;
  platform: SocialMediaPlatform;
  to?: string;
  size?: 'm' | 's';
  variant?: 'dark' | 'light';
};

const SocialLink = ({ className, platform, to, size = 'm', variant = 'dark' }: SocialLinkProps) => {
  const platformLink = LINKS.find((link) => link.platform === platform);

  if (!platformLink) {
    return null;
  }

  const { Icon, to: defaultTo, label } = platformLink;

  return (
    <ExternalLink
      className={cn(
        'flex items-center rounded-full border border-solid border-transparent outline-none transition-colors',
        {
          'p-1': size === 'm',
          'p-0.5': size === 's',
          'bg-grey-900 text-white hover:bg-white hover:text-grey-900 focus-visible:border-white':
            variant === 'dark',
          'bg-grey-200 text-grey-700 hover:bg-grey-400 hover:text-grey-700 focus-visible:border-black':
            variant === 'light',
        },
        className,
      )}
      href={to || defaultTo}
      title={label}
    >
      <Icon className={size === 'm' ? 'size-4' : 'size-2'} />
    </ExternalLink>
  );
};

export { SocialLink };
