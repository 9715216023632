import addDays from 'date-fns/add_days';
import storageKeys from '../constants/storageKeys';
import store from './store';

const DAYS_TO_REMEMBER = 60;

const storageKey = storageKeys.userSegment;

const forget = () => store.remove(storageKey);

const get = () => {
  const userSegment = store.get(storageKey);

  if (!userSegment) {
    return null;
  }

  return userSegment;
};

const remember = (userSegment) => {
  const now = new Date();

  store.set(
    storageKey,
    {
      userSegment,
    },
    {
      ttl: addDays(now, DAYS_TO_REMEMBER),
    },
  );
};

export { forget, get, remember };
