import cx from 'classnames';
import styles from './Loader.module.scss';

type LoaderProps = {
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Loader = ({ className, ...otherProps }: LoaderProps) => (
  <div className={cx(styles.root, className)} {...otherProps} />
);

export default Loader;
