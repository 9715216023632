import Image, { type ImageProps } from 'next/image';
import { siteName } from 'config';

type LogoImageProps = ImageProps & {
  width: number;
  white?: boolean;
};

const RATIO = 7.5;

const LogoImage = ({ priority, width, ...props }: LogoImageProps) => (
  <Image
    {...props}
    src="/logo.svg"
    alt={siteName}
    width={width}
    height={Math.round(width / RATIO)}
    priority={priority}
  />
);

export { LogoImage };
