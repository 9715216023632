import PropTypes from 'prop-types';
import cx from 'classnames';
import Image from 'next/image';
import { normalizeCountryCode } from '../../services/countries';
import styles from './Flag.module.scss';

const FLAG_DEFAULT_SIZE = 24;

const Flag = ({ as: RenderAs, className, countryCode, rounded, size, ...props }) => (
  <RenderAs
    className={cx(styles.root, rounded && styles.rounded, className)}
    style={{ '--size': `${size}px` }}
  >
    <Image
      src={`/images/flags/${normalizeCountryCode(countryCode)}.png`}
      alt={`${countryCode} flag`}
      fill
      sizes={`${size}px`}
      className={styles.flag}
      quality={100}
      {...props}
    />
  </RenderAs>
);

Flag.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  countryCode: PropTypes.string.isRequired,
  rounded: PropTypes.bool,
  size: PropTypes.number,
};

Flag.defaultProps = {
  as: 'div',
  className: undefined,
  rounded: false,
  size: FLAG_DEFAULT_SIZE,
};

export default Flag;
