import { Stack, AnchorOrButton } from '@carvertical/ui';
import cx from 'classnames';
import { Link } from 'components/common/Link';
import type { MenuItem } from './types';
import styles from './Menu.module.scss';

type MenuProps = {
  items: MenuItem[];
  header?: React.ReactNode;
  subHeader?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  itemClassName?: string;
  variant?: 's' | 'm';
};

const Menu = ({
  items,
  header,
  subHeader,
  footer,
  className,
  itemClassName,
  variant = 'm',
}: MenuProps) => {
  const renderItem = (item: MenuItem) => {
    const { title, path, onClick, href, separate, disabled, active, blank, emphasized } = item;
    const RenderAs = onClick || href || disabled ? AnchorOrButton : Link;

    return (
      <li key={title} className={cx(styles.itemWrapper, separate && styles.separate)}>
        <RenderAs
          to={path}
          onClick={onClick}
          className={cx(
            styles.item,
            itemClassName,
            disabled && styles.disabled,
            active && styles.active,
            emphasized && styles.emphasized,
            variant && styles[variant],
            (active || emphasized) && '!font-semibold',
          )}
          href={disabled ? undefined : href}
          tabIndex={disabled || active ? -1 : 0}
          aria-current={active ? 'page' : undefined}
          target={blank ? '_blank' : undefined}
        >
          {title}
        </RenderAs>
      </li>
    );
  };

  return (
    <Stack gap={0} crossAxisAlign="stretch" className={className}>
      {header && <div className={styles.header}>{header}</div>}

      <div className={styles.divider} />

      {subHeader && (
        <>
          <div className="px-2 pb-2 pt-1">{subHeader}</div>

          <div className={styles.divider} />
        </>
      )}

      <nav>
        <Stack gap={0} as="ul" className="unstyledList">
          {items.map((link) => renderItem(link))}

          <div className={styles.divider} />
        </Stack>
      </nav>

      <div className={styles.divider} />

      {footer && <div className={styles.footer}>{footer}</div>}
    </Stack>
  );
};

export { Menu };
export type { MenuProps };
