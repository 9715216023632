import { useQuery } from '@tanstack/react-query';
import { BLACKLISTED_COUNTRIES } from 'constants/blacklists';
import { supportApi } from 'services/support-api';
import { normalizeCountryCode } from 'services/countries';

type GeocodeIpPayload = {
  as: string;
  city: string;
  country: string;
  countryCode: string;
  isp: string;
  lat: number;
  lon: number;
  org: string;
  query: string;
  region: string;
  regionName: string;
  status: 'success' | 'fail';
  timezone: string;
  zip: string;
};

const useGeoData = () => {
  const { data: geoData } = useQuery({
    queryKey: ['geoData'],
    queryFn: async () => {
      const { data } = await supportApi.get<GeocodeIpPayload>('/geocodeIp');
      if (data.status !== 'success' || isBlacklisted(data.countryCode)) {
        return undefined;
      }
      return {
        ...data,
        countryCode: normalizeCountryCode(data.countryCode),
      };
    },
    staleTime: Infinity,
  });

  return geoData;
};

function isBlacklisted(countryCode?: string) {
  return !!countryCode && BLACKLISTED_COUNTRIES.includes(countryCode.toLowerCase());
}

export { useGeoData };
