import axios from 'axios';
import qs from 'querystringify';
import { mapKeys, upperFirst, isEmpty } from 'lodash';
import type { MarketId } from 'types/market';
import { subscribeUrl } from 'config';
import { LIST_IDS_BY_LOCALES, NON_PROD_LIST_ID } from './constants';

type SubscribeParams = {
  email: string;
  source: string;
  market: MarketId;
  locale: string;
};

type KlaviyoSubscriptionResponse = {
  errors: string[];
  success: boolean;
  data: {
    is_subscribed: boolean;
  };
};

const subscribe = async <TExtraParams>({
  email,
  source,
  ...params
}: SubscribeParams & TExtraParams) => {
  const { locale } = params;
  const listId =
    process.env.APP_ENV === 'production' ? LIST_IDS_BY_LOCALES[locale] : NON_PROD_LIST_ID;

  const fields = {
    // fields should start with an uppercase letter:
    ...mapKeys(params, (_, key) => upperFirst(key)),
    source, // but due to legacy reasons, source stays lowercase
  };

  const payload = qs.stringify({
    email,
    g: listId,
    $fields: !isEmpty(fields) ? Object.keys(fields).join() : undefined,
    ...fields,
  });

  const { data } = await axios.post<KlaviyoSubscriptionResponse>(subscribeUrl, payload);

  return data;
};

export { subscribe };
export type { SubscribeParams, KlaviyoSubscriptionResponse };
