import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Trans } from 'next-i18next';
import { MessagePage } from '../common/MessagePage';
import NeedsRefresh from '../common/NeedsRefresh';

const UpdateTracker = ({ children }) => {
  const [needsUpdate, setNeedsUpdate] = useState(false);

  useEffect(() => {
    window.addEventListener('unhandledrejection', (event) =>
      setNeedsUpdate(/ChunkLoadError/.test(event.reason?.name)),
    );
  });

  return needsUpdate ? (
    <MessagePage>
      <Trans
        parent="p"
        i18nKey="errorPage.updateTitle"
        components={{
          b: <strong />,
        }}
      >
        We’ve made some <b>updates</b> to the site.
      </Trans>
      <NeedsRefresh />
    </MessagePage>
  ) : (
    children
  );
};

UpdateTracker.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UpdateTracker;
