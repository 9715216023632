import PropTypes from 'prop-types';
import { useRouteData } from '../context/RouteDataProvider';

const RouteData = ({ children }) => {
  const routeData = useRouteData();
  return children(routeData);
};

RouteData.propTypes = {
  children: PropTypes.func.isRequired,
};

export default RouteData;
