import { Children, cloneElement, forwardRef, type HTMLProps, type ReactElement } from 'react';
import { useMergeRefs } from '@floating-ui/react';
import { useTooltipContext } from './TooltipContext';

const TooltipTrigger = forwardRef<HTMLElement, HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setReference, propRef]);

    const child = Children.only(children) as ReactElement;

    if (context.plain) {
      return cloneElement(child, { ref });
    }

    return (
      <button
        type="button"
        ref={ref}
        aria-expanded={context.open}
        data-state={context.open ? 'open' : 'closed'}
        {...context.getReferenceProps(props)}
      >
        {children}
      </button>
    );
  },
);

export { TooltipTrigger };
