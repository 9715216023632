import { z } from 'zod';
import type { Market } from 'types/market';
import { validateIdentifier } from 'modules/shared/utils';

const getIdentifierFormSchema = (identifierByMarket: Market['identifier']) =>
  z
    .object({
      mode: z.union([z.literal('vin'), z.literal('lp')]),
      identifier: z.string().optional(),
      countryState: z.string().optional(),
    })
    .superRefine(({ identifier, mode }, refinementContext) =>
      validateIdentifier({
        value: identifier,
        refinementContext,
        identifier: identifierByMarket,
        mode,
        path: 'identifier',
      }),
    );

type IdentifierFormFields = z.infer<ReturnType<typeof getIdentifierFormSchema>>;

export { getIdentifierFormSchema };
export type { IdentifierFormFields };
