import { j as e } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as A } from "react";
import { c as r } from "./chunks/clsx.0d472180.js";
import { c as B } from "./chunks/cn.2ea4f521.js";
import { Icon as S } from "./icon.mjs";
import { v as j } from "./chunks/warnings.8f6035a3.js";
import { i as n } from "./chunks/styles.9b2ce419.js";
import { AnchorOrButton as O } from "./anchorOrButton.mjs";
import { A as b } from "./chunks/index.29668fd9.js";
import { m as C } from "./chunks/motion.24089995.js";
import { Spinner as E } from "./spinner.mjs";
import { Text as w } from "./text.mjs";
import "./chunks/useTranslatableUILabel.cc84b4da.js";
import "./chunks/TranslatableUIProvider.f5945ac7.js";
import "./chunks/TypographyElement.fa6e5999.js";
const f = {
  xxl: "m",
  l: "m",
  m: "xs",
  s: "xs"
}, R = {
  xxl: "m",
  l: "m",
  m: "s",
  s: "s"
}, z = {
  hidden: { opacity: 0, scale: 0 },
  replacing: { opacity: 0.5, scale: 0.5 },
  visible: { opacity: 1, scale: 1 }
}, W = {
  duration: 0.1,
  type: "spring",
  bounce: 0
}, y = A(
  ({
    variant: s = "yellow",
    size: a = "m",
    fullWidth: I,
    leadingIcon: m,
    trailingIcon: i,
    children: N,
    leadingIconClassName: x,
    trailingIconClassName: c,
    loading: t,
    className: g,
    disabled: _,
    progressPercentage: o,
    ...h
  }, V) => {
    const T = f[a], q = !!(t || i), d = i ? "replacing" : "hidden", p = (l, u) => {
      if (typeof l == "string")
        return /* @__PURE__ */ e.jsx(S, { className: u, name: l, size: T });
      const v = l;
      return j({
        iconName: l.displayName,
        componentSize: a,
        sizeMap: f,
        componentName: "Button"
      }), /* @__PURE__ */ e.jsx(
        v,
        {
          className: B(
            "inline-flex",
            {
              "size-2": a === "s" || a === "m",
              "size-3": a === "l" || a === "xxl"
            },
            u
          )
        }
      );
    };
    return /* @__PURE__ */ e.jsxs(
      O,
      {
        ref: V,
        className: r(
          n.root,
          n[s],
          n[a],
          I && n.fullWidth,
          t && n.loading,
          g
        ),
        disabled: _ || t,
        ...h,
        children: [
          m && p(m, r(n.leadingIcon, x)),
          N,
          /* @__PURE__ */ e.jsx(b, { mode: "wait", initial: !1, children: q && /* @__PURE__ */ e.jsx(
            C.span,
            {
              variants: z,
              initial: d,
              animate: "visible",
              exit: d,
              transition: W,
              className: n.iconWrapper,
              children: t ? /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
                /* @__PURE__ */ e.jsx(
                  E,
                  {
                    variant: "currentColor",
                    size: R[a],
                    className: r(
                      n.trailingIcon,
                      c,
                      o && "!ml-0"
                    )
                  }
                ),
                o && /* @__PURE__ */ e.jsx(w, { variant: "xs+", textColor: "light", className: "pl-0.5", children: `${o}%` })
              ] }) : i && p(i, r(n.trailingIcon, c))
            },
            t ? "loading" : "icon"
          ) })
        ]
      }
    );
  }
);
try {
  y.displayName = "Button", y.__docgenInfo = { description: "", displayName: "Button", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, variant: { defaultValue: { value: "yellow" }, description: "", name: "variant", required: !1, type: { name: "ButtonVariant" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "ButtonSize" } }, fullWidth: { defaultValue: null, description: "", name: "fullWidth", required: !1, type: { name: "boolean" } }, leadingIcon: { defaultValue: null, description: "", name: "leadingIcon", required: !1, type: { name: "IconType" } }, trailingIcon: { defaultValue: null, description: "", name: "trailingIcon", required: !1, type: { name: "IconType" } }, leadingIconClassName: { defaultValue: null, description: "", name: "leadingIconClassName", required: !1, type: { name: "string" } }, trailingIconClassName: { defaultValue: null, description: "", name: "trailingIconClassName", required: !1, type: { name: "string" } }, loading: { defaultValue: null, description: "", name: "loading", required: !1, type: { name: "boolean" } }, progressPercentage: { defaultValue: null, description: "", name: "progressPercentage", required: !1, type: { name: "number" } } } };
} catch {
}
export {
  y as Button
};
