import { includes } from 'lodash';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

type Mode = 'site' | 'app';

type ModeContextValue = {
  isSite: boolean;
  isApp: boolean;
};

const MODES: Mode[] = ['site', 'app'];

const MODE_REGEX = /\bmode=(.+?)\b/;

const EMBEDDED_REGEX = /\bembedded\b/;

const ModeContext = createContext<ModeContextValue>({
  isSite: false,
  isApp: false,
});

const ModeProvider = ({ ...otherProps }) => {
  const [selectedMode, setSelectedMode] = useState<Mode>('site');
  const { asPath } = useRouter();

  useEffect(() => {
    let [, mode] = asPath.match(MODE_REGEX) || [];

    // `embedded` backwards compatibility
    if (!mode && asPath.match(EMBEDDED_REGEX)) {
      mode = 'app';
    }

    if (includes(MODES, mode)) {
      setSelectedMode(mode as Mode);
    }
    // This should _intentionally_ run only once, thus:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      isSite: selectedMode === 'site',
      isApp: selectedMode === 'app',
    }),
    [selectedMode],
  );

  return <ModeContext.Provider {...otherProps} value={value} />;
};

const useMode = () => useContext(ModeContext);

export { ModeProvider, useMode };
