import {
  get,
  mapValues,
  includes,
  isPlainObject,
  isArray,
  isEmpty,
  identity,
  pickBy,
} from 'lodash';
import { Container } from 'unstated';
import isoDateRegex from 'regex-iso-date';
import qs from 'querystringify';
import { ReportStatus } from 'constants/report';
import RECORD_TYPES, { SPEC } from 'constants/recordTypes';
import { camelCaseKeys } from 'utils/data';
import { getVehicleName } from 'utils/vehicle';
import Spec from 'models/Spec';
import { getRequest, postRequest } from 'services/request';

const RETRY_TIMEOUT = 2_500;
const INITIAL_STATE = {
  reportStatus: null,
  error: null,
  errorStatus: null,
};
const WMI_ERROR = 'VINExceptionMessage.wmi';
const RECORD_COUNT_ENABLED = false;

class ReportContainer extends Container {
  state = {
    ...INITIAL_STATE,
  };

  get id() {
    return this.state.id;
  }

  get productId() {
    return this.state.productId;
  }

  get vin() {
    return this.state.vin;
  }

  get countryState() {
    return this.state.countryState;
  }

  get licensePlate() {
    return this.state.licensePlate;
  }

  get invokerParam() {
    const { vinEncrypted } = this.state.metaData || {};
    if ((!this.vin || includes(this.vin, '*')) && vinEncrypted) {
      return ['vinEncrypted', vinEncrypted];
    }
    return ['vin', this.vin];
  }

  get make() {
    const specs = this.getRecord(SPEC);
    return specs.make || this.state.make;
  }

  get model() {
    const specs = this.getRecord(SPEC);
    return specs.model;
  }

  get vehicleName() {
    return getVehicleName(this.make, this.model);
  }

  get bodyType() {
    const specs = this.getRecord(SPEC);
    return specs.bodyType;
  }

  get recordCount() {
    return this.state.metaData?.recordCount ?? 0;
  }

  isComplete() {
    const { reportStatus } = this.state;
    return reportStatus === ReportStatus.Finished;
  }

  reset() {
    clearTimeout(this.timeout);
    delete this.pollingKey;

    this.state = {
      ...INITIAL_STATE,
    };
    return this.setState(this.state);
  }

  pollPrecheck(
    productId,
    countryCode,
    { vin, licensePlate, vinEncrypted, voucherId, originalVin, forcedVin, countryState },
  ) {
    this.reset();

    this.pollingKey = vin || vinEncrypted;
    this.fetch(productId, countryCode, {
      vin,
      licensePlate,
      vinEncrypted,
      voucherId,
      originalVin,
      forcedVin,
      countryState,
    });
  }

  async createReport(
    productId,
    { vin, vinEncrypted, licensePlate, countryCode, orderId, countryState } = {},
  ) {
    let reportId;
    let reportVin;
    try {
      ({
        data: { reportId, vin: reportVin },
      } = await postRequest('/reports', {
        vinEncrypted,
        productId,
        countryCode,
        ...(vin && { vin }),
        ...(licensePlate && { licensePlate }),
        ...(countryState && { state: countryState }),
        ...(orderId && { orderMetadata: { orderId } }),
      }));
    } catch (err) {
      const { data = {} } = err;
      if (data.message === 'uservinreportlock.lock_exists_on_vin_for_user') {
        ({ reportId, vin: reportVin } = data);
      } else if (licensePlate) {
        this.setLicencePlateError();
      } else {
        this.handleError(err);
      }
    }
    return { reportId, vin: reportVin || vin };
  }

  getRecord = (type) => {
    if (!includes(RECORD_TYPES, type)) {
      throw new Error('Unknown record type');
    }
    const data = this.state.dataDict || {};
    let record = deserializeRecord(data[type.id]);
    // eslint-disable-next-line default-case
    switch (type) {
      case RECORD_TYPES.SPEC:
        record = !isEmpty(record)
          ? Spec.createAll(camelCaseKeys(record))
          : this.getRecord(RECORD_TYPES.SPEC_LIGHT);
        break;
      case RECORD_TYPES.SPEC_LIGHT:
        record = Spec.createAll(camelCaseKeys(record));
        break;
    }
    return record || type.fallback;
  };

  setLicencePlateError() {
    this.setState({ error: 'lp' });
  }

  async fetch(productId, countryCode, opts, callCount = 1) {
    const { vin, licensePlate, vinEncrypted, id, voucherId, originalVin, forcedVin, countryState } =
      opts;

    const retry = () => {
      this.timeout = setTimeout(() => {
        callCount += 1;
        this.fetch(productId, countryCode, opts, callCount);
      }, RETRY_TIMEOUT);
    };

    let report;
    try {
      let path;
      let getData = identity;

      const params = qs.stringify(
        pickBy({
          countryCode,
          voucherId,
          originalVin,
          forcedVin,
          ...(RECORD_COUNT_ENABLED && { recState: true }),
        }),
        true,
      );
      if (licensePlate) {
        path = `/lookup/lpn/${licensePlate}${params}`;
      } else if (vinEncrypted) {
        path = `/lookup/vin/${vinEncrypted}${params}`;
      } else {
        path = `/lookup/vin/${vin}${params}`;
      }
      getData = (data) => ({
        dataDict: data,
        vin: data.spec?.vin || vin,
        ...(licensePlate && {
          licensePlate: {
            number: vin,
            countryCode,
          },
          countryState,
        }),
        reportStatus: ReportStatus.Finished,
        metaData: { vinEncrypted, ...parseRecordState(data.recState) },
      });

      report = await getData((await getRequest(path)).data);
    } catch (err) {
      if (forcedVin && err.data?.message === WMI_ERROR) {
        report = { vin, reportStatus: ReportStatus.Finished };
      } else {
        this.handleError(err);
      }
    }

    if (!report) {
      return;
    }

    const pollingKey = id || vin || vinEncrypted;
    if (pollingKey !== this.pollingKey) {
      return;
    }

    if (vin && vin !== report.vin) {
      report.vinInvalid = vin;
    }

    await this.setState(report);

    if (!this.isComplete()) {
      retry();
    }
  }

  handleError(err) {
    const apiError = get(err, 'data.message');
    let errorMessage = apiError || err.message || 'Unknown Error';
    if (!apiError && err.status) {
      errorMessage += ` (status code ${err.status})`;
    }
    this.setState({
      error: errorMessage,
      errorStatus: err.status,
      data: err.data?.data,
    });
    if (!apiError) {
      throw new Error(errorMessage);
    }
  }
}

function deserializeRecord(record) {
  if (isPlainObject(record)) {
    return mapValues(record, (value) => {
      if (isoDateRegex().test(value)) {
        return new Date(value);
      }
      return value;
    });
  }
  if (isArray(record)) {
    return record.map(deserializeRecord);
  }
  return record;
}

function parseRecordState(recordState) {
  if (!recordState) {
    return undefined;
  }

  const [recordCount, previouslyGenerated] = recordState.split('|');

  return {
    recordCount: Number(recordCount),
    previouslyGenerated: previouslyGenerated === '+',
  };
}

export default ReportContainer;
