import { omitBy, isNil, camelCase, forOwn, isPlainObject } from 'lodash';

function camelCaseKeys(obj) {
  const deepMapKeys = (obj, fn) => {
    const newObj = {};
    forOwn(obj, (value, key) => {
      newObj[fn(value, key)] = isPlainObject(value) ? deepMapKeys(value, fn) : value;
    });
    return newObj;
  };
  return deepMapKeys(obj, (_, key) => camelCase(key));
}

const omitNilValues = (object) => omitBy(object, isNil);

const isBooleanLike = (value) =>
  typeof value === 'boolean' || value === 'true' || value === 'false';

export { camelCaseKeys, omitNilValues, isBooleanLike };
