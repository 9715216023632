import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getUserId } from 'services/session';
import { getAuthorizationHeader } from 'modules/auth/utils';
import { api } from '../api';

enum ConsentStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
}

enum ConsentType {
  Terms = 'terms',
  Marketing = 'marketing',
  PolicyUpdate2402 = 'policyUpdate2402',
}

type Consent = {
  createdAt: string;
  status: ConsentStatus;
};

type ConsentResponse = {
  email: string;
  consents: Partial<Record<ConsentType, Consent>>;
};

const ENDPOINT = '/consents';

const useConsents = () => {
  const queryClient = useQueryClient();
  const userId = getUserId();
  const authHeader = getAuthorizationHeader();
  const queryKey = ['consents', userId, authHeader];

  const { data: consentsData } = useQuery({
    enabled: !!userId,
    queryKey,
    queryFn: async () => {
      const { data } = await api.get<ConsentResponse>(ENDPOINT, {
        headers: authHeader,
      });

      return data;
    },
    retry: false,
    staleTime: Infinity,
  });

  const acceptConsentMutation = useMutation({
    mutationFn: ({ email, type }: { email: string; type: ConsentType }) =>
      api.post(ENDPOINT, {
        email,
        type,
        status: ConsentStatus.Accepted,
        source: 'privacy-notice',
      }),
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  return { consentsData, acceptConsent: acceptConsentMutation.mutate };
};

export { useConsents, ConsentStatus, ConsentType };
export type { ConsentResponse, Consent };
