import { $ as V, a as N, b as w, c as A, d as O, e as k, f as z, g as B, h as M, i as j, j as H } from "./useListState.d732a4d0.js";
import { e as p, g as U, a as v, m as W, n as q, i as G, o as J, k as g, l as h, p as Q, q as X, r as P, j as Y } from "./utils.f8c6b750.js";
import f, { useMemo as K, useRef as Z, useEffect as _, forwardRef as m, useContext as D, createContext as L } from "react";
const T = /* @__PURE__ */ new WeakMap();
function y(t, e, a) {
  return typeof e == "string" && (e = e.replace(/\s+/g, "")), `${T.get(t)}-${a}-${e}`;
}
function ee(t, e, a) {
  let { key: i, isDisabled: l, shouldSelectOnPressUp: d } = t, { selectionManager: s, selectedKey: n } = e, o = i === n, r = l || e.isDisabled || e.selectionManager.isDisabled(i), { itemProps: c, isPressed: u } = V({
    selectionManager: s,
    key: i,
    ref: a,
    isDisabled: r,
    shouldSelectOnPressUp: d,
    linkBehavior: "selection"
  }), $ = y(e, i, "tab"), x = y(e, i, "tabpanel"), { tabIndex: C } = c, b = e.collection.getItem(i), E = p(b == null ? void 0 : b.props, {
    labelable: !0
  });
  delete E.id;
  let R = U(b == null ? void 0 : b.props);
  return {
    tabProps: v(E, R, c, {
      id: $,
      "aria-selected": o,
      "aria-disabled": r || void 0,
      "aria-controls": o ? x : void 0,
      tabIndex: r ? void 0 : C,
      role: "tab"
    }),
    isSelected: o,
    isDisabled: r,
    isPressed: u
  };
}
function te(t, e, a) {
  let i = N(a) ? void 0 : 0;
  var l;
  const d = y(e, (l = t.id) !== null && l !== void 0 ? l : e == null ? void 0 : e.selectedKey, "tabpanel"), s = w({
    ...t,
    id: d,
    "aria-labelledby": y(e, e == null ? void 0 : e.selectedKey, "tab")
  });
  return {
    tabPanelProps: v(s, {
      tabIndex: i,
      role: "tabpanel",
      "aria-describedby": t["aria-describedby"],
      "aria-details": t["aria-details"]
    })
  };
}
class ae {
  getKeyLeftOf(e) {
    return this.flipDirection ? this.getNextKey(e) : this.getPreviousKey(e);
  }
  getKeyRightOf(e) {
    return this.flipDirection ? this.getPreviousKey(e) : this.getNextKey(e);
  }
  getKeyAbove(e) {
    return this.getPreviousKey(e);
  }
  getKeyBelow(e) {
    return this.getNextKey(e);
  }
  isDisabled(e) {
    var a, i;
    return this.disabledKeys.has(e) || !!(!((i = this.collection.getItem(e)) === null || i === void 0 || (a = i.props) === null || a === void 0) && a.isDisabled);
  }
  getFirstKey() {
    let e = this.collection.getFirstKey();
    return e != null && this.isDisabled(e) && (e = this.getNextKey(e)), e;
  }
  getLastKey() {
    let e = this.collection.getLastKey();
    return e != null && this.isDisabled(e) && (e = this.getPreviousKey(e)), e;
  }
  getNextKey(e) {
    do
      e = this.collection.getKeyAfter(e), e == null && (e = this.collection.getFirstKey());
    while (this.isDisabled(e));
    return e;
  }
  getPreviousKey(e) {
    do
      e = this.collection.getKeyBefore(e), e == null && (e = this.collection.getLastKey());
    while (this.isDisabled(e));
    return e;
  }
  constructor(e, a, i, l = /* @__PURE__ */ new Set()) {
    this.collection = e, this.flipDirection = a === "rtl" && i === "horizontal", this.disabledKeys = l;
  }
}
function ie(t, e, a) {
  let { orientation: i = "horizontal", keyboardActivation: l = "automatic" } = t, { collection: d, selectionManager: s, disabledKeys: n } = e, { direction: o } = A(), r = K(() => new ae(d, o, i, n), [
    d,
    n,
    i,
    o
  ]), { collectionProps: c } = O({
    ref: a,
    selectionManager: s,
    keyboardDelegate: r,
    selectOnFocus: l === "automatic",
    disallowEmptySelection: !0,
    scrollRef: a,
    linkBehavior: "selection"
  }), u = W();
  T.set(e, u);
  let $ = w({
    ...t,
    id: u
  });
  return {
    tabListProps: {
      ...v(c, $),
      role: "tablist",
      "aria-orientation": i,
      tabIndex: void 0
    }
  };
}
function le(t) {
  var e;
  let [a, i] = k(t.selectedKey, (e = t.defaultSelectedKey) !== null && e !== void 0 ? e : null, t.onSelectionChange), l = K(() => a != null ? [
    a
  ] : [], [
    a
  ]), { collection: d, disabledKeys: s, selectionManager: n } = z({
    ...t,
    selectionMode: "single",
    disallowEmptySelection: !0,
    allowDuplicateSelectionEvents: !0,
    selectedKeys: l,
    onSelectionChange: (r) => {
      var c;
      let u = (c = r.values().next().value) !== null && c !== void 0 ? c : null;
      u === a && t.onSelectionChange && t.onSelectionChange(u), i(u);
    }
  }), o = a != null ? d.getItem(a) : null;
  return {
    collection: d,
    disabledKeys: s,
    selectionManager: n,
    selectedKey: a,
    setSelectedKey: i,
    selectedItem: o
  };
}
function de(t) {
  var e;
  let a = le({
    ...t,
    suppressTextValueWarning: !0,
    defaultSelectedKey: (e = t.defaultSelectedKey) !== null && e !== void 0 ? e : I(t.collection, t.disabledKeys ? new Set(t.disabledKeys) : /* @__PURE__ */ new Set())
  }), { selectionManager: i, collection: l, selectedKey: d } = a, s = Z(d);
  return _(() => {
    let n = d;
    (i.isEmpty || !l.getItem(n)) && (n = I(l, a.disabledKeys), n != null && i.setSelectedKeys([
      n
    ])), (n != null && i.focusedKey == null || !i.isFocused && n !== s.current) && i.setFocusedKey(n), s.current = n;
  }), {
    ...a,
    isDisabled: t.isDisabled || !1
  };
}
function I(t, e) {
  let a = null;
  if (t) {
    var i, l, d, s;
    for (a = t.getFirstKey(); (e.has(a) || !((l = t.getItem(a)) === null || l === void 0 || (i = l.props) === null || i === void 0) && i.isDisabled) && a !== t.getLastKey(); )
      a = t.getKeyAfter(a);
    (e.has(a) || !((s = t.getItem(a)) === null || s === void 0 || (d = s.props) === null || d === void 0) && d.isDisabled) && a === t.getLastKey() && (a = t.getFirstKey());
  }
  return a;
}
const S = /* @__PURE__ */ L(null), F = /* @__PURE__ */ L(null);
function se(t, e) {
  [t, e] = G(t, e, S);
  let { collection: a, document: i } = B(), { children: l, orientation: d = "horizontal" } = t;
  return l = K(() => typeof l == "function" ? l({
    orientation: d,
    defaultChildren: null
  }) : l, [
    l,
    d
  ]), /* @__PURE__ */ f.createElement(f.Fragment, null, /* @__PURE__ */ f.createElement(J, null, /* @__PURE__ */ f.createElement(M.Provider, {
    value: i
  }, l)), /* @__PURE__ */ f.createElement(ne, {
    props: t,
    collection: a,
    tabsRef: e
  }));
}
function ne({ props: t, tabsRef: e, collection: a }) {
  let { orientation: i = "horizontal" } = t, l = de({
    ...t,
    collection: a,
    children: void 0
  }), { focusProps: d, isFocused: s, isFocusVisible: n } = g({
    within: !0
  }), o = K(() => ({
    orientation: i,
    isFocusWithin: s,
    isFocusVisible: n
  }), [
    i,
    s,
    n
  ]), r = h({
    ...t,
    defaultClassName: "react-aria-Tabs",
    values: o
  });
  return /* @__PURE__ */ f.createElement("div", {
    ...p(t),
    ...d,
    ...r,
    ref: e,
    slot: t.slot || void 0,
    "data-focused": s || void 0,
    "data-orientation": i,
    "data-focus-visible": n || void 0,
    "data-disabled": l.isDisabled || void 0
  }, /* @__PURE__ */ f.createElement(Q, {
    values: [
      [
        S,
        t
      ],
      [
        F,
        l
      ]
    ]
  }, r.children));
}
const ye = /* @__PURE__ */ m(se);
function oe(t, e) {
  return D(M) ? /* @__PURE__ */ f.createElement(j, t) : /* @__PURE__ */ f.createElement(re, {
    props: t,
    forwardedRef: e
  });
}
function re({ props: t, forwardedRef: e }) {
  let a = D(F), { orientation: i = "horizontal", keyboardActivation: l = "automatic" } = X(S), d = P(e), { tabListProps: s } = ie({
    ...t,
    orientation: i,
    keyboardActivation: l
  }, a, d), n = h({
    ...t,
    children: null,
    defaultClassName: "react-aria-TabList",
    values: {
      orientation: i,
      state: a
    }
  }), o = p(t);
  return delete o.id, /* @__PURE__ */ f.createElement("div", {
    ...o,
    ...s,
    ref: d,
    ...n,
    "data-orientation": i || void 0
  }, [
    ...a.collection
  ].map((r) => /* @__PURE__ */ f.createElement(ue, {
    key: r.key,
    item: r,
    state: a
  })));
}
const pe = /* @__PURE__ */ m(oe);
function ce(t, e) {
  return H("item", t, e, t.children);
}
const he = /* @__PURE__ */ m(ce);
function ue({ item: t, state: e }) {
  let a = P(t.props.ref), { tabProps: i, isSelected: l, isDisabled: d, isPressed: s } = ee({
    key: t.key,
    ...t.props
  }, e, a), { focusProps: n, isFocused: o, isFocusVisible: r } = g(), { hoverProps: c, isHovered: u } = Y({
    isDisabled: d
  }), $ = h({
    ...t.props,
    children: t.rendered,
    defaultClassName: "react-aria-Tab",
    values: {
      isSelected: l,
      isDisabled: d,
      isFocused: o,
      isFocusVisible: r,
      isPressed: s,
      isHovered: u,
      state: e
    }
  }), x = t.props.href ? "a" : "div";
  return /* @__PURE__ */ f.createElement(x, {
    ...v(i, n, c, $),
    ref: a,
    "data-selected": l || void 0,
    "data-disabled": d || void 0,
    "data-focused": o || void 0,
    "data-focus-visible": r || void 0,
    "data-pressed": s || void 0,
    "data-hovered": u || void 0
  });
}
function fe(t, e) {
  const a = D(F);
  let i = P(e), { tabPanelProps: l } = te(t, a, i), { focusProps: d, isFocused: s, isFocusVisible: n } = g(), o = a.selectedKey === t.id, r = h({
    ...t,
    defaultClassName: "react-aria-TabPanel",
    values: {
      isFocused: s,
      isFocusVisible: n,
      isInert: !o,
      state: a
    }
  });
  if (!o && !t.shouldForceMount)
    return null;
  let c = p(t);
  delete c.id;
  let u = o ? v(c, l, d, r) : r;
  return /* @__PURE__ */ f.createElement("div", {
    ...u,
    ref: i,
    "data-focused": s || void 0,
    "data-focus-visible": n || void 0,
    // @ts-ignore
    inert: o ? void 0 : "true",
    "data-inert": o ? void 0 : "true"
  });
}
const Ke = /* @__PURE__ */ q(fe);
export {
  ye as $,
  he as a,
  pe as b,
  Ke as c
};
