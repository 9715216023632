import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { createInstance } from 'services/store';
import { useEventTracking } from 'modules/analytics';
import type AuthContainer from 'containers/AuthContainer';
import { logInWithCode } from 'services/authApi';
import { useRouteData } from 'context/RouteDataProvider';
import { getBasePath } from 'utils/locale';
import { CODE_ACCESS_TOKEN_STORE_KEY } from '../constants';
import type { LoginCodeParams } from '../types';

type UseLoginWithCodeMutationProps = {
  auth: AuthContainer;
};

const sessionStore = createInstance('session');

const useLoginWithCodeMutation = ({ auth }: UseLoginWithCodeMutationProps) => {
  const { track } = useEventTracking();
  const router = useRouter();
  const { locale } = useRouteData();
  const basePath = getBasePath(locale);

  const codeAccessToken = useMemo(
    () => sessionStore.get(CODE_ACCESS_TOKEN_STORE_KEY) as string | undefined,
    [],
  );

  const { mutate, isPending, isError, error, reset } = useMutation({
    mutationFn: async ({ code, captchaResponse }: LoginCodeParams) => {
      auth.setTransientAuthParams({
        accessToken: await logInWithCode(code, {
          codeAccessToken,
          captchaResponse,
        }),
      });
    },
    onSuccess: () => {
      sessionStore.remove(CODE_ACCESS_TOKEN_STORE_KEY);
      track({ event: 'LoginCodeSucceeded' });
      router.push(`${basePath}auth/callback`);
    },
    onError: (_, { code, captchaResponse }) => {
      track({
        event: 'LoginCodeFailed',
        codeProvided: !!code,
        accessTokenAvailable: !!codeAccessToken,
        captchaAvailable: !!captchaResponse,
      });
    },
  });

  return { loginWithCode: mutate, isPending, isError, error, reset };
};

export { useLoginWithCodeMutation };
