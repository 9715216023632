import cx from 'classnames';
import type { ElementType } from 'react';
import type { ButtonProps as BaseButtonProps, PolymorphicComponentProp } from 'types/component';
import styles from './Button.module.scss';

type ButtonProps = BaseButtonProps & {
  variant?: 'plain' | 'primary' | 'dark' | 'blue' | 'outline';
  size?: 'tiny' | 'small' | 'medium' | 'large';
  fullWidth?: boolean;
  LeadingIcon?: () => JSX.Element;
  TrailingIcon?: () => JSX.Element;
};

/**
 * @deprecated Use Button from `@carvertical/ui` instead.
 */
const Button = <TElement extends ElementType = 'button'>({
  LeadingIcon,
  TrailingIcon,
  as,
  children,
  className,
  fullWidth,
  size = 'large',
  type = 'button',
  variant = 'plain',
  ...props
}: PolymorphicComponentProp<TElement, ButtonProps>) => {
  const RenderAs = as || 'button';

  return (
    <RenderAs
      className={cx(
        styles.root,
        styles[variant],
        styles[size],
        fullWidth && styles.fullWidth,
        (!!TrailingIcon || !!LeadingIcon) && styles.centered,
        className,
      )}
      type={RenderAs === 'button' ? type : undefined}
      {...props}
    >
      <>
        {LeadingIcon && (
          <span className={styles.leadingIcon}>
            <LeadingIcon />
          </span>
        )}
        {children}
        {TrailingIcon && (
          <span className={styles.trailingIcon}>
            <TrailingIcon />
          </span>
        )}
      </>
    </RenderAs>
  );
};

export { Button };
export type { ButtonProps };
