import { Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import styles from './Copyright.module.scss';

const Copyright = () => {
  const { t } = useTranslation();

  const explode = () => {
    throw new Error('💥');
  };

  return (
    <>
      <Text variant="xs" textColor="darkSecondary" align="center">
        {`© ${new Date().getFullYear()} carVertical. ${t('general.copyrightText')}`}
      </Text>

      <Text variant="s" textColor="darkSecondary" align="center">
        {process.env.APP_ENV !== 'production' && (
          <span>
            Environment: {process.env.APP_ENV}
            <button
              type="button"
              className={styles.explode}
              title="Don’t click here"
              onClick={explode}
            >
              <span role="img" aria-label="Explode">
                💣
              </span>
            </button>
          </span>
        )}
      </Text>
    </>
  );
};

export { Copyright };
