import { Heading, LinkButton, Stack, Text } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm, FormProvider } from 'react-hook-form';
import { useRouteData } from 'context/RouteDataProvider';
import { Link } from 'components/common/Link';
import { AppBanner } from './AppBanner';
import { SubscribeForm } from './SubscribeForm';
import { type SubscribeFormFields, SubscribeFormSchema } from './schemas';
import styles from './FollowSection.module.scss';

const FollowSection = () => {
  const { t } = useTranslation();
  const { pages } = useRouteData();

  const form = useForm<SubscribeFormFields>({
    defaultValues: { email: '' },
    resolver: zodResolver(SubscribeFormSchema),
  });

  return (
    <Stack
      gap={3}
      type="horizontal"
      crossAxisAlign="center"
      mainAxisAlign="spaceBetween"
      className={styles.root}
    >
      <Stack gap={3} mainAxisAlign="center" className={styles.block}>
        <Heading as="h2" variant="s" textColor="light">
          {t('footer.joinNewsletterTitle')}
        </Heading>

        <Text variant="s" textColor="light">
          {t('footer.joinNewsletterSubtitle')}
        </Text>

        <FormProvider {...form}>
          <SubscribeForm inverted className={styles.form} />
        </FormProvider>

        <Trans
          variant="xs"
          parent={Text}
          textColor="darkSecondary"
          i18nKey="footer.optOutLabel"
          as="p"
          components={{
            1: (
              <LinkButton
                as={Link}
                href={pages.privacy.path}
                target="_blank"
                className={styles.privacyLink}
              />
            ),
          }}
        >
          You may opt out any time. For more details, review our
          {'<1>Privacy Policy.</1>'}
        </Trans>
      </Stack>

      <AppBanner className="hideUntilTabletLandscape" />
    </Stack>
  );
};

export { FollowSection };
