type QueryParam = {
  name: string;
  value: string | Record<string, string>;
};

const satisfy =
  <TSatisfied>(): (<T extends TSatisfied>(value: T) => T) =>
  (value) =>
    value;

const QUERY_PARAMS = satisfy<Record<string, QueryParam>>()({
  identifierModeOverride: {
    name: 'im',
    value: {
      vin: 'vin',
      lp: 'lp',
    },
  },
  precheckFlow: {
    name: 'precheckFlow',
    value: {
      email: 'email',
    },
  },
});

export type { QueryParam };
export { QUERY_PARAMS };
