import { useRef, useEffect } from 'react';
import autoAnimate from '@formkit/auto-animate';

const useAutoAnimate = (options = {}) => {
  const element = useRef(null);

  useEffect(() => {
    if (element?.current) {
      autoAnimate(element.current, options);
    }
  }, [options]);

  return [element];
};

export { useAutoAnimate };
