import { Heading, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import cx from 'classnames';
import ArtDirectedImage from 'components/common/ArtDirectedImage';
import { useRouteData } from 'context/RouteDataProvider';
import { getFeatureIllustration } from '../../constants';
import { useFeatures } from '../../hooks';
import type { Feature } from '../../types';
import styles from './FeatureCard.module.scss';

type FeatureCardProps = Feature & { index?: number };

const FeatureCard = ({ index, id, descriptionKey, titleKey }: FeatureCardProps) => {
  const { t } = useTranslation();
  const { locale } = useRouteData();
  const [fallback, setFallback] = useState(false);
  const { highlightedFeature } = useFeatures();
  const illustration = getFeatureIllustration(id, locale);
  const illustrationSrc = fallback ? illustration.fallbackSrc : illustration.src;

  return (
    <Stack
      as="article"
      className={cx(styles.root, index && index !== highlightedFeature && 'opacity-[0.3]')}
      gap={{ mobileUp: 4, desktopUp: 12 }}
      crossAxisAlign="stretch"
      type={{ mobileUp: 'vertical', desktopUp: 'horizontal' }}
    >
      <Stack crossAxisAlign="center">
        <ArtDirectedImage
          className={styles.illustration}
          // @ts-expect-error TS(2739)
          srcSet={{
            forTabletPortraitUp: {
              src: illustrationSrc,
              width: 400,
              height: 330,
            },
            forDesktopUp: {
              src: illustrationSrc,
              width: 500,
              height: 413,
            },
          }}
          src={illustrationSrc}
          onError={() => setFallback(true)}
          quality={100}
          width={291}
          height={241}
        />
      </Stack>

      <Stack gap={2} mainAxisAlign="center">
        <Heading as="h3" variant="m">
          {t(titleKey)}
        </Heading>

        <Text as="p" variant="m">
          {t(descriptionKey)}
        </Text>
      </Stack>
    </Stack>
  );
};

export { FeatureCard };
