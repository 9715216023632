import { startsWith } from 'lodash';
import { Container } from 'unstated';
// FIXME: Import from the base of auth module creates a circular dependency
import { storeAccessToken } from 'modules/auth/utils';
import { createTempUser, logOut, impersonate, unimpersonate } from '../services/authApi';
import { getBaseUrl } from '../services/site';
import store from '../services/store';
import {
  clearSession,
  getGuestId,
  getImpersonatedUserId,
  getUserId,
  hasSessionExpired,
  initSession,
  setSession,
  setUserId,
} from '../services/session';
import { removeTrackingEmail } from '../services/recordEvent';
import { history } from '../hoc/withRouter';

const IS_TEST_ENV = process.env.APP_ENV === 'test';
const REDIRECT_TO = 'redirectTo';
const ENSURE_USER_ID_INTERVAL = IS_TEST_ENV ? 1 : 500;

let transientAuthParams = {};

function rememberRedirectPath() {
  const path = window.location.href.replace(getBaseUrl(), '/').replace('//', '/');

  if (!path || startsWith(path, '/auth/')) {
    return;
  }
  store.set(REDIRECT_TO, path);
}

export default class AuthContainer extends Container {
  state = {
    authenticated: false,
    unauthenticated: false,
    loggingOut: false,
    redirectTo: '/',
    error: undefined,
    guestId: getGuestId(),
    userId: getUserId(),
    requiresLoginCode: false,
  };

  init() {
    initSession();

    const authenticated = !hasSessionExpired();
    this.setState({
      authenticated,
      unauthenticated: !authenticated,
    });
  }

  getTransientAuthParams() {
    const params = transientAuthParams;
    transientAuthParams = {};
    return params;
  }

  setTransientAuthParams(params) {
    transientAuthParams = { ...params };
  }

  setUserId(userId) {
    setUserId(userId);
    this.setState({ userId });
  }

  logIn({ mode, email = '', andRedirectBack = true } = {}) {
    if (andRedirectBack) {
      clearSession();
      rememberRedirectPath();
    }
    this.setTransientAuthParams({ email });
    history.push(mode === 'signup' ? '/auth/signup' : '/auth');
  }

  logOut = async () => {
    await logOut();

    return new Promise((resolve) => {
      clearSession();

      removeTrackingEmail();

      this.setState({ loggingOut: true });

      // we’re giving a chance to authentication state consumers to perform custom logic before fully logging the user out
      setTimeout(() => {
        this.setState({
          loggingOut: false,
          authenticated: false,
          unauthenticated: true,
        });

        resolve();
      });
    });
  };

  async ensureUser(email, { redirectTimeout = 0 } = {}) {
    try {
      const accessToken = await createTempUser(email);

      setSession({ accessToken, expiresInDays: 90 });

      this.setState({
        authenticated: true,
        unauthenticated: false,
      });

      return await new Promise((resolve) => {
        const userIdInterval = setInterval(() => {
          const { userId } = this.state;
          if (!userId) {
            return;
          }
          clearInterval(userIdInterval);
          resolve(userId);
        }, ENSURE_USER_ID_INTERVAL);
      });
    } catch (error) {
      const { errorCode, accessToken } = error.response.data;

      const isConflict = error.response && error.response.status === 409;

      if (errorCode === 'code-required') {
        this.setState({ requiresLoginCode: true });
        storeAccessToken(accessToken);
        rememberRedirectPath();
        return undefined;
      }

      if (isConflict) {
        setTimeout(() => {
          this.logIn({ email });
        }, redirectTimeout);
        return undefined;
      }
      throw error;
    }
  }

  async authenticate(accessToken, { impersonatedUserId } = {}) {
    setSession({ accessToken, impersonatedUserId });

    return this.setState({
      authenticated: true,
      unauthenticated: false,
      redirectTo: store.getOnce(REDIRECT_TO),
    });
  }

  isImpersonating() {
    return Boolean(getImpersonatedUserId());
  }

  async impersonate(userId) {
    const {
      data: { accessToken },
    } = await impersonate(userId);
    return this.authenticate(accessToken, { impersonatedUserId: userId });
  }

  async unimpersonate() {
    const {
      data: { accessToken },
    } = await unimpersonate();
    return this.authenticate(accessToken);
  }
}
