const map = require('lodash/map');
const flatMap = require('lodash/flatMap');
const find = require('lodash/find');
const keyBy = require('lodash/keyBy');
const mapValues = require('lodash/mapValues');
const format = require('date-fns/format');
const PRODUCTS = require('./constants/products');
const {
  VIN,
  LP,
  VIN_PATTERN,
  LP_CROATIA,
  LP_FINLAND,
  LP_FRANCE,
  LP_SLOVAKIA,
  LP_SWEDEN,
  LP_DENMARK,
  LP_HUNGARY,
  LP_UKRAINE,
  LP_BRITAIN,
  LP_LATVIA,
  LP_CZECHIA,
  LP_ITALY,
  LP_POLAND,
  LP_MEXICO,
} = require('./constants/identifiers');
const REGIONS = require('./constants/regions');

const INFO_EMAIL = 'info@carvertical.com';
const SUPPORT_EMAIL = 'support@carvertical.com';

const TIER_2_PRICING = 't2';

/**
 * @deprecated use markets from config-ts instead.
 */
const markets = mapValues(
  {
    usa: {
      countryCode: 'us',
      countryCodeLong: 'usa',
      region: REGIONS.northAmerica,
      flavors: [
        { locale: 'en', basePath: '/us/' },
        {
          locale: 'es-US',
          basePath: '/us/es/',
          i18nLocale: 'es-MX',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'USD',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT_USA,
      },
    },
    europe: {
      countryCode: 'eu',
      countryCodeLong: 'eu',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [
        {
          locale: 'en-EU',
          basePath: '/en/',
          i18nLocale: 'en',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'EUR',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    britain: {
      countryCode: 'gb',
      countryCodeLong: 'gbr',
      region: REGIONS.europe,
      flavors: [{ locale: 'en-GB', basePath: '/gb/' }],
      marketSwitcherDisabled: true,
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_BRITAIN,
        initial: LP,
      },
      currency: 'GBP',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT_BRITAIN,
      },
    },
    australia: {
      countryCode: 'au',
      countryCodeLong: 'aus',
      region: REGIONS.oceania,
      flavors: [
        {
          locale: 'en-AU',
          basePath: '/au/',
          i18nLocale: 'en',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'AUD',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT_AUSTRALIA,
      },
    },
    lithuania: {
      countryCode: 'lt',
      countryCodeLong: 'ltu',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [
        { locale: 'lt', basePath: '/lt/' },
        {
          locale: 'ru-LT',
          basePath: '/lt/ru/',
          i18nLocale: 'ru',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'EUR',
      vat: {
        alreadyIncluded: true,
      },
      checkoutMethods: ['neopay', 'card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    estonia: {
      countryCode: 'ee',
      countryCodeLong: 'est',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [
        { locale: 'et', basePath: '/et/' },
        {
          locale: 'ru-EE',
          basePath: '/et/ru/',
          i18nLocale: 'ru',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'EUR',
      checkoutMethods: ['neopay', 'card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    latvia: {
      countryCode: 'lv',
      countryCodeLong: 'lva',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [
        { locale: 'lv', basePath: '/lv/' },
        {
          locale: 'ru-LV',
          basePath: '/lv/ru/',
          i18nLocale: 'ru',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_LATVIA,
        initial: VIN,
      },
      currency: 'EUR',
      checkoutMethods: ['neopay', 'card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    poland: {
      countryCode: 'pl',
      countryCodeLong: 'pol',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'pl', basePath: '/pl/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_POLAND,
        initial: LP,
      },
      currency: 'PLN',
      checkoutMethods: ['tpay', 'card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    romania: {
      countryCode: 'ro',
      countryCodeLong: 'rou',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'ro', basePath: '/ro/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'RON',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    hungary: {
      countryCode: 'hu',
      countryCodeLong: 'hun',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'hu', basePath: '/hu/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_HUNGARY,
        initial: VIN,
      },
      currency: 'HUF',
      checkoutMethods: ['card', 'paypal'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    france: {
      countryCode: 'fr',
      countryCodeLong: 'fra',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'fr', basePath: '/fr/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_FRANCE,
        initial: VIN,
      },
      currency: 'EUR',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    ukraine: {
      countryCode: 'ua',
      countryCodeLong: 'ukr',
      region: REGIONS.europe,
      flavors: [
        { locale: 'uk', basePath: '/ua/' },
        {
          locale: 'ru-UA',
          basePath: '/ua/ru/',
          i18nLocale: 'ru',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_UKRAINE,
        initial: VIN,
      },
      currency: 'USD',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    sweden: {
      countryCode: 'se',
      countryCodeLong: 'swe',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'sv', basePath: '/se/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_SWEDEN,
        initial: VIN,
      },
      currency: 'SEK',
      pricing: TIER_2_PRICING,
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    belgium: {
      countryCode: 'be',
      countryCodeLong: 'bel',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [
        {
          locale: 'nl-BE',
          basePath: '/be/',
          i18nLocale: 'nl',
        },
        {
          locale: 'fr-BE',
          basePath: '/be/fr/',
          i18nLocale: 'fr',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_FRANCE,
        initial: VIN,
      },
      currency: 'EUR',
      pricing: TIER_2_PRICING,
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    czechia: {
      countryCode: 'cz',
      countryCodeLong: 'cze',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'cs', basePath: '/cz/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_CZECHIA,
        initial: VIN,
      },
      currency: 'CZK',
      vat: {
        shortcutsDisabled: true,
      },
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
      exclude: ['odometerBrands', 'titleBrands', 'vehicleUsage', 'taxi'],
    },
    croatia: {
      countryCode: 'hr',
      countryCodeLong: 'hrv',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'hr', basePath: '/hr/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_CROATIA,
        initial: VIN,
      },
      currency: 'EUR',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    bulgaria: {
      countryCode: 'bg',
      countryCodeLong: 'bgr',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'bg', basePath: '/bg/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'BGN',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    slovakia: {
      countryCode: 'sk',
      countryCodeLong: 'svk',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'sk', basePath: '/sk/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_SLOVAKIA,
        initial: VIN,
      },
      currency: 'EUR',
      checkoutMethods: ['neopay', 'card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
      exclude: ['odometerBrands', 'titleBrands', 'taxi'],
      include: ['activityChecks.outstandingFinance'],
    },
    serbia: {
      countryCode: 'rs',
      countryCodeLong: 'srb',
      region: REGIONS.europe,
      flavors: [{ locale: 'sr', basePath: '/rs/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'RSD',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    finland: {
      countryCode: 'fi',
      countryCodeLong: 'fin',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [
        { locale: 'fi', basePath: '/fi/' },
        {
          locale: 'sv-FI',
          basePath: '/fi/sv/',
          i18nLocale: 'sv',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_FINLAND,
        initial: VIN,
      },
      currency: 'EUR',
      pricing: TIER_2_PRICING,
      checkoutMethods: ['neopay', 'card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    slovenia: {
      countryCode: 'si',
      countryCodeLong: 'svn',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'sl', basePath: '/si/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'EUR',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    germany: {
      countryCode: 'de',
      countryCodeLong: 'deu',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'de', basePath: '/de/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'EUR',
      vat: {
        alreadyIncluded: true,
      },
      checkoutMethods: ['sofort', 'neopay', 'card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    italy: {
      countryCode: 'it',
      countryCodeLong: 'ita',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'it', basePath: '/it/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_ITALY,
        initial: LP,
      },
      currency: 'EUR',
      pricing: TIER_2_PRICING,
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    switzerland: {
      countryCode: 'ch',
      countryCodeLong: 'che',
      region: REGIONS.europe,
      flavors: [
        {
          locale: 'de-CH',
          basePath: '/ch/',
          i18nLocale: 'de',
        },
        {
          locale: 'fr-CH',
          basePath: '/ch/fr/',
          i18nLocale: 'fr',
        },
        {
          locale: 'it-CH',
          basePath: '/ch/it/',
          i18nLocale: 'it',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'EUR',
      pricing: TIER_2_PRICING,
      checkoutMethods: ['card', 'sofort', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    denmark: {
      countryCode: 'dk',
      countryCodeLong: 'dnk',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'da', basePath: '/dk/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_DENMARK,
        initial: VIN,
      },
      currency: 'DKK',
      pricing: TIER_2_PRICING,
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    mexico: {
      countryCode: 'mx',
      countryCodeLong: 'mex',
      region: REGIONS.northAmerica,
      flavors: [{ locale: 'es-MX', basePath: '/mx/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
        [LP]: LP_MEXICO,
        initial: LP,
      },
      currency: 'MXN',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT_MEXICO,
      },
    },
    spain: {
      countryCode: 'es',
      countryCodeLong: 'esp',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'es', basePath: '/es/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'EUR',
      checkoutMethods: ['neopay', 'card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    portugal: {
      countryCode: 'pt',
      countryCodeLong: 'prt',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'pt', basePath: '/pt/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'EUR',
      vat: {
        alreadyIncluded: true,
      },
      checkoutMethods: ['mbway', 'card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    greece: {
      countryCode: 'gr',
      countryCodeLong: 'grc',
      isEuropeanUnion: true,
      region: REGIONS.europe,
      flavors: [{ locale: 'el-GR', basePath: '/gr/' }],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'EUR',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT,
      },
    },
    southAfrica: {
      countryCode: 'za',
      countryCodeLong: 'zaf',
      region: REGIONS.africa,
      flavors: [
        {
          locale: 'en-ZA',
          basePath: '/za/',
          i18nLocale: 'en',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'ZAR',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT_SOUTH_AFRICA,
      },
    },
    uae: {
      countryCode: 'ae',
      countryCodeLong: 'are',
      region: REGIONS.middleEast,
      flavors: [
        {
          locale: 'en-AE',
          basePath: '/ae/',
          i18nLocale: 'en',
        },
      ],
      identifier: {
        [VIN]: VIN_PATTERN,
      },
      currency: 'AED',
      checkoutMethods: ['card', 'paypal', 'crypto'],
      products: {
        precheck: PRODUCTS.PRECHECK,
        report: PRODUCTS.REPORT_MIDDLE_EAST,
      },
    },
  },
  (market, id) => ({
    ...market,
    vat: {
      ...market.vat,
      initialCountryCode: market.countryCode !== 'eu' ? market.countryCode : 'us',
      alreadyIncluded: market.vat?.alreadyIncluded || !market.isEuropeanUnion,
    },
    emails: {
      info: INFO_EMAIL,
      support: SUPPORT_EMAIL.replace('@', `+${id}@`),
    },
  }),
);

/**
 * @deprecated use flavors from config-ts instead.
 */
const flavors = flatMap(markets, 'flavors');
const baseLocale = find(flavors, { basePath: '/us/' }).locale;

const mergeConfigs = (defaultConfig, configsByEnv = {}) => ({
  ...defaultConfig,
  ...(process.env.APP_ENV && configsByEnv[process.env.APP_ENV]),
});

const apiConfig = mergeConfigs(
  {
    baseUrl: 'https://api.carvertical.dev/v1',
  },
  {
    production: {
      baseUrl: 'https://api.carvertical.net/v1',
    },
    test: {
      baseUrl: 'https://api.carvertical.dev/v1',
    },
  },
);

const supportApiConfig = mergeConfigs(
  {
    baseUrl: 'https://support-api.carvertical.dev',
  },
  {
    production: {
      baseUrl: 'https://support-api.carvertical.net',
    },
    test: {
      baseUrl: 'https://support-api.carvertical.dev',
    },
  },
);

const paymentsApiConfig = mergeConfigs(
  {
    baseUrl: 'https://payments-api.carvertical.dev/v1',
  },
  {
    production: {
      baseUrl: 'https://payments-api.carvertical.net/v1',
    },
  },
);

const authApiConfig = mergeConfigs(
  {
    baseUrl: 'https://auth-api.carvertical.dev/v1',
  },
  {
    production: {
      baseUrl: 'https://auth-api.carvertical.net/v1',
    },
  },
);

const siteConfig = mergeConfigs(
  {
    siteRoot: 'https://www.carvertical.com',
    viewerBaseUrl: 'https://viewer-staging.carvertical.com',
  },
  {
    staging: {
      siteRoot: 'https://staging.carvertical.com',
    },
    production: {
      viewerBaseUrl: 'https://viewer.carvertical.com',
    },
  },
);

const gtmConfig = mergeConfigs(undefined, {
  staging: {
    gtmId: 'GTM-5R73NMF',
  },
  production: {
    gtmId: 'GTM-PZDTRDW',
  },
});

const adyenConfig = mergeConfigs(
  {
    clientKey: 'test_DOFE2AQLSBE63GRIW27BTH4KOISJRJBR',
    sdk: 'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/4.9.0/adyen.js',
  },
  {
    staging: {
      clientKey: 'test_DOFE2AQLSBE63GRIW27BTH4KOISJRJBR',
      sdk: 'https://checkoutshopper-test.adyen.com/checkoutshopper/sdk/4.9.0/adyen.js',
    },
    production: {
      clientKey: 'live_7LG6APJQIJCFXHGMZSA7J4OXJ4WZHSTK',
      sdk: 'https://checkoutshopper-live.adyen.com/checkoutshopper/sdk/4.9.0/adyen.js',
    },
  },
);

const payPalConfig = mergeConfigs(
  {
    clientId: 'AUW5WHLG6RfPmby8A4Cb8H7bWCkSYx6lwOY2lCmvawNtzILeMKgOfsqWdlhIssB_61TtPgm69Qpwy9pV',
  },
  {
    production: {
      clientId: 'AarYrolhLYtw2vonJTtbpmPbfEtPbkf5-TuCW94tDzGxvnCXLoAQWJqZckcYVsI02LMW7Dwivq5s9z4P',
    },
  },
);

const cloudFrontConfig = mergeConfigs(
  {
    baseUrl: 'https://d2hfnjlb9g2hbt.cloudfront.net',
  },
  {
    production: {
      baseUrl: 'https://dzcn1o1d7w7lq.cloudfront.net',
    },
  },
);

const sanityConfig = {
  projectId: 'v2r3zeun',
  dataset: 'production',
  apiVersion: format(new Date(), 'YYYY-MM-DD'),
};

const recaptchaConfig = mergeConfigs(
  {
    invisibleV2Key: '6Lcjpu4pAAAAAASxnTXVCVKWWwoqhxYVlY9Na6PK',
  },
  {
    production: {
      invisibleV2Key: '6LfeCu8pAAAAAMaJ6S2NHtS7qB7OGkGyyypyf9gF',
    },
  },
);

const vwoConfig = mergeConfigs(undefined, {
  production: {
    vwoId: 562640,
  },
});

const payseraConfig = mergeConfigs(
  {
    projectId: 121200,
  },
  {
    production: {
      projectId: 118599,
    },
  },
);

const posthogConfig = mergeConfigs(
  {
    apiKey: 'phc_hHlo6TJ9715M6uo9UqcR86fLg9tYqcYcQKat9u0wEDQ',
    apiHost: 'https://eu.posthog.com',
    bootstrapCookieKey: 'posthogBootstrap',
  },
  {
    production: {
      apiKey: 'phc_s6Fzphr0Et3pOlAGagUsF3AYSlalPjO5CL6WykN8Gvo',
    },
  },
);

// CommonJS export is used here for convenience: to also export all keys, not just the whole object.
module.exports = {
  markets,
  flavors,
  baseLocale,
  baseLanguage: baseLocale.split('-')[0],
  locales: map(flavors, 'locale'),
  localesByBasePath: mapValues(keyBy(flavors, 'basePath'), 'locale'),
  /**
   * @deprecated use basePathsByLocale from config-ts instead.
   */
  basePathsByLocale: mapValues(keyBy(flavors, 'locale'), 'basePath'),
  siteName: 'carVertical',
  /**
   * @deprecated use homeCountryCode from config-ts instead.
   */
  homeCountryCode: 'lt',
  creditValidityMonths: 6,
  termsUpdatedAt: new Date('2023-03-01T06:00:00Z'),
  assetsUrl: 'https://s3.eu-central-1.amazonaws.com/carvertical-public',
  imgixUrl: 'https://carvertical.imgix.net',
  subscribeUrl: 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe',
  studentBeansUrl: 'https://connect.studentbeans.com/v4/hosted/carvertical',
  app: {
    googlePlay: 'https://play.google.com/store/apps/details?id=com.cvmobileapp',
    appStore: 'https://apps.apple.com/us/app/carvertical-check-car-history/id1528834650',
    carOneGooglePlay: 'https://play.google.com/store/apps/details?id=com.carvertical.caroneapp',
    carOneAppStore: 'https://apps.apple.com/us/app/carone/id1547182914',
  },
  social: {
    facebook: 'https://www.facebook.com/carverticalcom/',
    instagram: 'https://www.instagram.com/carvertical_com/',
    youTube: 'https://www.youtube.com/channel/UCRqoBXtP2v0H8C844YN8mdA',
    linkedin: 'https://linkedin.com/company/carvertical',
  },
  prismic: {
    endpoint: 'https://carvertical-reports.cdn.prismic.io/api/v2',
    accessToken:
      'MC5XNVVjY1NZQUFOZ3VtUDYy.77-9WmHvv712Pe-_ve-_vXVA77-9Mu-_vRbvv71I77-977-977-9HO-_vVoz77-9fe-_vQJu77-977-9FkE',
  },
  vinLookupApiKey: 'NHusumF9wjPddfVYpCbJgEL8bkfFpW7x',
  trustBadgeUrl: 'https://trust.carvertical.com',
  cookieBotUrl: (culture) =>
    `https://consent.cookiebot.com/api/v1/VjlRdnZaMkFHdDRpc0ZJVTdPWEJjRE00OXhITWhaRkY1YUxYZjEybWs5K0lyNy90SGVZemtnPT0=/json/domaingroup/18614814-33f4-4302-b38e-a0a39fe228d2/${culture}/domain/www.carvertical.com/cookies`,
  apiConfig,
  supportApiConfig,
  paymentsApiConfig,
  siteConfig,
  gtmConfig,
  authApiConfig,
  adyenConfig,
  cloudFrontConfig,
  recaptchaConfig,
  vwoConfig,
  payseraConfig,
  payPalConfig,
  sanityConfig,
  posthogConfig,
};
