import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { isServer } from '../../utils/ssr';
import withRouter from '../../hoc/withRouter';

const Redirect = ({ push, to, router: { history } }) => {
  useEffect(() => {
    history[push ? 'push' : 'replace'](to);
  }, [push, to, history]);

  if (isServer) {
    return <span>Redirecting…</span>;
  }

  return null;
};

Redirect.propTypes = {
  push: PropTypes.bool,
  to: PropTypes.string.isRequired,
  router: PropTypes.shape({
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
};
Redirect.defaultProps = {
  push: false,
};

export default withRouter(Redirect);
