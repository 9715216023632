import addMinutes from 'date-fns/add_minutes';
import { getAccessToken } from 'services/session';
import { createInstance } from 'services/store';
import { CODE_ACCESS_TOKEN_STORE_KEY, CODE_VALIDITY_MINUTES } from './constants';

const sessionStore = createInstance('session');

const getAuthorizationHeader = () => ({
  Authorization: `Bearer ${getAccessToken()}`,
});

const storeAccessToken = (accessToken: string) => {
  sessionStore.set(CODE_ACCESS_TOKEN_STORE_KEY, accessToken, {
    ttl: addMinutes(new Date(), CODE_VALIDITY_MINUTES),
  });
};

export { getAuthorizationHeader, storeAccessToken };
