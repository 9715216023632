import fifteenMinLogo from 'assets/images/logos/partners/15min.png';
import twentyFourTvLogo from 'assets/images/logos/partners/24tv.png';
import autoAsasLogo from 'assets/images/logos/partners/autoasas.png';
import autoMarketLogo from 'assets/images/logos/partners/automarket.png';
import autoSwiatLogo from 'assets/images/logos/partners/autoswiat.png';
import blicLogo from 'assets/images/logos/partners/blic.png';
import delfiLogo from 'assets/images/logos/partners/delfi.png';
import dnevnikLogo from 'assets/images/logos/partners/dnevnik.png';
import faktiLogo from 'assets/images/logos/partners/fakti.png';
import forbesLogo from 'assets/images/logos/partners/forbes.png';
import forteLogo from 'assets/images/logos/partners/forte.svg';
import hvgLogo from 'assets/images/logos/partners/hvg.png';
import indexLogo from 'assets/images/logos/partners/index.png';
import sicurautoLogo from 'assets/images/logos/partners/sicurauto.png';
import obozrevatelLogo from 'assets/images/logos/partners/obozrevatel.png';
import onetLogo from 'assets/images/logos/partners/onet.png';
import origoLogo from 'assets/images/logos/partners/origo.png';
import playTechLogo from 'assets/images/logos/partners/playtech.png';
import pravdaLogo from 'assets/images/logos/partners/pravda.png';
import reutersLogo from 'assets/images/logos/partners/reuters.png';
import tvNetLogo from 'assets/images/logos/partners/tvnet.png';
import statistaLogo from 'assets/images/logos/partners/statista.png';
import autobildLogo from 'assets/images/logos/partners/autobild.png';
import topgearLogo from 'assets/images/logos/partners/topgear.png';
import merkleLogo from 'assets/images/logos/partners/merkle.png';
import ibmLogo from 'assets/images/logos/partners/ibm.png';
import twentyMinutesLogo from 'assets/images/logos/partners/20minutes.png';
import hotNewsLogo from 'assets/images/logos/partners/hotNews.png';
import twentyMinutosLogo from 'assets/images/logos/partners/20minutos.png';
import avisenLogo from 'assets/images/logos/partners/avisen.png';
import novinkyLogo from 'assets/images/logos/partners/novinky.png';
import postimeesLogo from 'assets/images/logos/partners/postimees.png';
import zurnal24Logo from 'assets/images/logos/partners/zurnal24.png';

type Logo = {
  height: number;
  width: number;
  title: string;
  url: string;
  Component?: React.ReactNode;
};

type Logos = Record<string, Logo[]>;

const LOGOS = {
  delfi: {
    title: 'DELFI',
    url: delfiLogo,
  },
  reuters: {
    title: 'Reuters',
    url: reutersLogo,
  },
  forbes: {
    title: 'Forbes',
    url: forbesLogo,
  },
  onet: {
    title: 'Onet',
    url: onetLogo,
  },
  playtech: {
    title: 'PlayTech.ro',
    url: playTechLogo,
  },
  forte: {
    title: 'Forte',
    Component: forteLogo,
  },
  obozrevatel: {
    title: 'Obozrevatel',
    url: obozrevatelLogo,
  },
  hvg: {
    title: 'hvg.hu',
    url: hvgLogo,
  },
  origo: {
    title: 'ORIGO',
    url: origoLogo,
  },
  fakti: {
    title: 'Fakti',
    url: faktiLogo,
    height: 20,
    width: 90,
  },
  index: {
    title: 'Index.hu',
    url: indexLogo,
  },
  sicurauto: {
    title: 'Sicurauto',
    url: sicurautoLogo,
  },
  tvnet: {
    title: 'Tvnet.lv',
    url: tvNetLogo,
    height: 20,
    width: 120,
  },
  autoasas: {
    title: 'Autoasas',
    url: autoAsasLogo,
  },
  autoswiat: {
    title: 'Auto Swiat',
    url: autoSwiatLogo,
  },
  automarket: {
    title: 'Automarket',
    url: autoMarketLogo,
  },
  blic: {
    title: 'Blic',
    url: blicLogo,
  },
  pravda: {
    title: 'Pravda',
    url: pravdaLogo,
  },
  dnevnik: {
    title: 'Dnevnik',
    url: dnevnikLogo,
    height: 15,
    width: 108,
  },
  '24tv': {
    title: '24 Kanal',
    url: twentyFourTvLogo,
  },
  '15min': {
    title: '15min.lt',
    url: fifteenMinLogo,
  },
  statista: {
    title: 'Statista',
    url: statistaLogo,
  },
  autobild: {
    title: 'autobild',
    url: autobildLogo,
  },
  topgear: {
    title: 'topgear',
    url: topgearLogo,
  },
  merkle: {
    title: 'Merkle',
    url: merkleLogo,
  },
  ibm: {
    title: 'IBM',
    url: ibmLogo,
  },
  '20minutes': {
    title: '20 Minutes',
    url: twentyMinutesLogo,
  },
  hotNews: {
    title: 'Hot News',
    url: hotNewsLogo,
  },
  twentyMinutos: {
    title: '20 Minutos',
    url: twentyMinutosLogo,
  },
  avisen: {
    title: 'Avisen',
    url: avisenLogo,
  },
  novinky: {
    title: 'Novinky',
    url: novinkyLogo,
  },
  postimees: {
    title: 'Postimees',
    url: postimeesLogo,
  },
  zurnal24: {
    title: 'Zurnal 24',
    url: zurnal24Logo,
  },
};

const MARKET_LOGOS: Logos = {
  default: [
    { ...LOGOS.autobild, height: 33, width: 24 },
    { ...LOGOS.topgear, height: 17, width: 84 },
    { ...LOGOS.forbes, height: 16, width: 66 },
    { ...LOGOS.reuters, height: 25, width: 101 },
  ],
  serbia: [
    { ...LOGOS.autobild, height: 34, width: 25 },
    { ...LOGOS.topgear, height: 18, width: 87 },
    { ...LOGOS.forbes, height: 18, width: 73 },
    { ...LOGOS.blic, height: 30, width: 45 },
  ],
  france: [
    { ...LOGOS.autobild, height: 34, width: 25 },
    { ...LOGOS.topgear, height: 17, width: 83 },
    { ...LOGOS.forbes, height: 17, width: 69 },
    { ...LOGOS['20minutes'], height: 36, width: 36 },
  ],
  croatia: [
    { ...LOGOS.autobild, height: 33, width: 24 },
    { ...LOGOS.topgear, height: 17, width: 83 },
    { ...LOGOS.forbes, height: 17, width: 69 },
    { ...LOGOS.index, height: 15, width: 103 },
  ],
  czechia: [
    { ...LOGOS.autobild, height: 33, width: 24 },
    { ...LOGOS.topgear, height: 17, width: 83 },
    { ...LOGOS.forbes, height: 17, width: 69 },
    { ...LOGOS.novinky, height: 18, width: 92 },
  ],
  hungary: [
    { ...LOGOS.autobild, height: 35, width: 26 },
    { ...LOGOS.topgear, height: 16, width: 79 },
    { ...LOGOS.hvg, height: 22, width: 62 },
    { ...LOGOS.origo, height: 15, width: 69 },
  ],
  romania: [
    { ...LOGOS.autobild, height: 32, width: 23 },
    { ...LOGOS.topgear, height: 16, width: 76 },
    { ...LOGOS.hotNews, height: 20, width: 90 },
    { ...LOGOS.playtech, height: 12, width: 104 },
  ],
  lithuania: [
    { ...LOGOS.autobild, height: 35, width: 26 },
    { ...LOGOS.delfi, height: 24, width: 68 },
    { ...LOGOS['15min'], height: 32, width: 32 },
    { ...LOGOS.topgear, height: 17, width: 86 },
  ],
  latvia: [
    { ...LOGOS.autobild, height: 35, width: 26 },
    { ...LOGOS.delfi, height: 24, width: 68 },
    { ...LOGOS.topgear, height: 17, width: 86 },
    { ...LOGOS.forbes, height: 16, width: 66 },
  ],
  estonia: [
    { ...LOGOS.autobild, height: 35, width: 26 },
    { ...LOGOS.postimees, height: 18, width: 93 },
    { ...LOGOS.topgear, height: 17, width: 86 },
    { ...LOGOS.forbes, height: 16, width: 66 },
  ],
  spain: [
    { ...LOGOS.autobild, height: 35, width: 26 },
    { ...LOGOS.twentyMinutos, height: 16, width: 98 },
    { ...LOGOS.topgear, height: 17, width: 86 },
    { ...LOGOS.forbes, height: 16, width: 66 },
  ],
  denmark: [
    { ...LOGOS.autobild, height: 35, width: 26 },
    { ...LOGOS.avisen, height: 16, width: 96 },
    { ...LOGOS.topgear, height: 17, width: 86 },
    { ...LOGOS.forbes, height: 16, width: 66 },
  ],
  slovenia: [
    { ...LOGOS.autobild, height: 35, width: 26 },
    { ...LOGOS.zurnal24, height: 12, width: 104 },
    { ...LOGOS.topgear, height: 17, width: 86 },
    { ...LOGOS.forbes, height: 16, width: 66 },
  ],
  ukraine: [
    { ...LOGOS.autobild, height: 35, width: 26 },
    { ...LOGOS.topgear, height: 18, width: 86 },
    { ...LOGOS['24tv'], height: 34, width: 42 },
    { ...LOGOS.obozrevatel, height: 25, width: 106 },
  ],
  poland: [
    { ...LOGOS.autobild, height: 37, width: 27 },
    { ...LOGOS.topgear, height: 17, width: 83 },
    { ...LOGOS.autoswiat, height: 40, width: 26 },
    { ...LOGOS.onet, height: 24, width: 76 },
  ],
  italy: [
    { ...LOGOS.autobild, height: 33, width: 24 },
    { ...LOGOS.topgear, height: 17, width: 83 },
    { ...LOGOS.forbes, height: 16, width: 65 },
    { ...LOGOS.sicurauto, height: 18, width: 77 },
  ],
  slovakia: [
    { ...LOGOS.autobild, height: 33, width: 23 },
    { ...LOGOS.topgear, height: 17, width: 84 },
    { ...LOGOS.forbes, height: 16, width: 66 },
    { ...LOGOS.pravda, height: 22, width: 70 },
  ],
};

const PAGE_LOGOS: Logos = {
  press: [{ ...LOGOS.statista, height: 17, width: 83 }],
  affiliate: [
    { ...LOGOS.topgear, height: 17, width: 83 },
    { ...LOGOS.reuters, height: 25, width: 101 },
    { ...LOGOS.forbes, height: 16, width: 66 },
    { ...LOGOS.autobild, height: 33, width: 24 },
    { ...LOGOS.merkle, height: 21, width: 39 },
    { ...LOGOS.ibm, height: 21, width: 53 },
  ],
};

export type { Logo };
export default {
  LOGOS,
  MARKET_LOGOS,
  PAGE_LOGOS,
};
