import { Grid, Text } from '@carvertical/ui';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouteData } from 'context/RouteDataProvider';
import type { MarketId } from 'types/market';
import { DECODERS_GROUP, SECTION_GRID_PROPS } from './constants';
import { FooterLink } from './FooterLink';
import styles from './DecoderSection.module.scss';

// TODO: Remove later, when SEO is ready so all markets would show decoders
const MARKETS_WITH_FEW_DECODERS: MarketId[] = ['poland', 'hungary', 'romania'];

const DecoderSection = () => {
  const { t } = useTranslation();
  const { market, pages } = useRouteData();

  const hasTwoColumns = MARKETS_WITH_FEW_DECODERS.includes(market.id);
  const columnCount = hasTwoColumns ? 2 : SECTION_GRID_PROPS.columnCount;

  return (
    <div className="hideUntilTablet">
      <Text as="h2" variant="s" textColor="darkSecondary" className={styles.title}>
        {t('footer.decodersTitle')}
      </Text>

      <Grid
        {...SECTION_GRID_PROPS}
        as="ul"
        columnCount={columnCount}
        className={cx('unstyledList', hasTwoColumns && styles.twoColumns)}
      >
        {DECODERS_GROUP.map(({ links }) =>
          links.map(({ label, labelKey, page, pageId, url }) => {
            const pageInfo = page || pages[pageId];
            if (!pageInfo && !url) {
              return null;
            }

            return (
              <li key={pageId}>
                <FooterLink
                  label={labelKey ? t(labelKey) : label}
                  pageInfo={pageInfo}
                  pageId={pageId}
                  url={url}
                />
              </li>
            );
          }),
        )}
      </Grid>
    </div>
  );
};

export { DecoderSection };
