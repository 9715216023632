import PropTypes from 'prop-types';
import { FocusVisibleManager } from 'use-focus-visible';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HeaderProvider } from 'modules/shared/components/header/HeaderProvider';
import { TrackingProvider } from 'modules/analytics';
import { LastLocationProvider } from './LastLocationProvider';
import { ModeProvider } from './ModeProvider';
import DiscountProvider from './DiscountProvider';
import PricingProvider from './PricingProvider';
import UserInteractionProvider from './UserInteractionProvider';

const client = new QueryClient();

const AppProviders = ({ children }) => (
  <QueryClientProvider client={client}>
    <FocusVisibleManager>
      <LastLocationProvider>
        <ModeProvider>
          <DiscountProvider>
            <HeaderProvider>
              <UserInteractionProvider>
                <PricingProvider>
                  <TrackingProvider>{children}</TrackingProvider>
                </PricingProvider>
              </UserInteractionProvider>
            </HeaderProvider>
          </DiscountProvider>
        </ModeProvider>
      </LastLocationProvider>
    </FocusVisibleManager>
  </QueryClientProvider>
);

AppProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppProviders;
