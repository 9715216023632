import { useMutation } from '@tanstack/react-query';
import { postInquiry } from '../services/inquiry-api';
import type { InquiryParams, PostInquiryResponse } from '../types/inquiry';

const useInquiryMutation = <TExtraParams = Record<string, unknown>>(onSuccess?: () => void) => {
  const { isSuccess, isPending, mutate } = useMutation<
    PostInquiryResponse,
    unknown,
    InquiryParams & TExtraParams
  >({
    mutationFn: postInquiry,
    onSuccess,
  });

  return {
    sendInquiry: mutate,
    sendingInquiry: isPending,
    inquirySent: isSuccess,
  };
};

export { useInquiryMutation };
