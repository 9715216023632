import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        'text-2xs+',
        'text-2xs',
        'text-xs+',
        'text-xs',
        'text-s+',
        'text-s',
        'text-m+',
        'text-m',
        'text-l+',
        'text-l',
        'text-xl+',
        'text-xl',
        'text-h-2xl',
        'text-h-xl',
        'text-h-l',
        'text-h-m',
        'text-h-s',
        'text-h-xs',
      ],
      rounded: [
        'rounded-none',
        'rounded-s',
        'rounded-m',
        'rounded-l',
        'rounded-xl',
        'rounded-2xl',
        'rounded-full',
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
