import { useMutation } from '@tanstack/react-query';
import type AuthContainer from 'containers/AuthContainer';

type UseLogoutProps = {
  auth: Partial<AuthContainer>;
  onSuccess?: () => void;
};

const useLogout = ({ auth, onSuccess }: UseLogoutProps) => {
  const { mutate, isPending, isSuccess } = useMutation<void, unknown, unknown>({
    mutationFn: auth.logOut,
    onSuccess,
  });

  return {
    logout: mutate,
    isLoggedOut: isSuccess,
    isLoggingOut: isPending,
  };
};

export { useLogout };
export type { UseLogoutProps };
