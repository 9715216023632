import flatMap from 'lodash/flatMap';
import find from 'lodash/find';
import findKey from 'lodash/findKey';
import trim from 'lodash/trim';
import identity from 'lodash/identity';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import { baseLocale, localesByBasePath, flavors } from 'config';
import type { Flavor, Market, MarketId } from 'types/market';
import { markets } from 'config-ts';
import { getBasePathForCountryAndLanguage, getCountryAndLanguageForPath } from './router';

type MarketConfig = Omit<Market, 'id'>;

const LOCALE_OVERRIDES = {
  en: 'en-US',
  'en-EU': 'en',
};
const BASE_PATH_PATTERN = /^\/(\w{2})\/(\w{2})/;
const LANGUAGE_PATTERN = /^[a-z]{2}$/;
const LOCALE_SEPARATOR = '-';

function getLanguage(locale?: string) {
  const language = locale || '';
  return language.split(LOCALE_SEPARATOR)[0];
}

function getI18nLocale(locale: string) {
  const { i18nLocale }: { i18nLocale?: string } = find(flavors, { locale }) || {};
  return i18nLocale || locale;
}

function getCountry(locale?: string) {
  const parts = (locale || '').split(LOCALE_SEPARATOR);
  return parts[1]
    ? parts[1].toLowerCase()
    : {
        en: 'us',
        uk: 'ua',
        et: 'ee',
        sv: 'se',
        cs: 'cz',
        sr: 'rs',
        sl: 'si',
        da: 'dk',
      }[parts[0]] || parts[0];
}

function getBasePath(locale: string) {
  const flavor = find<Flavor>(flatMap(markets, 'flavors'), { locale });
  return flavor && locale !== 'en' ? flavor.basePath : '/';
}

function getFullBasePath(locale: string) {
  const basePath = locale === 'en' ? '/us/us/' : getBasePath(locale);
  const [country, language = country] = trim(basePath, '/').split('/');
  return `/${[country, language].join('/')}/`;
}

function getLocaleFromRouter({
  pathname,
  query,
}: {
  pathname?: string;
  query: Record<string, string>;
}): string {
  let { country, language } = query;

  if (!country && pathname && BASE_PATH_PATTERN.test(pathname)) {
    [country, language] = getCountryAndLanguageForPath(pathname);
  }

  const basePath = getBasePathForCountryAndLanguage(country, language);

  return (localesByBasePath[basePath] as string | undefined) || (baseLocale as string);
}

function getBasePathFromCountryAndLanguage(country: string, language?: string) {
  const pathSegments = uniq([country, language]).filter(identity);
  return `/${pathSegments.join('/')}/`;
}

function getFullLocale(locale: string, separator: string = LOCALE_SEPARATOR) {
  let fullLocale = locale;

  const marketMap = markets as Record<MarketId, MarketConfig>;

  if (locale.match(LANGUAGE_PATTERN)) {
    const { countryCode } = find(marketMap, (market) => !!find(market.flavors, { locale })) || {};

    if (countryCode) {
      fullLocale = `${locale}${LOCALE_SEPARATOR}${countryCode.toUpperCase()}`;
    }
  }

  return fullLocale.replace(LOCALE_SEPARATOR, separator);
}

const getMarketForLocale: (locale: string) => Market = (locale: string) => {
  const marketMap = markets as Record<MarketId, MarketConfig>;

  const predicate = (market: MarketConfig) => map(market.flavors, 'locale').includes(locale);

  const id = findKey(marketMap, predicate);
  const market = find(marketMap, predicate);

  return {
    id,
    ...market,
  } as Market; // TODO: remove casting when markets are typescripted in config
};

export {
  LOCALE_OVERRIDES,
  getLanguage,
  getI18nLocale,
  getCountry,
  getBasePath,
  getFullBasePath,
  getLocaleFromRouter,
  getBasePathFromCountryAndLanguage,
  getFullLocale,
  getMarketForLocale,
};
