import type { ParseKeys } from 'i18next';
import type { OptionalMarketIdRecord } from 'types/market';

const SUPPORT_POPUP_ID = 'support-popup';

const SUPPORT_NUMBER: OptionalMarketIdRecord<string> = {
  lithuania: '+370 66180600',
};

const TOP_QUESTIONS: { titleKey: ParseKeys; descriptionKey: ParseKeys }[] = [
  {
    titleKey: 'supportModal.topQuestions.service.title',
    descriptionKey: 'supportModal.topQuestions.service.description',
  },
  {
    titleKey: 'supportModal.topQuestions.reportInformation.title',
    descriptionKey: 'supportModal.topQuestions.reportInformation.description',
  },
  {
    titleKey: 'supportModal.topQuestions.reportFormat.title',
    descriptionKey: 'supportModal.topQuestions.reportFormat.description',
  },
  {
    titleKey: 'supportModal.topQuestions.reportCredits.title',
    descriptionKey: 'supportModal.topQuestions.reportCredits.description',
  },
  {
    titleKey: 'supportModal.topQuestions.reportBundles.title',
    descriptionKey: 'supportModal.topQuestions.reportBundles.description',
  },
];

export { TOP_QUESTIONS, SUPPORT_POPUP_ID, SUPPORT_NUMBER };
