import cx from 'classnames';
import styles from './MessagePage.module.scss';

type MessagePageProps = {
  className?: string;
  error?: boolean;
  children?: React.ReactNode;
};

const MessagePage = ({ className, error, ...props }: MessagePageProps) => (
  <div className={cx(styles.root, error && styles.error, className)} {...props} />
);

export { MessagePage };
export type { MessagePageProps };
