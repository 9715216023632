import { useTranslation } from 'next-i18next';
import { Button, Modal, ModalTitle } from '@carvertical/ui';
import AuthContainer from 'containers/AuthContainer';
import UserContainer from 'containers/UserContainer';
import withContainers from 'hoc/withContainers';
import { useModal } from 'modules/shared/hooks';
import { useLogout } from 'modules/auth/hooks';
import Redirect from 'components/common/Redirect';
import CarEnteringDoorIllustration from '../assets/car-entering-door.svg';

type UserLogoutModalProps = {
  containers: {
    auth: AuthContainer;
    user: UserContainer;
  };
  withoutAuthWall?: boolean;
};

const UserLogoutModal = ({ containers: { auth, user }, withoutAuthWall }: UserLogoutModalProps) => {
  const { t } = useTranslation();
  const { isModalOpen, close } = useModal('logout');
  const { logout, isLoggingOut, isLoggedOut } = useLogout({
    auth,
    onSuccess: () => {
      user.reset();
      close();
    },
  });

  if (withoutAuthWall && isLoggedOut) {
    return <Redirect to="/auth" />;
  }

  return (
    <Modal
      open={isModalOpen}
      onClose={close}
      contentClassName="p-3 pt-0"
      boxClassName="max-w-80 max-h-40 md:max-h-[304] m-3 rounded-l"
      footer={
        <div className="flex flex-col justify-end gap-2 md:flex-row">
          <Button variant="outlined" onClick={close}>
            {t('general.cancelAction')}
          </Button>

          <Button variant="black" disabled={isLoggingOut} onClick={logout}>
            {isLoggingOut ? `${t('general.processingLabel')}…` : t('auth.logOutAction')}
          </Button>
        </div>
      }
    >
      <CarEnteringDoorIllustration className="mb-2 h-8 w-8 md:mb-3" />

      <ModalTitle variant="xs" className="mb-0">
        {t('auth.logOutModalTitle')}
      </ModalTitle>
    </Modal>
  );
};

const WrappedUserLogoutModal = withContainers({ auth: AuthContainer, user: UserContainer })(
  UserLogoutModal,
);

export { WrappedUserLogoutModal as UserLogoutModal };
