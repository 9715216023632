import { useMemo, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { castArray, compact, filter, isEmpty } from 'lodash';
import getHocName from '../utils/getHocName';
import { loadNamespaces } from '../services/i18n';

const withTranslate = (namespaces) =>
  function withTranslate(WrappedComponent) {
    const WithTranslate = (props) => {
      const { t, i18n } = useTranslation(namespaces, {
        bindI18n: 'languageChanged loaded',
      });

      const loadedNamespaces = i18n.store.options.ns;

      // Find namespaces which are not loaded via server-side yet
      const missingNamespaces = useMemo(() => {
        if (!namespaces) {
          return null;
        }

        return compact(
          filter(castArray(namespaces), (namespace) => !loadedNamespaces.includes(namespace)),
        );
      }, [loadedNamespaces]);

      useEffect(() => {
        if (isEmpty(missingNamespaces)) {
          return;
        }

        loadNamespaces(missingNamespaces);
      }, [i18n, missingNamespaces]);

      return <WrappedComponent t={t} {...props} />;
    };

    WithTranslate.displayName = getHocName('withTranslate', WrappedComponent);

    return WithTranslate;
  };

export default withTranslate;
