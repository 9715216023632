import { MULTI_PACKAGE_MAX_DISCOUNT } from 'constants/packages';
import { isBusinessPackage, isInspectionPackage, isTwoForOnePackage } from 'utils/packages';

const EXCLUDED_MAX_DISCOUNT_VOUCHERS = ['halloween30', 'cvbf40', 'cvbf50', 'cvcm40'];

const isExceedingMaxDiscount = (pricing: { voucher?: string; discount?: number }) => {
  if (!pricing.voucher || !pricing.discount) {
    return false;
  }

  return (
    !EXCLUDED_MAX_DISCOUNT_VOUCHERS.includes(pricing.voucher) &&
    pricing.discount > MULTI_PACKAGE_MAX_DISCOUNT
  );
};

// FIXME
const isVoucherAllowed = (pricing: { id: string }) =>
  pricing.id &&
  !isBusinessPackage(pricing.id) &&
  !isInspectionPackage(pricing.id) &&
  !isTwoForOnePackage(pricing.id);

export { isExceedingMaxDiscount, isVoucherAllowed };
