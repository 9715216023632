import { create } from 'zustand';
import type { Market } from 'types/market';

type SuggestCorrectMarketType = 'checkout' | 'location';

type SuggestCorrectMarketStore = {
  forced: boolean;
  modalShown: boolean;
  checkoutUrl?: string;
  selectedMarket?: Market;
  suggestedMarket?: Market;
  type: SuggestCorrectMarketType;
  actions: {
    initCheckoutMarketSuggestion: ({
      checkoutUrl,
      forced,
    }: {
      checkoutUrl: string;
      forced?: boolean;
    }) => void;
    selectMarket: (market: Market) => void;
    suggestMarket: (market: Market) => void;
    toggleModalShown: (shown: boolean) => void;
  };
};

const useSuggestCorrectMarketStore = create<SuggestCorrectMarketStore>()((set) => ({
  forced: false,
  type: 'location',
  modalShown: false,
  actions: {
    toggleModalShown: (shown) => set({ modalShown: shown }),
    selectMarket: (market) => set({ selectedMarket: market }),
    suggestMarket: (market) => set({ suggestedMarket: market }),
    initCheckoutMarketSuggestion: ({ checkoutUrl, forced }) =>
      set({ type: 'checkout', modalShown: true, checkoutUrl, forced }),
  },
}));

const useType = () => useSuggestCorrectMarketStore((state) => state.type);
const useForced = () => useSuggestCorrectMarketStore((state) => state.forced);
const useModalShown = () => useSuggestCorrectMarketStore((state) => state.modalShown);
const useCheckoutUrl = () => useSuggestCorrectMarketStore((state) => state.checkoutUrl);
const useSelectedMarket = () => useSuggestCorrectMarketStore((state) => state.selectedMarket);
const useSuggestedMarket = () => useSuggestCorrectMarketStore((state) => state.suggestedMarket);
const useSuggestCorrectMarketActions = () => useSuggestCorrectMarketStore((state) => state.actions);

export {
  useType,
  useForced,
  useModalShown,
  useCheckoutUrl,
  useSelectedMarket,
  useSuggestedMarket,
  useSuggestCorrectMarketStore,
  useSuggestCorrectMarketActions,
};
