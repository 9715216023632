import { Stack, Text } from '@carvertical/ui';
import Image from 'next/image';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouteData } from 'context/RouteDataProvider';
import supportAvatarsUrl from '../assets/support.png';
import { SUPPORT_NUMBER } from '../constants';
import styles from './SupportBlock.module.scss';

type SupportBlockProps = {
  className?: string;
};

const SupportBlock = ({ className }: SupportBlockProps) => {
  const { market } = useRouteData();
  const { t } = useTranslation();

  const phoneNumber = SUPPORT_NUMBER[market.id];

  if (!phoneNumber) {
    return null;
  }

  return (
    <a href={`tel:${phoneNumber}`} className={cx(styles.root, className)}>
      <div className={styles.content}>
        <Stack
          gap={0}
          crossAxisAlign={{
            mobileUp: 'flexStart',
            tabletLandscapeUp: 'flexEnd',
          }}
        >
          <Text variant="s">{t('general.haveQuestionsLabel')}</Text>

          <Text variant="m+">
            {`${t('general.callUsLabel')}: `}
            <span className={styles.phoneNumber}>{phoneNumber}</span>
          </Text>
        </Stack>

        <Image src={supportAvatarsUrl} alt="" width={123} height={48} />
      </div>
    </a>
  );
};

export { SupportBlock };
export type { SupportBlockProps };
