import { forwardRef, type HTMLProps } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FloatingArrow, FloatingPortal, useMergeRefs } from '@floating-ui/react';
import cx from 'classnames';
import { theme } from '@carvertical/ui';
import { useTooltipContext } from './TooltipContext';
import styles from './TooltipContent.module.scss';

const ANIMATION_VARIANTS = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

const ANIMATION_TRANSITION = {
  duration: 0.3,
};

const TooltipContent = forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
  ({ children, style, ...props }, propRef) => {
    const {
      appendTo,
      arrowContext,
      arrowRef,
      arrowShown,
      floatingStyles,
      getFloatingProps,
      open,
      plain,
      refs,
    } = useTooltipContext();
    const ref = useMergeRefs([refs.setFloating, propRef]);

    return (
      <FloatingPortal root={appendTo}>
        <AnimatePresence mode="wait">
          {open && (
            <motion.div
              ref={ref}
              initial="hidden"
              animate="visible"
              exit="exit"
              variants={ANIMATION_VARIANTS}
              transition={ANIMATION_TRANSITION}
              className={cx(!plain && styles.root)}
              style={{
                ...floatingStyles,
                ...style,
              }}
              {...getFloatingProps(props)}
            >
              <>
                {children}

                {arrowShown && (
                  <FloatingArrow
                    ref={arrowRef}
                    context={arrowContext}
                    width={16}
                    height={10}
                    strokeWidth={1}
                    fill={theme.colorWhite}
                    stroke={theme.colorBlue}
                    tipRadius={1}
                  />
                )}
              </>
            </motion.div>
          )}
        </AnimatePresence>
      </FloatingPortal>
    );
  },
);

export { TooltipContent };
