import type { ArticleNegativeAnswerOption } from 'types/content';
import { getAccessToken } from '../session';
import { captureError } from '../errors';
import { supportApi } from './base';

const saveArticleFeedback = async (body: {
  url: string;
  market: string;
  locale: string;
  helpful: boolean;
  answerType?: ArticleNegativeAnswerOption;
  answer?: string;
}) => {
  try {
    await supportApi.post('/save-article-feedback', body, {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  } catch (err) {
    captureError(err);
  }
};

export { saveArticleFeedback };
