import { merge, trim } from 'lodash';
import axios from 'axios';
import { apiConfig } from 'config';
import {
  clearSession,
  hasSessionExpired,
  getAccessToken,
  getGuestId,
  isTemporaryUser,
} from './session';
import { getBasePath } from './site';

const instance = axios.create({
  baseURL: apiConfig.baseUrl,
  timeout: 60 * 1000,
});

const getRequest = (path, config) =>
  instance.get(cleanUpPath(path), createConfig(config)).catch(handleError);

const postRequest = (path, body, config) =>
  instance.post(cleanUpPath(path), body, createConfig(config)).catch(handleError);

const putRequest = (path, body, config) =>
  instance.put(cleanUpPath(path), body, createConfig(config)).catch(handleError);

const deleteRequest = (path, config) =>
  instance.delete(cleanUpPath(path), createConfig(config)).catch(handleError);

function cleanUpPath(path) {
  return trim(path, '/');
}

function createConfig({ noAuthorization, ...config } = {}) {
  return merge(
    {
      headers: {
        ...(!noAuthorization && {
          Authorization: hasSessionExpired()
            ? `Guest ${getGuestId()}`
            : `${isTemporaryUser() ? 'Temporary' : 'Bearer'} ${getAccessToken()}`,
        }),
      },
    },
    config,
  );
}

function handleError(err) {
  const { response } = err;
  if (response) {
    if ([401, 403].includes(response.status)) {
      clearSession();
      window.location.href = getBasePath();
    }
    return Promise.reject(response);
  }
  return Promise.reject(err);
}

export { getRequest, postRequest, putRequest, deleteRequest };
