import * as m from "react";
import { useLayoutEffect as At, useEffect as Ot, useRef as rn } from "react";
import * as on from "react-dom";
import { createPortal as sn } from "react-dom";
const cn = ["top", "right", "bottom", "left"], Fe = Math.min, ce = Math.max, Ie = Math.round, Ce = Math.floor, ne = (e) => ({
  x: e,
  y: e
}), un = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, ln = {
  start: "end",
  end: "start"
};
function lt(e, t, n) {
  return ce(e, Fe(t, n));
}
function Re(e, t) {
  return typeof e == "function" ? e(t) : e;
}
function ae(e) {
  return e.split("-")[0];
}
function $e(e) {
  return e.split("-")[1];
}
function Pt(e) {
  return e === "x" ? "y" : "x";
}
function Lt(e) {
  return e === "y" ? "height" : "width";
}
function _e(e) {
  return ["top", "bottom"].includes(ae(e)) ? "y" : "x";
}
function Ft(e) {
  return Pt(_e(e));
}
function an(e, t, n) {
  n === void 0 && (n = !1);
  const r = $e(e), o = Ft(e), i = Lt(o);
  let s = o === "x" ? r === (n ? "end" : "start") ? "right" : "left" : r === "start" ? "bottom" : "top";
  return t.reference[i] > t.floating[i] && (s = Me(s)), [s, Me(s)];
}
function fn(e) {
  const t = Me(e);
  return [Qe(e), t, Qe(t)];
}
function Qe(e) {
  return e.replace(/start|end/g, (t) => ln[t]);
}
function dn(e, t, n) {
  const r = ["left", "right"], o = ["right", "left"], i = ["top", "bottom"], s = ["bottom", "top"];
  switch (e) {
    case "top":
    case "bottom":
      return n ? t ? o : r : t ? r : o;
    case "left":
    case "right":
      return t ? i : s;
    default:
      return [];
  }
}
function mn(e, t, n, r) {
  const o = $e(e);
  let i = dn(ae(e), n === "start", r);
  return o && (i = i.map((s) => s + "-" + o), t && (i = i.concat(i.map(Qe)))), i;
}
function Me(e) {
  return e.replace(/left|right|bottom|top/g, (t) => un[t]);
}
function pn(e) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...e
  };
}
function gn(e) {
  return typeof e != "number" ? pn(e) : {
    top: e,
    right: e,
    bottom: e,
    left: e
  };
}
function ke(e) {
  return {
    ...e,
    top: e.y,
    left: e.x,
    right: e.x + e.width,
    bottom: e.y + e.height
  };
}
function at(e, t, n) {
  let {
    reference: r,
    floating: o
  } = e;
  const i = _e(t), s = Ft(t), c = Lt(s), u = ae(t), a = i === "y", g = r.x + r.width / 2 - o.width / 2, f = r.y + r.height / 2 - o.height / 2, p = r[c] / 2 - o[c] / 2;
  let l;
  switch (u) {
    case "top":
      l = {
        x: g,
        y: r.y - o.height
      };
      break;
    case "bottom":
      l = {
        x: g,
        y: r.y + r.height
      };
      break;
    case "right":
      l = {
        x: r.x + r.width,
        y: f
      };
      break;
    case "left":
      l = {
        x: r.x - o.width,
        y: f
      };
      break;
    default:
      l = {
        x: r.x,
        y: r.y
      };
  }
  switch ($e(t)) {
    case "start":
      l[s] -= p * (n && a ? -1 : 1);
      break;
    case "end":
      l[s] += p * (n && a ? -1 : 1);
      break;
  }
  return l;
}
const vn = async (e, t, n) => {
  const {
    placement: r = "bottom",
    strategy: o = "absolute",
    middleware: i = [],
    platform: s
  } = n, c = i.filter(Boolean), u = await (s.isRTL == null ? void 0 : s.isRTL(t));
  let a = await s.getElementRects({
    reference: e,
    floating: t,
    strategy: o
  }), {
    x: g,
    y: f
  } = at(a, r, u), p = r, l = {}, y = 0;
  for (let h = 0; h < c.length; h++) {
    const {
      name: C,
      fn: b
    } = c[h], {
      x: v,
      y: P,
      data: x,
      reset: E
    } = await b({
      x: g,
      y: f,
      initialPlacement: r,
      placement: p,
      strategy: o,
      middlewareData: l,
      rects: a,
      platform: s,
      elements: {
        reference: e,
        floating: t
      }
    });
    if (g = v ?? g, f = P ?? f, l = {
      ...l,
      [C]: {
        ...l[C],
        ...x
      }
    }, E && y <= 50) {
      y++, typeof E == "object" && (E.placement && (p = E.placement), E.rects && (a = E.rects === !0 ? await s.getElementRects({
        reference: e,
        floating: t,
        strategy: o
      }) : E.rects), {
        x: g,
        y: f
      } = at(a, p, u)), h = -1;
      continue;
    }
  }
  return {
    x: g,
    y: f,
    placement: p,
    strategy: o,
    middlewareData: l
  };
};
async function De(e, t) {
  var n;
  t === void 0 && (t = {});
  const {
    x: r,
    y: o,
    platform: i,
    rects: s,
    elements: c,
    strategy: u
  } = e, {
    boundary: a = "clippingAncestors",
    rootBoundary: g = "viewport",
    elementContext: f = "floating",
    altBoundary: p = !1,
    padding: l = 0
  } = Re(t, e), y = gn(l), C = c[p ? f === "floating" ? "reference" : "floating" : f], b = ke(await i.getClippingRect({
    element: (n = await (i.isElement == null ? void 0 : i.isElement(C))) == null || n ? C : C.contextElement || await (i.getDocumentElement == null ? void 0 : i.getDocumentElement(c.floating)),
    boundary: a,
    rootBoundary: g,
    strategy: u
  })), v = f === "floating" ? {
    ...s.floating,
    x: r,
    y: o
  } : s.reference, P = await (i.getOffsetParent == null ? void 0 : i.getOffsetParent(c.floating)), x = await (i.isElement == null ? void 0 : i.isElement(P)) ? await (i.getScale == null ? void 0 : i.getScale(P)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, E = ke(i.convertOffsetParentRelativeRectToViewportRelativeRect ? await i.convertOffsetParentRelativeRectToViewportRelativeRect({
    rect: v,
    offsetParent: P,
    strategy: u
  }) : v);
  return {
    top: (b.top - E.top + y.top) / x.y,
    bottom: (E.bottom - b.bottom + y.bottom) / x.y,
    left: (b.left - E.left + y.left) / x.x,
    right: (E.right - b.right + y.right) / x.x
  };
}
const Fr = function(e) {
  return e === void 0 && (e = {}), {
    name: "flip",
    options: e,
    async fn(t) {
      var n;
      const {
        placement: r,
        middlewareData: o,
        rects: i,
        initialPlacement: s,
        platform: c,
        elements: u
      } = t, {
        mainAxis: a = !0,
        crossAxis: g = !0,
        fallbackPlacements: f,
        fallbackStrategy: p = "bestFit",
        fallbackAxisSideDirection: l = "none",
        flipAlignment: y = !0,
        ...h
      } = Re(e, t), C = ae(r), b = ae(s) === s, v = await (c.isRTL == null ? void 0 : c.isRTL(u.floating)), P = f || (b || !y ? [Me(s)] : fn(s));
      !f && l !== "none" && P.push(...mn(s, y, l, v));
      const x = [s, ...P], E = await De(t, h), k = [];
      let D = ((n = o.flip) == null ? void 0 : n.overflows) || [];
      if (a && k.push(E[C]), g) {
        const S = an(r, i, v);
        k.push(E[S[0]], E[S[1]]);
      }
      if (D = [...D, {
        placement: r,
        overflows: k
      }], !k.every((S) => S <= 0)) {
        var O, W;
        const S = (((O = o.flip) == null ? void 0 : O.index) || 0) + 1, M = x[S];
        if (M)
          return {
            data: {
              index: S,
              overflows: D
            },
            reset: {
              placement: M
            }
          };
        let w = (W = D.filter((d) => d.overflows[0] <= 0).sort((d, T) => d.overflows[1] - T.overflows[1])[0]) == null ? void 0 : W.placement;
        if (!w)
          switch (p) {
            case "bestFit": {
              var H;
              const d = (H = D.map((T) => [T.placement, T.overflows.filter((R) => R > 0).reduce((R, L) => R + L, 0)]).sort((T, R) => T[1] - R[1])[0]) == null ? void 0 : H[0];
              d && (w = d);
              break;
            }
            case "initialPlacement":
              w = s;
              break;
          }
        if (r !== w)
          return {
            reset: {
              placement: w
            }
          };
      }
      return {};
    }
  };
};
function ft(e, t) {
  return {
    top: e.top - t.height,
    right: e.right - t.width,
    bottom: e.bottom - t.height,
    left: e.left - t.width
  };
}
function dt(e) {
  return cn.some((t) => e[t] >= 0);
}
const Ir = function(e) {
  return e === void 0 && (e = {}), {
    name: "hide",
    options: e,
    async fn(t) {
      const {
        rects: n
      } = t, {
        strategy: r = "referenceHidden",
        ...o
      } = Re(e, t);
      switch (r) {
        case "referenceHidden": {
          const i = await De(t, {
            ...o,
            elementContext: "reference"
          }), s = ft(i, n.reference);
          return {
            data: {
              referenceHiddenOffsets: s,
              referenceHidden: dt(s)
            }
          };
        }
        case "escaped": {
          const i = await De(t, {
            ...o,
            altBoundary: !0
          }), s = ft(i, n.floating);
          return {
            data: {
              escapedOffsets: s,
              escaped: dt(s)
            }
          };
        }
        default:
          return {};
      }
    }
  };
};
async function hn(e, t) {
  const {
    placement: n,
    platform: r,
    elements: o
  } = e, i = await (r.isRTL == null ? void 0 : r.isRTL(o.floating)), s = ae(n), c = $e(n), u = _e(n) === "y", a = ["left", "top"].includes(s) ? -1 : 1, g = i && u ? -1 : 1, f = Re(t, e);
  let {
    mainAxis: p,
    crossAxis: l,
    alignmentAxis: y
  } = typeof f == "number" ? {
    mainAxis: f,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: 0,
    crossAxis: 0,
    alignmentAxis: null,
    ...f
  };
  return c && typeof y == "number" && (l = c === "end" ? y * -1 : y), u ? {
    x: l * g,
    y: p * a
  } : {
    x: p * a,
    y: l * g
  };
}
const Mr = function(e) {
  return e === void 0 && (e = 0), {
    name: "offset",
    options: e,
    async fn(t) {
      const {
        x: n,
        y: r
      } = t, o = await hn(t, e);
      return {
        x: n + o.x,
        y: r + o.y,
        data: o
      };
    }
  };
}, kr = function(e) {
  return e === void 0 && (e = {}), {
    name: "shift",
    options: e,
    async fn(t) {
      const {
        x: n,
        y: r,
        placement: o
      } = t, {
        mainAxis: i = !0,
        crossAxis: s = !1,
        limiter: c = {
          fn: (C) => {
            let {
              x: b,
              y: v
            } = C;
            return {
              x: b,
              y: v
            };
          }
        },
        ...u
      } = Re(e, t), a = {
        x: n,
        y: r
      }, g = await De(t, u), f = _e(ae(o)), p = Pt(f);
      let l = a[p], y = a[f];
      if (i) {
        const C = p === "y" ? "top" : "left", b = p === "y" ? "bottom" : "right", v = l + g[C], P = l - g[b];
        l = lt(v, l, P);
      }
      if (s) {
        const C = f === "y" ? "top" : "left", b = f === "y" ? "bottom" : "right", v = y + g[C], P = y - g[b];
        y = lt(v, y, P);
      }
      const h = c.fn({
        ...t,
        [p]: l,
        [f]: y
      });
      return {
        ...h,
        data: {
          x: h.x - n,
          y: h.y - r
        }
      };
    }
  };
};
function re(e) {
  return It(e) ? (e.nodeName || "").toLowerCase() : "#document";
}
function U(e) {
  var t;
  return (e == null || (t = e.ownerDocument) == null ? void 0 : t.defaultView) || window;
}
function te(e) {
  var t;
  return (t = (It(e) ? e.ownerDocument : e.document) || window.document) == null ? void 0 : t.documentElement;
}
function It(e) {
  return e instanceof Node || e instanceof U(e).Node;
}
function ee(e) {
  return e instanceof Element || e instanceof U(e).Element;
}
function Q(e) {
  return e instanceof HTMLElement || e instanceof U(e).HTMLElement;
}
function mt(e) {
  return typeof ShadowRoot > "u" ? !1 : e instanceof ShadowRoot || e instanceof U(e).ShadowRoot;
}
function Te(e) {
  const {
    overflow: t,
    overflowX: n,
    overflowY: r,
    display: o
  } = Z(e);
  return /auto|scroll|overlay|hidden|clip/.test(t + r + n) && !["inline", "contents"].includes(o);
}
function bn(e) {
  return ["table", "td", "th"].includes(re(e));
}
function tt(e) {
  const t = nt(), n = Z(e);
  return n.transform !== "none" || n.perspective !== "none" || (n.containerType ? n.containerType !== "normal" : !1) || !t && (n.backdropFilter ? n.backdropFilter !== "none" : !1) || !t && (n.filter ? n.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((r) => (n.willChange || "").includes(r)) || ["paint", "layout", "strict", "content"].some((r) => (n.contain || "").includes(r));
}
function yn(e) {
  let t = he(e);
  for (; Q(t) && !je(t); ) {
    if (tt(t))
      return t;
    t = he(t);
  }
  return null;
}
function nt() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function je(e) {
  return ["html", "body", "#document"].includes(re(e));
}
function Z(e) {
  return U(e).getComputedStyle(e);
}
function Ke(e) {
  return ee(e) ? {
    scrollLeft: e.scrollLeft,
    scrollTop: e.scrollTop
  } : {
    scrollLeft: e.pageXOffset,
    scrollTop: e.pageYOffset
  };
}
function he(e) {
  if (re(e) === "html")
    return e;
  const t = (
    // Step into the shadow DOM of the parent of a slotted node.
    e.assignedSlot || // DOM Element detected.
    e.parentNode || // ShadowRoot detected.
    mt(e) && e.host || // Fallback.
    te(e)
  );
  return mt(t) ? t.host : t;
}
function Mt(e) {
  const t = he(e);
  return je(t) ? e.ownerDocument ? e.ownerDocument.body : e.body : Q(t) && Te(t) ? t : Mt(t);
}
function ue(e, t) {
  var n;
  t === void 0 && (t = []);
  const r = Mt(e), o = r === ((n = e.ownerDocument) == null ? void 0 : n.body), i = U(r);
  return o ? t.concat(i, i.visualViewport || [], Te(r) ? r : []) : t.concat(r, ue(r));
}
function kt(e) {
  const t = Z(e);
  let n = parseFloat(t.width) || 0, r = parseFloat(t.height) || 0;
  const o = Q(e), i = o ? e.offsetWidth : n, s = o ? e.offsetHeight : r, c = Ie(n) !== i || Ie(r) !== s;
  return c && (n = i, r = s), {
    width: n,
    height: r,
    $: c
  };
}
function rt(e) {
  return ee(e) ? e : e.contextElement;
}
function ge(e) {
  const t = rt(e);
  if (!Q(t))
    return ne(1);
  const n = t.getBoundingClientRect(), {
    width: r,
    height: o,
    $: i
  } = kt(t);
  let s = (i ? Ie(n.width) : n.width) / r, c = (i ? Ie(n.height) : n.height) / o;
  return (!s || !Number.isFinite(s)) && (s = 1), (!c || !Number.isFinite(c)) && (c = 1), {
    x: s,
    y: c
  };
}
const wn = /* @__PURE__ */ ne(0);
function Dt(e) {
  const t = U(e);
  return !nt() || !t.visualViewport ? wn : {
    x: t.visualViewport.offsetLeft,
    y: t.visualViewport.offsetTop
  };
}
function xn(e, t, n) {
  return t === void 0 && (t = !1), !n || t && n !== U(e) ? !1 : t;
}
function fe(e, t, n, r) {
  t === void 0 && (t = !1), n === void 0 && (n = !1);
  const o = e.getBoundingClientRect(), i = rt(e);
  let s = ne(1);
  t && (r ? ee(r) && (s = ge(r)) : s = ge(e));
  const c = xn(i, n, r) ? Dt(i) : ne(0);
  let u = (o.left + c.x) / s.x, a = (o.top + c.y) / s.y, g = o.width / s.x, f = o.height / s.y;
  if (i) {
    const p = U(i), l = r && ee(r) ? U(r) : r;
    let y = p.frameElement;
    for (; y && r && l !== p; ) {
      const h = ge(y), C = y.getBoundingClientRect(), b = Z(y), v = C.left + (y.clientLeft + parseFloat(b.paddingLeft)) * h.x, P = C.top + (y.clientTop + parseFloat(b.paddingTop)) * h.y;
      u *= h.x, a *= h.y, g *= h.x, f *= h.y, u += v, a += P, y = U(y).frameElement;
    }
  }
  return ke({
    width: g,
    height: f,
    x: u,
    y: a
  });
}
function En(e) {
  let {
    rect: t,
    offsetParent: n,
    strategy: r
  } = e;
  const o = Q(n), i = te(n);
  if (n === i)
    return t;
  let s = {
    scrollLeft: 0,
    scrollTop: 0
  }, c = ne(1);
  const u = ne(0);
  if ((o || !o && r !== "fixed") && ((re(n) !== "body" || Te(i)) && (s = Ke(n)), Q(n))) {
    const a = fe(n);
    c = ge(n), u.x = a.x + n.clientLeft, u.y = a.y + n.clientTop;
  }
  return {
    width: t.width * c.x,
    height: t.height * c.y,
    x: t.x * c.x - s.scrollLeft * c.x + u.x,
    y: t.y * c.y - s.scrollTop * c.y + u.y
  };
}
function Rn(e) {
  return Array.from(e.getClientRects());
}
function Nt(e) {
  return fe(te(e)).left + Ke(e).scrollLeft;
}
function Tn(e) {
  const t = te(e), n = Ke(e), r = e.ownerDocument.body, o = ce(t.scrollWidth, t.clientWidth, r.scrollWidth, r.clientWidth), i = ce(t.scrollHeight, t.clientHeight, r.scrollHeight, r.clientHeight);
  let s = -n.scrollLeft + Nt(e);
  const c = -n.scrollTop;
  return Z(r).direction === "rtl" && (s += ce(t.clientWidth, r.clientWidth) - o), {
    width: o,
    height: i,
    x: s,
    y: c
  };
}
function Cn(e, t) {
  const n = U(e), r = te(e), o = n.visualViewport;
  let i = r.clientWidth, s = r.clientHeight, c = 0, u = 0;
  if (o) {
    i = o.width, s = o.height;
    const a = nt();
    (!a || a && t === "fixed") && (c = o.offsetLeft, u = o.offsetTop);
  }
  return {
    width: i,
    height: s,
    x: c,
    y: u
  };
}
function Sn(e, t) {
  const n = fe(e, !0, t === "fixed"), r = n.top + e.clientTop, o = n.left + e.clientLeft, i = Q(e) ? ge(e) : ne(1), s = e.clientWidth * i.x, c = e.clientHeight * i.y, u = o * i.x, a = r * i.y;
  return {
    width: s,
    height: c,
    x: u,
    y: a
  };
}
function pt(e, t, n) {
  let r;
  if (t === "viewport")
    r = Cn(e, n);
  else if (t === "document")
    r = Tn(te(e));
  else if (ee(t))
    r = Sn(t, n);
  else {
    const o = Dt(e);
    r = {
      ...t,
      x: t.x - o.x,
      y: t.y - o.y
    };
  }
  return ke(r);
}
function Bt(e, t) {
  const n = he(e);
  return n === t || !ee(n) || je(n) ? !1 : Z(n).position === "fixed" || Bt(n, t);
}
function An(e, t) {
  const n = t.get(e);
  if (n)
    return n;
  let r = ue(e).filter((c) => ee(c) && re(c) !== "body"), o = null;
  const i = Z(e).position === "fixed";
  let s = i ? he(e) : e;
  for (; ee(s) && !je(s); ) {
    const c = Z(s), u = tt(s);
    !u && c.position === "fixed" && (o = null), (i ? !u && !o : !u && c.position === "static" && !!o && ["absolute", "fixed"].includes(o.position) || Te(s) && !u && Bt(e, s)) ? r = r.filter((g) => g !== s) : o = c, s = he(s);
  }
  return t.set(e, r), r;
}
function On(e) {
  let {
    element: t,
    boundary: n,
    rootBoundary: r,
    strategy: o
  } = e;
  const s = [...n === "clippingAncestors" ? An(t, this._c) : [].concat(n), r], c = s[0], u = s.reduce((a, g) => {
    const f = pt(t, g, o);
    return a.top = ce(f.top, a.top), a.right = Fe(f.right, a.right), a.bottom = Fe(f.bottom, a.bottom), a.left = ce(f.left, a.left), a;
  }, pt(t, c, o));
  return {
    width: u.right - u.left,
    height: u.bottom - u.top,
    x: u.left,
    y: u.top
  };
}
function Pn(e) {
  return kt(e);
}
function Ln(e, t, n) {
  const r = Q(t), o = te(t), i = n === "fixed", s = fe(e, !0, i, t);
  let c = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const u = ne(0);
  if (r || !r && !i)
    if ((re(t) !== "body" || Te(o)) && (c = Ke(t)), r) {
      const a = fe(t, !0, i, t);
      u.x = a.x + t.clientLeft, u.y = a.y + t.clientTop;
    } else
      o && (u.x = Nt(o));
  return {
    x: s.left + c.scrollLeft - u.x,
    y: s.top + c.scrollTop - u.y,
    width: s.width,
    height: s.height
  };
}
function gt(e, t) {
  return !Q(e) || Z(e).position === "fixed" ? null : t ? t(e) : e.offsetParent;
}
function Vt(e, t) {
  const n = U(e);
  if (!Q(e))
    return n;
  let r = gt(e, t);
  for (; r && bn(r) && Z(r).position === "static"; )
    r = gt(r, t);
  return r && (re(r) === "html" || re(r) === "body" && Z(r).position === "static" && !tt(r)) ? n : r || yn(e) || n;
}
const Fn = async function(e) {
  let {
    reference: t,
    floating: n,
    strategy: r
  } = e;
  const o = this.getOffsetParent || Vt, i = this.getDimensions;
  return {
    reference: Ln(t, await o(n), r),
    floating: {
      x: 0,
      y: 0,
      ...await i(n)
    }
  };
};
function In(e) {
  return Z(e).direction === "rtl";
}
const Mn = {
  convertOffsetParentRelativeRectToViewportRelativeRect: En,
  getDocumentElement: te,
  getClippingRect: On,
  getOffsetParent: Vt,
  getElementRects: Fn,
  getClientRects: Rn,
  getDimensions: Pn,
  getScale: ge,
  isElement: ee,
  isRTL: In
};
function kn(e, t) {
  let n = null, r;
  const o = te(e);
  function i() {
    clearTimeout(r), n && n.disconnect(), n = null;
  }
  function s(c, u) {
    c === void 0 && (c = !1), u === void 0 && (u = 1), i();
    const {
      left: a,
      top: g,
      width: f,
      height: p
    } = e.getBoundingClientRect();
    if (c || t(), !f || !p)
      return;
    const l = Ce(g), y = Ce(o.clientWidth - (a + f)), h = Ce(o.clientHeight - (g + p)), C = Ce(a), v = {
      rootMargin: -l + "px " + -y + "px " + -h + "px " + -C + "px",
      threshold: ce(0, Fe(1, u)) || 1
    };
    let P = !0;
    function x(E) {
      const k = E[0].intersectionRatio;
      if (k !== u) {
        if (!P)
          return s();
        k ? s(!1, k) : r = setTimeout(() => {
          s(!1, 1e-7);
        }, 100);
      }
      P = !1;
    }
    try {
      n = new IntersectionObserver(x, {
        ...v,
        // Handle <iframe>s
        root: o.ownerDocument
      });
    } catch {
      n = new IntersectionObserver(x, v);
    }
    n.observe(e);
  }
  return s(!0), i;
}
function Dr(e, t, n, r) {
  r === void 0 && (r = {});
  const {
    ancestorScroll: o = !0,
    ancestorResize: i = !0,
    elementResize: s = typeof ResizeObserver == "function",
    layoutShift: c = typeof IntersectionObserver == "function",
    animationFrame: u = !1
  } = r, a = rt(e), g = o || i ? [...a ? ue(a) : [], ...ue(t)] : [];
  g.forEach((b) => {
    o && b.addEventListener("scroll", n, {
      passive: !0
    }), i && b.addEventListener("resize", n);
  });
  const f = a && c ? kn(a, n) : null;
  let p = -1, l = null;
  s && (l = new ResizeObserver((b) => {
    let [v] = b;
    v && v.target === a && l && (l.unobserve(t), cancelAnimationFrame(p), p = requestAnimationFrame(() => {
      l && l.observe(t);
    })), n();
  }), a && !u && l.observe(a), l.observe(t));
  let y, h = u ? fe(e) : null;
  u && C();
  function C() {
    const b = fe(e);
    h && (b.x !== h.x || b.y !== h.y || b.width !== h.width || b.height !== h.height) && n(), h = b, y = requestAnimationFrame(C);
  }
  return n(), () => {
    g.forEach((b) => {
      o && b.removeEventListener("scroll", n), i && b.removeEventListener("resize", n);
    }), f && f(), l && l.disconnect(), l = null, u && cancelAnimationFrame(y);
  };
}
const Dn = (e, t, n) => {
  const r = /* @__PURE__ */ new Map(), o = {
    platform: Mn,
    ...n
  }, i = {
    ...o.platform,
    _c: r
  };
  return vn(e, t, {
    ...o,
    platform: i
  });
};
var Pe = typeof document < "u" ? At : Ot;
function Ne(e, t) {
  if (e === t)
    return !0;
  if (typeof e != typeof t)
    return !1;
  if (typeof e == "function" && e.toString() === t.toString())
    return !0;
  let n, r, o;
  if (e && t && typeof e == "object") {
    if (Array.isArray(e)) {
      if (n = e.length, n != t.length)
        return !1;
      for (r = n; r-- !== 0; )
        if (!Ne(e[r], t[r]))
          return !1;
      return !0;
    }
    if (o = Object.keys(e), n = o.length, n !== Object.keys(t).length)
      return !1;
    for (r = n; r-- !== 0; )
      if (!{}.hasOwnProperty.call(t, o[r]))
        return !1;
    for (r = n; r-- !== 0; ) {
      const i = o[r];
      if (!(i === "_owner" && e.$$typeof) && !Ne(e[i], t[i]))
        return !1;
    }
    return !0;
  }
  return e !== e && t !== t;
}
function Wt(e) {
  return typeof window > "u" ? 1 : (e.ownerDocument.defaultView || window).devicePixelRatio || 1;
}
function vt(e, t) {
  const n = Wt(e);
  return Math.round(t * n) / n;
}
function ht(e) {
  const t = m.useRef(e);
  return Pe(() => {
    t.current = e;
  }), t;
}
function Nn(e) {
  e === void 0 && (e = {});
  const {
    placement: t = "bottom",
    strategy: n = "absolute",
    middleware: r = [],
    platform: o,
    elements: {
      reference: i,
      floating: s
    } = {},
    transform: c = !0,
    whileElementsMounted: u,
    open: a
  } = e, [g, f] = m.useState({
    x: 0,
    y: 0,
    strategy: n,
    placement: t,
    middlewareData: {},
    isPositioned: !1
  }), [p, l] = m.useState(r);
  Ne(p, r) || l(r);
  const [y, h] = m.useState(null), [C, b] = m.useState(null), v = m.useCallback((R) => {
    R != k.current && (k.current = R, h(R));
  }, [h]), P = m.useCallback((R) => {
    R !== D.current && (D.current = R, b(R));
  }, [b]), x = i || y, E = s || C, k = m.useRef(null), D = m.useRef(null), O = m.useRef(g), W = ht(u), H = ht(o), S = m.useCallback(() => {
    if (!k.current || !D.current)
      return;
    const R = {
      placement: t,
      strategy: n,
      middleware: p
    };
    H.current && (R.platform = H.current), Dn(k.current, D.current, R).then((L) => {
      const A = {
        ...L,
        isPositioned: !0
      };
      M.current && !Ne(O.current, A) && (O.current = A, on.flushSync(() => {
        f(A);
      }));
    });
  }, [p, t, n, H]);
  Pe(() => {
    a === !1 && O.current.isPositioned && (O.current.isPositioned = !1, f((R) => ({
      ...R,
      isPositioned: !1
    })));
  }, [a]);
  const M = m.useRef(!1);
  Pe(() => (M.current = !0, () => {
    M.current = !1;
  }), []), Pe(() => {
    if (x && (k.current = x), E && (D.current = E), x && E) {
      if (W.current)
        return W.current(x, E, S);
      S();
    }
  }, [x, E, S, W]);
  const w = m.useMemo(() => ({
    reference: k,
    floating: D,
    setReference: v,
    setFloating: P
  }), [v, P]), d = m.useMemo(() => ({
    reference: x,
    floating: E
  }), [x, E]), T = m.useMemo(() => {
    const R = {
      position: n,
      left: 0,
      top: 0
    };
    if (!d.floating)
      return R;
    const L = vt(d.floating, g.x), A = vt(d.floating, g.y);
    return c ? {
      ...R,
      transform: "translate(" + L + "px, " + A + "px)",
      ...Wt(d.floating) >= 1.5 && {
        willChange: "transform"
      }
    } : {
      position: n,
      left: L,
      top: A
    };
  }, [n, c, d.floating, g.x, g.y]);
  return m.useMemo(() => ({
    ...g,
    update: S,
    refs: w,
    elements: d,
    floatingStyles: T
  }), [g, S, w, d, T]);
}
var Ht = function(e) {
  if (typeof document > "u")
    return null;
  var t = Array.isArray(e) ? e[0] : e;
  return t.ownerDocument.body;
}, me = /* @__PURE__ */ new WeakMap(), Se = /* @__PURE__ */ new WeakMap(), Ae = {}, qe = 0, $t = function(e) {
  return e && (e.host || $t(e.parentNode));
}, Bn = function(e, t) {
  return t.map(function(n) {
    if (e.contains(n))
      return n;
    var r = $t(n);
    return r && e.contains(r) ? r : (console.error("aria-hidden", n, "in not contained inside", e, ". Doing nothing"), null);
  }).filter(function(n) {
    return !!n;
  });
}, _t = function(e, t, n, r) {
  var o = Bn(t, Array.isArray(e) ? e : [e]);
  Ae[n] || (Ae[n] = /* @__PURE__ */ new WeakMap());
  var i = Ae[n], s = [], c = /* @__PURE__ */ new Set(), u = new Set(o), a = function(f) {
    !f || c.has(f) || (c.add(f), a(f.parentNode));
  };
  o.forEach(a);
  var g = function(f) {
    !f || u.has(f) || Array.prototype.forEach.call(f.children, function(p) {
      if (c.has(p))
        g(p);
      else {
        var l = p.getAttribute(r), y = l !== null && l !== "false", h = (me.get(p) || 0) + 1, C = (i.get(p) || 0) + 1;
        me.set(p, h), i.set(p, C), s.push(p), h === 1 && y && Se.set(p, !0), C === 1 && p.setAttribute(n, "true"), y || p.setAttribute(r, "true");
      }
    });
  };
  return g(t), c.clear(), qe++, function() {
    s.forEach(function(f) {
      var p = me.get(f) - 1, l = i.get(f) - 1;
      me.set(f, p), i.set(f, l), p || (Se.has(f) || f.removeAttribute(r), Se.delete(f)), l || f.removeAttribute(n);
    }), qe--, qe || (me = /* @__PURE__ */ new WeakMap(), me = /* @__PURE__ */ new WeakMap(), Se = /* @__PURE__ */ new WeakMap(), Ae = {});
  };
}, jt = function(e, t, n) {
  n === void 0 && (n = "data-aria-hidden");
  var r = Array.from(Array.isArray(e) ? e : [e]), o = t || Ht(e);
  return o ? (r.push.apply(r, Array.from(o.querySelectorAll("[aria-live]"))), _t(r, o, n, "aria-hidden")) : function() {
    return null;
  };
}, Vn = function(e, t, n) {
  n === void 0 && (n = "data-inert-ed");
  var r = t || Ht(e);
  return r ? _t(e, r, n, "inert") : function() {
    return null;
  };
}, Kt = function() {
  return typeof HTMLElement < "u" && HTMLElement.prototype.hasOwnProperty("inert");
}, Wn = function(e, t, n) {
  return n === void 0 && (n = "data-suppressed"), (Kt() ? Vn : jt)(e, t, n);
};
/*!
* tabbable 6.2.0
* @license MIT, https://github.com/focus-trap/tabbable/blob/master/LICENSE
*/
var Hn = ["input:not([inert])", "select:not([inert])", "textarea:not([inert])", "a[href]:not([inert])", "button:not([inert])", "[tabindex]:not(slot):not([inert])", "audio[controls]:not([inert])", "video[controls]:not([inert])", '[contenteditable]:not([contenteditable="false"]):not([inert])', "details>summary:first-of-type:not([inert])", "details:not([inert])"], et = /* @__PURE__ */ Hn.join(","), Xt = typeof Element > "u", we = Xt ? function() {
} : Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector, Be = !Xt && Element.prototype.getRootNode ? function(e) {
  var t;
  return e == null || (t = e.getRootNode) === null || t === void 0 ? void 0 : t.call(e);
} : function(e) {
  return e == null ? void 0 : e.ownerDocument;
}, Ve = function e(t, n) {
  var r;
  n === void 0 && (n = !0);
  var o = t == null || (r = t.getAttribute) === null || r === void 0 ? void 0 : r.call(t, "inert"), i = o === "" || o === "true", s = i || n && t && e(t.parentNode);
  return s;
}, $n = function(t) {
  var n, r = t == null || (n = t.getAttribute) === null || n === void 0 ? void 0 : n.call(t, "contenteditable");
  return r === "" || r === "true";
}, _n = function(t, n, r) {
  if (Ve(t))
    return [];
  var o = Array.prototype.slice.apply(t.querySelectorAll(et));
  return n && we.call(t, et) && o.unshift(t), o = o.filter(r), o;
}, jn = function e(t, n, r) {
  for (var o = [], i = Array.from(t); i.length; ) {
    var s = i.shift();
    if (!Ve(s, !1))
      if (s.tagName === "SLOT") {
        var c = s.assignedElements(), u = c.length ? c : s.children, a = e(u, !0, r);
        r.flatten ? o.push.apply(o, a) : o.push({
          scopeParent: s,
          candidates: a
        });
      } else {
        var g = we.call(s, et);
        g && r.filter(s) && (n || !t.includes(s)) && o.push(s);
        var f = s.shadowRoot || // check for an undisclosed shadow
        typeof r.getShadowRoot == "function" && r.getShadowRoot(s), p = !Ve(f, !1) && (!r.shadowRootFilter || r.shadowRootFilter(s));
        if (f && p) {
          var l = e(f === !0 ? s.children : f.children, !0, r);
          r.flatten ? o.push.apply(o, l) : o.push({
            scopeParent: s,
            candidates: l
          });
        } else
          i.unshift.apply(i, s.children);
      }
  }
  return o;
}, Yt = function(t) {
  return !isNaN(parseInt(t.getAttribute("tabindex"), 10));
}, qt = function(t) {
  if (!t)
    throw new Error("No node provided");
  return t.tabIndex < 0 && (/^(AUDIO|VIDEO|DETAILS)$/.test(t.tagName) || $n(t)) && !Yt(t) ? 0 : t.tabIndex;
}, Kn = function(t, n) {
  var r = qt(t);
  return r < 0 && n && !Yt(t) ? 0 : r;
}, Xn = function(t, n) {
  return t.tabIndex === n.tabIndex ? t.documentOrder - n.documentOrder : t.tabIndex - n.tabIndex;
}, zt = function(t) {
  return t.tagName === "INPUT";
}, Yn = function(t) {
  return zt(t) && t.type === "hidden";
}, qn = function(t) {
  var n = t.tagName === "DETAILS" && Array.prototype.slice.apply(t.children).some(function(r) {
    return r.tagName === "SUMMARY";
  });
  return n;
}, zn = function(t, n) {
  for (var r = 0; r < t.length; r++)
    if (t[r].checked && t[r].form === n)
      return t[r];
}, Un = function(t) {
  if (!t.name)
    return !0;
  var n = t.form || Be(t), r = function(c) {
    return n.querySelectorAll('input[type="radio"][name="' + c + '"]');
  }, o;
  if (typeof window < "u" && typeof window.CSS < "u" && typeof window.CSS.escape == "function")
    o = r(window.CSS.escape(t.name));
  else
    try {
      o = r(t.name);
    } catch (s) {
      return console.error("Looks like you have a radio button with a name attribute containing invalid CSS selector characters and need the CSS.escape polyfill: %s", s.message), !1;
    }
  var i = zn(o, t.form);
  return !i || i === t;
}, Gn = function(t) {
  return zt(t) && t.type === "radio";
}, Zn = function(t) {
  return Gn(t) && !Un(t);
}, Jn = function(t) {
  var n, r = t && Be(t), o = (n = r) === null || n === void 0 ? void 0 : n.host, i = !1;
  if (r && r !== t) {
    var s, c, u;
    for (i = !!((s = o) !== null && s !== void 0 && (c = s.ownerDocument) !== null && c !== void 0 && c.contains(o) || t != null && (u = t.ownerDocument) !== null && u !== void 0 && u.contains(t)); !i && o; ) {
      var a, g, f;
      r = Be(o), o = (a = r) === null || a === void 0 ? void 0 : a.host, i = !!((g = o) !== null && g !== void 0 && (f = g.ownerDocument) !== null && f !== void 0 && f.contains(o));
    }
  }
  return i;
}, bt = function(t) {
  var n = t.getBoundingClientRect(), r = n.width, o = n.height;
  return r === 0 && o === 0;
}, Qn = function(t, n) {
  var r = n.displayCheck, o = n.getShadowRoot;
  if (getComputedStyle(t).visibility === "hidden")
    return !0;
  var i = we.call(t, "details>summary:first-of-type"), s = i ? t.parentElement : t;
  if (we.call(s, "details:not([open]) *"))
    return !0;
  if (!r || r === "full" || r === "legacy-full") {
    if (typeof o == "function") {
      for (var c = t; t; ) {
        var u = t.parentElement, a = Be(t);
        if (u && !u.shadowRoot && o(u) === !0)
          return bt(t);
        t.assignedSlot ? t = t.assignedSlot : !u && a !== t.ownerDocument ? t = a.host : t = u;
      }
      t = c;
    }
    if (Jn(t))
      return !t.getClientRects().length;
    if (r !== "legacy-full")
      return !0;
  } else if (r === "non-zero-area")
    return bt(t);
  return !1;
}, er = function(t) {
  if (/^(INPUT|BUTTON|SELECT|TEXTAREA)$/.test(t.tagName))
    for (var n = t.parentElement; n; ) {
      if (n.tagName === "FIELDSET" && n.disabled) {
        for (var r = 0; r < n.children.length; r++) {
          var o = n.children.item(r);
          if (o.tagName === "LEGEND")
            return we.call(n, "fieldset[disabled] *") ? !0 : !o.contains(t);
        }
        return !0;
      }
      n = n.parentElement;
    }
  return !1;
}, tr = function(t, n) {
  return !(n.disabled || // we must do an inert look up to filter out any elements inside an inert ancestor
  //  because we're limited in the type of selectors we can use in JSDom (see related
  //  note related to `candidateSelectors`)
  Ve(n) || Yn(n) || Qn(n, t) || // For a details element with a summary, the summary element gets the focus
  qn(n) || er(n));
}, yt = function(t, n) {
  return !(Zn(n) || qt(n) < 0 || !tr(t, n));
}, nr = function(t) {
  var n = parseInt(t.getAttribute("tabindex"), 10);
  return !!(isNaN(n) || n >= 0);
}, rr = function e(t) {
  var n = [], r = [];
  return t.forEach(function(o, i) {
    var s = !!o.scopeParent, c = s ? o.scopeParent : o, u = Kn(c, s), a = s ? e(o.candidates) : c;
    u === 0 ? s ? n.push.apply(n, a) : n.push(c) : r.push({
      documentOrder: i,
      tabIndex: u,
      item: o,
      isScope: s,
      content: a
    });
  }), r.sort(Xn).reduce(function(o, i) {
    return i.isScope ? o.push.apply(o, i.content) : o.push(i.content), o;
  }, []).concat(n);
}, ot = function(t, n) {
  n = n || {};
  var r;
  return n.getShadowRoot ? r = jn([t], n.includeContainer, {
    filter: yt.bind(null, n),
    flatten: !1,
    getShadowRoot: n.getShadowRoot,
    shadowRootFilter: nr
  }) : r = _n(t, n.includeContainer, yt.bind(null, n)), rr(r);
};
function xe() {
  return xe = Object.assign ? Object.assign.bind() : function(e) {
    for (var t = 1; t < arguments.length; t++) {
      var n = arguments[t];
      for (var r in n)
        Object.prototype.hasOwnProperty.call(n, r) && (e[r] = n[r]);
    }
    return e;
  }, xe.apply(this, arguments);
}
var z = typeof document < "u" ? At : Ot;
let ze = !1, or = 0;
const wt = () => "floating-ui-" + or++;
function ir() {
  const [e, t] = m.useState(() => ze ? wt() : void 0);
  return z(() => {
    e == null && t(wt());
  }, []), m.useEffect(() => {
    ze || (ze = !0);
  }, []), e;
}
const sr = m[/* @__PURE__ */ "useId".toString()], it = sr || ir;
function cr() {
  const e = /* @__PURE__ */ new Map();
  return {
    emit(t, n) {
      var r;
      (r = e.get(t)) == null || r.forEach((o) => o(n));
    },
    on(t, n) {
      e.set(t, [...e.get(t) || [], n]);
    },
    off(t, n) {
      var r;
      e.set(t, ((r = e.get(t)) == null ? void 0 : r.filter((o) => o !== n)) || []);
    }
  };
}
const ur = /* @__PURE__ */ m.createContext(null), lr = /* @__PURE__ */ m.createContext(null), Ut = () => {
  var e;
  return ((e = m.useContext(ur)) == null ? void 0 : e.id) || null;
}, Xe = () => m.useContext(lr);
function G(e) {
  return (e == null ? void 0 : e.ownerDocument) || document;
}
function Gt() {
  const e = navigator.userAgentData;
  return e != null && e.platform ? e.platform : navigator.platform;
}
function ar() {
  const e = navigator.userAgentData;
  return e && Array.isArray(e.brands) ? e.brands.map((t) => {
    let {
      brand: n,
      version: r
    } = t;
    return n + "/" + r;
  }).join(" ") : navigator.userAgent;
}
function Ye(e) {
  return G(e).defaultView || window;
}
function J(e) {
  return e ? e instanceof Element || e instanceof Ye(e).Element : !1;
}
function Ee(e) {
  return e ? e instanceof HTMLElement || e instanceof Ye(e).HTMLElement : !1;
}
function fr(e) {
  if (typeof ShadowRoot > "u")
    return !1;
  const t = Ye(e).ShadowRoot;
  return e instanceof t || e instanceof ShadowRoot;
}
function dr(e) {
  if (e.mozInputSource === 0 && e.isTrusted)
    return !0;
  const t = /Android/i;
  return (t.test(Gt()) || t.test(ar())) && e.pointerType ? e.type === "click" && e.buttons === 1 : e.detail === 0 && !e.pointerType;
}
function mr(e) {
  return e.width === 0 && e.height === 0 || e.width === 1 && e.height === 1 && e.pressure === 0 && e.detail === 0 && e.pointerType !== "mouse" || // iOS VoiceOver returns 0.333• for width/height.
  e.width < 1 && e.height < 1 && e.pressure === 0 && e.detail === 0;
}
function pr() {
  return /apple/i.test(navigator.vendor);
}
function We(e, t) {
  const n = ["mouse", "pen"];
  return t || n.push("", void 0), n.includes(e);
}
function gr(e) {
  return "nativeEvent" in e;
}
function q(e, t) {
  if (!e || !t)
    return !1;
  const n = t.getRootNode && t.getRootNode();
  if (e.contains(t))
    return !0;
  if (n && fr(n)) {
    let r = t;
    for (; r; ) {
      if (e === r)
        return !0;
      r = r.parentNode || r.host;
    }
  }
  return !1;
}
function le(e) {
  return "data-floating-ui-" + e;
}
function be(e) {
  const t = rn(e);
  return z(() => {
    t.current = e;
  }), t;
}
const xt = /* @__PURE__ */ le("safe-polygon");
function Ue(e, t, n) {
  return n && !We(n) ? 0 : typeof e == "number" ? e : e == null ? void 0 : e[t];
}
function Nr(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    onOpenChange: r,
    dataRef: o,
    events: i,
    elements: {
      domReference: s,
      floating: c
    },
    refs: u
  } = e, {
    enabled: a = !0,
    delay: g = 0,
    handleClose: f = null,
    mouseOnly: p = !1,
    restMs: l = 0,
    move: y = !0
  } = t, h = Xe(), C = Ut(), b = be(f), v = be(g), P = m.useRef(), x = m.useRef(), E = m.useRef(), k = m.useRef(), D = m.useRef(!0), O = m.useRef(!1), W = m.useRef(() => {
  }), H = m.useCallback(() => {
    var d;
    const T = (d = o.current.openEvent) == null ? void 0 : d.type;
    return (T == null ? void 0 : T.includes("mouse")) && T !== "mousedown";
  }, [o]);
  m.useEffect(() => {
    if (!a)
      return;
    function d() {
      clearTimeout(x.current), clearTimeout(k.current), D.current = !0;
    }
    return i.on("dismiss", d), () => {
      i.off("dismiss", d);
    };
  }, [a, i]), m.useEffect(() => {
    if (!a || !b.current || !n)
      return;
    function d(R) {
      H() && r(!1, R);
    }
    const T = G(c).documentElement;
    return T.addEventListener("mouseleave", d), () => {
      T.removeEventListener("mouseleave", d);
    };
  }, [c, n, r, a, b, o, H]);
  const S = m.useCallback(function(d, T) {
    T === void 0 && (T = !0);
    const R = Ue(v.current, "close", P.current);
    R && !E.current ? (clearTimeout(x.current), x.current = setTimeout(() => r(!1, d), R)) : T && (clearTimeout(x.current), r(!1, d));
  }, [v, r]), M = m.useCallback(() => {
    W.current(), E.current = void 0;
  }, []), w = m.useCallback(() => {
    if (O.current) {
      const d = G(u.floating.current).body;
      d.style.pointerEvents = "", d.removeAttribute(xt), O.current = !1;
    }
  }, [u]);
  return m.useEffect(() => {
    if (!a)
      return;
    function d() {
      return o.current.openEvent ? ["click", "mousedown"].includes(o.current.openEvent.type) : !1;
    }
    function T(A) {
      if (clearTimeout(x.current), D.current = !1, p && !We(P.current) || l > 0 && Ue(v.current, "open") === 0)
        return;
      const $ = Ue(v.current, "open", P.current);
      $ ? x.current = setTimeout(() => {
        r(!0, A);
      }, $) : r(!0, A);
    }
    function R(A) {
      if (d())
        return;
      W.current();
      const $ = G(c);
      if (clearTimeout(k.current), b.current) {
        n || clearTimeout(x.current), E.current = b.current({
          ...e,
          tree: h,
          x: A.clientX,
          y: A.clientY,
          onClose() {
            w(), M(), S(A);
          }
        });
        const I = E.current;
        $.addEventListener("mousemove", I), W.current = () => {
          $.removeEventListener("mousemove", I);
        };
        return;
      }
      (P.current === "touch" ? !q(c, A.relatedTarget) : !0) && S(A);
    }
    function L(A) {
      d() || b.current == null || b.current({
        ...e,
        tree: h,
        x: A.clientX,
        y: A.clientY,
        onClose() {
          w(), M(), S(A);
        }
      })(A);
    }
    if (J(s)) {
      const A = s;
      return n && A.addEventListener("mouseleave", L), c == null || c.addEventListener("mouseleave", L), y && A.addEventListener("mousemove", T, {
        once: !0
      }), A.addEventListener("mouseenter", T), A.addEventListener("mouseleave", R), () => {
        n && A.removeEventListener("mouseleave", L), c == null || c.removeEventListener("mouseleave", L), y && A.removeEventListener("mousemove", T), A.removeEventListener("mouseenter", T), A.removeEventListener("mouseleave", R);
      };
    }
  }, [s, c, a, e, p, l, y, S, M, w, r, n, h, v, b, o]), z(() => {
    var d;
    if (a && n && (d = b.current) != null && d.__options.blockPointerEvents && H()) {
      const L = G(c).body;
      if (L.setAttribute(xt, ""), L.style.pointerEvents = "none", O.current = !0, J(s) && c) {
        var T, R;
        const A = s, $ = h == null || (T = h.nodesRef.current.find((Y) => Y.id === C)) == null || (R = T.context) == null ? void 0 : R.elements.floating;
        return $ && ($.style.pointerEvents = ""), A.style.pointerEvents = "auto", c.style.pointerEvents = "auto", () => {
          A.style.pointerEvents = "", c.style.pointerEvents = "";
        };
      }
    }
  }, [a, n, C, c, s, h, b, o, H]), z(() => {
    n || (P.current = void 0, M(), w());
  }, [n, M, w]), m.useEffect(() => () => {
    M(), clearTimeout(x.current), clearTimeout(k.current), w();
  }, [a, M, w]), m.useMemo(() => {
    if (!a)
      return {};
    function d(T) {
      P.current = T.pointerType;
    }
    return {
      reference: {
        onPointerDown: d,
        onPointerEnter: d,
        onMouseMove(T) {
          n || l === 0 || (clearTimeout(k.current), k.current = setTimeout(() => {
            D.current || r(!0, T.nativeEvent);
          }, l));
        }
      },
      floating: {
        onMouseEnter() {
          clearTimeout(x.current);
        },
        onMouseLeave(T) {
          i.emit("dismiss", {
            type: "mouseLeave",
            data: {
              returnFocus: !1
            }
          }), S(T.nativeEvent, !1);
        }
      }
    };
  }, [i, a, l, n, r, S]);
}
function pe(e) {
  let t = e.activeElement;
  for (; ((n = t) == null || (r = n.shadowRoot) == null ? void 0 : r.activeElement) != null; ) {
    var n, r;
    t = t.shadowRoot.activeElement;
  }
  return t;
}
let Et = 0;
function se(e, t) {
  t === void 0 && (t = {});
  const {
    preventScroll: n = !1,
    cancelPrevious: r = !0,
    sync: o = !1
  } = t;
  r && cancelAnimationFrame(Et);
  const i = () => e == null ? void 0 : e.focus({
    preventScroll: n
  });
  o ? i() : Et = requestAnimationFrame(i);
}
function vr(e, t) {
  var n;
  let r = [], o = (n = e.find((i) => i.id === t)) == null ? void 0 : n.parentId;
  for (; o; ) {
    const i = e.find((s) => s.id === o);
    o = i == null ? void 0 : i.parentId, i && (r = r.concat(i));
  }
  return r;
}
function ve(e, t) {
  let n = e.filter((o) => {
    var i;
    return o.parentId === t && ((i = o.context) == null ? void 0 : i.open);
  }), r = n;
  for (; r.length; )
    r = e.filter((o) => {
      var i;
      return (i = r) == null ? void 0 : i.some((s) => {
        var c;
        return o.parentId === s.id && ((c = o.context) == null ? void 0 : c.open);
      });
    }), n = n.concat(r);
  return n;
}
function st(e) {
  return "composedPath" in e ? e.composedPath()[0] : e.target;
}
const hr = "input:not([type='hidden']):not([disabled]),[contenteditable]:not([contenteditable='false']),textarea:not([disabled])";
function Zt(e) {
  return Ee(e) && e.matches(hr);
}
function Ge(e) {
  e.preventDefault(), e.stopPropagation();
}
const ct = () => ({
  getShadowRoot: !0,
  displayCheck: (
    // JSDOM does not support the `tabbable` library. To solve this we can
    // check if `ResizeObserver` is a real function (not polyfilled), which
    // determines if the current environment is JSDOM-like.
    typeof ResizeObserver == "function" && ResizeObserver.toString().includes("[native code]") ? "full" : "none"
  )
});
function Jt(e, t) {
  const n = ot(e, ct());
  t === "prev" && n.reverse();
  const r = n.indexOf(pe(G(e)));
  return n.slice(r + 1)[0];
}
function Qt() {
  return Jt(document.body, "next");
}
function en() {
  return Jt(document.body, "prev");
}
function ye(e, t) {
  const n = t || e.currentTarget, r = e.relatedTarget;
  return !r || !q(n, r);
}
function br(e) {
  ot(e, ct()).forEach((n) => {
    n.dataset.tabindex = n.getAttribute("tabindex") || "", n.setAttribute("tabindex", "-1");
  });
}
function yr(e) {
  e.querySelectorAll("[data-tabindex]").forEach((n) => {
    const r = n.dataset.tabindex;
    delete n.dataset.tabindex, r ? n.setAttribute("tabindex", r) : n.removeAttribute("tabindex");
  });
}
const ut = {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: "1px",
  margin: "-1px",
  overflow: "hidden",
  padding: 0,
  position: "fixed",
  whiteSpace: "nowrap",
  width: "1px",
  top: 0,
  left: 0
};
let wr;
function Rt(e) {
  e.key === "Tab" && (e.target, clearTimeout(wr));
}
const He = /* @__PURE__ */ m.forwardRef(function(t, n) {
  const [r, o] = m.useState();
  z(() => (pr() && o("button"), document.addEventListener("keydown", Rt), () => {
    document.removeEventListener("keydown", Rt);
  }), []);
  const i = {
    ref: n,
    tabIndex: 0,
    // Role is only for VoiceOver
    role: r,
    "aria-hidden": r ? void 0 : !0,
    [le("focus-guard")]: "",
    style: ut
  };
  return /* @__PURE__ */ m.createElement("span", xe({}, t, i));
}), tn = /* @__PURE__ */ m.createContext(null);
function xr(e) {
  let {
    id: t,
    root: n
  } = e === void 0 ? {} : e;
  const [r, o] = m.useState(null), i = it(), s = nn(), c = m.useMemo(() => ({
    id: t,
    root: n,
    portalContext: s,
    uniqueId: i
  }), [t, n, s, i]), u = m.useRef();
  return z(() => () => {
    r == null || r.remove();
  }, [r, c]), z(() => {
    if (u.current === c)
      return;
    u.current = c;
    const {
      id: a,
      root: g,
      portalContext: f,
      uniqueId: p
    } = c, l = a ? document.getElementById(a) : null, y = le("portal");
    if (l) {
      const h = document.createElement("div");
      h.id = p, h.setAttribute(y, ""), l.appendChild(h), o(h);
    } else {
      let h = g || (f == null ? void 0 : f.portalNode);
      h && !J(h) && (h = h.current), h = h || document.body;
      let C = null;
      a && (C = document.createElement("div"), C.id = a, h.appendChild(C));
      const b = document.createElement("div");
      b.id = p, b.setAttribute(y, ""), h = C || h, h.appendChild(b), o(b);
    }
  }, [c]), r;
}
function Br(e) {
  let {
    children: t,
    id: n,
    root: r = null,
    preserveTabOrder: o = !0
  } = e;
  const i = xr({
    id: n,
    root: r
  }), [s, c] = m.useState(null), u = m.useRef(null), a = m.useRef(null), g = m.useRef(null), f = m.useRef(null), p = (
    // The FocusManager and therefore floating element are currently open/
    // rendered.
    !!s && // Guards are only for non-modal focus management.
    !s.modal && // Don't render if unmount is transitioning.
    s.open && o && !!(r || i)
  );
  return m.useEffect(() => {
    if (!i || !o || s != null && s.modal)
      return;
    function l(y) {
      i && ye(y) && (y.type === "focusin" ? yr : br)(i);
    }
    return i.addEventListener("focusin", l, !0), i.addEventListener("focusout", l, !0), () => {
      i.removeEventListener("focusin", l, !0), i.removeEventListener("focusout", l, !0);
    };
  }, [i, o, s == null ? void 0 : s.modal]), /* @__PURE__ */ m.createElement(tn.Provider, {
    value: m.useMemo(() => ({
      preserveTabOrder: o,
      beforeOutsideRef: u,
      afterOutsideRef: a,
      beforeInsideRef: g,
      afterInsideRef: f,
      portalNode: i,
      setFocusManagerState: c
    }), [o, i])
  }, p && i && /* @__PURE__ */ m.createElement(He, {
    "data-type": "outside",
    ref: u,
    onFocus: (l) => {
      if (ye(l, i)) {
        var y;
        (y = g.current) == null || y.focus();
      } else {
        const h = en() || (s == null ? void 0 : s.refs.domReference.current);
        h == null || h.focus();
      }
    }
  }), p && i && /* @__PURE__ */ m.createElement("span", {
    "aria-owns": i.id,
    style: ut
  }), i && /* @__PURE__ */ sn(t, i), p && i && /* @__PURE__ */ m.createElement(He, {
    "data-type": "outside",
    ref: a,
    onFocus: (l) => {
      if (ye(l, i)) {
        var y;
        (y = f.current) == null || y.focus();
      } else {
        const h = Qt() || (s == null ? void 0 : s.refs.domReference.current);
        h == null || h.focus(), s != null && s.closeOnFocusOut && (s == null || s.onOpenChange(!1, l.nativeEvent));
      }
    }
  }));
}
const nn = () => m.useContext(tn), Er = /* @__PURE__ */ m.forwardRef(function(t, n) {
  return /* @__PURE__ */ m.createElement("button", xe({}, t, {
    type: "button",
    ref: n,
    tabIndex: -1,
    style: ut
  }));
});
function Vr(e) {
  const {
    context: t,
    children: n,
    disabled: r = !1,
    order: o = ["content"],
    guards: i = !0,
    initialFocus: s = 0,
    returnFocus: c = !0,
    modal: u = !0,
    visuallyHiddenDismiss: a = !1,
    closeOnFocusOut: g = !0
  } = e, {
    open: f,
    refs: p,
    nodeId: l,
    onOpenChange: y,
    events: h,
    dataRef: C,
    elements: {
      domReference: b,
      floating: v
    }
  } = t, P = Kt() ? i : !0, x = be(o), E = be(s), k = be(c), D = Xe(), O = nn(), W = typeof s == "number" && s < 0, H = m.useRef(null), S = m.useRef(null), M = m.useRef(!1), w = m.useRef(null), d = m.useRef(!1), T = O != null, R = b && b.getAttribute("role") === "combobox" && Zt(b), L = m.useCallback(function(I) {
    return I === void 0 && (I = v), I ? ot(I, ct()) : [];
  }, [v]), A = m.useCallback((I) => {
    const N = L(I);
    return x.current.map((F) => b && F === "reference" ? b : v && F === "floating" ? v : N).filter(Boolean).flat();
  }, [b, v, x, L]);
  m.useEffect(() => {
    if (r || !u)
      return;
    function I(F) {
      if (F.key === "Tab") {
        q(v, pe(G(v))) && L().length === 0 && !R && Ge(F);
        const B = A(), j = st(F);
        x.current[0] === "reference" && j === b && (Ge(F), F.shiftKey ? se(B[B.length - 1]) : se(B[1])), x.current[1] === "floating" && j === v && F.shiftKey && (Ge(F), se(B[0]));
      }
    }
    const N = G(v);
    return N.addEventListener("keydown", I), () => {
      N.removeEventListener("keydown", I);
    };
  }, [r, b, v, u, x, p, R, L, A]), m.useEffect(() => {
    if (r || !g)
      return;
    function I() {
      d.current = !0, setTimeout(() => {
        d.current = !1;
      });
    }
    function N(F) {
      const B = F.relatedTarget;
      queueMicrotask(() => {
        const j = !(q(b, B) || q(v, B) || q(B, v) || q(O == null ? void 0 : O.portalNode, B) || B != null && B.hasAttribute(le("focus-guard")) || D && (ve(D.nodesRef.current, l).find((K) => {
          var X, V;
          return q((X = K.context) == null ? void 0 : X.elements.floating, B) || q((V = K.context) == null ? void 0 : V.elements.domReference, B);
        }) || vr(D.nodesRef.current, l).find((K) => {
          var X, V;
          return ((X = K.context) == null ? void 0 : X.elements.floating) === B || ((V = K.context) == null ? void 0 : V.elements.domReference) === B;
        })));
        B && j && !d.current && // Fix React 18 Strict Mode returnFocus due to double rendering.
        B !== w.current && (M.current = !0, y(!1, F));
      });
    }
    if (v && Ee(b))
      return b.addEventListener("focusout", N), b.addEventListener("pointerdown", I), !u && v.addEventListener("focusout", N), () => {
        b.removeEventListener("focusout", N), b.removeEventListener("pointerdown", I), !u && v.removeEventListener("focusout", N);
      };
  }, [r, b, v, u, l, D, O, y, g]), m.useEffect(() => {
    var I;
    if (r)
      return;
    const N = Array.from((O == null || (I = O.portalNode) == null ? void 0 : I.querySelectorAll("[" + le("portal") + "]")) || []);
    if (v && u) {
      const F = [v, ...N, H.current, S.current].filter((K) => K != null), j = (P ? jt : Wn)(x.current.includes("reference") || R ? F.concat(b || []) : F, void 0, le("inert"));
      return () => {
        j();
      };
    }
  }, [r, b, v, u, x, O, R, P]), z(() => {
    if (r || !v)
      return;
    const I = G(v), N = pe(I);
    queueMicrotask(() => {
      const F = A(v), B = E.current, j = (typeof B == "number" ? F[B] : B.current) || v, K = q(v, N);
      !W && !K && f && se(j, {
        preventScroll: j === v
      });
    });
  }, [r, f, v, W, A, E]), z(() => {
    if (r || !v)
      return;
    let I = !1;
    const N = G(v), F = pe(N), B = C.current;
    w.current = F;
    function j(K) {
      if (K.type === "escapeKey" && p.domReference.current && (w.current = p.domReference.current), ["referencePress", "escapeKey"].includes(K.type))
        return;
      const X = K.data.returnFocus;
      typeof X == "object" ? (M.current = !1, I = X.preventScroll) : M.current = !X;
    }
    return h.on("dismiss", j), () => {
      h.off("dismiss", j);
      const K = pe(N);
      (q(v, K) || D && ve(D.nodesRef.current, l).some((V) => {
        var _;
        return q((_ = V.context) == null ? void 0 : _.elements.floating, K);
      }) || B.openEvent && ["click", "mousedown"].includes(B.openEvent.type)) && p.domReference.current && (w.current = p.domReference.current), // eslint-disable-next-line react-hooks/exhaustive-deps
      k.current && Ee(w.current) && !M.current && se(w.current, {
        // When dismissing nested floating elements, by the time the rAF has
        // executed, the menus will all have been unmounted. When they try
        // to get focused, the calls get ignored — leaving the root
        // reference focused as desired.
        cancelPrevious: !1,
        preventScroll: I
      });
    };
  }, [r, v, k, C, p, h, D, l]), z(() => {
    if (!(r || !O))
      return O.setFocusManagerState({
        ...t,
        modal: u,
        closeOnFocusOut: g,
        open: f
      }), () => {
        O.setFocusManagerState(null);
      };
  }, [r, O, u, f, g, t]), z(() => {
    if (!r && v && typeof MutationObserver == "function") {
      const I = () => {
        const F = v.getAttribute("tabindex");
        x.current.includes("floating") || pe(G(v)) !== p.domReference.current && L().length === 0 ? F !== "0" && v.setAttribute("tabindex", "0") : F !== "-1" && v.setAttribute("tabindex", "-1");
      };
      I();
      const N = new MutationObserver(I);
      return N.observe(v, {
        childList: !0,
        subtree: !0,
        attributes: !0
      }), () => {
        N.disconnect();
      };
    }
  }, [r, v, p, x, L]);
  function $(I) {
    return r || !a || !u ? null : /* @__PURE__ */ m.createElement(Er, {
      ref: I === "start" ? H : S,
      onClick: (N) => y(!1, N.nativeEvent)
    }, typeof a == "string" ? a : "Dismiss");
  }
  const Y = !r && P && !R && (T || u);
  return /* @__PURE__ */ m.createElement(m.Fragment, null, Y && /* @__PURE__ */ m.createElement(He, {
    "data-type": "inside",
    ref: O == null ? void 0 : O.beforeInsideRef,
    onFocus: (I) => {
      if (u) {
        const F = A();
        se(o[0] === "reference" ? F[0] : F[F.length - 1]);
      } else if (O != null && O.preserveTabOrder && O.portalNode)
        if (M.current = !1, ye(I, O.portalNode)) {
          const F = Qt() || b;
          F == null || F.focus();
        } else {
          var N;
          (N = O.beforeOutsideRef.current) == null || N.focus();
        }
    }
  }), !R && $("start"), n, $("end"), Y && /* @__PURE__ */ m.createElement(He, {
    "data-type": "inside",
    ref: O == null ? void 0 : O.afterInsideRef,
    onFocus: (I) => {
      if (u)
        se(A()[0]);
      else if (O != null && O.preserveTabOrder && O.portalNode)
        if (g && (M.current = !0), ye(I, O.portalNode)) {
          const F = en() || b;
          F == null || F.focus();
        } else {
          var N;
          (N = O.afterOutsideRef.current) == null || N.focus();
        }
    }
  }));
}
const Oe = /* @__PURE__ */ le("scroll-lock"), Wr = /* @__PURE__ */ m.forwardRef(function(t, n) {
  let {
    lockScroll: r = !1,
    ...o
  } = t;
  return z(() => {
    var i, s;
    if (!r || document.body.hasAttribute(Oe))
      return;
    document.body.setAttribute(Oe, "");
    const a = Math.round(document.documentElement.getBoundingClientRect().left) + document.documentElement.scrollLeft ? "paddingLeft" : "paddingRight", g = window.innerWidth - document.documentElement.clientWidth;
    if (!/iP(hone|ad|od)|iOS/.test(Gt()))
      return Object.assign(document.body.style, {
        overflow: "hidden",
        [a]: g + "px"
      }), () => {
        document.body.removeAttribute(Oe), Object.assign(document.body.style, {
          overflow: "",
          [a]: ""
        });
      };
    const f = ((i = window.visualViewport) == null ? void 0 : i.offsetLeft) || 0, p = ((s = window.visualViewport) == null ? void 0 : s.offsetTop) || 0, l = window.pageXOffset, y = window.pageYOffset;
    return Object.assign(document.body.style, {
      position: "fixed",
      overflow: "hidden",
      top: -(y - Math.floor(p)) + "px",
      left: -(l - Math.floor(f)) + "px",
      right: "0",
      [a]: g + "px"
    }), () => {
      Object.assign(document.body.style, {
        position: "",
        overflow: "",
        top: "",
        left: "",
        right: "",
        [a]: ""
      }), document.body.removeAttribute(Oe), window.scrollTo(l, y);
    };
  }, [r]), /* @__PURE__ */ m.createElement("div", xe({
    ref: n
  }, o, {
    style: {
      position: "fixed",
      overflow: "auto",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      ...o.style
    }
  }));
});
function Tt(e) {
  return Ee(e.target) && e.target.tagName === "BUTTON";
}
function Ct(e) {
  return Zt(e);
}
function Hr(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    onOpenChange: r,
    dataRef: o,
    elements: {
      domReference: i
    }
  } = e, {
    enabled: s = !0,
    event: c = "click",
    toggle: u = !0,
    ignoreMouse: a = !1,
    keyboardHandlers: g = !0
  } = t, f = m.useRef(), p = m.useRef(!1);
  return m.useMemo(() => s ? {
    reference: {
      onPointerDown(l) {
        f.current = l.pointerType;
      },
      onMouseDown(l) {
        l.button === 0 && (We(f.current, !0) && a || c !== "click" && (n && u && (!o.current.openEvent || o.current.openEvent.type === "mousedown") ? r(!1, l.nativeEvent) : (l.preventDefault(), r(!0, l.nativeEvent))));
      },
      onClick(l) {
        if (c === "mousedown" && f.current) {
          f.current = void 0;
          return;
        }
        We(f.current, !0) && a || (n && u && (!o.current.openEvent || o.current.openEvent.type === "click") ? r(!1, l.nativeEvent) : r(!0, l.nativeEvent));
      },
      onKeyDown(l) {
        f.current = void 0, !(l.defaultPrevented || !g || Tt(l)) && (l.key === " " && !Ct(i) && (l.preventDefault(), p.current = !0), l.key === "Enter" && r(!(n && u), l.nativeEvent));
      },
      onKeyUp(l) {
        l.defaultPrevented || !g || Tt(l) || Ct(i) || l.key === " " && p.current && (p.current = !1, r(!(n && u), l.nativeEvent));
      }
    }
  } : {}, [s, o, c, a, g, i, u, n, r]);
}
const Rr = m[/* @__PURE__ */ "useInsertionEffect".toString()], Tr = Rr || ((e) => e());
function Le(e) {
  const t = m.useRef(() => {
    if (process.env.NODE_ENV !== "production")
      throw new Error("Cannot call an event handler while rendering.");
  });
  return Tr(() => {
    t.current = e;
  }), m.useCallback(function() {
    for (var n = arguments.length, r = new Array(n), o = 0; o < n; o++)
      r[o] = arguments[o];
    return t.current == null ? void 0 : t.current(...r);
  }, []);
}
function Ze(e, t) {
  if (t == null)
    return !1;
  if ("composedPath" in e)
    return e.composedPath().includes(t);
  const n = e;
  return n.target != null && t.contains(n.target);
}
const Cr = {
  pointerdown: "onPointerDown",
  mousedown: "onMouseDown",
  click: "onClick"
}, Sr = {
  pointerdown: "onPointerDownCapture",
  mousedown: "onMouseDownCapture",
  click: "onClickCapture"
}, Ar = (e) => {
  var t, n;
  return {
    escapeKeyBubbles: typeof e == "boolean" ? e : (t = e == null ? void 0 : e.escapeKey) != null ? t : !1,
    outsidePressBubbles: typeof e == "boolean" ? e : (n = e == null ? void 0 : e.outsidePress) != null ? n : !0
  };
};
function $r(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    onOpenChange: r,
    events: o,
    nodeId: i,
    elements: {
      reference: s,
      domReference: c,
      floating: u
    },
    dataRef: a
  } = e, {
    enabled: g = !0,
    escapeKey: f = !0,
    outsidePress: p = !0,
    outsidePressEvent: l = "pointerdown",
    referencePress: y = !1,
    referencePressEvent: h = "pointerdown",
    ancestorScroll: C = !1,
    bubbles: b
  } = t, v = Xe(), P = Ut() != null, x = Le(typeof p == "function" ? p : () => !1), E = typeof p == "function" ? x : p, k = m.useRef(!1), {
    escapeKeyBubbles: D,
    outsidePressBubbles: O
  } = Ar(b), W = Le((S) => {
    if (!n || !g || !f || S.key !== "Escape")
      return;
    const M = v ? ve(v.nodesRef.current, i) : [];
    if (!D && (S.stopPropagation(), M.length > 0)) {
      let w = !0;
      if (M.forEach((d) => {
        var T;
        if ((T = d.context) != null && T.open && !d.context.dataRef.current.__escapeKeyBubbles) {
          w = !1;
          return;
        }
      }), !w)
        return;
    }
    o.emit("dismiss", {
      type: "escapeKey",
      data: {
        returnFocus: {
          preventScroll: !1
        }
      }
    }), r(!1, gr(S) ? S.nativeEvent : S);
  }), H = Le((S) => {
    const M = k.current;
    if (k.current = !1, M || typeof E == "function" && !E(S))
      return;
    const w = st(S);
    if (Ee(w) && u) {
      const R = w.clientWidth > 0 && w.scrollWidth > w.clientWidth, L = w.clientHeight > 0 && w.scrollHeight > w.clientHeight;
      let A = L && S.offsetX > w.clientWidth;
      if (L && Ye(u).getComputedStyle(w).direction === "rtl" && (A = S.offsetX <= w.offsetWidth - w.clientWidth), A || R && S.offsetY > w.clientHeight)
        return;
    }
    const d = v && ve(v.nodesRef.current, i).some((R) => {
      var L;
      return Ze(S, (L = R.context) == null ? void 0 : L.elements.floating);
    });
    if (Ze(S, u) || Ze(S, c) || d)
      return;
    const T = v ? ve(v.nodesRef.current, i) : [];
    if (T.length > 0) {
      let R = !0;
      if (T.forEach((L) => {
        var A;
        if ((A = L.context) != null && A.open && !L.context.dataRef.current.__outsidePressBubbles) {
          R = !1;
          return;
        }
      }), !R)
        return;
    }
    o.emit("dismiss", {
      type: "outsidePress",
      data: {
        returnFocus: P ? {
          preventScroll: !0
        } : dr(S) || mr(S)
      }
    }), r(!1, S);
  });
  return m.useEffect(() => {
    if (!n || !g)
      return;
    a.current.__escapeKeyBubbles = D, a.current.__outsidePressBubbles = O;
    function S(d) {
      r(!1, d);
    }
    const M = G(u);
    f && M.addEventListener("keydown", W), E && M.addEventListener(l, H);
    let w = [];
    return C && (J(c) && (w = ue(c)), J(u) && (w = w.concat(ue(u))), !J(s) && s && s.contextElement && (w = w.concat(ue(s.contextElement)))), w = w.filter((d) => {
      var T;
      return d !== ((T = M.defaultView) == null ? void 0 : T.visualViewport);
    }), w.forEach((d) => {
      d.addEventListener("scroll", S, {
        passive: !0
      });
    }), () => {
      f && M.removeEventListener("keydown", W), E && M.removeEventListener(l, H), w.forEach((d) => {
        d.removeEventListener("scroll", S);
      });
    };
  }, [a, u, c, s, f, E, l, n, r, C, g, D, O, W, H]), m.useEffect(() => {
    k.current = !1;
  }, [E, l]), m.useMemo(() => g ? {
    reference: {
      onKeyDown: W,
      [Cr[h]]: (S) => {
        y && (o.emit("dismiss", {
          type: "referencePress",
          data: {
            returnFocus: !1
          }
        }), r(!1, S.nativeEvent));
      }
    },
    floating: {
      onKeyDown: W,
      [Sr[l]]: () => {
        k.current = !0;
      }
    }
  } : {}, [g, o, y, l, h, r, W]);
}
function _r(e) {
  var t;
  e === void 0 && (e = {});
  const {
    open: n = !1,
    onOpenChange: r,
    nodeId: o
  } = e, [i, s] = m.useState(null), c = ((t = e.elements) == null ? void 0 : t.reference) || i, u = Nn(e), a = Xe(), g = Le((x, E) => {
    x && (p.current.openEvent = E), r == null || r(x, E);
  }), f = m.useRef(null), p = m.useRef({}), l = m.useState(() => cr())[0], y = it(), h = m.useCallback((x) => {
    const E = J(x) ? {
      getBoundingClientRect: () => x.getBoundingClientRect(),
      contextElement: x
    } : x;
    u.refs.setReference(E);
  }, [u.refs]), C = m.useCallback((x) => {
    (J(x) || x === null) && (f.current = x, s(x)), (J(u.refs.reference.current) || u.refs.reference.current === null || // Don't allow setting virtual elements using the old technique back to
    // `null` to support `positionReference` + an unstable `reference`
    // callback ref.
    x !== null && !J(x)) && u.refs.setReference(x);
  }, [u.refs]), b = m.useMemo(() => ({
    ...u.refs,
    setReference: C,
    setPositionReference: h,
    domReference: f
  }), [u.refs, C, h]), v = m.useMemo(() => ({
    ...u.elements,
    domReference: c
  }), [u.elements, c]), P = m.useMemo(() => ({
    ...u,
    refs: b,
    elements: v,
    dataRef: p,
    nodeId: o,
    floatingId: y,
    events: l,
    open: n,
    onOpenChange: g
  }), [u, o, y, l, n, g, b, v]);
  return z(() => {
    const x = a == null ? void 0 : a.nodesRef.current.find((E) => E.id === o);
    x && (x.context = P);
  }), m.useMemo(() => ({
    ...u,
    context: P,
    refs: b,
    elements: v
  }), [u, b, v, P]);
}
function Je(e, t, n) {
  const r = /* @__PURE__ */ new Map();
  return {
    ...n === "floating" && {
      tabIndex: -1
    },
    ...e,
    ...t.map((o) => o ? o[n] : null).concat(e).reduce((o, i) => (i && Object.entries(i).forEach((s) => {
      let [c, u] = s;
      if (c.indexOf("on") === 0) {
        if (r.has(c) || r.set(c, []), typeof u == "function") {
          var a;
          (a = r.get(c)) == null || a.push(u), o[c] = function() {
            for (var g, f = arguments.length, p = new Array(f), l = 0; l < f; l++)
              p[l] = arguments[l];
            return (g = r.get(c)) == null ? void 0 : g.map((y) => y(...p)).find((y) => y !== void 0);
          };
        }
      } else
        o[c] = u;
    }), o), {})
  };
}
function jr(e) {
  e === void 0 && (e = []);
  const t = e, n = m.useCallback(
    (i) => Je(i, e, "reference"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    t
  ), r = m.useCallback(
    (i) => Je(i, e, "floating"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    t
  ), o = m.useCallback(
    (i) => Je(i, e, "item"),
    // Granularly check for `item` changes, because the `getItemProps` getter
    // should be as referentially stable as possible since it may be passed as
    // a prop to many components. All `item` key values must therefore be
    // memoized.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    e.map((i) => i == null ? void 0 : i.item)
  );
  return m.useMemo(() => ({
    getReferenceProps: n,
    getFloatingProps: r,
    getItemProps: o
  }), [n, r, o]);
}
function Kr(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    floatingId: r
  } = e, {
    enabled: o = !0,
    role: i = "dialog"
  } = t, s = it();
  return m.useMemo(() => {
    const c = {
      id: r,
      role: i
    };
    return o ? i === "tooltip" ? {
      reference: {
        "aria-describedby": n ? r : void 0
      },
      floating: c
    } : {
      reference: {
        "aria-expanded": n ? "true" : "false",
        "aria-haspopup": i === "alertdialog" ? "dialog" : i,
        "aria-controls": n ? r : void 0,
        ...i === "listbox" && {
          role: "combobox"
        },
        ...i === "menu" && {
          id: s
        }
      },
      floating: {
        ...c,
        ...i === "menu" && {
          "aria-labelledby": s
        }
      }
    } : {};
  }, [o, i, n, r, s]);
}
function St(e, t) {
  const [n, r] = e;
  let o = !1;
  const i = t.length;
  for (let s = 0, c = i - 1; s < i; c = s++) {
    const [u, a] = t[s] || [0, 0], [g, f] = t[c] || [0, 0];
    a >= r != f >= r && n <= (g - u) * (r - a) / (f - a) + u && (o = !o);
  }
  return o;
}
function Or(e, t) {
  return e[0] >= t.x && e[0] <= t.x + t.width && e[1] >= t.y && e[1] <= t.y + t.height;
}
function Xr(e) {
  e === void 0 && (e = {});
  const {
    buffer: t = 0.5,
    blockPointerEvents: n = !1,
    requireIntent: r = !0
  } = e;
  let o, i = !1, s = null, c = null, u = performance.now();
  function a(f, p) {
    const l = performance.now(), y = l - u;
    if (s === null || c === null || y === 0)
      return s = f, c = p, u = l, null;
    const h = f - s, C = p - c, v = Math.sqrt(h * h + C * C) / y;
    return s = f, c = p, u = l, v;
  }
  const g = (f) => {
    let {
      x: p,
      y: l,
      placement: y,
      elements: h,
      onClose: C,
      nodeId: b,
      tree: v
    } = f;
    return function(x) {
      function E() {
        clearTimeout(o), C();
      }
      if (clearTimeout(o), !h.domReference || !h.floating || y == null || p == null || l == null)
        return;
      const {
        clientX: k,
        clientY: D
      } = x, O = [k, D], W = st(x), H = x.type === "mouseleave", S = q(h.floating, W), M = q(h.domReference, W), w = h.domReference.getBoundingClientRect(), d = h.floating.getBoundingClientRect(), T = y.split("-")[0], R = p > d.right - d.width / 2, L = l > d.bottom - d.height / 2, A = Or(O, w), $ = d.width > w.width, Y = d.height > w.height, I = ($ ? w : d).left, N = ($ ? w : d).right, F = (Y ? w : d).top, B = (Y ? w : d).bottom;
      if (S && (i = !0, !H))
        return;
      if (M && (i = !1), M && !H) {
        i = !0;
        return;
      }
      if (H && J(x.relatedTarget) && q(h.floating, x.relatedTarget) || v && ve(v.nodesRef.current, b).some((X) => {
        let {
          context: V
        } = X;
        return V == null ? void 0 : V.open;
      }))
        return;
      if (T === "top" && l >= w.bottom - 1 || T === "bottom" && l <= w.top + 1 || T === "left" && p >= w.right - 1 || T === "right" && p <= w.left + 1)
        return E();
      let j = [];
      switch (T) {
        case "top":
          j = [[I, w.top + 1], [I, d.bottom - 1], [N, d.bottom - 1], [N, w.top + 1]];
          break;
        case "bottom":
          j = [[I, d.top + 1], [I, w.bottom - 1], [N, w.bottom - 1], [N, d.top + 1]];
          break;
        case "left":
          j = [[d.right - 1, B], [d.right - 1, F], [w.left + 1, F], [w.left + 1, B]];
          break;
        case "right":
          j = [[w.right - 1, B], [w.right - 1, F], [d.left + 1, F], [d.left + 1, B]];
          break;
      }
      function K(X) {
        let [V, _] = X;
        switch (T) {
          case "top": {
            const oe = [$ ? V + t / 2 : R ? V + t * 4 : V - t * 4, _ + t + 1], ie = [$ ? V - t / 2 : R ? V + t * 4 : V - t * 4, _ + t + 1], de = [[d.left, R || $ ? d.bottom - t : d.top], [d.right, R ? $ ? d.bottom - t : d.top : d.bottom - t]];
            return [oe, ie, ...de];
          }
          case "bottom": {
            const oe = [$ ? V + t / 2 : R ? V + t * 4 : V - t * 4, _ - t], ie = [$ ? V - t / 2 : R ? V + t * 4 : V - t * 4, _ - t], de = [[d.left, R || $ ? d.top + t : d.bottom], [d.right, R ? $ ? d.top + t : d.bottom : d.top + t]];
            return [oe, ie, ...de];
          }
          case "left": {
            const oe = [V + t + 1, Y ? _ + t / 2 : L ? _ + t * 4 : _ - t * 4], ie = [V + t + 1, Y ? _ - t / 2 : L ? _ + t * 4 : _ - t * 4];
            return [...[[L || Y ? d.right - t : d.left, d.top], [L ? Y ? d.right - t : d.left : d.right - t, d.bottom]], oe, ie];
          }
          case "right": {
            const oe = [V - t, Y ? _ + t / 2 : L ? _ + t * 4 : _ - t * 4], ie = [V - t, Y ? _ - t / 2 : L ? _ + t * 4 : _ - t * 4], de = [[L || Y ? d.left + t : d.right, d.top], [L ? Y ? d.left + t : d.right : d.left + t, d.bottom]];
            return [oe, ie, ...de];
          }
        }
      }
      if (!St([k, D], j)) {
        if (i && !A)
          return E();
        if (!H && r) {
          const X = a(x.clientX, x.clientY);
          if (X !== null && X < 0.1)
            return E();
        }
        St([k, D], K([p, l])) ? !i && r && (o = window.setTimeout(E, 40)) : E();
      }
    };
  };
  return g.__options = {
    blockPointerEvents: n
  }, g;
}
const Yr = {
  duration: 0.5,
  type: "spring",
  bounce: 0
};
export {
  Br as F,
  Yr as O,
  Hr as a,
  Kr as b,
  $r as c,
  jr as d,
  Vr as e,
  Wr as f,
  Nr as g,
  Dr as h,
  Fr as i,
  kr as j,
  Ir as k,
  Mr as o,
  Xr as s,
  _r as u
};
