import { Container } from '@carvertical/ui';
import { AppBanner } from './AppBanner';
import { Copyright } from './Copyright';
import { Divider } from './Divider';
import { FollowSection } from './FollowSection';
import { FooterSections } from './FooterSections';
import { MiscLinks } from './MiscLinks';
import styles from './Footer.module.scss';

const Footer = () => (
  <footer className={styles.root}>
    <Container>
      <FollowSection />

      <Divider spaceSize="l" />

      <FooterSections />

      <AppBanner className="hideFromTabletLandscape" />

      <Divider spaceSize="m" />

      <MiscLinks />

      <Divider spaceSize="s" />

      <Copyright />
    </Container>
  </footer>
);

export { Footer };
