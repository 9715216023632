import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import emailMisspelled, { top100 } from 'email-misspelled';
import { recordEvent } from 'services/recordEvent';
import { useMode } from 'context/ModeProvider';
import { useRouteData } from 'context/RouteDataProvider';
import type AuthContainer from 'containers/AuthContainer';
import type { CheckoutFormFields } from '../schemas';
import { LOG_IN_TIMEOUT } from '../constants';
import { useCheckoutAuthActions, useStep } from './useCheckoutAuthStore';

type UserFieldsParams = {
  auth: AuthContainer;
  onSubmit?: () => void;
};

const useUserFields = ({ auth, onSubmit }: UserFieldsParams) => {
  const step = useStep();
  const { changeAuthStep, rememberEmail } = useCheckoutAuthActions();
  const [enteredInvalidEmail, setEnteredInvalidEmail] = useState(false);
  const [checkingEmail, setCheckingEmail] = useState(false);
  const [emailSuggestion, setEmailSuggestion] = useState<string>();
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [ready, setReady] = useState(false);

  const { isApp } = useMode();
  const form = useFormContext<CheckoutFormFields>();

  const formEmail = form.watch('email') || '';
  const emailSuggestionShown = emailSuggestion && !checkingEmail;

  const {
    pages: { privacy: privacyPage, terms: termsPage },
  } = useRouteData();

  const validateEmail = async () => {
    const valid = await form.trigger('email');

    setEnteredInvalidEmail(!valid);
    setEmailSuggestion(undefined);

    if (!valid) {
      return false;
    }

    setCheckingEmail(true);

    const userId = await auth.ensureUser(formEmail, {
      redirectTimeout: LOG_IN_TIMEOUT,
    });

    if (!userId) {
      if (auth.state.requiresLoginCode) {
        rememberEmail(formEmail);
        changeAuthStep('otp');
      } else {
        setRedirectToLogin(true);
      }
    } else {
      recordEvent('completeRegistration');
    }

    setCheckingEmail(false);
    setReady(true);

    return true;
  };

  const maybeSubmit = async (event: KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }
    if (await validateEmail()) {
      onSubmit?.();
    }
  };

  const resendCode = async (email: string) => {
    await auth.ensureUser(email, {
      redirectTimeout: LOG_IN_TIMEOUT,
    });
  };

  const resetEmail = () => {
    setEnteredInvalidEmail(false);
    setReady(false);
    form.setValue('email', '');
    auth.logOut();
  };

  useEffect(() => {
    if (step === 'email') {
      setReady(false);
    }
  }, [step]);

  const checkMisspelledEmail = () => {
    const emailChecker = emailMisspelled({ domains: top100 });
    const correctedEmail = emailChecker(formEmail)[0]?.corrected;
    setEmailSuggestion(correctedEmail);
  };

  const acceptSuggestedEmail = () => {
    form.setValue('email', emailSuggestion);
    form.trigger('email');
    setEmailSuggestion(undefined);
  };

  return {
    validateEmail,
    checkMisspelledEmail,
    acceptSuggestedEmail,
    emailSuggestionShown,
    emailSuggestion,
    resetEmail,
    maybeSubmit,
    enteredInvalidEmail,
    redirectToLogin,
    ready,
    checkingEmail,
    privacyPage,
    termsPage,
    form,
    isApp,
    resendCode,
  };
};

export type { UserFieldsParams };
export default useUserFields;
