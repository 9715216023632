import differenceInDays from 'date-fns/difference_in_days';

const EXPIRATION_THRESHOLD = 28;

const validateDaysTillExpiry = (expiryDate: string, expirationThreshold = EXPIRATION_THRESHOLD) => {
  const daysTilExpiration = differenceInDays(expiryDate, new Date());
  return daysTilExpiration >= 0 && daysTilExpiration <= expirationThreshold;
};

export { validateDaysTillExpiry };
