const NS_COMMON = 'common';
const NS_HOME = 'home';
const NS_PRESS = 'press';
const NS_REPORT = 'report'; // TODO: remove after `report` page deleted
const NS_PRICING = 'pricing';
const NS_USER = 'user';
const NS_BUSINESS = 'business';
const NS_FEEDBACK = 'feedback';
const NS_CHECKOUT = 'checkout';
const NS_AUTH = 'auth';
const NS_GEO = 'geo';
const NS_TRANSPARENCY_INDEX = 'transparencyIndex';
const NS_VALUATION = 'valuation';
const NS_INSPECTION = 'inspection';
const NS_PRECHECK = 'precheck';
const NS_AFFILIATE = 'affiliate';
const NS_ADVANTAGES = 'advantages';
const NS_CONTENT = 'content';

const DEFAULT_NS = NS_COMMON;

const ERROR_TRANSLATION_KEYS = {
  lp: 'validationErrors.lp',
  vin: 'validationErrors.vin',
  vinOrLp: 'validationErrors.vinOrLp',
  year: 'validationErrors.year',
  phone: 'validationErrors.phone',
  power: 'validationErrors.power',
  email: 'validationErrors.email',
  vatCode: 'validationErrors.vatCode',
  required: 'validationErrors.required',
  password: 'validationErrors.password',
  odometer: 'validationErrors.odometer',
  vinType: 'validationErrors.vinError.type',
  vinLong: 'validationErrors.vinError.long',
  vinShort: 'validationErrors.vinError.short',
  vinTitle: 'validationErrors.vinError.title',
  unauthorized: 'validationErrors.unauthorized',
  accountExist: 'validationErrors.accountExist',
  invalidAuthEmail: 'validationErrors.invalidAuthEmail',
  unknownTitle: 'validationErrors.unknown.title',
  unknownMessage: 'validationErrors.unknown.message',
  passwordNotEqual: 'validationErrors.passwordNotEqual',
  invalidCaptcha: 'validationErrors.invalidCaptcha',
} as const;

export {
  NS_COMMON,
  NS_AFFILIATE,
  NS_PRECHECK,
  NS_HOME,
  NS_PRESS,
  NS_REPORT,
  NS_PRICING,
  NS_USER,
  NS_BUSINESS,
  NS_FEEDBACK,
  NS_CHECKOUT,
  NS_AUTH,
  NS_GEO,
  NS_TRANSPARENCY_INDEX,
  NS_VALUATION,
  NS_INSPECTION,
  NS_ADVANTAGES,
  NS_CONTENT,
  DEFAULT_NS,
  ERROR_TRANSLATION_KEYS,
};
