import type { STATES_AUSTRALIA } from 'constants/states';
import { ACT, NSW, NT, QLD, SA, TAS, VIC, WA } from './assets';

type StateIconProps = {
  state: keyof typeof STATES_AUSTRALIA;
  className?: string;
};

const ICONS = {
  ACT,
  NSW,
  NT,
  QLD,
  SA,
  TAS,
  VIC,
  WA,
};

const StateIcon = ({ state, className }: StateIconProps) => {
  const Component = ICONS[state];

  return Component ? <Component className={className} /> : null;
};

export { StateIcon };
