import cx from 'classnames';
import styles from './Divider.module.scss';

type DividerProps = {
  spaceSize: 's' | 'm' | 'l';
};

const Divider = ({ spaceSize }: DividerProps) => (
  <div className={cx(styles.root, styles[spaceSize])} />
);

export { Divider };
export type { DividerProps };
