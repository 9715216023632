import { forOwn, isPlainObject } from 'lodash';

type GenericObject = Record<string, unknown>;
type KeyTransformer = (value: unknown, key: string) => string;

const mapKeysDeep = (object: GenericObject, transformKey: KeyTransformer): GenericObject => {
  const result: GenericObject = {};

  forOwn(object, (value: unknown, key: string) => {
    const newValue = isPlainObject(value)
      ? mapKeysDeep(value as GenericObject, transformKey)
      : value;
    result[transformKey(newValue, key)] = newValue;
  });

  return result;
};

const flattenPropertiesDeep = (object: GenericObject, keySeparator = '__') => {
  const flattenRecursive = (
    obj: GenericObject,
    parentProperty?: string,
    propertyMap: Record<string, unknown> = {},
  ) =>
    Object.entries(obj).reduce((acc, [key, value]) => {
      const property = parentProperty ? `${parentProperty}${keySeparator}${key}` : key;
      if (isPlainObject(value)) {
        flattenRecursive(value as GenericObject, property, acc);
      } else {
        acc[property] = value;
      }
      return acc;
    }, propertyMap);

  return flattenRecursive(object);
};

export { mapKeysDeep, flattenPropertiesDeep };
