import type { ParseKeys } from 'i18next';
import { app } from 'config';
import GooglePlayIcon from 'modules/shared/assets/google-play.svg';
import AppleIcon from 'modules/shared/assets/apple.svg';

type AppLink = {
  id?: 'playStore' | 'appStore';
  labelKey: ParseKeys;
  href: string;
  AppIcon: React.FC<React.SVGProps<SVGSVGElement>>;
};

const APP_LINKS: AppLink[] = [
  {
    id: 'playStore',
    labelKey: 'menu.playStoreLabel',
    href: app.googlePlay,
    AppIcon: GooglePlayIcon,
  },
  {
    id: 'appStore',
    labelKey: 'menu.appStoreLabel',
    href: app.appStore,
    AppIcon: AppleIcon,
  },
];

export { APP_LINKS };
export type { AppLink };
