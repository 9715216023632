import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { setPassword } from 'services/authApi';

const REDIRECT_TO_LOGIN_AFTER = 3_000;

const useResetPassword = () => {
  const [mutationError, setMutationError] = useState<AxiosError | undefined>(undefined);
  const { push, query } = useRouter();
  const { token } = query;

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: async (password: string) => {
      await setPassword(password, token);
    },
    onSuccess: () => {
      setTimeout(() => {
        push('/auth');
      }, REDIRECT_TO_LOGIN_AFTER);
    },
    onError: (error: AxiosError) => {
      setMutationError(error);
    },
  });

  return {
    resetPassword: mutate,
    resetPasswordSuccess: isSuccess,
    resetPasswordLoading: isPending,
    resetPasswordError: mutationError,
  };
};

export { useResetPassword };
