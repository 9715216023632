import { useRef } from 'react';
import fitty from 'fitty';
// @ts-expect-error - `exports` is missing in the types
import useFontFaceObserver from 'use-font-face-observer';
import { useIsomorphicLayoutEffect } from 'react-use';
import styles from './FitText.module.scss';

type FitTextProps = {
  minSize: number;
  maxSize: number;
  multiLine?: boolean;
  children: React.ReactNode;
};

const FONT_FAMILY = 'Inter';

const FitText = ({ minSize, maxSize, multiLine = false, children }: FitTextProps) => {
  const internalRef = useRef<HTMLDivElement>(null);

  const fontLoaded = useFontFaceObserver([{ family: FONT_FAMILY }]);

  useIsomorphicLayoutEffect(() => {
    if (!internalRef.current || !fontLoaded) {
      return undefined;
    }

    const fittyInstance = fitty(internalRef.current, {
      minSize,
      maxSize,
      multiLine,
    });

    return () => fittyInstance.unsubscribe();
  }, [fontLoaded, minSize, maxSize, multiLine]);

  return (
    <div className={styles.root}>
      <div ref={internalRef} className={styles.content}>
        {children}
      </div>
    </div>
  );
};

export { FitText };
export type { FitTextProps };
