import cx from 'classnames';
import { Text } from '@carvertical/ui';
import { motion } from 'framer-motion';
import { useFocusVisible } from 'use-focus-visible';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import type { ParseKeys } from 'i18next';
import type { IdentifierFormFields } from '../schemas';
import styles from './IdentifierSwitchButton.module.scss';

type IdentifierSwitchButtonProps = {
  id: string;
  active: boolean;
  labelKey: ParseKeys;
};

const TRANSITION_CONFIG = {
  type: 'spring',
  bounce: 0.2,
  duration: 0.6,
};

const IdentifierSwitchButton = ({ active, id, labelKey }: IdentifierSwitchButtonProps) => {
  const { t } = useTranslation();

  const { focusVisible, onBlur, onFocus } = useFocusVisible();

  const {
    formState: { isSubmitSuccessful },
    register,
  } = useFormContext<IdentifierFormFields>();

  return (
    <div className={styles.root}>
      <label
        className={cx(
          styles.button,
          isSubmitSuccessful && styles.disabled,
          focusVisible && styles.focused,
          active && styles.active,
        )}
      >
        <input
          {...register('mode', { onBlur })}
          type="radio"
          value={id}
          className="visuallyHidden"
          disabled={isSubmitSuccessful}
          onFocus={onFocus}
        />

        <Text as="span" variant="xs+" textColor={active && !isSubmitSuccessful ? 'light' : 'dark'}>
          {t(labelKey)}
        </Text>
      </label>

      {active && (
        <motion.span
          layoutId="bubble"
          className={styles.activeIndicator}
          transition={TRANSITION_CONFIG}
          style={{ originY: '0px' }} // https://github.com/framer/motion/issues/1972
        />
      )}
    </div>
  );
};

export { IdentifierSwitchButton };
