import { useMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import type { AxiosError } from 'axios';
import { useState } from 'react';
import { useRouteData } from 'context/RouteDataProvider';
import { signUp } from 'services/authApi';
import { getBasePath } from 'utils/locale';
import type { EmailPasswordFormFields } from '../schemas';
import { useRecaptcha } from './useRecaptcha';

const useSignUp = () => {
  const { locale } = useRouteData();
  const { getCaptchaResponse } = useRecaptcha();
  const [mutationError, setMutationError] = useState<AxiosError | undefined>(undefined);
  const router = useRouter();
  const basePath = getBasePath(locale);

  const { mutate, isPending } = useMutation({
    mutationFn: async ({ email, password }: EmailPasswordFormFields) => {
      signUp(email, password, {
        captchaResponse: await getCaptchaResponse(),
      });
    },
    onSuccess: () => {
      router.push({
        pathname: `${basePath}auth/activate`,
      });
    },
    onError: (error: AxiosError) => {
      setMutationError(error);
    },
  });

  return {
    signUp: mutate,
    signUpError: mutationError,
    signUpLoading: isPending,
  };
};

export { useSignUp };
