import { Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { getCountryName } from 'services/countries';
import { NonModalDialog } from 'components/common/NonModalDialog';
import { useSuggestCorrectMarket, useModalShown, useSuggestedMarket } from './hooks';
import { MarketSelection } from './MarketSelection';

const SuggestLocationModal = () => {
  const { t } = useTranslation('geo');
  const { confirmMarketSelection } = useSuggestCorrectMarket();

  const modalShown = useModalShown();
  const suggestedMarket = useSuggestedMarket();

  if (!suggestedMarket) {
    return null;
  }

  const countryName = getCountryName(suggestedMarket.countryCode);

  return (
    <NonModalDialog closeButtonShown open={modalShown} onClose={confirmMarketSelection}>
      <div className="flex flex-col items-stretch gap-2">
        <Text as="h2" align="center" variant="m+">
          {t('suggestLanguage.title', { country: countryName })}
        </Text>

        <MarketSelection />
      </div>
    </NonModalDialog>
  );
};

export { SuggestLocationModal };
