const isClient = typeof window !== 'undefined';
const isServer = typeof window === 'undefined';

const forClient = (func) => (isClient ? func() : null);

export { isClient, isServer, forClient };

// Do not implement `forServer` function. `require`s inside such function will
// not work correctly. To run code for server, do it inside:
// if (typeof window === 'undefined') { <…> }
