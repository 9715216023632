import { j as i } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as l } from "./chunks/clsx.0d472180.js";
import { u as n } from "./chunks/OverlayContext.c337c144.js";
import { d as o } from "./chunks/styles.9b2ce419.js";
import { Heading as d } from "./heading.mjs";
import "react";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.fa6e5999.js";
const a = ({ className: e, ...t }) => {
  const r = n();
  return /* @__PURE__ */ i.jsx(d, { as: "h2", variant: "s", id: r, className: l(o.root, e), ...t });
};
try {
  a.displayName = "ModalTitle", a.__docgenInfo = { description: "", displayName: "ModalTitle", props: { inline: { defaultValue: null, description: "", name: "inline", required: !1, type: { name: "boolean" } }, variant: { defaultValue: null, description: "", name: "variant", required: !1, type: { name: "HeadingVariant" } }, align: { defaultValue: null, description: "", name: "align", required: !1, type: { name: '"center" | "left" | "right" | "justify"' } }, textColor: { defaultValue: null, description: "", name: "textColor", required: !1, type: { name: '"dark" | "light" | "darkSecondary" | "highlighted" | "inherited"' } } } };
} catch {
}
export {
  a as ModalTitle
};
