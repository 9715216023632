import { trim, identity } from 'lodash';

const LAST_CHAR_DOT_REGEX = /\.$/;
const SPACE_REGEX = /\s+/g;

const convertNewLinesToArray = (text: string) =>
  text
    .split('\n')
    .map((paragraph) => trim(paragraph))
    .filter(identity);

const convertNewLinesToLineBreaks = (text: string) => convertNewLinesToArray(text).join('<br />');

const addEllipsis = (text: string) => `${text}...`;

const formatLargeNumber = (number: number) =>
  new Intl.NumberFormat('en-GB', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(number);

const stripHtml = (html: string) => {
  const node = document.createElement('div');
  node.innerHTML = html;
  return node.textContent;
};

const removeEndingDot = (text: string) => text.replace(LAST_CHAR_DOT_REGEX, '');

const removeSpaces = (text: string) => text.replace(SPACE_REGEX, '');

const applyFormatter = <TValue, TFormatted = string>(
  value: TValue | undefined,
  format: (value: TValue) => TFormatted | undefined,
) => (value ? format(value) : undefined);

const createEmailAnchor = (email: string) => `<a href='mailto:${email}'>${email}</a>`;

const createLocaleString = (locale: string) =>
  locale.replace(/..$/g, (match) => match.toUpperCase());

export {
  convertNewLinesToArray,
  convertNewLinesToLineBreaks,
  addEllipsis,
  formatLargeNumber,
  stripHtml,
  removeEndingDot,
  removeSpaces,
  createEmailAnchor,
  createLocaleString,
  applyFormatter,
};
