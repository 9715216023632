import { Stack, Text, Button } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import withContainers from 'hoc/withContainers';
import AuthContainer from 'containers/AuthContainer';
import styles from './CreditLoginBar.module.scss';

type CreditLoginBarProps = {
  containers: {
    auth: AuthContainer;
  };
};

const CreditLoginBar = ({ containers: { auth } }: CreditLoginBarProps) => {
  const { t } = useTranslation();
  const { authenticated } = auth.state;

  const login = () => auth.logIn();

  if (authenticated) {
    return null;
  }

  return (
    <Stack gap={1} type="horizontal" crossAxisAlign="center" className="hideUntilTablet">
      <Text variant="xs" className={styles.creditsQuestion}>
        {t('pricingSection.creditsQuestion')}
      </Text>

      <Button className="hideFromDesktop" variant="outlined" onClick={login}>
        {t('auth.signUpOrLogInAction')}
      </Button>

      <Button className="hideUntilDesktop" variant="outlined" size="m" onClick={login}>
        {t('auth.signUpOrLogInAction')}
      </Button>
    </Stack>
  );
};

const WrappedCreditLoginBar = withContainers({ auth: AuthContainer })(CreditLoginBar);

export { WrappedCreditLoginBar as CreditLoginBar };
export type { CreditLoginBarProps };
