import { includes } from 'lodash';
import parseUrl from 'url-parse';
import { beautifyPath } from '../utils/router';
import { removeLegacyExperiments, runExperiments } from './experiments';

let locale;
let basePath;
let baseUrl;
let market;

function init(settings) {
  ({ locale, basePath, market } = settings);
  baseUrl = `${parseUrl().origin}${beautifyPath(basePath)}`;

  runExperiments(settings.experiments);
  removeLegacyExperiments();
}

const getLocale = () => locale;

const getBasePath = () => basePath;

const getBaseUrl = () => baseUrl;

const getMarket = () => market;

const getPathname = (pathname) => pathname.replace(new RegExp(`^${basePath}`), '/');

const createSubset = (items) =>
  items.filter(
    (item) =>
      (!item.locale || item.locale === locale || includes(item.locale, locale)) &&
      (!item.localeExcept || !includes(item.localeExcept, locale)),
  );

export { init, getLocale, getBasePath, getBaseUrl, getMarket, getPathname, createSubset };
