import { type ReactNode, useMemo } from 'react';
import { useTooltip } from './useTooltip';
import { TooltipContext } from './TooltipContext';
import type { TooltipOptions } from './types';

const Tooltip = ({
  arrowShown = true,
  children,

  ...options
}: { children: ReactNode } & TooltipOptions) => {
  const tooltip = useTooltip(options);

  const value = useMemo(() => ({ ...tooltip, arrowShown }), [tooltip, arrowShown]);

  return <TooltipContext.Provider value={value}>{children}</TooltipContext.Provider>;
};

export { Tooltip };
