import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useState } from 'react';
import { resetPassword } from 'services/authApi';
import { getBaseUrl } from 'services/site';
import { addTrailingSlash } from 'utils/router';
import { useRecaptcha } from './useRecaptcha';

const useForgotPassword = () => {
  const { getCaptchaResponse } = useRecaptcha();
  const [mutationError, setMutationError] = useState<AxiosError | undefined>(undefined);
  const baseUrl = addTrailingSlash(getBaseUrl());

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      await resetPassword(email, {
        passwordChangeUrl: `${baseUrl}auth/reset-password`,
        captchaResponse: await getCaptchaResponse(),
      });
    },
    onError: (error: AxiosError) => {
      setMutationError(error);
    },
  });

  return {
    forgotPassword: mutate,
    forgotPasswordSuccess: isSuccess,
    forgotPasswordLoading: isPending,
    forgotPasswordError: mutationError,
  };
};

export { useForgotPassword };
