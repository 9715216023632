import { BlogDocumentType, type Singleton } from '../types';

const SINGLETON_DOCUMENT_IDS = {
  blogIndex: 'blogIndex',
};

const TYPES_WITH_ROUTES = [
  BlogDocumentType.Article,
  BlogDocumentType.Author,
  BlogDocumentType.Category,
  BlogDocumentType.Collection,
];

const SINGLETONS: Singleton[] = [
  {
    baseId: SINGLETON_DOCUMENT_IDS.blogIndex,
    type: BlogDocumentType.Collection,
    title: 'Blog index',
  },
];

const TOC_EXTRACTABLE_TAG = 'h2';

export { TOC_EXTRACTABLE_TAG, TYPES_WITH_ROUTES, SINGLETONS, SINGLETON_DOCUMENT_IDS };
