import { each, isObjectLike, update } from 'lodash';
import isPast from 'date-fns/is_past';
import { forClient } from '../utils/ssr';
import { isBooleanLike } from '../utils/data';

const STORE_PREFIX = 'cv:';

const toKey = (key) => `${STORE_PREFIX}${key}`;

function createInstance(type) {
  let storage = forClient(() => window[`${type}Storage`]);
  if (!storage || !isStorageSupported(storage)) {
    storage = createMemoryStorage();
  }

  const remove = (key) => storage.removeItem(toKey(key));

  const get = (key) => {
    const value = storage.getItem(toKey(key));
    try {
      const parsedValue = JSON.parse(value);

      if (parsedValue.expires) {
        if (isPast(parsedValue.expires)) {
          remove(key);
          return null;
        }

        return parsedValue.value;
      }

      return parsedValue;
    } catch (e) {
      return value;
    }
  };

  const has = (key) => !!get(key);

  const set = (key, rawValue, config = {}) => {
    let value = rawValue;

    if (isBooleanLike(value)) {
      value = rawValue === true || rawValue === 'true';
    }

    if (value === undefined) {
      return remove(key);
    }

    if (config.ttl) {
      value = {
        value,
        expires: config.ttl,
      };
    }

    value = isObjectLike(value) ? JSON.stringify(value) : value;
    return storage.setItem(toKey(key), value);
  };

  const getOnce = (key) => {
    const value = get(key);
    remove(key);
    return value;
  };

  const getAll = () => {
    const storageCopy = { ...storage };

    return each(storageCopy, (_, key) => {
      update(storageCopy, key, (value) => {
        try {
          return JSON.parse(value);
        } catch {
          return value;
        }
      });
    });
  };

  return {
    get,
    getAll,
    has,
    storage,
    remove,
    set,
    getOnce,
  };
}

function isStorageSupported(storage) {
  const testKey = 'local-storage-test';
  try {
    storage.setItem(testKey, testKey);
    const value = storage.getItem(testKey);
    storage.removeItem(testKey);
    return value === testKey;
  } catch (e) {
    return false;
  }
}

function createMemoryStorage() {
  const data = {};
  return {
    _memory: true,
    setItem: (key, value) => {
      data[key] = String(value);
    },
    getItem: (key) => data[key] || null,
    removeItem: (key) => delete data[key],
  };
}

export default createInstance('local');
export { STORE_PREFIX, createInstance };
