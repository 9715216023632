import { startsWith, endsWith } from 'lodash';

const isBusinessPackage = (id) => startsWith(id, 'biz');

const isSubscriptionPackage = (id) => endsWith(id, 'sub');

const isInspectionPackage = (id) => startsWith(id, 'inspection');

const isTwoForOnePackage = (id) => startsWith(id, 'twoforone');

export { isBusinessPackage, isSubscriptionPackage, isInspectionPackage, isTwoForOnePackage };
