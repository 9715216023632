import { useTranslation } from 'next-i18next';
import { Heading } from '@carvertical/ui';
import { convertNewLinesToArray } from 'utils/text';
import CheckMarkSuccessIcon from 'assets/icons/check-mark-success.svg';
import styles from './ContactSuccessMessage.module.scss';

const ContactSuccessMessage = () => {
  const { t } = useTranslation();

  const [heading, text, appeal] = convertNewLinesToArray(t('supportModal.successText'));

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <CheckMarkSuccessIcon className={styles.icon} />

        <Heading as="h3" variant="xs">
          {heading}
        </Heading>

        <p>{text}</p>

        {appeal && <p className={styles.emphasis}>{appeal}</p>}
      </div>
    </div>
  );
};

export { ContactSuccessMessage };
