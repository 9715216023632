import isBefore from 'date-fns/is_before';
import { useEffect, useState } from 'react';
import type UserContainer from 'containers/UserContainer';
import { ConsentType, useConsents } from 'modules/shared/hooks';
import { POLICY_UPDATE_DATE } from '../constants';

const CONSENT_TYPE: ConsentType = ConsentType.PolicyUpdate2402;
const POLICY_NOTICE_VISIBILITY_TIME = 60_000;

const usePolicyNotice = ({ details: { createdAt, email } }: UserContainer) => {
  const [shown, setShown] = useState(false);
  const { consentsData, acceptConsent } = useConsents();

  useEffect(() => {
    if (consentsData && email) {
      if (isBefore(createdAt, POLICY_UPDATE_DATE) && !consentsData.consents[CONSENT_TYPE]) {
        setShown(true);
        acceptConsent({ email, type: CONSENT_TYPE });
      }
    }
  }, [acceptConsent, email, createdAt, consentsData]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (shown) {
      timeout = setTimeout(() => {
        setShown(false);
      }, POLICY_NOTICE_VISIBILITY_TIME);
    }

    return () => window.clearTimeout(timeout);
  }, [shown]);

  const hide = () => setShown(false);

  return { shown, hide };
};

export { usePolicyNotice, POLICY_NOTICE_VISIBILITY_TIME };
