import Image from 'next/image';
import { Trans, useTranslation } from 'next-i18next';
import { Container, Heading, Stack, Text, LinkButton } from '@carvertical/ui';
import type { PropsWithChildren } from 'react';
import { NS_HOME } from 'constants/i18n';
import { Link } from 'components/common/Link';
import SpeakerIcon from 'components/landings/assets/speaker.svg';
import EnvelopeIcon from 'components/landings/assets/envelope.svg';
import ClockIcon from 'components/landings/assets/clock.svg';
import { Section, type SectionProps } from 'components/landings/common/Section';
import augisAvatarUrl from '../assets/augis.png';
import brigidaAvatarUrl from '../assets/brigida.png';
import pauliusAvatarUrl from '../assets/paulius.png';
import tomaAvatarUrl from '../assets/toma.png';
import kornelijaAvatarUrl from '../assets/kornelija.png';
import styles from './SupportSection.module.scss';

type SupportSectionProps = {
  className?: string;
  containerClassName?: string;
  color?: SectionProps['color'];
};

const TEAM_MEMBERS = [
  {
    name: 'Brigida',
    image: brigidaAvatarUrl,
  },
  {
    name: 'Paulius',
    image: pauliusAvatarUrl,
  },
  {
    name: 'Kornelija',
    image: kornelijaAvatarUrl,
  },
  {
    name: 'Augis',
    image: augisAvatarUrl,
  },
  {
    name: 'Toma',
    image: tomaAvatarUrl,
  },
] as const;

const FEATURES = [
  {
    id: 'satisfaction',
    Icon: SpeakerIcon,
    number: '97%',
    titleKey: 'supportSection.features.satisfaction',
  },
  {
    id: 'response',
    Icon: EnvelopeIcon,
    number: '12-24h',
    titleKey: 'supportSection.features.responseTime',
  },
  {
    id: 'availability',
    Icon: ClockIcon,
    number: '24/7',
    titleKey: 'supportSection.features.availability',
  },
] as const;

const SupportSection = ({
  children,
  className,
  color,
  containerClassName,
}: PropsWithChildren<SupportSectionProps>) => {
  const { t } = useTranslation(NS_HOME);

  return (
    <Section color={color} className={className}>
      <Container className={containerClassName}>
        <Stack gap={{ mobileUp: 3, tabletLandscapeUp: 8 }} crossAxisAlign="stretch">
          <Stack gap={2} crossAxisAlign="center" className={styles.wrapper}>
            <figure className={styles.avatars}>
              {TEAM_MEMBERS.map(({ image, name }) => (
                <Image
                  key={name}
                  src={image}
                  alt={`Support team member: ${name}`}
                  width={56}
                  height={56}
                  className={styles.avatar}
                />
              ))}
            </figure>

            <Heading as="h2" variant="l">
              {t('supportSection.title')}
            </Heading>

            <Text variant="l">
              <Trans
                i18nKey="supportSection.subtitle"
                t={t}
                components={{
                  1: (
                    <LinkButton
                      as={Link}
                      href="/contacts#contact-form"
                      variant="blue"
                      className="text-l font-bold"
                    />
                  ),
                }}
              />
            </Text>
          </Stack>

          {children || (
            <Stack wrap gap={4} mainAxisAlign="spaceAround" type="horizontal">
              {FEATURES.map(({ Icon, id, number, titleKey }) => (
                <section className={styles.feature} key={id}>
                  <Icon className={styles.icon} />

                  <Heading as="h3" variant="m">
                    {number}
                  </Heading>

                  <Text variant="m">{t(titleKey)}</Text>
                </section>
              ))}
            </Stack>
          )}
        </Stack>
      </Container>
    </Section>
  );
};

export { SupportSection };
export type { SupportSectionProps };
