import { Stack } from '@carvertical/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import cx from 'classnames';
import { trimStart } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useId, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRouteData } from 'context/RouteDataProvider';
import { QUERY_PARAMS } from 'constants/queryParams';
import { FormField } from 'components/common/ui/forms';
import type { Mode } from 'types/identifier';
import { type ExistingMarkets, isExistingMarket } from 'utils/market';
import { isQueryMatchingParam, sanitizeQueryParams } from 'utils/url';
import { useFeatureFlagPayload } from 'modules/analytics';
import type { Market } from 'types/market';
import { AustralianStateSelector } from './AustralianStateSelector';
import { ContinueWithoutVinCta } from './ContinueWithoutVinCta';
import { IdentifierInput, type IdentifierInputProps } from './IdentifierInput';
import { IdentifierSwitch } from './IdentifierSwitch';
import { LicensePlatePrefix } from './LicensePlatePrefix';
import { type IdentifierFormFields, getIdentifierFormSchema } from './schemas';
import type { SurroundingBackground } from './types';
import styles from './IdentifierForm.module.scss';

type IdentifierFormProps = {
  className?: string;
  fullWidth?: boolean;
  surroundingBackground?: SurroundingBackground;
  defaultValue?: string;
  focused?: boolean;
  forcedMode?: Mode;
  queryParamForwardingDisabled?: boolean;
  onSubmit?: (vin: string) => void;
  continueWithoutVinCtaVisible?: boolean;
  continueWithoutVinCtaLabel?: string;
} & Pick<
  IdentifierInputProps,
  | 'size'
  | 'buttonVariant'
  | 'buttonLabel'
  | 'explanationShown'
  | 'outsideButtonShown'
  | 'labelHidden'
>;

const WHITESPACE_REGEXP = /[-\s]/g;

const LICENSE_PLATE_EXCLUDED_MARKETS: ExistingMarkets = [{ belgium: 'fr-BE' }];

const IdentifierForm = ({
  buttonLabel,
  buttonVariant,
  className,
  continueWithoutVinCtaVisible = true,
  continueWithoutVinCtaLabel,
  defaultValue,
  focused = false,
  forcedMode,
  fullWidth = false,
  labelHidden = true,
  explanationShown = true,
  queryParamForwardingDisabled = false,
  outsideButtonShown,
  onSubmit,
  size = 'l',
  surroundingBackground = 'mediumDark',
}: IdentifierFormProps) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);
  const hintId = useId();
  const { basePath, locale, market, pages } = useRouteData();
  const { push, query } = useRouter();

  const lpDisabled = useLpDisabled(market);

  useEffect(() => {
    if (formRef.current && focused) {
      const inputElement: HTMLInputElement = formRef.current.querySelector(`[name="identifier"]`)!;

      inputElement.focus();
    }
  }, [focused]);

  const { identifier: identifierFromMarket } = market;

  const initialMode = (() => {
    const { identifierModeOverride } = QUERY_PARAMS;

    const queryMatches = isQueryMatchingParam(query, identifierModeOverride);

    if (queryMatches && identifierFromMarket.initial) {
      return query[identifierModeOverride.name] as Mode;
    }

    return lpDisabled ? 'vin' : forcedMode || identifierFromMarket.initial;
  })();

  const form = useForm<IdentifierFormFields>({
    defaultValues: {
      mode: forcedMode || initialMode || 'vin',
      identifier: defaultValue,
    },
    resolver: zodResolver(getIdentifierFormSchema(identifierFromMarket)),
  });

  const { formState, handleSubmit, setValue, watch } = form;

  useEffect(() => {
    if (initialMode) {
      setValue('mode', initialMode);
    }
  }, [forcedMode, initialMode, setValue]);

  const mode = watch('mode');
  const lpMode = mode === 'lp';
  const switchable = lpDisabled ? false : !forcedMode && !!initialMode;
  const loading = formState.isSubmitSuccessful;

  const submit = useCallback(
    ({ identifier: submittedValue, countryState }: IdentifierFormFields) => {
      const vin = submittedValue?.replace(WHITESPACE_REGEXP, '').toUpperCase();

      if (onSubmit && vin) {
        onSubmit(vin);
      } else {
        push({
          pathname: `${basePath}${trimStart(pages.precheck.path, '/')}/searching`,
          query: {
            ...(!queryParamForwardingDisabled && sanitizeQueryParams(query)),
            ...(vin ? { vin } : {}),
            ...(countryState ? { countryState } : {}),
          },
        });
      }
    },
    [basePath, pages.precheck.path, push, onSubmit, query, queryParamForwardingDisabled],
  );

  const label = {
    vin: t('vinForm.placeholder'),
    lp: t('vinForm.placeholderLp'),
  }[mode];

  const renderPrefix = () => {
    const existingMarket = (markets: ExistingMarkets) =>
      isExistingMarket({
        markets,
        market,
        locale,
      });

    if (existingMarket(['australia']) && lpMode) {
      return <AustralianStateSelector disabled={loading} />;
    }

    if (lpMode && !existingMarket(LICENSE_PLATE_EXCLUDED_MARKETS)) {
      return <LicensePlatePrefix hintId={hintId} />;
    }

    return undefined;
  };

  const Field = FormField<IdentifierFormFields>;

  return (
    <FormProvider {...form}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(submit)}
        className={cx(styles.root, fullWidth && styles.fullWidth, className)}
      >
        <Stack gap={2}>
          {switchable && (
            <div className={styles.toggleWrapper}>
              <IdentifierSwitch surroundingBackground={surroundingBackground} />
            </div>
          )}

          <div className={styles.inputWrapper}>
            <Field name="identifier">
              <IdentifierInput
                label={label}
                size={size}
                prefix={renderPrefix()}
                loading={loading}
                buttonLabel={buttonLabel}
                labelHidden={labelHidden}
                outsideButtonShown={outsideButtonShown}
                buttonVariant={buttonVariant}
                surroundingBackground={surroundingBackground}
                explanationShown={explanationShown && !lpMode}
                activePlaceholder={lpMode ? identifierFromMarket.lp?.placeholder : undefined}
                aria-describedby={hintId}
              />
            </Field>
          </div>

          {continueWithoutVinCtaVisible && (
            <div className={styles.ctaWrapper}>
              <ContinueWithoutVinCta
                surroundingBackground={surroundingBackground}
                disabled={loading}
                ctaLabel={continueWithoutVinCtaLabel}
              />
            </div>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};

function useLpDisabled(market: Market) {
  const lpDisabledMarkets = useFeatureFlagPayload('lpDisabledMarkets');
  const lpDisabled = lpDisabledMarkets
    ? isExistingMarket({ markets: lpDisabledMarkets, market })
    : false;

  return lpDisabled;
}

export { IdentifierForm };
export type { IdentifierFormProps };
