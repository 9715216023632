import { trimStart } from 'lodash';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import * as seo from 'utils/seo';
import { siteConfig, siteName, social } from 'config';
import { useRouteData } from 'context/RouteDataProvider';
import { getURL } from 'utils/url';
import { getFullLocale, getLanguage } from 'utils/locale';
import { CanonicalType } from 'constants/seo';

const SITE_ROOT = siteConfig.siteRoot;

const MetaData = () => {
  const { asPath: pathname } = useRouter();
  const { alternativePaths, basePath, locale, market, meta, page } = useRouteData();
  const { t } = useTranslation();

  const { emails } = market;

  const canonicalUrl = (() => {
    const { basePathNeeded, path, type } = meta?.canonical || {};

    // 1. if canonical path is set explicitly, use it to build the canonical URL
    if (path) {
      // 1.1. if canonical is passed via CMS backend (prismic), it's already a full path
      // 1.2 if canonical is passed via frontend on route creation, prefix the base path
      const basePrefix = basePathNeeded ? basePath : '';
      return getURL(basePrefix + trimStart(path, '/'));
    }

    // 2. if canonical type is language-referring, use the path of the default language as the canonical URL
    if (type === CanonicalType.LanguageReferring) {
      // get default language from the current locale, e.g. 'en' from 'en-US', 'en-GB', etc.
      const defaultLanguage = getLanguage(locale);

      // treat pages in different locales as the same page, e.g '/en/about' from '/en-US/about', '/en-GB/about', etc.
      const canonicalPath = alternativePaths?.[defaultLanguage];

      // 2.1 if the canonical path is not found, do not set the canonical URL
      if (!canonicalPath) {
        return undefined;
      }

      return getURL(canonicalPath);
    }

    // 3. if canonical type is self-referring or undefined, use the current path as the canonical URL
    return getURL(pathname);
  })();

  const formattedMeta = {
    title: seo.formatTitle(meta?.title || page?.title || t('seo.title')),
    description: meta?.description || t('seo.description'),
  };

  return (
    <Head>
      <base href="/" />
      <title key="title">{formattedMeta.title}</title>
      <meta name="description" content={formattedMeta.description} key="description" />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} key="canonical" />}
      {meta?.noIndex && <meta name="robots" content="noindex, nofollow" />}
      <meta property="og:type" content="website" key="ogType" />
      <meta property="og:site_name" content={siteName} key="ogSiteName" />
      <meta property="og:title" content={formattedMeta.title} key="ogTitle" />
      <meta property="og:description" content={formattedMeta.description} key="ogDescription" />
      <meta property="og:url" content={canonicalUrl} key="ogUrl" />
      <meta property="og:locale" content={getFullLocale(locale, '_')} key="ogLocale" />
      <meta property="og:image" content={getURL('share.png')} key="ogImage" />
      <script
        type="application/ld+json"
        key="jsonSchema"
        // eslint-disable-next-line react/no-danger -- this is a static string
        dangerouslySetInnerHTML={{
          __html: `
          {
            "@context": "https://schema.org",
            "@graph": [
              {
                "@type": "Organization",
                "@id": "${SITE_ROOT}/#organization",
                "url": "${SITE_ROOT}/",
                "name": "carVertical",
                "description": "${t('app.companyDescription')}",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${SITE_ROOT}/logo.png"
                },
                "contactPoint": [
                  { "@type": "ContactPoint", "email": "${emails.info}" }
                ],
                "sameAs": [
                  "${social.facebook}",
                  "${social.instagram}",
                  "${social.youTube}"
                ]
              },
              {
                "@type": "WebSite",
                "@id": "${SITE_ROOT}/#website",
                "url": "${SITE_ROOT}/",
                "name": "${siteName}",
                "description": "${t('app.description')}",
                "publisher": { "@id": "${SITE_ROOT}/#organization" }
              }
            ]
          }
        `,
        }}
      />
    </Head>
  );
};

export { MetaData };
