import { getRequest } from './request';

const getPackages = ({ cancelToken, voucher }: { voucher?: string; cancelToken?: string }) =>
  // TODO: rewrite, when request.js is refactored
  getRequest('/purchases/packages', {
    params: { voucherId: voucher },
    cancelToken,
  });

export { getPackages };
