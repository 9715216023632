enum PaymentMethod {
  Neopay = 'neopay',
  Card = 'card',
  Paypal = 'paypal',
  Crypto = 'crypto',
  Tpay = 'tpay',
  Sofort = 'sofort',
  Paysera = 'paysera',
  Dotpay = 'dotpay',
  Wire = 'wire',
  Gift = 'gift',
  ApplePay = 'applepay',
  MBWay = 'mbway',
  GooglePay = 'googlepay',
  Blik = 'blik',
}

export { PaymentMethod };
