import { type SVGMotionProps, motion } from 'framer-motion';
import { theme, AriaButton, getBaselineSize } from '@carvertical/ui';
import { useMedia } from 'react-use';
import { useTranslation } from 'next-i18next';
import { createMediaQuery } from 'utils/styles';
import styles from './HamburgerToggle.module.scss';

type HamburgerToggleProps = {
  open?: boolean;
  onClick?: () => void;
};

const ICON_WIDTH = getBaselineSize(2.25);
const ICON_HEIGHT = ICON_WIDTH * 0.8;

const TRANSITION = {
  type: 'spring',
  stiffness: 1000,
  damping: 100,
};
const TOP_LINE_VARIANTS = {
  closed: {
    rotate: 0,
    translateY: 0,
  },
  opened: {
    rotate: 45,
    translateY: 2,
  },
};
const CENTER_LINE_VARIANTS = {
  closed: {
    opacity: 1,
    scaleX: 1,
    y: '0',
  },
  opened: {
    opacity: 0,
    scaleX: 0,
    y: '200%',
  },
};
const BOTTOM_LINE_VARIANTS = {
  closed: {
    rotate: 0,
    translateY: 0,
    scaleX: 1,
  },
  opened: {
    rotate: -45,
    translateY: -2,
  },
};

const HamburgerToggle = ({ open = false, onClick }: HamburgerToggleProps) => {
  const variant = open ? 'opened' : 'closed';
  const isDesktop = useMedia(createMediaQuery(theme.breakpointDesktop), false);
  const { t } = useTranslation();

  const unitHeight = 4;
  const unitWidth = (unitHeight * ICON_WIDTH) / ICON_HEIGHT;

  const lineProps: SVGMotionProps<SVGLineElement> = {
    stroke: theme.colorBlack,
    strokeWidth: 1.5,
    vectorEffect: 'non-scaling-stroke',
    animate: variant,
    initial: 'closed',
    strokeLinecap: 'round',
    x1: '0',
    x2: unitWidth,
    transition: TRANSITION,
  };

  return (
    <AriaButton
      onPress={onClick}
      className={styles.root}
      isDisabled={!!isDesktop}
      aria-label={open ? t('menu.closeAction') : t('menu.openAction')}
    >
      <motion.svg
        viewBox={`0 0 ${unitWidth} ${unitHeight}`}
        overflow="visible"
        preserveAspectRatio="none"
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
      >
        <motion.line y1="0" y2="0" variants={TOP_LINE_VARIANTS} {...lineProps} />
        <motion.line y1="2" y2="2" variants={CENTER_LINE_VARIANTS} {...lineProps} />
        <motion.line y1="4" y2="4" variants={BOTTOM_LINE_VARIANTS} {...lineProps} />
      </motion.svg>
    </AriaButton>
  );
};

export { HamburgerToggle };
export type { HamburgerToggleProps };
