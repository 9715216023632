import getHocName from '../utils/getHocName';
import { useRouteData } from '../context/RouteDataProvider';

export default function withRouteData(WrappedComponent) {
  const WithRouteData = (props) => {
    const routeData = useRouteData();
    return <WrappedComponent routeData={routeData} {...props} />;
  };

  WithRouteData.displayName = getHocName('withRouteData', WrappedComponent);

  return WithRouteData;
}
