const path = require('path');
const HttpBackend = require('i18next-http-backend/cjs');
const ChainedBackend = require('i18next-chained-backend').default;
const LocalStorageBackend = require('i18next-localstorage-backend').default;

const { isClient } = require('./src/utils/ssr');
const { locales, baseLocale } = require('./src/config');

const ONE_HOUR = 60 * 60 * 1_000;

module.exports = {
  debug: false,
  backend: {
    backendOptions: [
      {
        expirationTime: ONE_HOUR,
        store: typeof window !== 'undefined' ? window.sessionStorage : null,
        prefix: 'cv-translations:',
      },
      {},
    ],
    backends: isClient ? [LocalStorageBackend, HttpBackend] : [],
  },
  react: {
    useSuspense: false,
  },
  interpolation: {
    escapeValue: false,
    skipOnVariables: false,
  },
  keySeparator: '.',
  nsSeparator: ':',
  defaultNS: 'common',
  i18n: {
    defaultLocale: baseLocale,
    locales,
    ...(!isClient ? { localePath: path.resolve('./public/locales') } : {}),
  },
  serializeConfig: false,
  reloadOnPrerender: process.env.APP_ENV === 'local',
  use: isClient ? [ChainedBackend] : [],
};
