enum BlogDocumentType {
  Article = 'blog.article',
  Author = 'blog.author',
  Category = 'blog.category',
  Tag = 'blog.tag',
  Collection = 'collectionIndex',
}

type DocumentForPath = BlogDocumentType;

export { BlogDocumentType };
export type { DocumentForPath };
