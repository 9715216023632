import PropTypes from 'prop-types';
import { Button } from '@carvertical/ui';
import withTranslate from '../../hoc/withTranslate';
import styles from './NeedsRefresh.module.scss';

const NeedsRefresh = ({ t }) => (
  <>
    <p>{t('errorPage.refreshText')}</p>
    <Button onClick={() => window.location.reload()} className={styles.button}>
      {t('errorPage.refreshAction')}
    </Button>
  </>
);

NeedsRefresh.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslate()(NeedsRefresh);
