import PropTypes from 'prop-types';
import { createContext, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { toPrecision } from '../utils/money';
import { getRequest } from '../services/request';
import { createInstance } from '../services/store';
import withRouter from '../hoc/withRouter';

const DISCOUNT_KEY = 'discount';

const DiscountContext = createContext();
const store = createInstance('session');

const DiscountProvider = ({ router: { query }, ...props }) => {
  const { voucher, campaign } = query;
  const [stored, setStored] = useState();

  useEffect(() => {
    if (campaign) {
      store.remove(DISCOUNT_KEY);
    }
    setStored(store.get(DISCOUNT_KEY));
  }, [campaign]);

  const load = useCallback(async (name, { silent = false } = {}) => {
    try {
      const {
        data: { discount, special },
      } = await getRequest(`/user/voucher/${name}`);
      const voucher = {
        voucher: name,
        percentage: toPrecision(100 * discount),
        special,
        silent,
      };
      store.set(DISCOUNT_KEY, voucher);
      setStored(voucher);
      return voucher;
    } catch (err) {
      if (err.status !== 404) {
        throw err;
      }
    }
    return undefined;
  }, []);

  const reset = useCallback(() => {
    if (store.has(DISCOUNT_KEY)) {
      store.remove(DISCOUNT_KEY);
      setStored();
    }
  }, []);

  useEffect(() => {
    if (!voucher || campaign) {
      return;
    }
    load(voucher);
  }, [load, voucher, campaign]);

  const value = useMemo(() => ({ ...stored, load, reset }), [stored, load, reset]);

  return <DiscountContext.Provider value={value} {...props} />;
};

DiscountProvider.propTypes = {
  router: PropTypes.shape().isRequired,
};

export default withRouter(DiscountProvider);

// Hooks

export const useDiscount = () => useContext(DiscountContext);
