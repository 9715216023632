import { motion } from 'framer-motion';
import { map, reduce } from 'lodash';
import { IconButton, Stack, Tag, TagGroup } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { SwipeableSections, type Step } from 'components/landings/common/SwipeableSections';
import type { PartialFeaturesRecord } from '../../types';
import { useFeatures } from '../../hooks';
import { FeatureCard } from './FeatureCard';
import styles from './FeaturesCarousel.module.scss';

const DRAG_THRESHOLD = 50;
const GAP = 48;

const getSteps = (features: PartialFeaturesRecord) =>
  reduce(
    features,
    (result: Step[], feature) => {
      if (feature) {
        result.push({
          Component: <FeatureCard {...feature} />,
        });
      }

      return result;
    },
    [],
  );

const FeaturesCarousel = () => {
  const { t } = useTranslation();
  const { features, highlightFeature, highlightedFeature, tags } = useFeatures();
  const featuresCount = Object.keys(features).length;
  const steps = getSteps(features);

  const paginate = (direction: 'next' | 'prev') => {
    highlightFeature(
      direction === 'next'
        ? (highlightedFeature + 1) % featuresCount
        : (highlightedFeature - 1 + featuresCount) % featuresCount,
    );
  };

  const renderFeatures = () =>
    map(features, (feature) =>
      feature ? (
        <div key={feature.id} className={cx(styles.carouselItem)}>
          <FeatureCard {...feature} />
        </div>
      ) : null,
    );

  return (
    <Stack crossAxisAlign="stretch">
      <Stack
        className={cx(styles.carouselWrapper, 'hidden lg:flex')}
        gap={6}
        crossAxisAlign="center"
      >
        {!!tags.length && (
          <Stack crossAxisAlign="center">
            <TagGroup
              aria-label={t('features.informationTitle')}
              listClassName="flex flex-wrap justify-center"
              selectionMode="single"
              selectedKeys={[highlightedFeature]}
              onChange={(keys) => {
                if (typeof keys === 'object') {
                  Object.values(keys).forEach((key) => highlightFeature(key));
                }
              }}
            >
              {tags.map(({ id, textKey }) => (
                <Tag key={id} id={id} size="l">
                  {t(textKey)}
                </Tag>
              ))}
            </TagGroup>
          </Stack>
        )}

        <Stack className={styles.wrapper} type="vertical">
          <div className={cx(styles.buttonWrapper, styles.left)}>
            <IconButton
              icon="arrow-left"
              label={t('general.previousLabel')}
              variant="blue"
              size="l"
              disabled={highlightedFeature === 0}
              onClick={() => paginate('prev')}
            />
          </div>

          <motion.div
            className={styles.carousel}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={0.1}
            transition={{ type: 'spring', mass: 3, stiffness: 360, damping: 60 }}
            whileDrag={{ cursor: 'grabbing', opacity: 0.5 }}
            onDragEnd={(_, { offset: { x, y } }) => {
              if (Math.abs(x) < Math.abs(y)) {
                return;
              }

              if (x > DRAG_THRESHOLD && highlightedFeature > 0) {
                paginate('prev');
              } else if (x < DRAG_THRESHOLD * -1 && highlightedFeature < featuresCount - 1) {
                paginate('next');
              }
            }}
            animate={{
              translateX: `calc(-${highlightedFeature * 100}% - ${GAP * highlightedFeature}px)`,
            }}
          >
            {renderFeatures()}
          </motion.div>

          <div className={cx(styles.buttonWrapper, styles.right)}>
            <IconButton
              icon="arrow-right"
              label={t('general.nextLabel')}
              variant="blue"
              size="l"
              disabled={highlightedFeature === featuresCount - 1}
              onClick={() => paginate('next')}
            />
          </div>
        </Stack>
      </Stack>

      <SwipeableSections
        activeIndex={highlightedFeature}
        className="lg:hidden"
        fullWidth
        steps={steps}
        onChange={highlightFeature}
      />
    </Stack>
  );
};

export { FeaturesCarousel };
