import { Container, Icon, IconButton, LinkButton, Stack, Text } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import cx from 'classnames';
import { Link } from 'components/common/Link';
import withContainers from 'hoc/withContainers';
import UserContainer from 'containers/UserContainer';
import { formatDate } from 'utils/date';
import { usePolicyNotice } from './hooks';
import { POLICY_UPDATE_DATE } from './constants';
import styles from './PolicyNotice.module.scss';

type PolicyNoticeProps = {
  containers: {
    user: UserContainer;
  };
};

const FORMATTED_DATE = formatDate(POLICY_UPDATE_DATE);

const PolicyNotice = ({ containers: { user } }: PolicyNoticeProps) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const { shown, hide } = usePolicyNotice(user);

  const linkButton = (
    <LinkButton as={Link} href="/policies" target="_blank" size="m" variant="blue" onClick={hide} />
  );

  return (
    // "popLayout" is used for better performance
    <AnimatePresence initial={false} mode="popLayout">
      {shown && (
        <motion.div
          className={styles.root}
          role="alert"
          variants={{
            visible: { opacity: 1, height: 'auto' },
            hidden: { opacity: 0, height: 0 },
          }}
          initial="hidden"
          animate="visible"
          exit="hidden"
          transition={{
            duration: 0.15,
            ease: 'easeOut',
          }}
        >
          <Container>
            <Stack
              crossAxisAlign="center"
              type={{ mobileUp: 'vertical', tabletPortraitUp: 'horizontal' }}
              gap={{ mobileUp: 2, tabletPortraitUp: 1 }}
            >
              <Icon className={styles.icon} name="info-circle-filled" />

              <Text variant="s">
                <Trans
                  i18nKey="policies.noticeIntro"
                  as="span"
                  values={{ date: FORMATTED_DATE }}
                  components={{
                    1: linkButton,
                  }}
                />

                <span className={!expanded ? 'hideUntilTablet' : undefined}>
                  {' '}
                  <Trans
                    i18nKey="policies.noticeRest"
                    as="span"
                    values={{ date: FORMATTED_DATE }}
                    components={{
                      1: linkButton,
                    }}
                  />
                </span>

                <span className={cx('hideFromTablet', expanded && 'hideUntilTablet')}>
                  {' '}
                  {'<..>'}{' '}
                  <LinkButton
                    variant="blue"
                    size="m"
                    onClick={() => {
                      setExpanded(true);
                    }}
                  >
                    {t('general.readMoreLabel')}
                  </LinkButton>
                </span>
              </Text>

              <IconButton
                className={styles.closeButton}
                icon="close"
                label={t('general.closeAction')}
                variant="transparent"
                size="s"
                onClick={hide}
              />
            </Stack>
          </Container>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

const WrappedPolicyNotice = withContainers({ user: UserContainer })(PolicyNotice);

export { WrappedPolicyNotice as PolicyNotice };
export type { PolicyNoticeProps };
