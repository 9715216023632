import { ReportStatus } from 'constants/report';
import ReportContainer from './ReportContainer';

export default class PrecheckContainer extends ReportContainer {
  resetToUnknown(state) {
    return new Promise((resolve) => {
      this.reset();
      this.setState(
        {
          reportStatus: ReportStatus.Finished,
          ...state,
        },
        resolve,
      );
    });
  }

  resetToLicensePlate(number, countryCode) {
    this.resetToUnknown({
      vin: number,
      licensePlate: {
        number,
        countryCode,
      },
    });
  }
}
