import type { AnchorProps } from 'types/component';

type ExternalLinkProps = {
  href?: string;
  to?: string;
  noRel?: boolean;
} & AnchorProps;

const ExternalLink = ({ href, noRel = false, to, ...props }: ExternalLinkProps) => (
  // eslint-disable-next-line jsx-a11y/anchor-has-content, react/jsx-no-target-blank
  <a
    href={href || to}
    rel={!noRel ? 'nofollow noopener noreferrer' : undefined}
    target="_blank"
    {...props}
  />
);

export default ExternalLink;
