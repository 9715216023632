import { useEffect } from 'react';
import { once } from 'lodash';

const WebFont = once(() => import('webfontloader'));

const loadFont = async ({ family, url }) => {
  (await WebFont()).load({
    custom: {
      families: [family],
      urls: [url],
    },
  });
};

const WebFontLoader = () => {
  useEffect(() => {
    loadFont({
      family: 'Inter:n4,n6,n7,n9',
      url: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700;900&display=swap',
    });
  }, []);

  return null;
};

export default WebFontLoader;
