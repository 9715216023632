import { beautifyPath, normalizePath } from './router';

const HISTORY_METHODS = ['pushState', 'replaceState'];

export const patchHistoryApi = () => {
  if (typeof window === 'undefined') {
    return;
  }

  const { history } = window;

  HISTORY_METHODS.forEach((methodName) => {
    const originalMethod = history[methodName].bind(history);

    history[methodName] = (state, title, url) => {
      state.as = normalizePath(state.as);
      originalMethod(state, title, beautifyPath(url));
    };
  });
};
