import { compact } from 'lodash';
import Spec from '../models/Spec';

const getVehicleName = (make, model) => compact([make, model]).join(' ');

const deserialize = (data) => {
  const specs = Spec.createAll(data.dataDict.spec);
  const { make, model } = specs;
  const { vin } = data;

  return {
    vin,
    make,
    model,
    vehicleName: getVehicleName(make, model),
    specs,
  };
};

export { getVehicleName, deserialize };
