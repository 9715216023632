import { create } from 'zustand';
import type { CheckoutAuthStep } from '../types';

type CheckoutAuthStore = {
  email?: string;
  step?: CheckoutAuthStep;
  requiresExtraVerification?: boolean;
  resendCodeDisabled?: boolean;
  actions: {
    rememberEmail: (email: string) => void;
    changeAuthStep: (step?: CheckoutAuthStep) => void;
    setRequiresExtraVerification: (requiresExtraVerification: boolean) => void;
    setResendCodeDisabled: (resendCodeDisabled: boolean) => void;
    reset: () => void;
  };
};

const INITIAL_STATE: Partial<CheckoutAuthStore> = {
  step: 'email',
  requiresExtraVerification: false,
  resendCodeDisabled: false,
};

const useCheckoutAuthStore = create<CheckoutAuthStore>((set) => ({
  ...INITIAL_STATE,
  actions: {
    rememberEmail: (email) => set({ email }),
    changeAuthStep: (step) => set({ step }),
    setRequiresExtraVerification: (requiresExtraVerification) => set({ requiresExtraVerification }),
    setResendCodeDisabled: (resendCodeDisabled) => set({ resendCodeDisabled }),
    reset: () => set(INITIAL_STATE),
  },
}));

const useEmail = () => useCheckoutAuthStore((store) => store.email);
const useStep = () => useCheckoutAuthStore((store) => store.step);
const useRequiresExtraVerification = () =>
  useCheckoutAuthStore((store) => store.requiresExtraVerification);
const useResendCodeDisabled = () => useCheckoutAuthStore((store) => store.resendCodeDisabled);
const useCheckoutAuthActions = () => useCheckoutAuthStore((store) => store.actions);

export {
  useCheckoutAuthActions,
  useEmail,
  useStep,
  useResendCodeDisabled,
  useRequiresExtraVerification,
};
