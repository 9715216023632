import { j as b } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as g } from "./chunks/clsx.0d472180.js";
import { forwardRef as C } from "react";
import { g as e, a as y } from "./chunks/utils.60e8c32c.js";
import { r as G } from "./chunks/styles.9b2ce419.js";
import "./chunks/lodash.14d7cb6d.js";
const o = C(
  ({
    as: n = "div",
    columnCount: a = 12,
    rowGap: r = 1,
    columnGap: i = 1,
    className: s,
    children: l,
    style: t,
    ...u
  }, c) => {
    const m = e(a), p = e(i), d = e(r), f = y([
      { name: "colCount", config: m },
      { name: "columnGap", config: p },
      { name: "rowGap", config: d }
    ]);
    return /* @__PURE__ */ b.jsx(
      n,
      {
        ref: c,
        className: g(G.root, s),
        style: {
          ...t,
          ...f
        },
        ...u,
        children: l
      }
    );
  }
);
try {
  o.displayName = "Grid", o.__docgenInfo = { description: "", displayName: "Grid", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, columnCount: { defaultValue: null, description: `Count of grid columns to be applied for children.

Can be numeric value OR responsive configuration object, based on mobile-first order.`, name: "columnCount", required: !1, type: { name: "ValueOrResponsiveConfig<GridColumnCountRange>" } }, rowGap: { defaultValue: null, description: `Space between rows multiplied by **$sizeBaseline** value.

Can be numeric value OR responsive configuration object, based on mobile-first order.`, name: "rowGap", required: !1, type: { name: "ValueOrResponsiveConfig" } }, columnGap: { defaultValue: null, description: `Space between columns multiplied by **$sizeBaseline** value.

Can be numeric value OR responsive configuration object, based on mobile-first order.`, name: "columnGap", required: !1, type: { name: "ValueOrResponsiveConfig" } } } };
} catch {
}
export {
  o as Grid
};
