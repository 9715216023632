import { find, mapValues, pickBy, reduce } from 'lodash';
import { useCallback, useEffect } from 'react';
import { markets } from 'config-ts';
import type { Market } from 'types/market';
import { useRouteData } from 'context/RouteDataProvider';
import { FEATURES } from '../constants/features';
import type { FeatureTagProps, PartialFeaturesRecord } from '../types';
import { useGeoData } from './useGeoData';
import {
  useFeaturesList,
  useHighlightedFeatureIndex,
  useFeaturesActions,
} from './useFeaturesStore';

const getFeatures = (market: Market, countryCode?: string): PartialFeaturesRecord => {
  const features =
    market.id !== 'europe' ? market.features : find(markets, { countryCode })?.features;

  const filteredFeatures = pickBy(FEATURES, ({ id }) => features?.includes(id));
  let index = 0;

  return mapValues(filteredFeatures, (feature) => {
    const featureIndex = index;
    index += 1;

    return {
      ...feature,
      index: featureIndex,
    };
  });
};

const getTags = (features: PartialFeaturesRecord) =>
  reduce(
    features,
    (result: FeatureTagProps[], feature) => {
      if (feature) {
        const { index, titleKey } = feature;

        result.push({
          id: index,
          textKey: titleKey,
        });
      }

      return result;
    },
    [],
  );

const useFeatures = () => {
  const features = useFeaturesList();
  const highlightedFeatureIndex = useHighlightedFeatureIndex();
  const geoData = useGeoData();
  const { market } = useRouteData();
  const { highlight, addFeatures } = useFeaturesActions();
  const tags = getTags(features);

  useEffect(() => {
    addFeatures(getFeatures(market, geoData?.countryCode));
  }, [geoData, market, addFeatures]);

  const highlightFeature = useCallback(
    (index: number) => {
      highlight(index);
    },
    [highlight],
  );

  return { features, tags, highlightedFeature: highlightedFeatureIndex, highlightFeature };
};

export { useFeatures };
