import { isFinite } from 'lodash';
import { Badge, Icon, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

type UsageCounterProps = {
  quota: number;
  compact?: boolean;
  fullWidth?: boolean;
  labelClassName?: string;
};

const UsageCounter = ({
  quota,
  compact = false,
  fullWidth = true,
  labelClassName,
}: UsageCounterProps) => {
  const { t } = useTranslation();

  return (
    <div className={cx('flex flex-wrap items-center justify-between gap-1', fullWidth && 'w-full')}>
      <div
        className={cx(
          'flex flex-1 items-center gap-1',
          compact ? 'justify-start' : 'justify-center',
        )}
      >
        <div className="flex items-center gap-0.5">
          <Icon name="coin" size={compact ? 'l' : 'm'} />

          <Text variant="m" className={labelClassName}>
            {t('usage.reportsRemainingLabel')}:
          </Text>
        </div>

        <Badge
          size="s"
          variant={quota ? 'greenLight' : 'yellowLight'}
          className="min-w-4 justify-center rounded-full text-center text-black"
        >
          <Text variant="m+">{isFinite(quota) ? quota : '…'}</Text>
        </Badge>
      </div>
    </div>
  );
};

export { UsageCounter };
