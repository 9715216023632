import { identity, find } from 'lodash';
import { homeCountryCode } from 'config';
import { EUR, ETH, CV, PRECISION, CRYPTO_SYMBOLS } from 'constants/currencies';
import VAT_RATES from 'constants/vatRates';
import { EU_VAT_CODE } from 'constants/validationPatterns';
import { getLocale } from 'services/site';

const toPrecision = (amount, precision = 2) => {
  const factor = 10 ** precision;
  return Math.round((Number(amount) + Number.EPSILON) * factor) / factor;
};

const format = (amount, currency = EUR, { round = identity } = {}) => {
  const locale = getLocale();
  const roundedAmount = round(toPrecision(amount, PRECISION[currency]));

  if (currency === ETH || currency === CV) {
    return `${roundedAmount}\u00A0${CRYPTO_SYMBOLS[currency]}`;
  }

  const fractionDigits = Number.isInteger(roundedAmount) ? 0 : 2;

  return Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(roundedAmount);
};

const getVatRate = (countryCode) => {
  const { rate } = find(VAT_RATES, { countryCode }) || { rate: 0 };
  return {
    rate,
    factor: 1 + rate / 100,
  };
};

const reverseVat = (amount, vatRate = 0) => toPrecision(amount * (100 / (100 + vatRate)));

const splitVat = (vat = '') => {
  const [, countryCode, vatCode] = vat.toLowerCase().match(new RegExp(EU_VAT_CODE)) ?? [];

  return {
    countryCode,
    vatCode: vatCode ?? vat,
  };
};

const getApplicableVat = ({ countryCode, vatCode, isIndividual }) => {
  const { rate = 0, factor = 1 } =
    (!vatCode && isIndividual) || countryCode === homeCountryCode ? getVatRate(countryCode) : {};
  return {
    countryCode,
    code: vatCode,
    rate,
    factor,
  };
};

const calculatePrice = ({ netAmount, currency, vat, fullPrice }) => {
  const amount = toPrecision(netAmount * (vat.factor || 1), PRECISION[currency]);

  return {
    amount,
    fullAmount: toPrecision(fullPrice * (vat.factor || 1), PRECISION[currency]),
    netAmount,
    currency,
    vatRate: vat.rate || 0,
    vatCountryCode: vat.countryCode,
    vatCode: vat.code,
    vatAmount: toPrecision(amount - netAmount, PRECISION[currency]),
  };
};

const calculateCheckoutDisOrder = ({
  fullPackagePrice,
  packageSavings,
  basePackagePrice,
  currency,
  vat,
  vatAlreadyIncluded,
  packageVoucherSavings,
  voucherDiscount,
}) => {
  const vatAmount = toPrecision(basePackagePrice * (vat.rate / 100), PRECISION[currency]);
  const subtotal = toPrecision(
    fullPackagePrice + (vatAlreadyIncluded ? 0 : vatAmount),
    PRECISION[currency],
  );
  const vatSavings = toPrecision(vatAmount * (voucherDiscount / 100), PRECISION[currency]);
  const voucherSavings = toPrecision(
    packageVoucherSavings + (vatAlreadyIncluded ? 0 : vatSavings),
    PRECISION[currency],
  );
  const totalSavings = toPrecision(voucherSavings + packageSavings, PRECISION[currency]);

  return {
    fullPrice: {
      package: fullPackagePrice,
      vat: vatAmount,
      subtotal,
    },
    savings: {
      package: packageSavings,
      voucher: voucherSavings,
      total: totalSavings,
    },
    actualPrice: {
      amount: toPrecision(subtotal - totalSavings, PRECISION[currency]),
      currency,
      vatRate: vat.rate,
      vatCountryCode: vat.countryCode,
    },
  };
};

const calculateDiscount = (fullPrice, baseFullPrice) =>
  Math.round((1 - fullPrice / baseFullPrice) * 100);

const formatSavings = (savings) => `-${savings}`;

export {
  toPrecision,
  format,
  getVatRate,
  reverseVat,
  splitVat,
  getApplicableVat,
  calculatePrice,
  calculateDiscount,
  calculateCheckoutDisOrder,
  formatSavings,
};
