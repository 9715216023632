import { j as r } from "./chunks/jsx-runtime.82a9ddf6.js";
import { $ as i } from "./chunks/Tabs.553963d9.js";
import { T as t } from "./chunks/TabsContext.9430d3b8.js";
import "react";
import "./chunks/useListState.d732a4d0.js";
import "./chunks/utils.f8c6b750.js";
import "./chunks/clsx.0d472180.js";
import "react-dom";
const a = ({ disabled: e, ...o }) => /* @__PURE__ */ r.jsx(t, { children: /* @__PURE__ */ r.jsx(i, { keyboardActivation: "manual", isDisabled: e, ...o }) });
try {
  a.displayName = "Tabs", a.__docgenInfo = { description: "", displayName: "Tabs", props: { disabled: { defaultValue: null, description: "", name: "disabled", required: !1, type: { name: "boolean" } } } };
} catch {
}
export {
  a as Tabs
};
