import { isEqual } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { useLayoutEffect, useState } from 'react';
import type { FeatureFlagMap } from './types';

const useFeatureFlagMap = () => {
  const posthog = usePostHog();

  const [flagMap, setFlagMap] = useState<FeatureFlagMap>(posthog.featureFlags.getFlagVariants());

  useLayoutEffect(() => {
    const removeHandler: () => void = posthog.onFeatureFlags(() => {
      const newFlagMap = posthog.featureFlags.getFlagVariants();

      if (!isEqual(newFlagMap, flagMap)) {
        setFlagMap(newFlagMap);
      }
    });

    return removeHandler;
  }, [posthog, flagMap]);

  return flagMap;
};

export { useFeatureFlagMap };
