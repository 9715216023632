import type { AxiosResponse } from 'axios';

type BasePollerProps = {
  interval?: number;
  retries?: number;
};

type PollerProps<TData> = {
  execute: () => TData;
  until?: (result: TData) => boolean;
} & BasePollerProps;

type ApiPollerProps<TData> = {
  execute: () => Promise<AxiosResponse<TData>>;
  until?: (result: AxiosResponse<TData>) => boolean;
} & BasePollerProps;

type PollProps<TData> = {
  tries: number;
  execute: () => Promise<TData>;
  until?: (result: TData) => boolean;
} & BasePollerProps;

const poll = async <TData>(props: PollProps<TData>) => {
  const { execute, interval = 1_000, retries = Infinity, tries, until = () => false } = props;

  const result = await execute();
  const passed = until(result);

  if (passed || tries + 1 > retries) {
    return { passed, result };
  }

  return new Promise<{ result: TData; passed: boolean }>((resolve) => {
    setTimeout(() => resolve(poll({ ...props, tries: tries + 1 })), interval);
  });
};

const poller = <TData>(data: PollerProps<TData>) =>
  poll<TData>({
    ...data,
    execute: () => Promise.resolve(data.execute()),
    tries: 1,
  });

const apiPoller = <TData>(data: ApiPollerProps<TData>) =>
  poll<AxiosResponse<TData>>({
    ...data,
    tries: 1,
  });

export { poller, apiPoller };
