import { i18n } from 'next-i18next';
import { getLanguage } from 'utils/locale';

const loadNamespaces = (namespaces) => i18n.reloadResources(i18n.resolvedLanguage, namespaces);

const loadCountries = async (locale) => {
  const language = getLanguage(locale);

  let countries = await maybeLoadCountriesOverride(language);
  if (!countries) {
    countries = (await import(`i18n-iso-countries/langs/${language}.json`)).default;
  }

  return countries;
};

async function maybeLoadCountriesOverride(language) {
  try {
    return (await import(`resources/countries/${language}.json`)).default;
  } catch (err) {
    return undefined;
  }
}

export { loadNamespaces, loadCountries };
