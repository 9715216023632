import cx from 'classnames';
import { Link } from 'components/common/Link';
import type { MenuItem } from '../types';

type SingleMenuItemProps = {
  item: MenuItem;
};

const SingleMenuItem = ({ item }: SingleMenuItemProps) => {
  return (
    <Link
      className={cx('font-semibold transition-colors duration-100 ease-out hover:text-blue', {
        'text-blue': item.active,
      })}
      title={item.title}
      href={item.path}
    >
      {item.title}
    </Link>
  );
};

export { SingleMenuItem };
export type { SingleMenuItemProps };
