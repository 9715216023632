import type { AnimationProps } from 'framer-motion';

const ANIMATION_VARIANTS: Pick<AnimationProps, 'initial' | 'exit' | 'animate'> = {
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
};

const ITEMS_WRAPPER_ANIMATION_PROPS: AnimationProps = {
  ...ANIMATION_VARIANTS,
};

const ITEM_ANIMATION_PROPS: AnimationProps = {
  variants: {
    visible: {
      x: 0,
      opacity: 1,
      zIndex: 1,
    },
    hidden: ({ reverse = false } = {}) => ({
      opacity: 0,
      x: reverse ? '100%' : '-100%',
      transitionEnd: { zIndex: 0 },
    }),
  },
  transition: {
    x: {
      type: 'spring',
      stiffness: 180,
      damping: 25,
    },
  },
};

const DIVIDER_ANIMATION_PROPS = {
  animate: {
    opacity: 1,
    transition: {
      delay: 0.2,
    },
  },
  initial: {
    opacity: 0,
  },
  exit: {
    opacity: 0,
  },
};

export {
  ANIMATION_VARIANTS,
  ITEMS_WRAPPER_ANIMATION_PROPS,
  ITEM_ANIMATION_PROPS,
  DIVIDER_ANIMATION_PROPS,
};
