import { isEmpty } from 'lodash';
import { cn } from '@carvertical/utils/styling';
import { useTranslation } from 'next-i18next';
import { Text } from '@carvertical/ui';
import { MarketSelectionButton } from './MarketSelectionButton';
import type { SwitcherMarket } from './types';
import { useMarketSwitcher } from './hooks';

type MarketSelectionListProps = {
  markets: SwitcherMarket[];
};

const MarketSelectionList = ({ markets }: MarketSelectionListProps) => {
  const { t } = useTranslation();
  const { switcherMarkets } = useMarketSwitcher();
  const marketsAvailable = !isEmpty(markets);

  const searching = switcherMarkets.length !== markets.length;

  return (
    <ul
      className={cn(
        'm-0 grid list-none grid-flow-col grid-cols-1 grid-rows-[repeat(32,auto)] gap-x-5 gap-y-1.5 px-0 py-2 sm:gap-y-2.5',
        searching && 'grid-flow-row',
        !marketsAvailable && 'grid-rows-1',
        marketsAvailable &&
          'sm:grid-cols-2 sm:grid-rows-[repeat(16,auto)] lg:grid-cols-4 lg:grid-rows-[repeat(8,auto)]',
      )}
    >
      {marketsAvailable ? (
        markets.map((market) => (
          <li key={market.countryCode} className="flex items-stretch">
            <MarketSelectionButton market={market} />
          </li>
        ))
      ) : (
        <li className="flex items-center justify-center text-center">
          <Text align="center">{t('general.noResultsLabel')}</Text>
        </li>
      )}
    </ul>
  );
};

export { MarketSelectionList };
export type { MarketSelectionListProps };
