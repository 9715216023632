import { cloneElement, Children } from 'react';
import { type RegisterOptions, useFormContext, type FieldValues, type Path } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import type { ParseKeys } from 'i18next';

type FormFieldProps<TFormValues> = {
  name: Path<TFormValues>;
  children: React.ReactNode;
  options?: RegisterOptions;
  message?: string;
  status?: 'error' | 'success';
};

const FormField = <TFormValues extends FieldValues>({
  children,
  name,
  options,
  message: messageFromProps,
  status: statusFromProps,
}: FormFieldProps<TFormValues>) => {
  const { i18n, t } = useTranslation();

  const formContext = useFormContext();
  if (!formContext) {
    throw new Error('FormField must be used together with FormProvider from `react-hook-form`');
  }

  const {
    control,
    formState: { errors },
    register,
  } = formContext;

  const message = name && errors[name]?.message;
  const errorMessage = i18n.exists(message as ParseKeys) ? t(message as ParseKeys) : message;

  const child = Children.only(children) as React.ReactElement;

  return cloneElement(child, {
    ...register(name, options),
    id: name, // TODO: remove later. https://carvertical.atlassian.net/browse/LAUNCH-1352
    control,
    error: errorMessage, // TODO: Remove, once TextInput / Checkbox / TextArea / Select components are removed from common/ui/forms
    message: messageFromProps || errorMessage,
    status: statusFromProps || (message ? 'error' : undefined),
  });
};

export { FormField };
export type { FormFieldProps };
