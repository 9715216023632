import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useFormContext } from 'react-hook-form';
import { LayoutGroup } from 'framer-motion';
import { useId } from 'react';
import type { ParseKeys } from 'i18next';
import type { Mode } from 'types/identifier';
import type { SurroundingBackground } from '../types';
import { IdentifierSwitchButton } from './IdentifierSwitchButton';
import styles from './IdentifierSwitch.module.scss';

type IdentifierSwitchProps = {
  surroundingBackground?: SurroundingBackground;
};

const OPTIONS: { id: Mode; labelKey: ParseKeys }[] = [
  { id: 'vin', labelKey: 'general.vinShortLabel' },
  { id: 'lp', labelKey: 'general.lpLabel' },
];

const IdentifierSwitch = ({ surroundingBackground = 'mediumDark' }: IdentifierSwitchProps) => {
  const layoutId = useId();
  const { t } = useTranslation();
  const { watch } = useFormContext<{ mode: Mode }>();

  const value = watch('mode');

  return (
    <fieldset className={cx(styles.root, surroundingBackground === 'light' && styles.light)}>
      <legend className="visuallyHidden">{t('vinForm.chooseMode')}</legend>

      <LayoutGroup id={layoutId}>
        {OPTIONS.map(({ id, labelKey }) => (
          <IdentifierSwitchButton key={id} active={id === value} id={id} labelKey={labelKey} />
        ))}
      </LayoutGroup>
    </fieldset>
  );
};

export { IdentifierSwitch };
