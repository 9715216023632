import { useMode } from 'context/ModeProvider';
import { useRouteData } from 'context/RouteDataProvider';
import store from 'services/store';
import { LanguageSelectionModal } from '../MarketSwitcher';
import { useModal } from '../../hooks';
import { SuggestLocationModal } from './SuggestLocationModal';
import { SuggestCheckoutModal } from './SuggestCheckoutModal';
import { useForced, useSuggestedMarket, useType, PREVENT_SUGGEST_MARKET_NAME } from './hooks';

type RouteData = {
  preventGeoModal?: boolean;
};

const SuggestCorrectMarket = () => {
  const { isApp } = useMode();
  const { alternativePaths, preventGeoModal } = useRouteData<RouteData>();
  const { isModalOpen: marketSelectionModalOpen } = useModal('marketSelection');

  const type = useType();
  const forced = useForced();
  const suggestedMarket = useSuggestedMarket();

  const prevented = !!(
    isApp ||
    (!forced && (preventGeoModal || store.has(PREVENT_SUGGEST_MARKET_NAME)))
  );

  if (prevented || !suggestedMarket || !alternativePaths?.[suggestedMarket.flavors[0].locale]) {
    return null;
  }

  return (
    <>
      {type === 'checkout' ? <SuggestCheckoutModal /> : <SuggestLocationModal />}

      {!marketSelectionModalOpen && <LanguageSelectionModal />}
    </>
  );
};

export { SuggestCorrectMarket };
