import cx from 'classnames';
import { Copyright } from './Copyright';
import styles from './CompactFooter.module.scss';

type CompactFooterProps = {
  className?: string;
};

const CompactFooter = ({ className }: CompactFooterProps) => (
  <footer className={cx(styles.root, className)}>
    <Copyright />
  </footer>
);

export { CompactFooter };
export type { CompactFooterProps };
