import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import FocusLock from 'react-focus-lock';
import { createPortal } from 'react-dom';
import { AnimatePresence, motion, type Transition } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useMedia } from 'react-use';
import { lock, clearBodyLocks } from 'tua-body-scroll-lock';
import { Heading, Stack } from '@carvertical/ui';
import { createMediaQuery } from 'utils/styles';
import { Icon } from 'components/common/Icon';
import { ContactSuccessMessage } from 'components/common/ContactSuccessMessage';
import { useSupportPopup } from 'modules/support/hooks';
import breakpoint from 'styles/variables.module.scss';
import { SupportForm } from 'modules/shared/components/SupportForm/SupportForm';
import { SUPPORT_POPUP_ID } from '../constants';
import { QuestionList } from './QuestionList';
import styles from './SupportPopup.module.scss';

type View = 'questions' | 'form' | 'success';

const DIALOG_ANIMATION_VARIANTS = {
  hidden: { scale: 0, opacity: 0 },
  visible: { scale: 1, opacity: 1 },
};

const TRANSITION_CONFIG: Transition = {
  duration: 0.24,
  type: 'spring',
  bounce: 0,
};

const CONTENT_ANIMATION_VARIANTS = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const SupportPopup = () => {
  const { closeSupportPopup, open, subject } = useSupportPopup();
  const [view, setView] = useState<View>('questions');
  const { t } = useTranslation();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [container, setContainer] = useState<HTMLElement | null>(null);
  const isTabletPortrait = useMedia(createMediaQuery(breakpoint.tabletPortraitBreakpoint), false);

  useEffect(() => {
    setContainer(document.body);
  }, []);

  useEffect(() => {
    if (dialogRef.current && !isTabletPortrait && open) {
      lock(contentRef.current);
    }

    return () => {
      clearBodyLocks();
    };
  }, [isTabletPortrait, open]);

  useEffect(() => {
    if (!open) {
      setView('questions');
    }
  }, [open]);

  useEffect(() => {
    if (subject) {
      setView('form');
    }
  }, [subject]);

  if (!container) {
    return null;
  }

  return createPortal(
    <AnimatePresence>
      {open && (
        <FocusLock returnFocus>
          <motion.dialog
            id={SUPPORT_POPUP_ID}
            ref={dialogRef}
            transition={TRANSITION_CONFIG}
            variants={DIALOG_ANIMATION_VARIANTS}
            initial="hidden"
            animate="visible"
            exit="hidden"
            open
            className={styles.root}
          >
            <header className={cx(styles.header, styles.content)}>
              <Heading as="h2" variant="xs" textColor="light" className={styles.title}>
                {t('general.contactUsAction')}
              </Heading>
              <button
                type="button"
                onClick={closeSupportPopup}
                className={styles.close}
                aria-label="Close support chat popup"
              >
                <Icon name="x" size="xs" />
              </button>
            </header>
            <Stack
              ref={contentRef}
              className={cx(styles.contentWrapper, view === 'questions' && styles.hideOverflow)}
            >
              <AnimatePresence mode="wait">
                <motion.div
                  key={view}
                  transition={TRANSITION_CONFIG}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={CONTENT_ANIMATION_VARIANTS}
                  className={cx(styles.main, styles.content)}
                >
                  {view === 'questions' && <QuestionList onContactUs={() => setView('form')} />}
                  {view === 'form' && (
                    <SupportForm defaultSubject={subject} onSuccess={() => setView('success')} />
                  )}
                  {view === 'success' && <ContactSuccessMessage />}
                </motion.div>
              </AnimatePresence>
            </Stack>
          </motion.dialog>
        </FocusLock>
      )}
    </AnimatePresence>,
    container,
  );
};

export { SupportPopup };
