import { each } from 'lodash';
import { getMarket } from 'services/site';

const FOREIGN_TO_LATIN = {
  А: 'A',
  а: 'A',
  В: 'B',
  в: 'B',
  І: 'I',
  і: 'I',
  Ѕ: 'S',
  ѕ: 'S',
  К: 'K',
  к: 'K',
  М: 'M',
  м: 'M',
  Н: 'H',
  н: 'H',
  О: 'O',
  о: 'O',
  Р: 'P',
  р: 'P',
  С: 'C',
  с: 'C',
  Т: 'T',
  т: 'T',
  Е: 'E',
  е: 'E',
  У: 'Y',
  у: 'Y',
  Х: 'X',
  х: 'X',
  Ž: 'Z',
  ž: 'Z',
  Š: 'S',
  š: 'S',
  Č: 'C',
  č: 'C',
};

const isLicensePlate = (identifier) => {
  const market = getMarket();
  const lpRegExp = market.identifier.lp && new RegExp(market.identifier.lp.pattern, 'i');
  return Boolean(identifier && lpRegExp?.test(identifier));
};

const replaceForeignChars = (vin) => {
  each(FOREIGN_TO_LATIN, (latin, foreign) => {
    vin = vin.replace(new RegExp(foreign, 'g'), latin);
  });
  return vin;
};

export { isLicensePlate, replaceForeignChars };
