// THIS FILE IS GENERATED AUTOMATICALLY - DO NOT EDIT THIS FILE
import Angle from 'assets/icons/new/angle.svg';
import Arrow from 'assets/icons/new/arrow.svg';
import ArrowExtended from 'assets/icons/new/arrow-extended.svg';
import Book from 'assets/icons/new/book.svg';
import Camera from 'assets/icons/new/camera.svg';
import Check from 'assets/icons/new/check.svg';
import CheckCircle from 'assets/icons/new/check-circle.svg';
import CheckRounded from 'assets/icons/new/check-rounded.svg';
import Checklist from 'assets/icons/new/checklist.svg';
import Chevron from 'assets/icons/new/chevron.svg';
import Clock from 'assets/icons/new/clock.svg';
import Damage from 'assets/icons/new/damage.svg';
import Female from 'assets/icons/new/female.svg';
import Gift from 'assets/icons/new/gift.svg';
import HappyFace from 'assets/icons/new/happy-face.svg';
import HistoryReport from 'assets/icons/new/history-report.svg';
import Info from 'assets/icons/new/info.svg';
import Issues from 'assets/icons/new/issues.svg';
import Lock from 'assets/icons/new/lock.svg';
import MagnifyingGlass from 'assets/icons/new/magnifying-glass.svg';
import Mail from 'assets/icons/new/mail.svg';
import Maintenance from 'assets/icons/new/maintenance.svg';
import Minus from 'assets/icons/new/minus.svg';
import OrderLaptop from 'assets/icons/new/order-laptop.svg';
import Owner from 'assets/icons/new/owner.svg';
import PieChart from 'assets/icons/new/pie-chart.svg';
import Play from 'assets/icons/new/play.svg';
import PlusCircle from 'assets/icons/new/plus-circle.svg';
import QuestionMark from 'assets/icons/new/question-mark.svg';
import QuestionMarkLight from 'assets/icons/new/question-mark-light.svg';
import QuestionMarkLightFilled from 'assets/icons/new/question-mark-light-filled.svg';
import ReportReduction from 'assets/icons/new/report-reduction.svg';
import Rollback from 'assets/icons/new/rollback.svg';
import SadFace from 'assets/icons/new/sad-face.svg';
import Specs from 'assets/icons/new/specs.svg';
import Speech from 'assets/icons/new/speech.svg';
import Speedometer from 'assets/icons/new/speedometer.svg';
import Store from 'assets/icons/new/store.svg';
import Theft from 'assets/icons/new/theft.svg';
import UserOutline from 'assets/icons/new/user-outline.svg';
import X from 'assets/icons/new/x.svg';

const iconMap = {
  angle: Angle,
  arrow: Arrow,
  'arrow-extended': ArrowExtended,
  book: Book,
  camera: Camera,
  check: Check,
  'check-circle': CheckCircle,
  'check-rounded': CheckRounded,
  checklist: Checklist,
  chevron: Chevron,
  clock: Clock,
  damage: Damage,
  female: Female,
  gift: Gift,
  'happy-face': HappyFace,
  'history-report': HistoryReport,
  info: Info,
  issues: Issues,
  lock: Lock,
  'magnifying-glass': MagnifyingGlass,
  mail: Mail,
  maintenance: Maintenance,
  minus: Minus,
  'order-laptop': OrderLaptop,
  owner: Owner,
  'pie-chart': PieChart,
  play: Play,
  'plus-circle': PlusCircle,
  'question-mark': QuestionMark,
  'question-mark-light': QuestionMarkLight,
  'question-mark-light-filled': QuestionMarkLightFilled,
  'report-reduction': ReportReduction,
  rollback: Rollback,
  'sad-face': SadFace,
  specs: Specs,
  speech: Speech,
  speedometer: Speedometer,
  store: Store,
  theft: Theft,
  'user-outline': UserOutline,
  x: X,
};

type IconMapMember = keyof typeof iconMap;

export { iconMap };
export type { IconMapMember };
