import {
  Button,
  type ButtonProps,
  Popover,
  getBaselineSize,
  type PopoverProps,
} from '@carvertical/ui';
import cx from 'classnames';
import styles from './MenuPopover.module.scss';

type MenuPopoverProps = Partial<PopoverProps> & {
  title: string;
  anchorType?: 'linkButton' | 'button';
  anchorIcon?: ButtonProps['leadingIcon'];
  className?: string;
};

const MenuPopover = ({
  title,
  anchorType = 'linkButton',
  anchorIcon,
  className,
  children,
}: MenuPopoverProps) => {
  const offset = {
    mainAxis: anchorType === 'button' ? getBaselineSize(3.25) : getBaselineSize(4),
    crossAxis: getBaselineSize(-3.5),
  };

  return (
    <Popover
      initialFocus={-1}
      className={cx(styles.root, className)}
      trigger="hover"
      strategy="fixed"
      renderAnchor={(props, { open }) => (
        <Button
          className={cx(styles.anchor, open && styles.open, styles[anchorType])}
          variant={anchorType === 'linkButton' ? 'yellow' : 'outlined'}
          trailingIconClassName={styles.trailingIcon}
          trailingIcon="chevron-down-bold"
          leadingIcon={anchorIcon}
          title={title}
          size={anchorType === 'linkButton' ? 'l' : 'm'}
          leadingIconClassName={styles.leadingIcon}
          {...props}
        >
          {title}
        </Button>
      )}
      offset={offset}
    >
      {children}
    </Popover>
  );
};

export { MenuPopover };
export type { MenuPopoverProps };
