import { j as n } from "./chunks/jsx-runtime.82a9ddf6.js";
import { h as t } from "./chunks/styles.9b2ce419.js";
import { Button as l } from "./button.mjs";
import "react";
import "./chunks/clsx.0d472180.js";
import "./chunks/cn.2ea4f521.js";
import "./icon.mjs";
import "./chunks/warnings.8f6035a3.js";
import "./anchorOrButton.mjs";
import "./chunks/index.29668fd9.js";
import "./chunks/motion.24089995.js";
import "./spinner.mjs";
import "./chunks/useTranslatableUILabel.cc84b4da.js";
import "./chunks/TranslatableUIProvider.f5945ac7.js";
import "./text.mjs";
import "./chunks/TypographyElement.fa6e5999.js";
const a = ({ ...e }) => /* @__PURE__ */ n.jsx(l, { className: t.root, size: "s", ...e });
try {
  a.displayName = "TextFieldButton", a.__docgenInfo = { description: "", displayName: "TextFieldButton", props: { variant: { defaultValue: null, description: "", name: "variant", required: !1, type: { name: "ButtonVariant" } }, as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, loading: { defaultValue: null, description: "", name: "loading", required: !1, type: { name: "boolean" } }, fullWidth: { defaultValue: null, description: "", name: "fullWidth", required: !1, type: { name: "boolean" } }, leadingIcon: { defaultValue: null, description: "", name: "leadingIcon", required: !1, type: { name: "IconType" } }, trailingIcon: { defaultValue: null, description: "", name: "trailingIcon", required: !1, type: { name: "IconType" } }, leadingIconClassName: { defaultValue: null, description: "", name: "leadingIconClassName", required: !1, type: { name: "string" } }, trailingIconClassName: { defaultValue: null, description: "", name: "trailingIconClassName", required: !1, type: { name: "string" } }, progressPercentage: { defaultValue: null, description: "", name: "progressPercentage", required: !1, type: { name: "number" } } } };
} catch {
}
export {
  a as TextFieldButton
};
