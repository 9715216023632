import { createContext, useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import parseUrl from 'url-parse';
import { normalizePath } from '../utils/router';

type LastLocation = parseUrl<string>;

const LastLocationContext = createContext<LastLocation | undefined>(undefined);

const LastLocationProvider = ({ ...otherProps }) => {
  const { asPath } = useRouter();
  const [location, setLocation] = useState<LastLocation>();
  const [lastLocation, setLastLocation] = useState<LastLocation>();

  useEffect(() => {
    setLastLocation(location);
    setLocation(parseUrl(normalizePath(asPath)));
  }, [asPath]); // eslint-disable-line react-hooks/exhaustive-deps

  return <LastLocationContext.Provider {...otherProps} value={lastLocation} />;
};

const useLastLocation = () => useContext(LastLocationContext);

export { LastLocationProvider, useLastLocation };
