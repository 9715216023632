import { AnchorOrButton, Text } from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';
import Flag from 'components/common/Flag';
import { useModal, useMarket } from '../../hooks';
import { useMarketSwitcher } from './hooks';
import type { SwitcherMarket } from './types';

type MarketSelectionButtonProps = {
  market: SwitcherMarket;
};

const MarketSelectionButton = ({ market }: MarketSelectionButtonProps) => {
  const { getMarketLanguages } = useMarket();
  const { selectMarket } = useMarketSwitcher();
  const { countryCode, name, active } = market;
  const { setLanguageModalType } = useMarketSwitcher();
  const { open: openLanguageSelectionModal } = useModal('languageSelection');

  const [mainLanguage, ...otherLanguages] = getMarketLanguages(countryCode);
  const multilingual = !!otherLanguages.length;

  const selectMultilingualMarket = () => {
    if (!multilingual) {
      return;
    }

    selectMarket(market);
    openLanguageSelectionModal();
    setLanguageModalType('switcher');
  };

  return (
    <AnchorOrButton
      href={!multilingual ? mainLanguage.slug : undefined}
      onClick={selectMultilingualMarket}
      className={cn(
        'flex h-5 w-full items-center gap-1 rounded-xs border border-solid border-transparent px-2 py-3 text-left transition-colors focus:outline-none focus-visible:ring',
        active ? 'border-blue' : 'border-transparent hover:bg-grey-100',
      )}
    >
      <Flag countryCode={countryCode} rounded as={undefined} className="flex-shrink-0" size={24} />

      <Text as="span" variant="m+">
        {name}
      </Text>
    </AnchorOrButton>
  );
};

export { MarketSelectionButton };
export type { MarketSelectionButtonProps };
