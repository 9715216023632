import { Text, type DivProps } from '@carvertical/ui';

type ListingEmptyStateProps = {
  title: string;
  description?: string;
  illustration?: React.ReactNode;
} & DivProps;

const ListingEmptyState = ({
  title,
  description,
  illustration,
  ...props
}: ListingEmptyStateProps) => (
  <div {...props}>
    {illustration && <div className="mx-auto mb-2 flex h-10 w-10">{illustration}</div>}

    <Text variant="m+" className="mb-1" align="center">
      {title}
    </Text>

    {description && (
      <Text variant="s" align="center" textColor="darkSecondary">
        {description}
      </Text>
    )}
  </div>
);

export { ListingEmptyState };
export type { ListingEmptyStateProps };
