import { useMutation } from '@tanstack/react-query';
import {
  subscribe,
  type SubscribeParams,
  type KlaviyoSubscriptionResponse,
} from 'modules/subscription/api';

const useEmailSubscription = <TExtraParams = Record<string, unknown>>() => {
  const { isError, isSuccess, isPending, mutate, reset } = useMutation<
    KlaviyoSubscriptionResponse,
    unknown,
    SubscribeParams & TExtraParams
  >({
    mutationFn: subscribe,
  });

  return {
    subscribe: mutate,
    subscribed: isSuccess,
    subscribing: isPending,
    subscriptionError: isError,
    reset,
  };
};

export { useEmailSubscription };
