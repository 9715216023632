import { useEffect } from 'react';
import Router from 'next/router';
import ProgressBar from '@badrap/bar-of-progress';
import { theme } from '@carvertical/ui';

const progress = new ProgressBar({
  color: theme.colorBlue,
});

const PageLoadProgress = () => {
  useEffect(() => {
    Router.events.on('routeChangeStart', () => progress.start());
    Router.events.on('routeChangeComplete', () => progress.finish());
    Router.events.on('routeChangeError', () => progress.finish());
  }, []);

  return null;
};

export default PageLoadProgress;
