const trimEnd = require('lodash/trimEnd');
const trimStart = require('lodash/trimStart');
const uniq = require('lodash/uniq');
const memoize = require('lodash/memoize');
const identity = require('lodash/identity');
const { pathToRegexp, compile } = require('path-to-regexp');
const parseUrl = require('url-parse');
const rewritesConfig = require('../../proxy/rewrites/rewrites-middleware.json');
const reverseRewritesConfig = require('../../proxy/rewrites/rewrites-reverse.json');

const PATH_OVERRIDES = ['/cz/cz', '/et/et', '/pl/pl', '/hu/hu', '/lv/lv', '/sk/sk', '/gb/gb'];

const rewritePath = (path = '/', processedRewrites = []) => {
  const parsedUrl = parseUrl(`/${trimStart(path, '/')}`);
  const { pathname, hash, query } = parsedUrl;
  const finalPathName = processedRewrites.reduce((pathname, { regexp, keys, destination }) => {
    const results = regexp.exec(trimEnd(pathname, '/'));

    if (results) {
      const toPath = compile(destination);
      const props = keys.reduce((props, key, index) => {
        const value = results[index + 1];
        if (value) {
          const segments = value.split('/');
          props[key.name] = segments.length > 1 ? segments : segments[0];
        }
        return props;
      }, {});

      return `/${trimStart(toPath(props), '/')}`;
    }

    return pathname;
  }, pathname);

  return `${trimEnd(finalPathName, '/') || finalPathName}${query}${hash}`;
};

const processRewrites = (rewrites) =>
  rewrites.map(({ source, destination }) => {
    const keys = [];
    const regexp = pathToRegexp(source, keys);
    return { destination, regexp, keys };
  });

const createRewritePath = (rewrites, processPath = identity) => {
  const processedRewrites = processRewrites(rewrites);
  return memoize((path) => rewritePath(processPath(path), processedRewrites));
};

const normalizePath = createRewritePath(rewritesConfig);

const beautifyPath = createRewritePath(
  [
    {
      source: '/us/:path([\\w-.]{3,}|v\\d)*',
      destination: '/:path*',
    },
    ...reverseRewritesConfig,
  ],
  (path) => path.replace(/^(\/[a-z]{2})\1?(\/|$|\?)/, '$1$2'),
);

const pathToPattern = (path) => {
  if (PATH_OVERRIDES.includes(path)) {
    return path;
  }

  return path.replace(/^\/[a-z]{2}\/[a-z]{2}(\/.*)?/, '/[country]/[language]$1');
};

const removeBasePath = (path) => path.replace(/^\/?[a-z]{2}(?:\/[a-z]{2})?(\/|$)/, '$1') || '/';

const cleanPath = (path) => trimStart(removeBasePath(path), '/');

const getRoute = (path, pattern = path, basePath) => [
  pathToPattern(normalizePath(`${basePath}${cleanPath(pattern)}`)),
  normalizePath(`${basePath}${cleanPath(path)}`),
];

const getLinkProps = (path, pattern, basePath) => {
  const [href, as] = getRoute(path, pattern, basePath);

  return {
    href,
    as,
  };
};

function getBasePathForCountryAndLanguage(country, language = country) {
  return `/${uniq([country, language]).join('/')}/`.replace(/\/+/g, '/');
}

const BASE_PATH_REGEXP = /^\/(\w{2})\/(\w{2})/;

function getCountryAndLanguageForPath(path) {
  return (BASE_PATH_REGEXP.exec(path) || []).slice(1);
}

function addTrailingSlash(path) {
  return path.replace(/\/*$/, '/');
}

module.exports = {
  normalizePath,
  beautifyPath,
  removeBasePath,
  getRoute,
  getLinkProps,
  getBasePathForCountryAndLanguage,
  getCountryAndLanguageForPath,
  addTrailingSlash,
  createRewritePath,
};
