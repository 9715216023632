import { j as n } from "./chunks/jsx-runtime.82a9ddf6.js";
import { c as N } from "./chunks/clsx.0d472180.js";
import { forwardRef as x } from "react";
import { c as _ } from "./chunks/cn.2ea4f521.js";
import { Icon as B } from "./icon.mjs";
import { v as V } from "./chunks/warnings.8f6035a3.js";
import { n as t } from "./chunks/styles.9b2ce419.js";
import { AnchorOrButton as b } from "./anchorOrButton.mjs";
const s = {
  xs: "xs",
  s: "m",
  m: "m",
  l: "m"
}, r = x(
  ({
    icon: e,
    label: o,
    "aria-label": m = o,
    "aria-labelledby": l,
    variant: u = "yellow",
    size: a = "m",
    iconClassName: i,
    className: c,
    title: p = o,
    ...d
  }, f) => {
    const y = () => {
      if (typeof e == "string")
        return /* @__PURE__ */ n.jsx(
          B,
          {
            name: e,
            size: s[a],
            className: i
          }
        );
      const I = e;
      return V({
        componentName: r.displayName || "IconButton",
        iconName: e.displayName || "",
        componentSize: a,
        sizeMap: s
      }), /* @__PURE__ */ n.jsx(
        I,
        {
          className: _(
            "inline-flex",
            {
              "size-2": a === "xs",
              "size-3": a !== "xs"
            },
            i
          )
        }
      );
    };
    return /* @__PURE__ */ n.jsx(
      b,
      {
        className: N(t.root, t[u], t[a], c),
        ref: f,
        "aria-label": l ? void 0 : m,
        "aria-labelledby": l,
        title: p,
        ...d,
        children: y()
      }
    );
  }
);
try {
  r.displayName = "IconButton", r.__docgenInfo = { description: "", displayName: "IconButton", props: { as: { defaultValue: null, description: "", name: "as", required: !1, type: { name: "ElementType<any, keyof IntrinsicElements>" } }, unstyled: { defaultValue: null, description: "", name: "unstyled", required: !1, type: { name: "boolean" } }, icon: { defaultValue: null, description: "", name: "icon", required: !0, type: { name: "IconType" } }, label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "string" } }, variant: { defaultValue: { value: "yellow" }, description: "", name: "variant", required: !1, type: { name: "IconButtonVariant" } }, size: { defaultValue: { value: "m" }, description: "", name: "size", required: !1, type: { name: "IconButtonSize" } }, iconClassName: { defaultValue: null, description: "", name: "iconClassName", required: !1, type: { name: "string" } } } };
} catch {
}
export {
  r as IconButton
};
