import type { ParsedUrlQuery } from 'querystring';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { STORE_PREFIX } from 'services/store';

type LandingParamsState = {
  initial: boolean;
  params: ParsedUrlQuery;
  setParams: (params: ParsedUrlQuery) => void;
};

const useLandingParamsStore = create<LandingParamsState>()(
  persist(
    (set, get) => ({
      initial: true,
      params: {},
      setParams: (params) => {
        if (get().initial) {
          set({ initial: false, params });
        }
      },
    }),
    {
      name: `${STORE_PREFIX}landing-params`,
      storage: createJSONStorage(() => window.sessionStorage),
    },
  ),
);

const getLandingParam = (key: string) => useLandingParamsStore.getState().params[key];

export { useLandingParamsStore, getLandingParam };
