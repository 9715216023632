import Image from 'next/image';
import { Stack } from '@carvertical/ui';
import type { Logo } from 'constants/partners';

type TrustBannerLogosProps = {
  logos: Logo[];
  logoClassName?: string;
};

const TrustBannerLogos = ({ logoClassName, logos }: TrustBannerLogosProps) => (
  <>
    {logos.map((logo) => {
      const { height, title, url, width } = logo;

      return (
        <li key={title}>
          <Stack style={{ height: `${height}px`, width: `${width}px` }}>
            <Image src={url} alt={title} height={height} width={width} className={logoClassName} />
          </Stack>
        </li>
      );
    })}
  </>
);

export { TrustBannerLogos };
