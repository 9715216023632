import { Stack, Text, Button } from '@carvertical/ui';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { APP_LINKS } from 'modules/shared/constants/links';
import styles from './AppLinks.module.scss';

const AppLinks = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={1}>
      <Text as="h3" variant="s" textColor="darkSecondary">
        {t('menu.appsSubtitle')}
      </Text>

      <Stack as="ul" type="horizontal" gap={2} className="unstyledList">
        {APP_LINKS.map(({ AppIcon, labelKey, href, id }) => (
          <li key={labelKey}>
            <Button
              variant="outlined"
              size="s"
              className={styles.button}
              as="a"
              href={href}
              target="_blank"
            >
              <Stack gap={1} type="horizontal" crossAxisAlign="center" wrap>
                <AppIcon
                  className={cx(
                    styles.icon,
                    id === 'appStore' ? styles.appStoreIcon : styles.playStoreIcon,
                  )}
                />

                {t(labelKey)}
              </Stack>
            </Button>
          </li>
        ))}
      </Stack>
    </Stack>
  );
};

export { AppLinks };
