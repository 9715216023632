import { Text } from '@carvertical/ui';
import { motion } from 'framer-motion';
import { useRouteData } from 'context/RouteDataProvider';
import Flag from 'components/common/Flag';
import EuropeanUnionStarsIcon from 'assets/icons/eu-stars.svg';
import styles from './LicensePlatePrefix.module.scss';

type LicensePlatePrefixProps = {
  hintId: string;
};

enum LicensePlatePrefixTestId {
  Root = 'LicensePlatePrefixTestId',
}

const CONTENT_ANIMATION_VARIANTS = {
  hidden: { opacity: 0, scale: 0 },
  visible: { opacity: 1, scale: 1, transition: { delay: 0.2 } },
};

const LicensePlatePrefix = ({ hintId }: LicensePlatePrefixProps) => {
  const { market } = useRouteData();

  return (
    <div className={styles.root} data-testid={LicensePlatePrefixTestId.Root}>
      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        className={styles.content}
        variants={CONTENT_ANIMATION_VARIANTS}
      >
        {market.isEuropeanUnion ? (
          <EuropeanUnionStarsIcon className={styles.stars} original />
        ) : (
          /* @ts-expect-error TS(2741) */
          <Flag countryCode={market.countryCode} size={24} rounded aria-hidden />
        )}
        <Text id={hintId} align="center" variant="xs+" textColor="light">
          {market.countryCode.toUpperCase()}
        </Text>
      </motion.div>
    </div>
  );
};

export { LicensePlatePrefix, LicensePlatePrefixTestId };
