import { Stack, Radio, SkeletonWrapper } from '@carvertical/ui';
import { Controller, type UseControllerProps, useFormContext } from 'react-hook-form';

type RadioGroupProps = {
  className?: string;
  radioButtonClassName?: string;
  name: string;
  items: { label: string; value: string }[];
  rules?: UseControllerProps['rules'];
  loading?: boolean;
  onChange?: () => void;
};

const RadioGroup = ({
  className,
  items,
  name,
  radioButtonClassName,
  loading,
  onChange,
  ...props
}: RadioGroupProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <Stack gap={2} type="horizontal" wrap className={className}>
          {items.map(({ label, value }) => (
            <SkeletonWrapper key={value} loading={loading} radius="l">
              <Radio
                className={radioButtonClassName}
                status={fieldState.error ? 'error' : undefined}
                id={value}
                label={label}
                {...field}
                value={value}
                checked={value === field.value}
                onChange={(e) => {
                  field.onChange(e);
                  onChange?.();
                }}
              />
            </SkeletonWrapper>
          ))}
        </Stack>
      )}
      {...props}
    />
  );
};

export default RadioGroup;
