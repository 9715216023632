import { useCrawlerDetect } from 'hooks/useCrawlerDetect';
import { useUserInteraction } from 'context/UserInteractionProvider';
import { SuggestCorrectMarket } from './SuggestCorrectMarket';
import { useSuggestCorrectMarket } from './hooks';

type LazySuggestCorrectMarketProps = {
  immediate?: boolean;
};

const LazySuggestCorrectMarket = ({ immediate }: LazySuggestCorrectMarketProps) => {
  const userInteraction = useUserInteraction();
  const crawlerDetected = useCrawlerDetect();

  useSuggestCorrectMarket();

  if (crawlerDetected || (!immediate && !userInteraction)) {
    return null;
  }

  return <SuggestCorrectMarket />;
};

export { LazySuggestCorrectMarket };
export type { LazySuggestCorrectMarketProps };
