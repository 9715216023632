// CommonJS export is used here for convenience: to also export all keys, not just the whole object.
module.exports = {
  SPEC: {
    id: 'spec',
    fallback: {},
  },
  SPEC_LIGHT: {
    id: 'lightSpec',
    fallback: {},
  },
};
