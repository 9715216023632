import { supportApi } from 'services/support-api';
import type { InquiryParams, PostInquiryResponse } from '../types/inquiry';

const postInquiry = async <TExtraParams>(inquiry: InquiryParams & TExtraParams) => {
  const payload = { template: 'inquiry', ...inquiry };

  const { data } = await supportApi.post<PostInquiryResponse>('/sendEmail', payload);

  return data;
};

export { postInquiry };
