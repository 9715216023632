import { create } from 'zustand';
import type { SupportSubject } from 'types/content';

type SupportPopupState = {
  hidden: boolean;
  open: boolean;
  openPopup: (subject?: SupportSubject) => void;
  closePopup: () => void;
  hidePopup: () => void;
  showPopup: () => void;
  subject?: SupportSubject;
};

const useSupportPopupStore = create<SupportPopupState>()((set) => ({
  hidden: false,
  open: false,
  openPopup: (subject) => set({ open: true, subject }),
  closePopup: () => set({ open: false }),
  hidePopup: () => set({ hidden: true }),
  showPopup: () => set({ hidden: false }),
}));

export { useSupportPopupStore };
export type { SupportPopupState };
