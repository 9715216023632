const VIN = 'vin';
const LP = 'lp';
const UNIVERSAL = 'universal';

module.exports = {
  VIN,
  LP,
  UNIVERSAL,
  IDENTIFIER_TYPE: { VIN, LP, UNIVERSAL },

  VIN_PATTERN:
    '^\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*[A-Z0-9]\\s*$',

  LP_FRANCE: {
    placeholder: 'AA-000-AA',
    pattern: toPattern(
      // AA-000-AA / 000-AAA-00 / 00-AAA-00
      '^(?:[A-Z]{2}<SEP>[0-9]{3}<SEP>[A-Z]{2}|[0-9]{3}<SEP>[A-Z]{3}<SEP>[0-9]{2}|[0-9]{2}<SEP>[A-Z]{3}<SEP>[0-9]{2})$',
    ),
  },
  LP_SWEDEN: {
    placeholder: 'AAA 00A',
    pattern: toPattern(
      // AAA-00A
      '^(?:[A-Z]{3}<SEP>[0-9]{2}[A-Z0-9])$',
    ),
  },
  LP_CZECHIA: {
    placeholder: '0A0 0000',
    pattern: toPattern(
      // 0A[A0]-0000 / 0A-0000 (moto)
      '^(?:[0-9][A-Z][A-Z0-9]<SEP>[0-9]{4}|[0-9][A-Z]<SEP>[0-9]{4})$',
    ),
  },
  LP_CROATIA: {
    placeholder: 'AA 000-AA',
    pattern: toPattern(
      // AA-000-A / AA-000-AA / AA-0000-A / AA-0000-AA
      '^(?:[A-Z]{2}<SEP>[0-9]{3,4}<SEP>[A-Z]{1,2})$',
    ),
  },
  LP_SLOVAKIA: {
    placeholder: 'AA-000AA',
    pattern: toPattern(
      // AA-000-AA
      '^(?:[A-Z]{2}<SEP>[0-9]{3}<SEP>[A-Z]{2})$',
    ),
  },
  LP_FINLAND: {
    placeholder: 'AAA-000',
    pattern: toPattern(
      // AAA-000 / AA-000 (moto) / 00-AAA (moto)
      '^(?:[A-Z]{2,3}<SEP>[0-9]{3}|[0-9]{2}<SEP>[A-Z]{3})$',
    ),
  },
  LP_HUNGARY: {
    placeholder: 'AAA-000',
    pattern: toPattern(
      // AAA-000 / AAAA-000 / AA-AA-000
      '^(?:[A-Z]{3,4}<SEP>[0-9]{3}|[A-Z]{2}<SEP>[A-Z]{2}<SEP>[0-9]{3})$',
    ),
  },
  LP_UKRAINE: {
    placeholder: 'AA 0000 AA',
    pattern: toPattern(
      // AA-0000-AA
      '^(?:[A-Z]{2}<SEP>[0-9]{4}<SEP>[A-Z]{2})$',
    ),
  },
  LP_ITALY: {
    placeholder: 'AA 000AA',
    pattern: toPattern(
      // AA-000-AA / AA-00000 (moto) / X[A0][A0]-[A0][A0][A0] (moped)
      '^(?:[A-Z]{2}<SEP>[0-9]{3}<SEP>[A-Z]{2}|[A-Z]{2}<SEP>[0-9]{5}|X[A-Z0-9]{2}<SEP>[A-Z0-9]{3})$',
    ),
  },
  LP_DENMARK: {
    placeholder: 'AA 00 000',
    pattern: toPattern(
      // AA-00-000
      '^(?:[A-Z]{2}<SEP>[0-9]{2}<SEP>[0-9]{3})$',
    ),
  },
  LP_LATVIA: {
    placeholder: 'AA-0000',
    pattern: toPattern(
      // AA-0 – AA-0000
      '^(?:[A-Z]{2}<SEP>[0-9]{1,4})$',
    ),
  },
  LP_BRITAIN: {
    placeholder: 'AA00 AAA',
    pattern: toPattern(
      // A-00-AAA / AA-00-AAA / A00-AAA – A000-AAA / AAA-000 – AAA-0000 / AAA-000-A – AAA-0000-A
      '^(?:[A-Z]{1,2}<SEP>[0-9]{2}<SEP>[A-Z]{3}|[A-Z][0-9]{2,3}<SEP>[A-Z]{3}|[A-Z]{3}<SEP>[0-9]{3,4}(?:<SEP>[A-Z])?)$',
    ),
  },
  LP_LITHUANIA: {
    placeholder: 'AAA 000',
    pattern: toPattern(
      // AAA-000 / EA-0000
      '^(?:[A-Z]{1,2}<SEP>[0-9]{2}<SEP>[A-Z]{3}|[A-Z][0-9]{2,3}<SEP>[A-Z]{3}|[A-Z]{3}<SEP>[0-9]{3,4}(?:<SEP>[A-Z])?)$',
    ),
  },
  LP_SERBIA: {
    placeholder: 'AA 000-AA',
    pattern: toPattern(
      // AA-000-AA – AA-0000-AA
      '^(?:[A-Z]{1,2}<SEP>[0-9]{3,4}<SEP>[A-Z]{2})$',
    ),
  },
  LP_POLAND: {
    placeholder: 'AA 00000',
    pattern: toPattern(
      // A-000 – AAA-00000
      '^[A-Z]{1,3}<SEP>[A-Z0-9]{2,5}$',
    ),
  },
  LP_MEXICO: {
    placeholder: 'AAA-000-A',
    pattern: toPattern(
      // AAA-00-00 / AAA-000-A / A00-AAA
      '^(?:[A-Z]{3}<SEP>[0-9]{2}<SEP>[0-9]{2}|[A-Z]{3}<SEP>[0-9]{3}<SEP>[A-Z]|[A-Z][0-9]{2}<SEP>[A-Z]{3})$',
    ),
  },
  LP_AUSTRALIA: {
    placeholder: '',
    pattern: toPattern(
      // 6-7 symbols
      '^[A-Z0-9]{6,7}$',
    ),
  },
  LP_PORTUGAL: {
    placeholder: '',
    pattern: toPattern(
      // 00-00-00 / 00-AA-00 / AA-00-AA / AA-AA-00
      '^([A-Z]{2}|[0-9]{2})<SEP>([A-Z]{2}|[0-9]{2})<SEP>([A-Z]{2}|[0-9]{2})$',
    ),
  },
};

function toPattern(pattern) {
  return pattern.replace(/<SEP>/g, '[-\\s]?');
}
