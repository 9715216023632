import { noop } from 'lodash';
import { useTranslation } from 'next-i18next';
import { useRef, useState, type ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMount } from 'react-use';
import type { TextFieldProps } from '@carvertical/ui';
import { useRouteData } from 'context/RouteDataProvider';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';
import type { ChangeVatFunction } from 'components/CheckoutPage/hooks/useCheckout';
import { isValidVatCode } from 'services/support-api';
import { getFullLocale } from 'utils/locale';
import { splitVat } from 'utils/money';
import { FormField } from './ui/forms/FormField';
import ExternalLink from './ExternalLink';
import type { TextInputProps } from './ui/forms/TextInput';
import TextInput from './ui/forms/TextInput';
import styles from './VatInput.module.scss';

type VatInputFormFields = {
  vat: string;
  vatCode: string;
};

type VatInputProps = {
  allowedCountryCode?: string;
  onValidated?: ChangeVatFunction;
  name: 'vat' | 'vatCode';
} & Pick<TextFieldProps, 'fullWidth' | 'className'>;

const VIES_URL = 'https://ec.europa.eu/taxation_customs/vies/';

const VatInput = ({
  allowedCountryCode,
  className,
  fullWidth,
  name,
  onValidated = noop,
}: VatInputProps) => {
  const { t } = useTranslation(['user', 'common']);
  const { locale } = useRouteData();
  const isValidVatCodePromiseRef = useRef<Promise<boolean>>();
  const [validation, setValidation] = useState<TextInputProps['state']>();
  const [previousVat, setPreviousVat] = useState('');
  const { setValue, trigger, watch } = useFormContext<VatInputFormFields>();
  const vat = watch(name);
  const validationFailed = validation === 'failure';

  const reset = () => {
    setValue(name, '');
    setValidation(undefined);
    onValidated(false);
  };

  const performQuickValidation = async (event: ChangeEvent<HTMLInputElement>) => {
    isValidVatCodePromiseRef.current = undefined;

    const newVat = event.target.value;

    if (!newVat) {
      reset();
    }

    if (validationFailed) {
      await trigger(name);
    }
  };

  const performFullValidation = async () => {
    setPreviousVat(vat);

    if (!vat || vat === previousVat) {
      return;
    }
    const valid = await trigger(name);

    if (!valid) {
      setValidation('failure');
      onValidated(false);
      return;
    }

    setValidation('pending');

    try {
      const { countryCode, vatCode } = splitVat(vat);

      if (allowedCountryCode && countryCode !== allowedCountryCode) {
        throw new Error('VAT country not allowed');
      }

      const promise = isValidVatCode({ countryCode, vatCode });
      isValidVatCodePromiseRef.current = promise;

      const validVatCode = await promise;

      if (!validVatCode) {
        throw new Error('Invalid VAT code');
      }

      setValidation('success');
      onValidated(true, countryCode, vatCode);
    } catch (err) {
      setValidation('failure');
      onValidated(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  useMount(async () => {
    if (!vat) {
      return;
    }

    setPreviousVat(vat);

    const valid = await trigger(name);
    if (valid) {
      const { countryCode, vatCode } = splitVat(vat);
      onValidated(true, countryCode, vatCode);
    }
  });

  const renderMessage = () => {
    if (validationFailed) {
      return (
        <>
          {t(`common:${ERROR_TRANSLATION_KEYS.vatCode}`)}
          {allowedCountryCode && ` (${allowedCountryCode.toUpperCase()}…)`}
          <ExternalLink
            aria-label={t('userForm.vatValidationLinkLabel')}
            className={styles.vatQuestion}
            href={`${VIES_URL}?locale=${getFullLocale(locale, '_')}`}
          >
            ?
          </ExternalLink>
        </>
      );
    }

    return undefined;
  };

  const Field = FormField<VatInputFormFields>;

  return (
    <Field
      name={name}
      options={{
        onChange: (event) => {
          performQuickValidation(event);
        },
        onBlur: () => {
          performFullValidation();
        },
      }}
    >
      {/* TODO: Replace with `TextField` when pending state is ready */}
      <TextInput
        state={validation}
        stateText={renderMessage()}
        label={`${t('userForm.vatCodeLabel')} (${t('common:general.optionalLabel')})`}
        readOnly={validation === 'pending'}
        fullWidth={fullWidth}
        className={className}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.currentTarget.blur();
          }
        }}
      />
    </Field>
  );
};

export { VatInput };
export type { VatInputProps };
