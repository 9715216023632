export function push(event, data = {}) {
  if (typeof window === 'undefined') {
    return;
  }
  const eventPayload = { event, ...data };
  if (!window.dataLayer) {
    window.dataLayer = [];
  }
  window.dataLayer.push(eventPayload);
}
