import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import * as Sentry from '@sentry/nextjs';
import { schema, type TrackingSchema } from './schema';

type EventHandler = (event: React.SyntheticEvent) => void;

const useEventTracking = (context?: Parameters<typeof useTracking>[0]) => {
  const { Track, trackEvent } = useTracking(context);

  const track = useCallback(
    (data: TrackingSchema) => {
      const result = schema.safeParse(data);
      if (result.success) {
        trackEvent(result.data);
      } else {
        Sentry.captureMessage('Invalid event tracking data', { extra: { data } });
      }
    },
    [trackEvent],
  );

  const attachTracking = useCallback(
    (data: TrackingSchema, handle?: EventHandler) =>
      (...handlerArgs: Parameters<NonNullable<EventHandler>>) => {
        track(data);
        return handle?.(...handlerArgs);
      },
    [track],
  );

  return {
    TrackingContextProvider: Track,
    track,
    attachTracking,
  };
};

export { useEventTracking };
