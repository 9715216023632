const p = {
  l: "IconL",
  m: "IconM",
  s: "IconS",
  xs: "IconXS"
}, d = "@carvertical/ui", f = ({
  iconName: t,
  componentSize: n,
  componentName: S,
  sizeMap: r
}) => {
  var s;
  if (process.env.NODE_ENV === "development") {
    const e = r[n];
    if (!e)
      return;
    const c = p[e], I = ((s = t == null ? void 0 : t.match(/(Icon(?:XS|S|M|L))$/)) == null ? void 0 : s[1]) ?? "", o = t == null ? void 0 : t.replace(I, c);
    t && !t.endsWith(c) && console.warn(
      `[${d}]: Icon Size Mismatch
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━
Component:      <${S} />
Expected Size:  ${e}
❌ Current:     ${t}
✅ Suggested:   ${o}
━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━`
    );
  }
};
export {
  f as v
};
