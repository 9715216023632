const LOCALIZED_SINGLETON_IDENTIFIER = '__i18n_';

const BLOG_DEFAULT_LOCALE = 'en';

const BLOG_ROOT_PATH = 'blog';

const BLOG_LOCALE_OVERRIDES: Record<string, string> = {
  'en-us': BLOG_DEFAULT_LOCALE,
  'es-us': 'es-mx',
};

// Used to avoid generating static paths for locales, that we do not support, but they are listed in sanity
const UNSUPPORTED_LOCALES = ['ru', 'nl'];

const FEATURED_ARTICLE_LIMIT = 6;

const ENTRIES_PER_PAGE = 12;

const INITIAL_ENTRIES_PER_PAGE = FEATURED_ARTICLE_LIMIT + ENTRIES_PER_PAGE;

const SECTION_ENTRIES_PER_PAGE = 3;

enum CategoryKey {
  Research = 'Research',
  Business = 'Business',
}

enum Tag {
  TransparencyIndex = 'transparency-index',
}

export {
  LOCALIZED_SINGLETON_IDENTIFIER,
  BLOG_LOCALE_OVERRIDES,
  UNSUPPORTED_LOCALES,
  FEATURED_ARTICLE_LIMIT,
  ENTRIES_PER_PAGE,
  INITIAL_ENTRIES_PER_PAGE,
  SECTION_ENTRIES_PER_PAGE,
  BLOG_DEFAULT_LOCALE,
  BLOG_ROOT_PATH,
  CategoryKey,
  Tag,
};
