import { Children, type ElementType } from 'react';
import { useTranslation } from 'next-i18next';
import { useSupportPopup } from 'modules/support/hooks';
import type { SupportSubject } from 'types/content';
import type { PolymorphicComponentProp } from 'types/component';

type SupportEmailProps = {
  className?: string;
  defaultSubject?: SupportSubject;
};

const SupportEmail = <TElement extends ElementType = 'a'>({
  as,
  children,
  className,
  defaultSubject,
  ...props
}: PolymorphicComponentProp<TElement, SupportEmailProps>) => {
  const { t } = useTranslation();
  const { openSupportPopup } = useSupportPopup();

  const RenderAs = as || 'a';

  const open = () => {
    openSupportPopup(defaultSubject);
  };

  return (
    <RenderAs className={className} {...props} onClick={open}>
      {!children || !Children.toArray(children).length ? t('general.contactUsAction') : children}
    </RenderAs>
  );
};
export { SupportEmail };
export type { SupportEmailProps };
