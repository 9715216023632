import z from 'zod';
import { camelCase } from 'lodash';

const schema = z
  .union([
    z
      .object({
        event: z.literal('ButtonClicked'),
        action: z.string(),
        href: z.string().optional(),
      })
      .transform(({ event, action, href }) => ({
        event,
        buttonAction: camelCase(action),
        buttonHref: href,
      })),

    z.object({
      event: z.literal('VehicleDecoded'),
      vehicleName: z.string(),
      recordCount: z.number(),
    }),

    z.object({ event: z.literal('PrecheckVisited') }),

    z.object({ event: z.literal('CheckoutVisited') }),

    z.object({ event: z.literal('LoginCodeSucceeded') }),

    z.object({
      event: z.literal('LoginCodeFailed'),
      codeProvided: z.boolean(),
      accessTokenAvailable: z.boolean(),
      captchaAvailable: z.boolean(),
    }),

    z
      .object({
        event: z.literal('FileDownloaded'),
        type: z.string(),
        name: z.string(),
      })
      .transform(({ event, type, name }) => ({
        event,
        fileType: type,
        fileName: name,
      })),

    z.object({ event: z.literal('RefundPolicyOpened') }),
  ])
  .refine(({ event }) => !!event);

type TrackingSchema = z.input<typeof schema>;

export { schema };
export type { TrackingSchema };
