import { useTranslation } from 'next-i18next';
import { Button, type ButtonProps } from '@carvertical/ui';
import type { PropsWithChildren } from 'react';
import AuthContainer from 'containers/AuthContainer';
import withContainers from 'hoc/withContainers';

type AuthButtonProps = {
  containers: {
    auth: AuthContainer;
  };
  mode?: 'signup' | 'login';
} & ButtonProps;

const AuthButton = ({
  children,
  containers: { auth },
  mode = 'login',
  ...props
}: PropsWithChildren<AuthButtonProps>) => {
  const { t } = useTranslation();

  return (
    <Button onClick={() => auth.logIn({ mode })} {...props}>
      {children || t('auth.signUpOrLogInAction')}
    </Button>
  );
};

const WrappedAuthButton = withContainers({ auth: AuthContainer })(AuthButton);

export { WrappedAuthButton as AuthButton };
