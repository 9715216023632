import { create } from 'zustand';
import type { PartialFeaturesRecord } from '../types';

type FeaturesStore = {
  features: PartialFeaturesRecord;
  highlightedFeatureIndex: number;
  actions: {
    highlight: (index: number) => void;
    addFeatures: (features: PartialFeaturesRecord) => void;
  };
};

const useFeaturesStore = create<FeaturesStore>((set) => ({
  features: {},
  highlightedFeatureIndex: 0,
  actions: {
    highlight: (index: number) => set({ highlightedFeatureIndex: index }),
    addFeatures: (features) => set({ features }),
  },
}));

const useFeaturesList = () => useFeaturesStore((store) => store.features);
const useHighlightedFeatureIndex = () => useFeaturesStore((store) => store.highlightedFeatureIndex);
const useFeaturesActions = () => useFeaturesStore((store) => store.actions);

export { useFeaturesList, useHighlightedFeatureIndex, useFeaturesActions };
