import { Stack } from '@carvertical/ui';
import { SocialLink } from '../SocialLink';
import type { SocialMediaPlatform } from '../../types';

const PLATFORMS: SocialMediaPlatform[] = ['youtube', 'facebook', 'instagram', 'linkedin'];

const SocialLinks = () => (
  <Stack gap={1.5} as="ul" type="horizontal" className="unstyledList">
    {PLATFORMS.map((platform) => (
      <li key={platform}>
        <SocialLink platform={platform} />
      </li>
    ))}
  </Stack>
);

export { SocialLinks };
