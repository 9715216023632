import { z } from 'zod';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';
import { validatePhone } from 'modules/shared/utils';

type ErrorTranslationKey = keyof typeof ERROR_TRANSLATION_KEYS;

const emailSchema = z
  .string()
  .min(1, { message: ERROR_TRANSLATION_KEYS.required })
  .email(ERROR_TRANSLATION_KEYS.email);

const phoneSchema = z.string().optional().refine(validatePhone, ERROR_TRANSLATION_KEYS.phone);

const getNumberSchema = (validator: (value: number) => boolean, message: ErrorTranslationKey) =>
  z
    .number({
      invalid_type_error: ERROR_TRANSLATION_KEYS.required,
    })
    .positive({ message: ERROR_TRANSLATION_KEYS[message] })
    .refine(validator, { message: ERROR_TRANSLATION_KEYS[message] })
    .or(z.literal(''));

export { emailSchema, phoneSchema, getNumberSchema };
