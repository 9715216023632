import { Icon, Stack, Text } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import withContainers from 'hoc/withContainers';
import PrecheckContainer from 'containers/PrecheckContainer';
import { PrecheckState } from 'services/precheckState';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/common/Tooltip';
import { useIsSubscription } from '../hooks';
import styles from './PricingExplanation.module.scss';

type PricingExplanationProps = {
  vehicleName?: string;
  licensePlateNumber?: string;
  reportCount: number;
  tooltipShown?: boolean;
  containers: {
    report: PrecheckContainer;
  };
};

const PricingExplanation = ({
  vehicleName,
  licensePlateNumber,
  reportCount,
  containers: { report },
  tooltipShown = true,
}: PricingExplanationProps) => {
  const { t } = useTranslation(['common', 'business']);
  const isSubscription = useIsSubscription();
  const extraCreditsCount = reportCount - 1;
  const precheckState = PrecheckState.get();
  const isMotorcycle =
    report.bodyType?.value === 'motorcycle' ||
    precheckState?.spec?.bodyType?.value === 'motorcycle';

  const getVehicleData = () => {
    if (vehicleName) {
      const makeValue = precheckState?.spec?.make?.value;
      return isMotorcycle && typeof makeValue === 'string' ? makeValue : vehicleName;
    }

    return licensePlateNumber;
  };

  const renderExplanationLine = (text: React.ReactNode, description?: string) => (
    <Stack crossAxisAlign="center" mainAxisAlign="spaceBetween" type="horizontal" gap={2}>
      <Stack crossAxisAlign="center" type="horizontal" gap={0.5}>
        <Icon className={styles.icon} name="check-circle" size="xs" />

        <Text className="leading-snug" as="span" variant="s">
          {text}
        </Text>
      </Stack>

      {tooltipShown && description && (
        <Tooltip>
          <TooltipTrigger className={styles.tooltipButton} aria-label={t('general.readMoreLabel')}>
            <Icon name="question-mark-round" size="xs" />
          </TooltipTrigger>

          <TooltipContent className={styles.tooltip}>
            <Text variant="s">{description}</Text>
          </TooltipContent>
        </Tooltip>
      )}
    </Stack>
  );

  const renderExplanation = () => {
    if (isSubscription) {
      return renderExplanationLine(
        <>
          <Text as="span" inline variant="s+">
            {t('business:pricing.reportsLabel', { count: reportCount })}
          </Text>
          {` / ${t('general.monthLabel')}`}
        </>,
      );
    }

    if (!vehicleName && !licensePlateNumber) {
      return renderExplanationLine(
        <Trans
          i18nKey="pricing.pricingExplanation.creditsLabel"
          t={t}
          values={{ count: reportCount }}
          components={{ 1: <Text as="span" inline variant="s+" /> }}
        />,
        t('pricing.pricingExplanation.extraCreditsDescription'),
      );
    }

    return (
      <>
        {renderExplanationLine(
          <Trans
            i18nKey="pricing.pricingExplanation.vehicleLabel"
            t={t}
            values={{ vehicleData: getVehicleData() }}
            components={{ 1: <Text as="span" inline variant="s+" /> }}
          />,
          t(`pricing.pricingExplanation.vehicleDescription${licensePlateNumber ? 'Lp' : 'Vin'}`),
        )}

        {!!extraCreditsCount &&
          renderExplanationLine(
            t(
              isMotorcycle
                ? 'pricing.pricingExplanation.extraCreditsMotoLabel'
                : 'pricing.pricingExplanation.extraCreditsLabel',
              { count: extraCreditsCount },
            ),
            t('pricing.pricingExplanation.extraCreditsDescription'),
          )}
      </>
    );
  };

  return (
    <Stack crossAxisAlign="stretch" gap={1.5}>
      {renderExplanation()}
    </Stack>
  );
};

const WrappedPricingExplanation = withContainers({
  report: PrecheckContainer,
})(PricingExplanation);

export { WrappedPricingExplanation as PricingExplanation };
