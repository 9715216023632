import { entries, map } from 'lodash';
import { Component } from 'react';
import { Subscribe } from 'unstated';
import getHocName from '../utils/getHocName';

const withContainers = (containers) => {
  containers = entries(containers);

  const Containers = map(containers, '1');
  const containerNames = map(containers, '0');

  return function withContainers(WrappedComponent) {
    return class WithContainers extends Component {
      static displayName = getHocName('withContainers', WrappedComponent);

      getContainerProps(containersInstances) {
        return containersInstances.reduce(
          (containers, containerInstance, index) => ({
            ...containers,
            [containerNames[index]]: containerInstance,
          }),
          {},
        );
      }

      render() {
        return (
          <Subscribe to={Containers}>
            {(...containerInstances) => (
              <WrappedComponent
                containers={this.getContainerProps(containerInstances)}
                {...this.props}
              />
            )}
          </Subscribe>
        );
      }
    };
  };
};

export default withContainers;
