import { j as n } from "./jsx-runtime.82a9ddf6.js";
import { createContext as p, useMemo as s } from "react";
const i = {
  size: "m",
  variant: "outline"
}, c = p(i), o = ({ value: { size: e, variant: t }, children: r }) => {
  const a = s(() => ({ size: e, variant: t }), [e, t]);
  return /* @__PURE__ */ n.jsx(c.Provider, { value: a, children: r });
};
try {
  o.displayName = "TagGroupProvider", o.__docgenInfo = { description: "", displayName: "TagGroupProvider", props: { value: { defaultValue: null, description: "", name: "value", required: !0, type: { name: "TagGroupContextValue" } } } };
} catch {
}
try {
  Context.displayName = "Context", Context.__docgenInfo = { description: `Context lets components pass information deep down without explicitly
passing props.

Created from {@link createContext}`, displayName: "Context", props: {} };
} catch {
}
export {
  o as T,
  c as a
};
