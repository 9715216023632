import type { Flavor } from 'types/market';

const LANGUAGE: Record<Flavor['locale'], string> = {
  en: 'English',
  lt: 'Lietuvių',
  et: 'Eesti',
  lv: 'Latviešu',
  pl: 'Polski',
  ro: 'Română',
  hu: 'Magyar',
  fr: 'Français',
  uk: 'Українська',
  ru: 'Русский',
  sv: 'Svenska',
  nl: 'Nederlands',
  cs: 'Český',
  hr: 'Hrvatski',
  bg: 'Български',
  sk: 'Slovenský',
  sr: 'Српски',
  fi: 'Suomi',
  es: 'Español',
  sl: 'Slovenski',
  de: 'Deutsch',
  it: 'Italiano',
  da: 'Dansk',
  pt: 'Português',
  el: 'Ελληνικά',
  ar: 'العربية',
};

export { LANGUAGE };
