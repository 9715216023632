import type { SanityImageSource } from '@sanity/image-url/lib/types/types';
import type { DocumentForPath } from './document';

enum PublishStatus {
  private = 'private',
  hidden = 'hidden',
  public = 'public',
}

type Singleton = {
  baseId: string;
  type: DocumentForPath;
  title: string;
};

type CollectionIndex = {
  id: string;
  locale: string;
  pathname: {
    current: string;
  };
  seo?: { title: string };
};

type RouteDocument = {
  type: DocumentForPath;
  id: string;
};

type Pathname = {
  pathname: string;
};

type InternalLink = {
  locale: string;
  _type?: DocumentForPath;
} & Pathname;

type CollectionQueryOptions = {
  /**
   * Which document type(s) this collection contains
   */
  entryDocTypes: DocumentForPath[];

  /**
   * If undefined, collectionIndex will be a single page with all entries.
   */
  entriesPerPage?: number;

  pageNum?: number;

  /**
   * Extra filters to add to the collection query.
   * Will be applied in AND conditions.
   *
   * @example
   * { extraFilters: ['publishedAt < now()', 'isFeatured == true'] }
   * // Leads to an AND filter that includes, among others:
   * // `_type in $entryDocTypes && publishedAt < now() && isFeatured == true`
   *
   * @variation If you want OR behavior, be explicit a about it in a single filter:
   * @example
   * { extraFilters: ['publishedAt > 2023-05-01 || isFeatured == true'] }
   * // Result: `_type in $entryDocTypes && (publishedAt > 2023-05-01 || isFeatured == true)`
   */
  extraFilters?: string[];

  /**
   * What data to fetch for each document in the collection when rendering index pages.
   */
  entryQueryFragment: string;

  /**
   * What data to fetch for the currently applied reference filters.
   * Useful for displaying filter-specific data such as SEO copy below entries, related filters, etc.
   */
  currentFilterQueryFragment?: string;

  /**
   * GROQ order operation to sort the collection.
   *
   * @optional defaults to `order(orderRank, _createdAt desc)`
   *
   * @see Documentation on GROQ ordering: https://www.sanity.io/docs/query-cheat-sheet#b5aec96cf56c
   */
  order?: string;
};

type LinkData = {
  _type: 'link';
  linkType?: 'external' | 'externalNoRel' | 'internal';

  /**
   * Absolute URL to another site or internal section — `url`
   */
  url?: string;

  internalLink?: InternalLink;

  /**
   * Open the link in a new window? — `boolean`
   */
  newWindow?: boolean;

  /**
   * Applicable only to internal link
   */
  utmParameters?: string;

  /**
   * (optional) rel attribute for the link — `string`
   */
  rel?: string;
};

type Image = {
  asset: {
    _ref: SanityImageSource;
    lqip?: string;
  };
  caption?: string;
  alt?: string;
};

export { PublishStatus };
export type {
  Singleton,
  RouteDocument,
  CollectionQueryOptions,
  CollectionIndex,
  LinkData,
  Image,
  InternalLink,
  Pathname,
};
