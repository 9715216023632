import { SEPARATOR } from 'constants/seo';
import { siteName } from 'config';

const formatTitle = (title: string) => `${title} ${SEPARATOR} ${siteName}`;

const buildStructuredData = ({ prop, type }: { type?: string; prop?: string }) => ({
  ...(type && {
    itemScope: '',
    itemtype: `https://schema.org/${type}`,
  }),
  ...(prop && {
    itemProp: prop,
  }),
});

export { formatTitle, buildStructuredData };
