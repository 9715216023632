import cx from 'classnames';

type CardProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const Card = ({ children, className, ...props }: CardProps) => (
  <section {...props} className={cx('rounded-m bg-white p-3', className)}>
    {children}
  </section>
);

export { Card };
export type { CardProps };
