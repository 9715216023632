import { Tag, TagGroup, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { useFeatures } from '../hooks';

type FeatureChecklistProps = {
  featuresSectionRef?: React.RefObject<HTMLDivElement>;
  interactive?: boolean;
};

const FeatureChecklist = ({ featuresSectionRef, interactive = true }: FeatureChecklistProps) => {
  const { t } = useTranslation();
  const { tags, highlightFeature } = useFeatures();

  return (
    <div className="flex flex-col gap-1.5">
      <Text as="p" variant="s+" id="featuresTitle">
        {t('features.informationTitle')}
      </Text>

      {!!tags.length && (
        <TagGroup
          aria-labelledby="featuresTitle"
          listClassName="max-w-60"
          selectionMode={interactive ? 'single' : 'none'}
          onChange={(keys) => {
            if (typeof keys === 'object') {
              Object.values(keys).forEach((key) => highlightFeature(key));

              const featuresSection = featuresSectionRef?.current;
              if (featuresSection) {
                featuresSection.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
              }
            }
          }}
        >
          {tags.map(({ id, textKey }) => (
            <Tag
              key={id}
              id={id}
              size="m"
              className="selected:bg-white selected:text-black selected:outline-white"
            >
              {t(textKey)}
            </Tag>
          ))}
        </TagGroup>
      )}
    </div>
  );
};

export { FeatureChecklist };
