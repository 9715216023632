import { createContext, useContext, useState, useMemo } from 'react';

type HeaderContextProps = {
  height: number;
  setHeight: (height: number) => void;
  pageOffset: number;
  setPageOffset: (offset: number) => void;
  activeMenuGroupId: string | null;
  setActiveMenuGroupId: (submenuId: string | null) => void;
};

const HeaderContext = createContext<HeaderContextProps | undefined>(undefined);

type HeaderProviderProps = {
  children: React.ReactNode;
};

const HeaderProvider = ({ children }: HeaderProviderProps) => {
  const [height, setHeight] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [activeMenuGroupId, setActiveMenuGroupId] = useState<string | null>(null);

  const value = useMemo(
    () => ({
      height,
      setHeight,
      pageOffset,
      setPageOffset,
      activeMenuGroupId,
      setActiveMenuGroupId,
    }),
    [height, pageOffset, activeMenuGroupId],
  );

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};

const useHeader = () => {
  const context = useContext(HeaderContext);

  if (!context) {
    throw new Error('useHeader must be used within a HeaderProvider');
  }

  return context;
};

export { HeaderProvider, useHeader };
export type { HeaderContextProps };
