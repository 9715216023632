import { Container, Heading, Stack, Text } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { isEmpty } from 'lodash';
import { Section } from 'components/landings/common/Section';
import { useFeatures } from '../../hooks';
import { FeaturesCarousel } from './FeaturesCarousel';
import styles from './FeaturesSection.module.scss';

const FeaturesSection = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation(['home', 'pricing']);
  const { features } = useFeatures();

  if (isEmpty(features)) {
    return null;
  }

  return (
    <Section>
      <Container>
        <Stack crossAxisAlign="stretch" gap={6} ref={ref}>
          <Stack gap={2} crossAxisAlign={{ mobileUp: 'flexStart', desktopUp: 'center' }}>
            <Heading as="h2" variant="l">
              {t('checksSection.title')}
            </Heading>

            <Text className={styles.subtitle} variant="l">
              {t('pricing:disclosureSection.subtitle')}
            </Text>
          </Stack>

          <FeaturesCarousel />
        </Stack>
      </Container>
    </Section>
  );
});

export { FeaturesSection };
