import m, { useRef as w, useCallback as h, useState as K, useContext as A, useEffect as V, createContext as Ae, useMemo as Z } from "react";
import { c as Qe } from "./clsx.0d472180.js";
import Ze from "react-dom";
const U = typeof document < "u" ? m.useLayoutEffect : () => {
};
function O(e) {
  const t = w(null);
  return U(() => {
    t.current = e;
  }, [
    e
  ]), h((...r) => {
    const n = t.current;
    return n == null ? void 0 : n(...r);
  }, []);
}
function et(e) {
  let [t, r] = K(e), n = w(null), a = O(() => {
    if (!n.current)
      return;
    let s = n.current.next();
    if (s.done) {
      n.current = null;
      return;
    }
    t === s.value ? a() : r(s.value);
  });
  U(() => {
    n.current && a();
  });
  let l = O((s) => {
    n.current = s(t), a();
  });
  return [
    t,
    l
  ];
}
const ie = {
  prefix: String(Math.round(Math.random() * 1e10)),
  current: 0
}, De = /* @__PURE__ */ m.createContext(ie), tt = /* @__PURE__ */ m.createContext(!1);
let rt = !!(typeof window < "u" && window.document && window.document.createElement), de = /* @__PURE__ */ new WeakMap();
function nt(e = !1) {
  let t = A(De), r = w(null);
  if (r.current === null && !e) {
    var n, a;
    let l = (a = m.__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED) === null || a === void 0 || (n = a.ReactCurrentOwner) === null || n === void 0 ? void 0 : n.current;
    if (l) {
      let s = de.get(l);
      s == null ? de.set(l, {
        id: t.current,
        state: l.memoizedState
      }) : l.memoizedState !== s.state && (t.current = s.id, de.delete(l));
    }
    r.current = ++t.current;
  }
  return r.current;
}
function ot(e) {
  let t = A(De);
  t === ie && !rt && console.warn("When server rendering, you must wrap your application in an <SSRProvider> to ensure consistent ids are generated between the client and server.");
  let r = nt(!!e), n = t === ie && process.env.NODE_ENV === "test" ? "react-aria" : `react-aria${t.prefix}`;
  return e || `${n}-${r}`;
}
function at(e) {
  let t = m.useId(), [r] = K(Ee()), n = r || process.env.NODE_ENV === "test" ? "react-aria" : `react-aria${ie.prefix}`;
  return e || `${n}-${t}`;
}
const it = typeof m.useId == "function" ? at : ot;
function st() {
  return !1;
}
function lt() {
  return !0;
}
function ut(e) {
  return () => {
  };
}
function Ee() {
  return typeof m.useSyncExternalStore == "function" ? m.useSyncExternalStore(ut, st, lt) : A(tt);
}
let ct = !!(typeof window < "u" && window.document && window.document.createElement), se = /* @__PURE__ */ new Map();
function ft(e) {
  let [t, r] = K(e), n = w(null), a = it(t), l = h((s) => {
    n.current = s;
  }, []);
  return ct && se.set(a, l), U(() => {
    let s = a;
    return () => {
      se.delete(s);
    };
  }, [
    a
  ]), V(() => {
    let s = n.current;
    s && (n.current = null, r(s));
  }), a;
}
function dt(e, t) {
  if (e === t)
    return e;
  let r = se.get(e);
  if (r)
    return r(t), t;
  let n = se.get(t);
  return n ? (n(e), e) : t;
}
function ar(e = []) {
  let t = ft(), [r, n] = et(t), a = h(() => {
    n(function* () {
      yield t, yield document.getElementById(t) ? t : void 0;
    });
  }, [
    t,
    n
  ]);
  return U(a, [
    t,
    a,
    ...e
  ]), r;
}
function We(...e) {
  return (...t) => {
    for (let r of e)
      typeof r == "function" && r(...t);
  };
}
const E = (e) => {
  var t;
  return (t = e == null ? void 0 : e.ownerDocument) !== null && t !== void 0 ? t : document;
}, M = (e) => e && "window" in e && e.window === e ? e : E(e).defaultView || window;
function Pe(...e) {
  let t = {
    ...e[0]
  };
  for (let r = 1; r < e.length; r++) {
    let n = e[r];
    for (let a in n) {
      let l = t[a], s = n[a];
      typeof l == "function" && typeof s == "function" && // This is a lot faster than a regex.
      a[0] === "o" && a[1] === "n" && a.charCodeAt(2) >= /* 'A' */
      65 && a.charCodeAt(2) <= /* 'Z' */
      90 ? t[a] = We(l, s) : (a === "className" || a === "UNSAFE_className") && typeof l == "string" && typeof s == "string" ? t[a] = Qe(l, s) : a === "id" && l && s ? t.id = dt(l, s) : t[a] = s !== void 0 ? s : l;
    }
  }
  return t;
}
function pt(...e) {
  return e.length === 1 ? e[0] : (t) => {
    for (let r of e)
      typeof r == "function" ? r(t) : r != null && (r.current = t);
  };
}
const vt = /* @__PURE__ */ new Set([
  "id"
]), $t = /* @__PURE__ */ new Set([
  "aria-label",
  "aria-labelledby",
  "aria-describedby",
  "aria-details"
]), gt = /* @__PURE__ */ new Set([
  "href",
  "hrefLang",
  "target",
  "rel",
  "download",
  "ping",
  "referrerPolicy"
]), bt = /^(data-.*)$/;
function ir(e, t = {}) {
  let { labelable: r, isLink: n, propNames: a } = t, l = {};
  for (const s in e)
    Object.prototype.hasOwnProperty.call(e, s) && (vt.has(s) || r && $t.has(s) || n && gt.has(s) || a != null && a.has(s) || bt.test(s)) && (l[s] = e[s]);
  return l;
}
function N(e) {
  if (yt())
    e.focus({
      preventScroll: !0
    });
  else {
    let t = mt(e);
    e.focus(), ht(t);
  }
}
let te = null;
function yt() {
  if (te == null) {
    te = !1;
    try {
      document.createElement("div").focus({
        get preventScroll() {
          return te = !0, !0;
        }
      });
    } catch {
    }
  }
  return te;
}
function mt(e) {
  let t = e.parentNode, r = [], n = document.scrollingElement || document.documentElement;
  for (; t instanceof HTMLElement && t !== n; )
    (t.offsetHeight < t.scrollHeight || t.offsetWidth < t.scrollWidth) && r.push({
      element: t,
      scrollTop: t.scrollTop,
      scrollLeft: t.scrollLeft
    }), t = t.parentNode;
  return n instanceof HTMLElement && r.push({
    element: n,
    scrollTop: n.scrollTop,
    scrollLeft: n.scrollLeft
  }), r;
}
function ht(e) {
  for (let { element: t, scrollTop: r, scrollLeft: n } of e)
    t.scrollTop = r, t.scrollLeft = n;
}
function fe(e) {
  var t;
  return typeof window > "u" || window.navigator == null ? !1 : ((t = window.navigator.userAgentData) === null || t === void 0 ? void 0 : t.brands.some((r) => e.test(r.brand))) || e.test(window.navigator.userAgent);
}
function we(e) {
  var t;
  return typeof window < "u" && window.navigator != null ? e.test(((t = window.navigator.userAgentData) === null || t === void 0 ? void 0 : t.platform) || window.navigator.platform) : !1;
}
function _() {
  return we(/^Mac/i);
}
function Tt() {
  return we(/^iPhone/i);
}
function Ne() {
  return we(/^iPad/i) || // iPadOS 13 lies and says it's a Mac, but we can distinguish by detecting touch support.
  _() && navigator.maxTouchPoints > 1;
}
function Se() {
  return Tt() || Ne();
}
function sr() {
  return _() || Se();
}
function Et() {
  return fe(/AppleWebKit/i) && !Pt();
}
function Pt() {
  return fe(/Chrome/i);
}
function Ve() {
  return fe(/Android/i);
}
function wt() {
  return fe(/Firefox/i);
}
const St = /* @__PURE__ */ Ae({
  isNative: !0,
  open: Mt,
  useHref: (e) => e
});
function xt() {
  return A(St);
}
function lr(e, t) {
  let r = e.getAttribute("target");
  return (!r || r === "_self") && e.origin === location.origin && !e.hasAttribute("download") && !t.metaKey && // open in new tab (mac)
  !t.ctrlKey && // open in new tab (windows)
  !t.altKey && // download
  !t.shiftKey;
}
function R(e, t, r = !0) {
  var n, a;
  let { metaKey: l, ctrlKey: s, altKey: f, shiftKey: d } = t;
  wt() && (!((a = window.event) === null || a === void 0 || (n = a.type) === null || n === void 0) && n.startsWith("key")) && e.target === "_blank" && (_() ? l = !0 : s = !0);
  let v = Et() && _() && !Ne() ? new KeyboardEvent("keydown", {
    keyIdentifier: "Enter",
    metaKey: l,
    ctrlKey: s,
    altKey: f,
    shiftKey: d
  }) : new MouseEvent("click", {
    metaKey: l,
    ctrlKey: s,
    altKey: f,
    shiftKey: d,
    bubbles: !0,
    cancelable: !0
  });
  R.isOpening = r, N(e), e.dispatchEvent(v), R.isOpening = !1;
}
R.isOpening = !1;
function Lt(e, t) {
  if (e instanceof HTMLAnchorElement)
    t(e);
  else if (e.hasAttribute("data-href")) {
    let r = document.createElement("a");
    r.href = e.getAttribute("data-href"), e.hasAttribute("data-target") && (r.target = e.getAttribute("data-target")), e.hasAttribute("data-rel") && (r.rel = e.getAttribute("data-rel")), e.hasAttribute("data-download") && (r.download = e.getAttribute("data-download")), e.hasAttribute("data-ping") && (r.ping = e.getAttribute("data-ping")), e.hasAttribute("data-referrer-policy") && (r.referrerPolicy = e.getAttribute("data-referrer-policy")), e.appendChild(r), t(r), e.removeChild(r);
  }
}
function Mt(e, t) {
  Lt(e, (r) => R(r, t));
}
function ur(e) {
  return {
    "data-href": e.href,
    "data-target": e.target,
    "data-rel": e.rel,
    "data-download": e.download,
    "data-ping": e.ping,
    "data-referrer-policy": e.referrerPolicy
  };
}
function cr(e) {
  let t = xt();
  return {
    href: e != null && e.href ? t.useHref(e == null ? void 0 : e.href) : void 0,
    target: e == null ? void 0 : e.target,
    rel: e == null ? void 0 : e.rel,
    download: e == null ? void 0 : e.download,
    ping: e == null ? void 0 : e.ping,
    referrerPolicy: e == null ? void 0 : e.referrerPolicy
  };
}
let q = /* @__PURE__ */ new Map(), ge = /* @__PURE__ */ new Set();
function Me() {
  if (typeof window > "u")
    return;
  function e(n) {
    return "propertyName" in n;
  }
  let t = (n) => {
    if (!e(n) || !n.target)
      return;
    let a = q.get(n.target);
    a || (a = /* @__PURE__ */ new Set(), q.set(n.target, a), n.target.addEventListener("transitioncancel", r, {
      once: !0
    })), a.add(n.propertyName);
  }, r = (n) => {
    if (!e(n) || !n.target)
      return;
    let a = q.get(n.target);
    if (a && (a.delete(n.propertyName), a.size === 0 && (n.target.removeEventListener("transitioncancel", r), q.delete(n.target)), q.size === 0)) {
      for (let l of ge)
        l();
      ge.clear();
    }
  };
  document.body.addEventListener("transitionrun", t), document.body.addEventListener("transitionend", r);
}
typeof document < "u" && (document.readyState !== "loading" ? Me() : document.addEventListener("DOMContentLoaded", Me));
function Ge(e) {
  requestAnimationFrame(() => {
    q.size === 0 ? e() : ge.add(e);
  });
}
function Ct() {
  let e = w(/* @__PURE__ */ new Map()), t = h((a, l, s, f) => {
    let d = f != null && f.once ? (...v) => {
      e.current.delete(s), s(...v);
    } : s;
    e.current.set(s, {
      type: l,
      eventTarget: a,
      fn: d,
      options: f
    }), a.addEventListener(l, s, f);
  }, []), r = h((a, l, s, f) => {
    var d;
    let v = ((d = e.current.get(s)) === null || d === void 0 ? void 0 : d.fn) || s;
    a.removeEventListener(l, v, f), e.current.delete(s);
  }, []), n = h(() => {
    e.current.forEach((a, l) => {
      r(a.eventTarget, a.type, l, a.options);
    });
  }, [
    r
  ]);
  return V(() => n, [
    n
  ]), {
    addGlobalListener: t,
    removeGlobalListener: r,
    removeAllGlobalListeners: n
  };
}
function Ft(e) {
  const t = w(null);
  return Z(() => ({
    get current() {
      return t.current;
    },
    set current(r) {
      t.current = r, typeof e == "function" ? e(r) : e && (e.current = r);
    }
  }), [
    e
  ]);
}
function kt(e, t) {
  U(() => {
    if (e && e.ref && t)
      return e.ref.current = t.current, () => {
        e.ref && (e.ref.current = null);
      };
  });
}
function be(e) {
  return e.mozInputSource === 0 && e.isTrusted ? !0 : Ve() && e.pointerType ? e.type === "click" && e.buttons === 1 : e.detail === 0 && !e.pointerType;
}
function Kt(e) {
  return !Ve() && e.width === 0 && e.height === 0 || e.width === 1 && e.height === 1 && e.pressure === 0 && e.detail === 0 && e.pointerType === "mouse";
}
let Y = "default", ye = "", ae = /* @__PURE__ */ new WeakMap();
function Ce(e) {
  if (Se()) {
    if (Y === "default") {
      const t = E(e);
      ye = t.documentElement.style.webkitUserSelect, t.documentElement.style.webkitUserSelect = "none";
    }
    Y = "disabled";
  } else
    (e instanceof HTMLElement || e instanceof SVGElement) && (ae.set(e, e.style.userSelect), e.style.userSelect = "none");
}
function re(e) {
  if (Se()) {
    if (Y !== "disabled")
      return;
    Y = "restoring", setTimeout(() => {
      Ge(() => {
        if (Y === "restoring") {
          const t = E(e);
          t.documentElement.style.webkitUserSelect === "none" && (t.documentElement.style.webkitUserSelect = ye || ""), ye = "", Y = "default";
        }
      });
    }, 300);
  } else if ((e instanceof HTMLElement || e instanceof SVGElement) && e && ae.has(e)) {
    let t = ae.get(e);
    e.style.userSelect === "none" && (e.style.userSelect = t), e.getAttribute("style") === "" && e.removeAttribute("style"), ae.delete(e);
  }
}
const Ue = m.createContext({
  register: () => {
  }
});
Ue.displayName = "PressResponderContext";
function Ht(e, t) {
  return t.get ? t.get.call(e) : t.value;
}
function Be(e, t, r) {
  if (!t.has(e))
    throw new TypeError("attempted to " + r + " private field on non-instance");
  return t.get(e);
}
function Ot(e, t) {
  var r = Be(e, t, "get");
  return Ht(e, r);
}
function It(e, t) {
  if (t.has(e))
    throw new TypeError("Cannot initialize the same private elements twice on an object");
}
function At(e, t, r) {
  It(e, t), t.set(e, r);
}
function Dt(e, t, r) {
  if (t.set)
    t.set.call(e, r);
  else {
    if (!t.writable)
      throw new TypeError("attempted to set read only private field");
    t.value = r;
  }
}
function Fe(e, t, r) {
  var n = Be(e, t, "set");
  return Dt(e, n, r), r;
}
function Wt(e) {
  let t = A(Ue);
  if (t) {
    let { register: r, ...n } = t;
    e = Pe(n, e), r();
  }
  return kt(t, e.ref), e;
}
var ne = /* @__PURE__ */ new WeakMap();
class oe {
  continuePropagation() {
    Fe(this, ne, !1);
  }
  get shouldStopPropagation() {
    return Ot(this, ne);
  }
  constructor(t, r, n) {
    At(this, ne, {
      writable: !0,
      value: void 0
    }), Fe(this, ne, !0), this.type = t, this.pointerType = r, this.target = n.currentTarget, this.shiftKey = n.shiftKey, this.metaKey = n.metaKey, this.ctrlKey = n.ctrlKey, this.altKey = n.altKey;
  }
}
const ke = Symbol("linkClicked");
function fr(e) {
  let {
    onPress: t,
    onPressChange: r,
    onPressStart: n,
    onPressEnd: a,
    onPressUp: l,
    isDisabled: s,
    isPressed: f,
    preventFocusOnPress: d,
    shouldCancelOnPointerExit: v,
    allowTextSelectionOnPress: g,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref: H,
    ...L
  } = Wt(e), [$, C] = K(!1), S = w({
    isPressed: !1,
    ignoreEmulatedMouseEvents: !1,
    ignoreClickAfterPress: !1,
    didFirePressStart: !1,
    isTriggeringEvent: !1,
    activePointerId: null,
    target: null,
    isOverTarget: !1,
    pointerType: null
  }), { addGlobalListener: D, removeAllGlobalListeners: B } = Ct(), F = O((o, p) => {
    let x = S.current;
    if (s || x.didFirePressStart)
      return !1;
    let c = !0;
    if (x.isTriggeringEvent = !0, n) {
      let b = new oe("pressstart", p, o);
      n(b), c = b.shouldStopPropagation;
    }
    return r && r(!0), x.isTriggeringEvent = !1, x.didFirePressStart = !0, C(!0), c;
  }), T = O((o, p, x = !0) => {
    let c = S.current;
    if (!c.didFirePressStart)
      return !1;
    c.ignoreClickAfterPress = !0, c.didFirePressStart = !1, c.isTriggeringEvent = !0;
    let b = !0;
    if (a) {
      let i = new oe("pressend", p, o);
      a(i), b = i.shouldStopPropagation;
    }
    if (r && r(!1), C(!1), t && x && !s) {
      let i = new oe("press", p, o);
      t(i), b && (b = i.shouldStopPropagation);
    }
    return c.isTriggeringEvent = !1, b;
  }), j = O((o, p) => {
    let x = S.current;
    if (s)
      return !1;
    if (l) {
      x.isTriggeringEvent = !0;
      let c = new oe("pressup", p, o);
      return l(c), x.isTriggeringEvent = !1, c.shouldStopPropagation;
    }
    return !0;
  }), W = O((o) => {
    let p = S.current;
    p.isPressed && p.target && (p.isOverTarget && p.pointerType != null && T(k(p.target, o), p.pointerType, !1), p.isPressed = !1, p.isOverTarget = !1, p.activePointerId = null, p.pointerType = null, B(), g || re(p.target));
  }), ee = O((o) => {
    v && W(o);
  }), Je = Z(() => {
    let o = S.current, p = {
      onKeyDown(c) {
        if (pe(c.nativeEvent, c.currentTarget) && c.currentTarget.contains(c.target)) {
          var b;
          He(c.target, c.key) && c.preventDefault();
          let i = !0;
          if (!o.isPressed && !c.repeat) {
            o.target = c.currentTarget, o.isPressed = !0, i = F(c, "keyboard");
            let u = c.currentTarget, y = (z) => {
              pe(z, u) && !z.repeat && u.contains(z.target) && o.target && j(k(o.target, z), "keyboard");
            };
            D(E(c.currentTarget), "keyup", We(y, x), !0);
          }
          i && c.stopPropagation(), c.metaKey && _() && ((b = o.metaKeyEvents) === null || b === void 0 || b.set(c.key, c.nativeEvent));
        } else
          c.key === "Meta" && (o.metaKeyEvents = /* @__PURE__ */ new Map());
      },
      onClick(c) {
        if (!(c && !c.currentTarget.contains(c.target)) && c && c.button === 0 && !o.isTriggeringEvent && !R.isOpening) {
          let b = !0;
          if (s && c.preventDefault(), !o.ignoreClickAfterPress && !o.ignoreEmulatedMouseEvents && !o.isPressed && (o.pointerType === "virtual" || be(c.nativeEvent))) {
            !s && !d && N(c.currentTarget);
            let i = F(c, "virtual"), u = j(c, "virtual"), y = T(c, "virtual");
            b = i && u && y;
          }
          o.ignoreEmulatedMouseEvents = !1, o.ignoreClickAfterPress = !1, b && c.stopPropagation();
        }
      }
    }, x = (c) => {
      var b;
      if (o.isPressed && o.target && pe(c, o.target)) {
        var i;
        He(c.target, c.key) && c.preventDefault();
        let y = c.target;
        T(k(o.target, c), "keyboard", o.target.contains(y)), B(), c.key !== "Enter" && xe(o.target) && o.target.contains(y) && !c[ke] && (c[ke] = !0, R(o.target, c, !1)), o.isPressed = !1, (i = o.metaKeyEvents) === null || i === void 0 || i.delete(c.key);
      } else if (c.key === "Meta" && (!((b = o.metaKeyEvents) === null || b === void 0) && b.size)) {
        var u;
        let y = o.metaKeyEvents;
        o.metaKeyEvents = void 0;
        for (let z of y.values())
          (u = o.target) === null || u === void 0 || u.dispatchEvent(new KeyboardEvent("keyup", z));
      }
    };
    if (typeof PointerEvent < "u") {
      p.onPointerDown = (u) => {
        if (u.button !== 0 || !u.currentTarget.contains(u.target))
          return;
        if (Kt(u.nativeEvent)) {
          o.pointerType = "virtual";
          return;
        }
        ve(u.currentTarget) && u.preventDefault(), o.pointerType = u.pointerType;
        let y = !0;
        o.isPressed || (o.isPressed = !0, o.isOverTarget = !0, o.activePointerId = u.pointerId, o.target = u.currentTarget, !s && !d && N(u.currentTarget), g || Ce(o.target), y = F(u, o.pointerType), D(E(u.currentTarget), "pointermove", c, !1), D(E(u.currentTarget), "pointerup", b, !1), D(E(u.currentTarget), "pointercancel", i, !1)), y && u.stopPropagation();
      }, p.onMouseDown = (u) => {
        u.currentTarget.contains(u.target) && u.button === 0 && (ve(u.currentTarget) && u.preventDefault(), u.stopPropagation());
      }, p.onPointerUp = (u) => {
        !u.currentTarget.contains(u.target) || o.pointerType === "virtual" || u.button === 0 && X(u, u.currentTarget) && j(u, o.pointerType || u.pointerType);
      };
      let c = (u) => {
        u.pointerId === o.activePointerId && (o.target && X(u, o.target) ? !o.isOverTarget && o.pointerType != null && (o.isOverTarget = !0, F(k(o.target, u), o.pointerType)) : o.target && o.isOverTarget && o.pointerType != null && (o.isOverTarget = !1, T(k(o.target, u), o.pointerType, !1), ee(u)));
      }, b = (u) => {
        u.pointerId === o.activePointerId && o.isPressed && u.button === 0 && o.target && (X(u, o.target) && o.pointerType != null ? T(k(o.target, u), o.pointerType) : o.isOverTarget && o.pointerType != null && T(k(o.target, u), o.pointerType, !1), o.isPressed = !1, o.isOverTarget = !1, o.activePointerId = null, o.pointerType = null, B(), g || re(o.target));
      }, i = (u) => {
        W(u);
      };
      p.onDragStart = (u) => {
        u.currentTarget.contains(u.target) && W(u);
      };
    } else {
      p.onMouseDown = (i) => {
        if (i.button !== 0 || !i.currentTarget.contains(i.target))
          return;
        if (ve(i.currentTarget) && i.preventDefault(), o.ignoreEmulatedMouseEvents) {
          i.stopPropagation();
          return;
        }
        o.isPressed = !0, o.isOverTarget = !0, o.target = i.currentTarget, o.pointerType = be(i.nativeEvent) ? "virtual" : "mouse", !s && !d && N(i.currentTarget), F(i, o.pointerType) && i.stopPropagation(), D(E(i.currentTarget), "mouseup", c, !1);
      }, p.onMouseEnter = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        let u = !0;
        o.isPressed && !o.ignoreEmulatedMouseEvents && o.pointerType != null && (o.isOverTarget = !0, u = F(i, o.pointerType)), u && i.stopPropagation();
      }, p.onMouseLeave = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        let u = !0;
        o.isPressed && !o.ignoreEmulatedMouseEvents && o.pointerType != null && (o.isOverTarget = !1, u = T(i, o.pointerType, !1), ee(i)), u && i.stopPropagation();
      }, p.onMouseUp = (i) => {
        i.currentTarget.contains(i.target) && !o.ignoreEmulatedMouseEvents && i.button === 0 && j(i, o.pointerType || "mouse");
      };
      let c = (i) => {
        if (i.button === 0) {
          if (o.isPressed = !1, B(), o.ignoreEmulatedMouseEvents) {
            o.ignoreEmulatedMouseEvents = !1;
            return;
          }
          o.target && X(i, o.target) && o.pointerType != null ? T(k(o.target, i), o.pointerType) : o.target && o.isOverTarget && o.pointerType != null && T(k(o.target, i), o.pointerType, !1), o.isOverTarget = !1;
        }
      };
      p.onTouchStart = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        let u = Nt(i.nativeEvent);
        if (!u)
          return;
        o.activePointerId = u.identifier, o.ignoreEmulatedMouseEvents = !0, o.isOverTarget = !0, o.isPressed = !0, o.target = i.currentTarget, o.pointerType = "touch", !s && !d && N(i.currentTarget), g || Ce(o.target), F(i, o.pointerType) && i.stopPropagation(), D(M(i.currentTarget), "scroll", b, !0);
      }, p.onTouchMove = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        if (!o.isPressed) {
          i.stopPropagation();
          return;
        }
        let u = Ke(i.nativeEvent, o.activePointerId), y = !0;
        u && X(u, i.currentTarget) ? !o.isOverTarget && o.pointerType != null && (o.isOverTarget = !0, y = F(i, o.pointerType)) : o.isOverTarget && o.pointerType != null && (o.isOverTarget = !1, y = T(i, o.pointerType, !1), ee(i)), y && i.stopPropagation();
      }, p.onTouchEnd = (i) => {
        if (!i.currentTarget.contains(i.target))
          return;
        if (!o.isPressed) {
          i.stopPropagation();
          return;
        }
        let u = Ke(i.nativeEvent, o.activePointerId), y = !0;
        u && X(u, i.currentTarget) && o.pointerType != null ? (j(i, o.pointerType), y = T(i, o.pointerType)) : o.isOverTarget && o.pointerType != null && (y = T(i, o.pointerType, !1)), y && i.stopPropagation(), o.isPressed = !1, o.activePointerId = null, o.isOverTarget = !1, o.ignoreEmulatedMouseEvents = !0, o.target && !g && re(o.target), B();
      }, p.onTouchCancel = (i) => {
        i.currentTarget.contains(i.target) && (i.stopPropagation(), o.isPressed && W(i));
      };
      let b = (i) => {
        o.isPressed && i.target.contains(o.target) && W({
          currentTarget: o.target,
          shiftKey: !1,
          ctrlKey: !1,
          metaKey: !1,
          altKey: !1
        });
      };
      p.onDragStart = (i) => {
        i.currentTarget.contains(i.target) && W(i);
      };
    }
    return p;
  }, [
    D,
    s,
    d,
    B,
    g,
    W,
    ee,
    T,
    F,
    j
  ]);
  return V(() => () => {
    var o;
    g || re((o = S.current.target) !== null && o !== void 0 ? o : void 0);
  }, [
    g
  ]), {
    isPressed: f || $,
    pressProps: Pe(L, Je)
  };
}
function xe(e) {
  return e.tagName === "A" && e.hasAttribute("href");
}
function pe(e, t) {
  const { key: r, code: n } = e, a = t, l = a.getAttribute("role");
  return (r === "Enter" || r === " " || r === "Spacebar" || n === "Space") && !(a instanceof M(a).HTMLInputElement && !je(a, r) || a instanceof M(a).HTMLTextAreaElement || a.isContentEditable) && // Links should only trigger with Enter key
  !((l === "link" || !l && xe(a)) && r !== "Enter");
}
function Nt(e) {
  const { targetTouches: t } = e;
  return t.length > 0 ? t[0] : null;
}
function Ke(e, t) {
  const r = e.changedTouches;
  for (let n = 0; n < r.length; n++) {
    const a = r[n];
    if (a.identifier === t)
      return a;
  }
  return null;
}
function k(e, t) {
  return {
    currentTarget: e,
    shiftKey: t.shiftKey,
    ctrlKey: t.ctrlKey,
    metaKey: t.metaKey,
    altKey: t.altKey
  };
}
function Vt(e) {
  let t = 0, r = 0;
  return e.width !== void 0 ? t = e.width / 2 : e.radiusX !== void 0 && (t = e.radiusX), e.height !== void 0 ? r = e.height / 2 : e.radiusY !== void 0 && (r = e.radiusY), {
    top: e.clientY - r,
    right: e.clientX + t,
    bottom: e.clientY + r,
    left: e.clientX - t
  };
}
function Gt(e, t) {
  return !(e.left > t.right || t.left > e.right || e.top > t.bottom || t.top > e.bottom);
}
function X(e, t) {
  let r = t.getBoundingClientRect(), n = Vt(e);
  return Gt(r, n);
}
function ve(e) {
  return !(e instanceof HTMLElement) || !e.hasAttribute("draggable");
}
function He(e, t) {
  return e instanceof HTMLInputElement ? !je(e, t) : e instanceof HTMLButtonElement ? e.type !== "submit" && e.type !== "reset" : !xe(e);
}
const Ut = /* @__PURE__ */ new Set([
  "checkbox",
  "radio",
  "range",
  "color",
  "file",
  "image",
  "button",
  "submit",
  "reset"
]);
function je(e, t) {
  return e.type === "checkbox" || e.type === "radio" ? t === " " : Ut.has(e.type);
}
class Bt {
  isDefaultPrevented() {
    return this.nativeEvent.defaultPrevented;
  }
  preventDefault() {
    this.defaultPrevented = !0, this.nativeEvent.preventDefault();
  }
  stopPropagation() {
    this.nativeEvent.stopPropagation(), this.isPropagationStopped = () => !0;
  }
  isPropagationStopped() {
    return !1;
  }
  persist() {
  }
  constructor(t, r) {
    this.nativeEvent = r, this.target = r.target, this.currentTarget = r.currentTarget, this.relatedTarget = r.relatedTarget, this.bubbles = r.bubbles, this.cancelable = r.cancelable, this.defaultPrevented = r.defaultPrevented, this.eventPhase = r.eventPhase, this.isTrusted = r.isTrusted, this.timeStamp = r.timeStamp, this.type = t;
  }
}
function ze(e) {
  let t = w({
    isFocused: !1,
    observer: null
  });
  U(() => {
    const n = t.current;
    return () => {
      n.observer && (n.observer.disconnect(), n.observer = null);
    };
  }, []);
  let r = O((n) => {
    e == null || e(n);
  });
  return h((n) => {
    if (n.target instanceof HTMLButtonElement || n.target instanceof HTMLInputElement || n.target instanceof HTMLTextAreaElement || n.target instanceof HTMLSelectElement) {
      t.current.isFocused = !0;
      let a = n.target, l = (s) => {
        t.current.isFocused = !1, a.disabled && r(new Bt("blur", s)), t.current.observer && (t.current.observer.disconnect(), t.current.observer = null);
      };
      a.addEventListener("focusout", l, {
        once: !0
      }), t.current.observer = new MutationObserver(() => {
        if (t.current.isFocused && a.disabled) {
          var s;
          (s = t.current.observer) === null || s === void 0 || s.disconnect();
          let f = a === document.activeElement ? null : document.activeElement;
          a.dispatchEvent(new FocusEvent("blur", {
            relatedTarget: f
          })), a.dispatchEvent(new FocusEvent("focusout", {
            bubbles: !0,
            relatedTarget: f
          }));
        }
      }), t.current.observer.observe(a, {
        attributes: !0,
        attributeFilter: [
          "disabled"
        ]
      });
    }
  }, [
    r
  ]);
}
function jt(e) {
  let { isDisabled: t, onFocus: r, onBlur: n, onFocusChange: a } = e;
  const l = h((d) => {
    if (d.target === d.currentTarget)
      return n && n(d), a && a(!1), !0;
  }, [
    n,
    a
  ]), s = ze(l), f = h((d) => {
    const v = E(d.target);
    d.target === d.currentTarget && v.activeElement === d.target && (r && r(d), a && a(!0), s(d));
  }, [
    a,
    r,
    s
  ]);
  return {
    focusProps: {
      onFocus: !t && (r || a || n) ? f : void 0,
      onBlur: !t && (n || a) ? l : void 0
    }
  };
}
let I = null, Q = /* @__PURE__ */ new Set(), J = /* @__PURE__ */ new Map(), G = !1, me = !1;
const zt = {
  Tab: !0,
  Escape: !0
};
function Le(e, t) {
  for (let r of Q)
    r(e, t);
}
function Xt(e) {
  return !(e.metaKey || !_() && e.altKey || e.ctrlKey || e.key === "Control" || e.key === "Shift" || e.key === "Meta");
}
function le(e) {
  G = !0, Xt(e) && (I = "keyboard", Le("keyboard", e));
}
function P(e) {
  I = "pointer", (e.type === "mousedown" || e.type === "pointerdown") && (G = !0, Le("pointer", e));
}
function Xe(e) {
  be(e) && (G = !0, I = "virtual");
}
function qe(e) {
  e.target === window || e.target === document || (!G && !me && (I = "virtual", Le("virtual", e)), G = !1, me = !1);
}
function Ye() {
  G = !1, me = !0;
}
function ue(e) {
  if (typeof window > "u" || J.get(M(e)))
    return;
  const t = M(e), r = E(e);
  let n = t.HTMLElement.prototype.focus;
  t.HTMLElement.prototype.focus = function() {
    G = !0, n.apply(this, arguments);
  }, r.addEventListener("keydown", le, !0), r.addEventListener("keyup", le, !0), r.addEventListener("click", Xe, !0), t.addEventListener("focus", qe, !0), t.addEventListener("blur", Ye, !1), typeof PointerEvent < "u" ? (r.addEventListener("pointerdown", P, !0), r.addEventListener("pointermove", P, !0), r.addEventListener("pointerup", P, !0)) : (r.addEventListener("mousedown", P, !0), r.addEventListener("mousemove", P, !0), r.addEventListener("mouseup", P, !0)), t.addEventListener("beforeunload", () => {
    _e(e);
  }, {
    once: !0
  }), J.set(t, {
    focus: n
  });
}
const _e = (e, t) => {
  const r = M(e), n = E(e);
  t && n.removeEventListener("DOMContentLoaded", t), J.has(r) && (r.HTMLElement.prototype.focus = J.get(r).focus, n.removeEventListener("keydown", le, !0), n.removeEventListener("keyup", le, !0), n.removeEventListener("click", Xe, !0), r.removeEventListener("focus", qe, !0), r.removeEventListener("blur", Ye, !1), typeof PointerEvent < "u" ? (n.removeEventListener("pointerdown", P, !0), n.removeEventListener("pointermove", P, !0), n.removeEventListener("pointerup", P, !0)) : (n.removeEventListener("mousedown", P, !0), n.removeEventListener("mousemove", P, !0), n.removeEventListener("mouseup", P, !0)), J.delete(r));
};
function qt(e) {
  const t = E(e);
  let r;
  return t.readyState !== "loading" ? ue(e) : (r = () => {
    ue(e);
  }, t.addEventListener("DOMContentLoaded", r)), () => _e(e, r);
}
typeof document < "u" && qt();
function Re() {
  return I !== "pointer";
}
function Yt() {
  return I;
}
function dr() {
  ue();
  let [e, t] = K(I);
  return V(() => {
    let r = () => {
      t(I);
    };
    return Q.add(r), () => {
      Q.delete(r);
    };
  }, []), Ee() ? null : e;
}
const _t = /* @__PURE__ */ new Set([
  "checkbox",
  "radio",
  "range",
  "color",
  "file",
  "image",
  "button",
  "submit",
  "reset"
]);
function Rt(e, t, r) {
  var n;
  const a = typeof window < "u" ? M(r == null ? void 0 : r.target).HTMLInputElement : HTMLInputElement, l = typeof window < "u" ? M(r == null ? void 0 : r.target).HTMLTextAreaElement : HTMLTextAreaElement, s = typeof window < "u" ? M(r == null ? void 0 : r.target).HTMLElement : HTMLElement, f = typeof window < "u" ? M(r == null ? void 0 : r.target).KeyboardEvent : KeyboardEvent;
  return e = e || (r == null ? void 0 : r.target) instanceof a && !_t.has(r == null || (n = r.target) === null || n === void 0 ? void 0 : n.type) || (r == null ? void 0 : r.target) instanceof l || (r == null ? void 0 : r.target) instanceof s && (r == null ? void 0 : r.target.isContentEditable), !(e && t === "keyboard" && r instanceof f && !zt[r.key]);
}
function Jt(e, t, r) {
  ue(), V(() => {
    let n = (a, l) => {
      Rt(!!(r != null && r.isTextInput), a, l) && e(Re());
    };
    return Q.add(n), () => {
      Q.delete(n);
    };
  }, t);
}
function Qt(e) {
  let { isDisabled: t, onBlurWithin: r, onFocusWithin: n, onFocusWithinChange: a } = e, l = w({
    isFocusWithin: !1
  }), s = h((v) => {
    l.current.isFocusWithin && !v.currentTarget.contains(v.relatedTarget) && (l.current.isFocusWithin = !1, r && r(v), a && a(!1));
  }, [
    r,
    a,
    l
  ]), f = ze(s), d = h((v) => {
    !l.current.isFocusWithin && document.activeElement === v.target && (n && n(v), a && a(!0), l.current.isFocusWithin = !0, f(v));
  }, [
    n,
    a,
    f
  ]);
  return t ? {
    focusWithinProps: {
      // These should not have been null, that would conflict in mergeProps
      onFocus: void 0,
      onBlur: void 0
    }
  } : {
    focusWithinProps: {
      onFocus: d,
      onBlur: s
    }
  };
}
let ce = !1, $e = 0;
function he() {
  ce = !0, setTimeout(() => {
    ce = !1;
  }, 50);
}
function Oe(e) {
  e.pointerType === "touch" && he();
}
function Zt() {
  if (!(typeof document > "u"))
    return typeof PointerEvent < "u" ? document.addEventListener("pointerup", Oe) : document.addEventListener("touchend", he), $e++, () => {
      $e--, !($e > 0) && (typeof PointerEvent < "u" ? document.removeEventListener("pointerup", Oe) : document.removeEventListener("touchend", he));
    };
}
function pr(e) {
  let { onHoverStart: t, onHoverChange: r, onHoverEnd: n, isDisabled: a } = e, [l, s] = K(!1), f = w({
    isHovered: !1,
    ignoreEmulatedMouseEvents: !1,
    pointerType: "",
    target: null
  }).current;
  V(Zt, []);
  let { hoverProps: d, triggerHoverEnd: v } = Z(() => {
    let g = ($, C) => {
      if (f.pointerType = C, a || C === "touch" || f.isHovered || !$.currentTarget.contains($.target))
        return;
      f.isHovered = !0;
      let S = $.currentTarget;
      f.target = S, t && t({
        type: "hoverstart",
        target: S,
        pointerType: C
      }), r && r(!0), s(!0);
    }, H = ($, C) => {
      if (f.pointerType = "", f.target = null, C === "touch" || !f.isHovered)
        return;
      f.isHovered = !1;
      let S = $.currentTarget;
      n && n({
        type: "hoverend",
        target: S,
        pointerType: C
      }), r && r(!1), s(!1);
    }, L = {};
    return typeof PointerEvent < "u" ? (L.onPointerEnter = ($) => {
      ce && $.pointerType === "mouse" || g($, $.pointerType);
    }, L.onPointerLeave = ($) => {
      !a && $.currentTarget.contains($.target) && H($, $.pointerType);
    }) : (L.onTouchStart = () => {
      f.ignoreEmulatedMouseEvents = !0;
    }, L.onMouseEnter = ($) => {
      !f.ignoreEmulatedMouseEvents && !ce && g($, "mouse"), f.ignoreEmulatedMouseEvents = !1;
    }, L.onMouseLeave = ($) => {
      !a && $.currentTarget.contains($.target) && H($, "mouse");
    }), {
      hoverProps: L,
      triggerHoverEnd: H
    };
  }, [
    t,
    r,
    n,
    a,
    f
  ]);
  return V(() => {
    a && v({
      currentTarget: f.target
    }, f.pointerType);
  }, [
    a
  ]), {
    hoverProps: d,
    isHovered: l
  };
}
function vr(e) {
  const t = E(e);
  if (Yt() === "virtual") {
    let r = t.activeElement;
    Ge(() => {
      t.activeElement === r && e.isConnected && N(e);
    });
  } else
    N(e);
}
function $r(e = {}) {
  let { autoFocus: t = !1, isTextInput: r, within: n } = e, a = w({
    isFocused: !1,
    isFocusVisible: t || Re()
  }), [l, s] = K(!1), [f, d] = K(() => a.current.isFocused && a.current.isFocusVisible), v = h(() => d(a.current.isFocused && a.current.isFocusVisible), []), g = h(($) => {
    a.current.isFocused = $, s($), v();
  }, [
    v
  ]);
  Jt(($) => {
    a.current.isFocusVisible = $, v();
  }, [], {
    isTextInput: r
  });
  let { focusProps: H } = jt({
    isDisabled: n,
    onFocusChange: g
  }), { focusWithinProps: L } = Qt({
    isDisabled: !n,
    onFocusWithinChange: g
  });
  return {
    isFocused: l,
    isFocusVisible: f,
    focusProps: n ? L : H
  };
}
const Ie = Symbol("default");
function gr({ values: e, children: t }) {
  for (let [r, n] of e)
    t = /* @__PURE__ */ m.createElement(r.Provider, {
      value: n
    }, t);
  return t;
}
function br(e) {
  let { className: t, style: r, children: n, defaultClassName: a, defaultChildren: l, defaultStyle: s, values: f } = e;
  return Z(() => {
    let d, v, g;
    return typeof t == "function" ? d = t({
      ...f,
      defaultClassName: a
    }) : d = t, typeof r == "function" ? v = r({
      ...f,
      defaultStyle: s || {}
    }) : v = r, typeof n == "function" ? g = n({
      ...f,
      defaultChildren: l
    }) : n == null ? g = l : g = n, {
      className: d ?? a,
      style: v || s ? {
        ...s,
        ...v
      } : void 0,
      children: g ?? l,
      "data-rac": ""
    };
  }, [
    t,
    r,
    n,
    a,
    l,
    s,
    f
  ]);
}
function er(e, t) {
  let r = A(e);
  if (t === null)
    return null;
  if (r && typeof r == "object" && "slots" in r && r.slots) {
    let n = new Intl.ListFormat().format(Object.keys(r.slots).map((l) => `"${l}"`));
    if (!t && !r.slots[Ie])
      throw new Error(`A slot prop is required. Valid slot names are ${n}.`);
    let a = t || Ie;
    if (!r.slots[a])
      throw new Error(`Invalid slot "${t}". Valid slot names are ${n}.`);
    return r.slots[a];
  }
  return r;
}
function yr(e, t, r) {
  let n = er(r, e.slot) || {}, { ref: a, ...l } = n, s = Ft(Z(() => pt(t, a), [
    t,
    a
  ])), f = Pe(l, e);
  return "style" in l && l.style && "style" in e && e.style && (typeof l.style == "function" || typeof e.style == "function" ? f.style = (d) => {
    let v = typeof l.style == "function" ? l.style(d) : l.style, g = {
      ...d.defaultStyle,
      ...v
    }, H = typeof e.style == "function" ? e.style({
      ...d,
      defaultStyle: g
    }) : e.style;
    return {
      ...g,
      ...H
    };
  } : f.style = {
    ...l.style,
    ...e.style
  }), [
    f,
    s
  ];
}
function mr() {
  let [e, t] = K(!0), r = w(!1), n = h((a) => {
    r.current = !0, t(!!a);
  }, []);
  return U(() => {
    r.current || t(!1);
  }, []), [
    n,
    e
  ];
}
if (typeof HTMLTemplateElement < "u") {
  const e = Object.getOwnPropertyDescriptor(Node.prototype, "firstChild").get;
  Object.defineProperty(HTMLTemplateElement.prototype, "firstChild", {
    configurable: !0,
    enumerable: !0,
    get: function() {
      return this.dataset.reactAriaHidden ? this.content.firstChild : e.call(this);
    }
  });
}
const Te = /* @__PURE__ */ Ae(!1), tr = typeof DocumentFragment < "u" ? new DocumentFragment() : null;
function hr(e) {
  let t = A(Te), r = Ee();
  if (t)
    return /* @__PURE__ */ m.createElement(m.Fragment, null, e.children);
  let n = /* @__PURE__ */ m.createElement(Te.Provider, {
    value: !0
  }, e.children);
  return r ? /* @__PURE__ */ m.createElement("template", {
    "data-react-aria-hidden": !0
  }, n) : /* @__PURE__ */ Ze.createPortal(n, tr);
}
function Tr(e) {
  let t = (r, n) => A(Te) ? null : e(r, n);
  return t.displayName = e.displayName || e.name, m.forwardRef(t);
}
export {
  jt as $,
  Yt as A,
  N as B,
  R as C,
  ar as D,
  dr as E,
  We as F,
  ur as G,
  Re as H,
  Qt as I,
  mr as J,
  Pe as a,
  vr as b,
  kt as c,
  fr as d,
  ir as e,
  xt as f,
  cr as g,
  lr as h,
  yr as i,
  pr as j,
  $r as k,
  br as l,
  ft as m,
  Tr as n,
  hr as o,
  gr as p,
  er as q,
  Ft as r,
  U as s,
  O as t,
  Ct as u,
  M as v,
  E as w,
  Ee as x,
  sr as y,
  _ as z
};
