import Cookies from 'js-cookie';
import type { $TSFixMe } from 'types';
import { getLandingParam } from 'store/useLandingParamsStore';
import { supportApi } from './support-api';
import * as dataLayer from './dataLayer';
import { getLocale } from './site';
import { getAccessToken, getBrowsingSessionId, getUserId } from './session';

type Event =
  | 'viewPage'
  | 'completeRegistration'
  | 'initiateCheckout'
  | 'checkCar'
  | 'lookup'
  | 'experiment'
  | 'startCheckout';
type EventData = Record<string, $TSFixMe>;

// Do not rename this key as it’s used in GA
const EMAIL_KEY = 'userEmail';

const getGaClientId = () => {
  try {
    return window.ga.getAll()[0].get('clientId') as string;
  } catch (err) {
    return undefined;
  }
};

const getFbBrowserId = () => Cookies.get('_fbp');
const getFbClickId = () => Cookies.get('_fbc');

const getFbTrackingParams = () => {
  const browserId = getFbBrowserId();
  if (!browserId) {
    return undefined;
  }
  return {
    browserId,
    clickId: getFbClickId(),
  };
};

const getTikTokCookie = () => Cookies.get('_ttp');
const getTikTokClickId = () => getLandingParam('ttclid');

const getTikTokTrackingParams = () => {
  const ttp = getTikTokCookie();
  const ttclid = getTikTokClickId();
  return ttp || ttclid ? { ttp, ttclid } : undefined;
};

const getTrackingEmail = () => window[EMAIL_KEY];

const setTrackingEmail = (email: string) => {
  if (email) {
    window[EMAIL_KEY] = email;
  }
};

const removeTrackingEmail = () => {
  delete window[EMAIL_KEY];
};

const recordEvent = async (event: Event, data?: EventData, opts?: { serverOnly?: boolean }) => {
  try {
    const eventId = Math.random().toString(36).slice(2, 12);

    if (!opts?.serverOnly) {
      dataLayer.push(event, { eventId });
    }

    await supportApi.post(
      '/recordEvent',
      {
        event,
        eventId,
        trackingId: getBrowsingSessionId(),
        url: window.location.href,
        locale: getLocale(),
        email: getTrackingEmail(),
        userId: getUserId(),
        facebook: getFbTrackingParams(),
        tiktok: getTikTokTrackingParams(),
        ...data,
      },
      {
        headers: { Authorization: `Bearer ${getAccessToken()}` },
      },
    );

    return true;
  } catch (err) {
    return false;
  }
};

export {
  getGaClientId,
  getFbBrowserId,
  getFbClickId,
  getTikTokCookie,
  getTikTokClickId,
  getTrackingEmail,
  setTrackingEmail,
  removeTrackingEmail,
  recordEvent,
};
