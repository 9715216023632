import { j as o } from "./chunks/jsx-runtime.82a9ddf6.js";
import { forwardRef as s } from "react";
import { l as t } from "./chunks/styles.9b2ce419.js";
import { O as i } from "./chunks/OptionBase.e599e44f.js";
import "./chunks/clsx.0d472180.js";
import "./text.mjs";
import "./chunks/cn.2ea4f521.js";
import "./chunks/TypographyElement.fa6e5999.js";
const r = s((e, a) => /* @__PURE__ */ o.jsx(
  i,
  {
    ref: a,
    ...e,
    type: "radio",
    selectionBoxClassName: t.selectionBox,
    selectionIndicator: /* @__PURE__ */ o.jsx("span", { className: t.selectionIndicator })
  }
));
try {
  r.displayName = "Radio", r.__docgenInfo = { description: "", displayName: "Radio", props: { label: { defaultValue: null, description: "", name: "label", required: !0, type: { name: "ReactNode" } }, status: { defaultValue: null, description: "", name: "status", required: !1, type: { name: '"error"' } } } };
} catch {
}
export {
  r as Radio
};
