import { j as n } from "./jsx-runtime.82a9ddf6.js";
import { createContext as a, useId as i, useMemo as c, useContext as d } from "react";
const r = a(void 0), o = ({ children: t }) => {
  const e = i(), s = c(() => ({ id: e }), [e]);
  return /* @__PURE__ */ n.jsx(r.Provider, { value: s, children: t });
}, _ = () => {
  const t = d(r);
  if (t === void 0)
    throw new Error("useTabsContext must be used within a TabsProvider");
  return t;
};
try {
  o.displayName = "TabsProvider", o.__docgenInfo = { description: "", displayName: "TabsProvider", props: {} };
} catch {
}
try {
  Context.displayName = "Context", Context.__docgenInfo = { description: `Context lets components pass information deep down without explicitly
passing props.

Created from {@link createContext}`, displayName: "Context", props: {} };
} catch {
}
export {
  o as T,
  _ as u
};
