import PropTypes from 'prop-types';
import { flow, pickBy, mapKeys } from 'lodash';
import { Component } from 'react';
import withRouter from '../../hoc/withRouter';
import { init as initSite } from '../../services/site';
import withRouteData from '../../hoc/withRouteData';

const EXPERIMENT_PARAM_PATTERN = /^e\((.+?)\)$/;
class SiteProvider extends Component {
  constructor(props) {
    super(props);

    const { locale, basePath, market } = props.routeData;

    initSite({
      locale,
      basePath,
      market,
      experiments: extractExperiments(props.router.query),
    });
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

function extractExperiments(params) {
  return mapKeys(
    pickBy(params, (_, key) => key.match(EXPERIMENT_PARAM_PATTERN)),
    (_, key) => key.match(EXPERIMENT_PARAM_PATTERN)[1],
  );
}

SiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
  routeData: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    basePath: PropTypes.string.isRequired,
    market: PropTypes.shape().isRequired,
  }).isRequired,
  router: PropTypes.shape().isRequired,
};

export default flow(withRouteData, withRouter)(SiteProvider);
