const EUR = 'EUR';
const USD = 'USD';
const RON = 'RON';
const PLN = 'PLN';
const HUF = 'HUF';
const LTL = 'LTL';
const UAH = 'UAH';
const CZK = 'CZK';
const SEK = 'SEK';
const HRK = 'HRK';
const BGN = 'BGN';
const RSD = 'RSD';
const BYN = 'BYN';
const RUB = 'RUB';
const DKK = 'DKK';
const GBP = 'GBP';
const AUD = 'AUD';
const MXN = 'MXN';
const BRL = 'BRL';
const ZAR = 'ZAR';
const GEL = 'GEL';
const CAD = 'CAD';
const AED = 'AED';

// crypto
const CV = 'CV';
const ETH = 'ETH';

const CRYPTO_SYMBOLS = {
  [CV]: 'cV',
  [ETH]: 'Ξ',
};

const PRECISION = {
  [CV]: 0,
  [ETH]: 5,
};

export default [
  EUR,
  USD,
  RON,
  PLN,
  HUF,
  LTL,
  UAH,
  CZK,
  SEK,
  HRK,
  BGN,
  RSD,
  BYN,
  RUB,
  DKK,
  GBP,
  AUD,
  MXN,
  BRL,
  ZAR,
  GEL,
  CAD,
  AED,
  CV,
  ETH,
];

export {
  EUR,
  USD,
  RON,
  PLN,
  HUF,
  LTL,
  UAH,
  CZK,
  SEK,
  HRK,
  BGN,
  RSD,
  BYN,
  RUB,
  DKK,
  GBP,
  AUD,
  MXN,
  BRL,
  ZAR,
  GEL,
  CAD,
  AED,
  CV,
  ETH,
  CRYPTO_SYMBOLS,
  PRECISION,
};
