import { Icon, Text } from '@carvertical/ui';
import cx from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { useMode } from 'context/ModeProvider';
import { useRouteData } from 'context/RouteDataProvider';
import { useSupportPopup } from 'modules/support/hooks';
import { useModal } from 'modules/shared/hooks';
import { SUPPORT_POPUP_ID } from '../constants';
import mascotsAvatarUrl from '../assets/mascots.png';
import { SupportEmail } from './SupportEmail';
import styles from './SupportToggle.module.scss';

const FloatingWhatsApp = dynamic(() =>
  import('react-floating-whatsapp').then((module) => module.FloatingWhatsApp),
);

const ID = 'support-toggle';
const EXCLUDED_PAGES = ['precheck', 'vin'];
const WHATS_APP_PHONE_NUMBER = '37066180610';
const WHATS_APP_ACCOUNT_NAME = 'carVertical';

const SupportToggle = () => {
  const { t } = useTranslation(['common', 'business']);
  const { page } = useRouteData();
  const { asPath } = useRouter();

  const { isSite } = useMode();
  const [expanded, setExpanded] = useState(false);
  const { open: supportPopupOpen, closeSupportPopup, hidden } = useSupportPopup();
  const { isModalOpen: marketSelectionModalOpen } = useModal('marketSelection');

  const label = t('general.contactUsAction');

  const expand = () => setExpanded(true);
  const collapse = () => setExpanded(false);

  if (marketSelectionModalOpen || !isSite || EXCLUDED_PAGES.includes(page?.id ?? '')) {
    return null;
  }

  if (page?.id === 'business' || asPath.includes('business/campaign')) {
    return (
      <FloatingWhatsApp
        phoneNumber={WHATS_APP_PHONE_NUMBER}
        accountName={WHATS_APP_ACCOUNT_NAME}
        placeholder={t('business:whatsAppFloatingModal.placeholder')}
        chatMessage={t('business:whatsAppFloatingModal.chatMessage')}
        statusMessage={t('business:whatsAppFloatingModal.statusMessage')}
        onClick={closeSupportPopup}
        avatar={mascotsAvatarUrl}
        allowClickAway
        chatboxClassName="!h-auto"
      />
    );
  }

  return (
    <SupportEmail
      as="button"
      aria-expanded={supportPopupOpen}
      aria-controls={SUPPORT_POPUP_ID}
      aria-label={!expanded ? label : undefined}
      className={cx(styles.root, { '!hidden': hidden })}
      id={ID}
      onMouseEnter={expand}
      onMouseLeave={() => {
        if (document.activeElement && document.activeElement.id !== ID) {
          collapse();
        }
      }}
      onFocus={expand}
      onBlur={collapse}
    >
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.span
            initial="hidden"
            animate="visible"
            exit="hidden"
            layout="size"
            variants={{
              hidden: { opacity: 0, width: 0 },
              visible: {
                opacity: 1,
                width: 'auto',
                transition: { type: 'spring', bounce: 0.25, duration: 0.6 },
              },
            }}
          >
            <Text className={styles.text} variant="s+" textColor="light">
              {label}
            </Text>
          </motion.span>
        )}
      </AnimatePresence>

      <Icon className={styles.icon} name="contact" />
    </SupportEmail>
  );
};

export { SupportToggle };
