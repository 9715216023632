import cx from 'classnames';
import { iconMap, type IconMapMember } from './icon-map.generated';
import styles from './Icon.module.scss';

type SVGElementProps = React.HTMLAttributes<SVGElement>;

type IconSize = 'xs' | 's' | 'm' | 'l';

type IconProps = {
  name: IconMapMember;
  size?: IconSize;
} & SVGElementProps;

/**
 * @deprecated Use Icon from `@carvertical/ui` instead.
 */
const Icon = ({ className, name, size = 'm', ...props }: React.PropsWithChildren<IconProps>) => {
  const IconComponent = iconMap[name];

  return (
    <IconComponent
      original
      className={cx(styles.root, styles[size], className)}
      aria-hidden
      focusable={false}
      {...props}
    />
  );
};

export { Icon };
export type { IconProps, IconSize };
