import { useEffect } from 'react';
import { useMeasure } from 'react-use';
import { useHeader } from './HeaderProvider';
import { VoucherBar } from './VoucherBar';

type HeaderControllerProps = {
  header?: React.ReactNode;
};

const HeaderController = ({ header }: HeaderControllerProps) => {
  const [voucherBarRef, { height: voucherBarHeight }] = useMeasure<HTMLDivElement>();
  const [headerRef, { height: headerHeight }] = useMeasure<HTMLDivElement>();

  const { height, setHeight, setPageOffset } = useHeader();

  useEffect(() => {
    if (header) {
      setHeight(headerHeight);
    }
  }, [header, setHeight, headerHeight]);

  useEffect(() => {
    const totalHeight = height + voucherBarHeight;

    setPageOffset(totalHeight);
  }, [height, voucherBarHeight, setPageOffset]);

  return (
    <>
      <div ref={voucherBarRef}>
        <VoucherBar />
      </div>
      <div ref={headerRef}>{header}</div>
    </>
  );
};

export { HeaderController };
export type { HeaderControllerProps };
