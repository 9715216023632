import ACT from './act.svg';
import NSW from './nsw.svg';
import NT from './nt.svg';
import QLD from './qld.svg';
import SA from './sa.svg';
import TAS from './tas.svg';
import VIC from './vic.svg';
import WA from './wa.svg';

export { ACT, NSW, NT, QLD, SA, TAS, VIC, WA };
