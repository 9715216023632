import { forwardRef, type PropsWithChildren } from 'react';
import cx from 'classnames';
import { theme } from '@carvertical/ui';
import type { ThemeColor } from 'types/color';
import styles from './Section.module.scss';

type SectionProps = PropsWithChildren<{
  id?: string;
  color?: ThemeColor;
  className?: string;
  variant?: 'l' | 's';
}>;

const Section = forwardRef<HTMLElement, SectionProps>(
  ({ children, className, color = 'colorWhite', id, variant = 'l' }, ref) => (
    <section
      ref={ref}
      className={cx(styles.root, styles[variant], className)}
      id={id}
      data-testid={id}
      style={{
        '--backgroundColor': color === 'transparent' ? 'transparent' : theme[color],
      }}
    >
      {children}
    </section>
  ),
);

export { Section };
export type { SectionProps };
