import { captureError } from '../errors';
import { supportApi } from './base';

const isValidVatCode = async ({
  countryCode,
  vatCode,
}: {
  vatCode: string;
  countryCode: string;
}): Promise<boolean> => {
  let valid = false;
  try {
    ({
      data: { valid },
    } = await supportApi.post('/check-vat-code', {
      countryCode,
      vatCode,
    }));
  } catch (err) {
    captureError(err);
  }
  return valid;
};

export { isValidVatCode };
