enum Region {
  Europe = 'europe',
  NorthAmerica = 'northAmerica',
  SouthAmerica = 'southAmerica',
  MiddleEast = 'middleEast',
  Oceania = 'oceania',
  Africa = 'africa',
}

export { Region };
