import addDays from 'date-fns/add_days';
import { each, split, startsWith, isNil, memoize, omitBy } from 'lodash';
import store, { STORE_PREFIX } from './store';

type ExperimentValue = boolean | string;
type OngoingExperiments = Record<string, ExperimentValue>;
type Experiment = {
  value: ExperimentValue;
  expires: string;
};

const STORE_KEY = 'e'; // for “experiments”
const EXPERIMENT_RUNNING_DAYS = 30;

const isExperimentValue = (value: unknown): value is ExperimentValue =>
  typeof value === 'boolean' || typeof value === 'string';

const isExperiment = (value: unknown): value is Experiment =>
  !!(value && !isNil((value as Experiment).value) && !isNil((value as Experiment).expires));

const getExperiment = memoize((experiment: string): ExperimentValue | null => {
  const value: string | null = store.get(`${STORE_KEY}:${experiment}`);
  return value === '' ? true : value;
});

const runExperiments = (experiments: Record<string, unknown>) =>
  each(experiments, (value, key) => {
    if (isExperimentValue(value)) {
      store.set(`${STORE_KEY}:${key}`, value, {
        ttl: addDays(new Date(), EXPERIMENT_RUNNING_DAYS),
      });
    }
  });

const getOngoingExperiments = () =>
  Object.keys(store.storage)
    .filter((key) => startsWith(key, `${STORE_PREFIX}${STORE_KEY}:`))
    .reduce<OngoingExperiments>((experiments, key) => {
      const [, , name] = split(key, ':');

      if (name) {
        const value = getExperiment(name);
        if (!isNil(value)) {
          experiments[name] = value;
        }
      }

      return experiments;
    }, {});

const removeLegacyExperiments = () => {
  const experiments: Record<string, unknown> = omitBy(
    store.getAll(),
    (_, key) => !key.startsWith(`${STORE_PREFIX}${STORE_KEY}:`),
  );

  each(experiments, (value, key) => {
    if (!isExperiment(value)) {
      store.storage.removeItem(key);
    }
  });
};

export {
  isExperimentValue,
  isExperiment,
  getExperiment,
  runExperiments,
  getOngoingExperiments,
  removeLegacyExperiments,
  EXPERIMENT_RUNNING_DAYS,
};
