var i = /* @__PURE__ */ ((e) => (e.Hidden = "hidden", e.Visible = "visible", e))(i || {});
const s = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
}, A = {
  hidden: { scale: 0 },
  visible: { scale: 1 }
}, c = {
  hidden: { scale: 0.5, opacity: 0 },
  visible: { scale: 1, opacity: 1 }
};
export {
  i as A,
  s as F,
  c as M,
  A as S
};
