import type { MarketId } from 'types/market';

const POLICY_UPDATE_DATE = new Date('2024-02-06');

const TRUST_BADGE_LABEL = 'Trust Badge';

const TRUST_BADGE_MARKETS: MarketId[] = [
  'bulgaria',
  'czechia',
  'estonia',
  'france',
  'croatia',
  'hungary',
  'italy',
  'lithuania',
  'latvia',
  'poland',
  'romania',
];

export { TRUST_BADGE_LABEL, TRUST_BADGE_MARKETS, POLICY_UPDATE_DATE };
