import { uniqueId } from 'lodash';
import { useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { recaptchaConfig } from 'config';
import { load } from 'utils/script';
import { getLanguage } from 'utils/locale';
import { poller } from 'utils/poller';
import { getLocale } from 'services/site';
import { useFeatureFlagEnabled } from 'modules/analytics';

const SCRIPT_URL = 'https://www.google.com/recaptcha/api.js';
const TOKEN_TIMEOUT = 2 * 60 * 1_000;
const TOKEN_INTERVAL = 500;

const useRecaptchaQuery = () =>
  useQuery<ReCaptchaV2.ReCaptcha>({
    enabled: useFeatureFlagEnabled('captcha'),
    queryKey: ['recaptcha'],
    queryFn: async () => {
      const language = getLanguage(getLocale());
      await load(`${SCRIPT_URL}?render=explicit&hl=${language}`, 'recaptcha');
      return new Promise((resolve) => {
        window.grecaptcha.ready(() => resolve(window.grecaptcha));
      });
    },
    staleTime: Infinity,
  });

const useRecaptcha = () => {
  const widgetRef = useRef<number>();
  const challengeVisibilityRef = useRef(false);
  const { data: recaptcha } = useRecaptchaQuery();

  useEffect(() => {
    if (!recaptcha) {
      return undefined;
    }

    const container = document.createElement('div');
    container.id = uniqueId('recaptcha-');
    document.body.appendChild(container);

    widgetRef.current = recaptcha.render(container.id, {
      sitekey: recaptchaConfig.invisibleV2Key,
      size: 'invisible',
      badge: 'bottomleft',
    });

    return () => {
      try {
        recaptcha.reset(widgetRef.current);
      } catch (err) {} // eslint-disable-line no-empty
      document.body.removeChild(container);
    };
  }, [recaptcha]);

  const wasChallengeClosed = () => {
    const iframe = document.querySelector('iframe[src*="recaptcha/api2/bframe"]');
    const wrapper = iframe?.parentNode?.parentNode;
    if (!wrapper || !isDivElement(wrapper)) {
      return false;
    }

    const visible = wrapper.style.visibility === 'visible';
    const wasVisible = challengeVisibilityRef.current;
    challengeVisibilityRef.current = visible;

    return !visible && wasVisible;
  };

  return {
    getCaptchaResponse: async () => {
      if (!recaptcha) {
        return undefined;
      }

      const widgetId = widgetRef.current;

      if (recaptcha?.getResponse()) {
        challengeVisibilityRef.current = false;
        recaptcha?.reset(widgetId);
      }

      const token = (await recaptcha?.execute(widgetId)) as string | undefined;

      if (token) {
        return token;
      }

      const { passed, result } = await poller({
        retries: TOKEN_TIMEOUT / TOKEN_INTERVAL,
        interval: TOKEN_INTERVAL,
        execute: () => recaptcha?.getResponse(),
        until: (response) => !!response || wasChallengeClosed(),
      });

      if (!passed) {
        recaptcha?.reset(widgetId);
      }

      return result;
    },
  };
};

function isDivElement(element: Node): element is HTMLDivElement {
  return 'tagName' in element && element.tagName === 'DIV';
}

export { useRecaptcha };
