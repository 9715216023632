import { type RefinementCtx, z } from 'zod';
import { PHONE, NUMBER, EU_VAT_CODE } from 'constants/validationPatterns';
import { LP, VIN } from 'constants/identifiers';
import { ERROR_TRANSLATION_KEYS } from 'constants/i18n';
import type { Market } from 'types/market';
import type { Mode } from 'types/identifier';

type ValidateIdentifierProps = {
  identifier: Market['identifier'];
  refinementContext: RefinementCtx;
  value?: string;
  mode?: Mode;
  path?: string;
};

const VALID_VIN_LENGTH = 17;
const YEAR_MIN = 1900;
const POWER_MIN = 1;
const POWER_MAX = 1000;
const ODOMETER_RANGE = [1, 1000000];

const setIdentifierError = (refinementContext: RefinementCtx, message: string, path: string) => {
  refinementContext?.addIssue({
    code: z.ZodIssueCode.custom,
    message,
    path: [path],
  });
};

const validateIdentifier = ({
  value,
  identifier,
  refinementContext,
  mode = 'vin',
  path = VIN,
}: ValidateIdentifierProps) => {
  const vinRegExp = new RegExp(identifier.vin, 'i');
  const lpRegExp = new RegExp(identifier.lp?.pattern || '', 'i');

  if (!value) {
    setIdentifierError(refinementContext, ERROR_TRANSLATION_KEYS.required, path);
    return;
  }

  if (mode === LP && ![vinRegExp, lpRegExp].some((regExp) => regExp.test(value))) {
    setIdentifierError(refinementContext, ERROR_TRANSLATION_KEYS.vinOrLp, path);
  }

  if (mode === VIN) {
    const getErrorKey = () => {
      if (value.length < VALID_VIN_LENGTH) {
        return ERROR_TRANSLATION_KEYS.vinShort;
      }
      if (value.length > VALID_VIN_LENGTH) {
        return ERROR_TRANSLATION_KEYS.vinLong;
      }
      if (!vinRegExp.test(value)) {
        return ERROR_TRANSLATION_KEYS.vinType;
      }
      return null;
    };

    const errorKey = getErrorKey();
    if (errorKey) {
      setIdentifierError(refinementContext, errorKey, path);
    }
  }
};

const validatePhone = (value?: string) => !value || new RegExp(PHONE).test(value);

const validateNumber = (value: string) => new RegExp(NUMBER).test(value);

const validateYear = (value: number) =>
  !value || (value >= YEAR_MIN && value <= new Date().getFullYear());

const validatePower = (value: number) => !value || (value >= POWER_MIN && value <= POWER_MAX);

const validateOdometer = (value: number) =>
  !value || (value >= ODOMETER_RANGE[0] && value <= ODOMETER_RANGE[1]);

const validateVatCode = (value?: string) => !value || value?.match(new RegExp(EU_VAT_CODE));

export {
  validatePhone,
  validateNumber,
  validateIdentifier,
  validateYear,
  validatePower,
  validateOdometer,
  validateVatCode,
};
