import PropTypes from 'prop-types';
import Component from '@reactions/component';
import { Subscribe } from 'unstated';
import withRouteData from '../../hoc/withRouteData';
import UserContainer from '../../containers/UserContainer';
import AuthContainer from '../../containers/AuthContainer';

const AuthHandler = ({ children, routeData: { market } }) => (
  <Subscribe to={[AuthContainer, UserContainer]}>
    {(auth, user) => (
      <Component
        authenticated={auth.state.authenticated}
        user={user.state}
        userId={auth.state.userId}
        fetchUser={() => {
          user.fetch(!auth.state.userId && auth.state.guestId, {
            products: market.products,
          });
        }}
        didMount={({ props }) => {
          auth.init();
          if (props.authenticated) {
            props.fetchUser();
          }
        }}
        didUpdate={({ props, prevProps }) => {
          if (props.authenticated && !prevProps.authenticated) {
            props.fetchUser();
          }
          if (props.user.id !== prevProps.user.id) {
            auth.setUserId(props.user.id);
          }
        }}
      >
        {children}
      </Component>
    )}
  </Subscribe>
);

AuthHandler.propTypes = {
  children: PropTypes.node.isRequired,
  routeData: PropTypes.shape({
    market: PropTypes.shape().isRequired,
  }).isRequired,
};

export default withRouteData(AuthHandler);
