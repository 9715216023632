import { useQuery } from '@tanstack/react-query';
import { getUserQuotas } from '../api';

type UseUserQuotasProps = {
  productId: number;
};

const USER_QUOTAS_QUERY_KEY = {
  shared: ['quotas'],
  personal: (productId: number) => ['quotas', productId],
};

const useUserQuotas = ({ productId }: UseUserQuotasProps) => {
  const { data, isLoading } = useQuery({
    queryKey: USER_QUOTAS_QUERY_KEY.personal(productId),
    queryFn: () => getUserQuotas(productId),
  });

  const quotas = data?.quotas[productId] || 0;

  return {
    data: quotas,
    quotasLoading: isLoading,
  };
};

export { useUserQuotas, USER_QUOTAS_QUERY_KEY };
export type { UseUserQuotasProps };
