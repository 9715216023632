import { Button } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { useRouteData } from 'context/RouteDataProvider';
import { NO_VIN_PRECHECK_URL } from 'constants/links';
import { beautifyPath, getRoute } from 'utils/router';
import type { SurroundingBackground } from './types';
import styles from './ContinueWithoutVinCta.module.scss';

type ContinueWithoutVinCtaProps = {
  disabled?: boolean;
  surroundingBackground?: SurroundingBackground;
  ctaLabel?: string;
};

const ContinueWithoutVinCta = ({
  disabled,
  surroundingBackground = 'mediumDark',
  ctaLabel,
}: ContinueWithoutVinCtaProps) => {
  const { t } = useTranslation();
  const { basePath } = useRouteData();

  const [, href] = getRoute(NO_VIN_PRECHECK_URL, undefined, basePath);

  return (
    <Button
      href={!disabled ? beautifyPath(href) : undefined}
      variant="outlined"
      disabled={disabled}
      size="m"
      className={cx(
        styles.root,
        disabled && styles.disabled,
        styles[surroundingBackground],
        'text-center',
      )}
      trailingIcon="arrow-right"
    >
      {ctaLabel || t('general.continueWithoutVin')}
    </Button>
  );
};

export { ContinueWithoutVinCta };
