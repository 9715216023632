import { useEffect } from 'react';
import { once } from 'lodash';
import * as dataLayer from '../../services/dataLayer';
import { useUserInteraction } from '../../context/UserInteractionProvider';

const fireEvent = once(() => dataLayer.push('loadThirdPartyScripts'));

const ThirdPartyScriptLoader = () => {
  const userInteraction = useUserInteraction();

  useEffect(() => {
    if (userInteraction) {
      fireEvent();
    }
  }, [userInteraction]);

  return null;
};

export default ThirdPartyScriptLoader;
