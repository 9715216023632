import type { ParsedUrlQuery } from 'querystring';
import { isMatch } from 'lodash';
import { siteConfig } from 'config';
import type { QueryParam } from 'constants/queryParams';
import { beautifyPath } from './router';

const getEmailFromQuery = (query: ParsedUrlQuery) => {
  let { email } = query;
  const { ee: encodedEmail } = query;
  if (encodedEmail) {
    try {
      email = window.atob(encodedEmail as string);
    } catch (err) {} // eslint-disable-line no-empty
  }
  return email;
};

const getURL = (path: string) => `${siteConfig.siteRoot}${beautifyPath(path)}`;

const isQueryMatchingParam = (query: ParsedUrlQuery, param: QueryParam) => {
  const { name, value } = param;

  const entries = typeof value === 'object' ? Object.values(value) : [value];

  return entries.some((entry) => isMatch(query, { [name]: entry }));
};

const sanitizeQueryParams = (query: ParsedUrlQuery) => {
  // Country and language are used within SSR context, but they are not
  // needed in the client-side context.
  const { country, language, ...queryParams } = query;

  return queryParams;
};

export { getEmailFromQuery, getURL, isQueryMatchingParam, sanitizeQueryParams };
