import { useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Button, Stack, Text } from '@carvertical/ui';
import { AnimatePresence, motion } from 'framer-motion';
import { Icon } from 'components/common/Icon';
import { TOP_QUESTIONS } from '../constants';
import styles from './QuestionList.module.scss';

type QuestionListProps = {
  onContactUs: () => void;
};

const TRANSITION_CONFIG = {
  duration: 0.1,
  type: 'spring',
  bounce: 0,
};

const ANIMATION_VARIANTS = {
  visible: { height: 'auto' },
  hidden: { height: 0 },
};

const QuestionList = ({ onContactUs }: QuestionListProps) => {
  const { t } = useTranslation();
  const [expandedSection, setExpandedSection] = useState<string | null>();
  const listItemRefs = useRef<Record<string, HTMLLIElement>>({});

  const toggle = (key: string) => {
    setExpandedSection(expandedSection === key ? null : key);
  };

  const scrollToSection = (key: string) => {
    listItemRefs.current[key].scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Stack className={styles.root}>
      <Text variant="xs">{t('supportModal.topResultsLabel')}</Text>

      <Stack as="ul" gap={2} className={styles.list} crossAxisAlign="stretch">
        {TOP_QUESTIONS.map(({ descriptionKey, titleKey }) => (
          <Stack
            as="li"
            gap={0}
            crossAxisAlign="stretch"
            key={titleKey}
            ref={(listElement: HTMLLIElement) => {
              if (listElement) {
                listItemRefs.current[titleKey] = listElement;
              }
            }}
          >
            <button
              type="button"
              className={cx(
                styles.content,
                styles.listItemButton,
                expandedSection === titleKey && styles.opened,
              )}
              onClick={() => toggle(titleKey)}
            >
              <span className={styles.iconWrapper}>
                <Icon name="book" size="s" />
              </span>
              <Text as="span" variant="s+" align="left">
                {t(titleKey)}
              </Text>
            </button>

            <AnimatePresence>
              {expandedSection === titleKey && (
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={ANIMATION_VARIANTS}
                  transition={TRANSITION_CONFIG}
                  className={cx(styles.content, styles.descriptionWrapper)}
                  onAnimationComplete={(definition) => {
                    if (definition === 'visible') {
                      scrollToSection(titleKey);
                    }
                  }}
                >
                  <Text variant="s">{t(descriptionKey)}</Text>
                </motion.div>
              )}
            </AnimatePresence>
          </Stack>
        ))}
      </Stack>

      <Stack className={styles.buttonWrapper}>
        <Button trailingIcon="arrow-right" variant="outlined" fullWidth onClick={onContactUs}>
          {t('general.contactUsAction')}
        </Button>
      </Stack>
    </Stack>
  );
};

export { QuestionList };
export type { QuestionListProps };
