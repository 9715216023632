import type { ParsedUrlQuery } from 'querystring';
import { useEffect } from 'react';
import withRouter from 'hoc/withRouter';
import { useLandingParamsStore } from 'store/useLandingParamsStore';

type LandingParamsProviderProps = React.PropsWithChildren<{
  router: {
    query: ParsedUrlQuery;
  };
}>;

const LandingParamsProvider = withRouter(({ router: { query } }: LandingParamsProviderProps) => {
  const { setParams } = useLandingParamsStore();

  useEffect(() => {
    setParams(query);
  }, [query, setParams]);

  return null;
});

export { LandingParamsProvider };
export type { LandingParamsProviderProps };
